export type Country = {
  name: string;
  code: string;
}

interface Nationality {
  [key: string]: {
    m: string,
    f: string
  }
}

const countriesValues = [
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mr.png",
      "svg": "https://flagcdn.com/mr.svg"
    },
    "cca2": "MR",
    "capital": [
      "Nouakchott"
    ],
    "altSpellings": [
      "MR",
      "Islamic Republic of Mauritania",
      "al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية الإسلامية الموريتانية",
        "common": "موريتانيا"
      },
      "bre": {
        "official": "Republik islamek Maouritania",
        "common": "Maouritania"
      },
      "ces": {
        "official": "Mauritánská islámská republika",
        "common": "Mauritánie"
      },
      "cym": {
        "official": "Islamic Republic of Mauritania",
        "common": "Mauritania"
      },
      "deu": {
        "official": "Islamische Republik Mauretanien",
        "common": "Mauretanien"
      },
      "est": {
        "official": "Mauritaania Islamivabariik",
        "common": "Mauritaania"
      },
      "fin": {
        "official": "Mauritanian islamilainen tasavalta",
        "common": "Mauritania"
      },
      "fra": {
        "official": "République islamique de Mauritanie",
        "common": "Mauritanie"
      },
      "hrv": {
        "official": "Islamska Republika Mauritanija",
        "common": "Mauritanija"
      },
      "hun": {
        "official": "Mauritániai Iszlám Köztársaság",
        "common": "Mauritánia"
      },
      "ita": {
        "official": "Repubblica islamica di Mauritania",
        "common": "Mauritania"
      },
      "jpn": {
        "official": "モーリタニア·イスラム共和国",
        "common": "モーリタニア"
      },
      "kor": {
        "official": "모리타니 이슬람 공화국",
        "common": "모리타니"
      },
      "nld": {
        "official": "Islamitische Republiek Mauritanië",
        "common": "Mauritanië"
      },
      "per": {
        "official": "جمهوری اسلامی موریتانی",
        "common": "موریتانی"
      },
      "pol": {
        "official": "Islamska Republika Mauretańska",
        "common": "Mauretania"
      },
      "por": {
        "official": "República Islâmica da Mauritânia",
        "common": "Mauritânia"
      },
      "rus": {
        "official": "Исламская Республика Мавритания",
        "common": "Мавритания"
      },
      "slk": {
        "official": "Mauritánska islamská republika",
        "common": "Mauritánia"
      },
      "spa": {
        "official": "República Islámica de Mauritania",
        "common": "Mauritania"
      },
      "swe": {
        "official": "Islamiska republiken Mauretanien",
        "common": "Mauretanien"
      },
      "tur": {
        "official": "Moritanya İslam Cumhuriyeti",
        "common": "Moritanya"
      },
      "urd": {
        "official": "اسلامی جمہوریہ موریتانیہ",
        "common": "موریتانیہ"
      },
      "zho": {
        "official": "毛里塔尼亚伊斯兰共和国",
        "common": "毛里塔尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mauritanian",
        "m": "Mauritanian"
      },
      "fra": {
        "f": "Mauritanienne",
        "m": "Mauritanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/aw.png",
      "svg": "https://flagcdn.com/aw.svg"
    },
    "cca2": "AW",
    "capital": [
      "Oranjestad"
    ],
    "altSpellings": [
      "AW"
    ],
    "translations": {
      "ara": {
        "official": "أروبا",
        "common": "أروبا"
      },
      "bre": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "ces": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "cym": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "deu": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "est": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "fin": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "fra": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "hrv": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "hun": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "ita": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "jpn": {
        "official": "アルバ",
        "common": "アルバ"
      },
      "kor": {
        "official": "아루바",
        "common": "아루바"
      },
      "nld": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "per": {
        "official": "آروبا",
        "common": "آروبا"
      },
      "pol": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "por": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "rus": {
        "official": "Аруба",
        "common": "Аруба"
      },
      "slk": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "spa": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "swe": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "tur": {
        "official": "Aruba",
        "common": "Aruba"
      },
      "urd": {
        "official": "اروبا",
        "common": "اروبا"
      },
      "zho": {
        "official": "阿鲁巴",
        "common": "阿鲁巴"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Aruban",
        "m": "Aruban"
      },
      "fra": {
        "f": "Arubaise",
        "m": "Arubais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ar.png",
      "svg": "https://flagcdn.com/ar.svg"
    },
    "cca2": "AR",
    "capital": [
      "Buenos Aires"
    ],
    "altSpellings": [
      "AR",
      "Argentine Republic",
      "República Argentina"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الأرجنتين",
        "common": "الأرجنتين"
      },
      "bre": {
        "official": "Republik Arc'hantina",
        "common": "Arc'hantina"
      },
      "ces": {
        "official": "Argentinská republika",
        "common": "Argentina"
      },
      "cym": {
        "official": "Gweriniaeth yr Ariannin",
        "common": "Ariannin"
      },
      "deu": {
        "official": "Argentinische Republik",
        "common": "Argentinien"
      },
      "est": {
        "official": "Argentina Vabariik",
        "common": "Argentina"
      },
      "fin": {
        "official": "Argentiinan tasavalta",
        "common": "Argentiina"
      },
      "fra": {
        "official": "République argentine",
        "common": "Argentine"
      },
      "hrv": {
        "official": "Argentinski Republika",
        "common": "Argentina"
      },
      "hun": {
        "official": "Argentin Köztársaság",
        "common": "Argentína"
      },
      "ita": {
        "official": "Repubblica Argentina",
        "common": "Argentina"
      },
      "jpn": {
        "official": "アルゼンチン共和国",
        "common": "アルゼンチン"
      },
      "kor": {
        "official": "아르헨티나 공화국",
        "common": "아르헨티나"
      },
      "nld": {
        "official": "Argentijnse Republiek",
        "common": "Argentinië"
      },
      "per": {
        "official": "جمهوری آرژانتین",
        "common": "آرژانتین"
      },
      "pol": {
        "official": "Republika Argentyńska",
        "common": "Argentyna"
      },
      "por": {
        "official": "República Argentina",
        "common": "Argentina"
      },
      "rus": {
        "official": "Аргентинская Республика",
        "common": "Аргентина"
      },
      "slk": {
        "official": "Argentínska republika",
        "common": "Argentína"
      },
      "spa": {
        "official": "República Argentina",
        "common": "Argentina"
      },
      "swe": {
        "official": "Republiken Argentina",
        "common": "Argentina"
      },
      "tur": {
        "official": "Arjantin Cumhuriyeti",
        "common": "Arjantin"
      },
      "urd": {
        "official": "جمہوریہ ارجنٹائن",
        "common": "ارجنٹائن"
      },
      "zho": {
        "official": "阿根廷共和国",
        "common": "阿根廷"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Argentine",
        "m": "Argentine"
      },
      "fra": {
        "f": "Argentine",
        "m": "Argentin"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/se.png",
      "svg": "https://flagcdn.com/se.svg"
    },
    "cca2": "SE",
    "capital": [
      "Stockholm"
    ],
    "altSpellings": [
      "SE",
      "Kingdom of Sweden",
      "Konungariket Sverige"
    ],
    "translations": {
      "ara": {
        "official": "مملكة السويد",
        "common": "السويد"
      },
      "bre": {
        "official": "Rouantelezh Sveden",
        "common": "Sveden"
      },
      "ces": {
        "official": "Švédské království",
        "common": "Švédsko"
      },
      "cym": {
        "official": "Kingdom of Sweden",
        "common": "Sweden"
      },
      "deu": {
        "official": "Königreich Schweden",
        "common": "Schweden"
      },
      "est": {
        "official": "Rootsi Kuningriik",
        "common": "Rootsi"
      },
      "fin": {
        "official": "Ruotsin kuningaskunta",
        "common": "Ruotsi"
      },
      "fra": {
        "official": "Royaume de Suède",
        "common": "Suède"
      },
      "hrv": {
        "official": "Kraljevina Švedska",
        "common": "Švedska"
      },
      "hun": {
        "official": "Svéd Királyság",
        "common": "Svédország"
      },
      "ita": {
        "official": "Regno di Svezia",
        "common": "Svezia"
      },
      "jpn": {
        "official": "スウェーデン王国",
        "common": "スウェーデン"
      },
      "kor": {
        "official": "스웨덴 왕국",
        "common": "스웨덴"
      },
      "nld": {
        "official": "Koninkrijk Zweden",
        "common": "Zweden"
      },
      "per": {
        "official": "پادشاهی سوئد",
        "common": "سوئد"
      },
      "pol": {
        "official": "Królestwo Szwecji",
        "common": "Szwecja"
      },
      "por": {
        "official": "Reino da Suécia",
        "common": "Suécia"
      },
      "rus": {
        "official": "Королевство Швеция",
        "common": "Швеция"
      },
      "slk": {
        "official": "Švédske kráľovstvo",
        "common": "Švédsko"
      },
      "spa": {
        "official": "Reino de Suecia",
        "common": "Suecia"
      },
      "swe": {
        "official": "Konungariket Sverige",
        "common": "Sverige"
      },
      "tur": {
        "official": "İsveç Krallığı",
        "common": "İsveç"
      },
      "urd": {
        "official": "مملکتِ سویڈن",
        "common": "سویڈن"
      },
      "zho": {
        "official": "瑞典王国",
        "common": "瑞典"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Swedish",
        "m": "Swedish"
      },
      "fra": {
        "f": "Suédoise",
        "m": "Suédois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mv.png",
      "svg": "https://flagcdn.com/mv.svg"
    },
    "cca2": "MV",
    "capital": [
      "Malé"
    ],
    "altSpellings": [
      "MV",
      "Maldive Islands",
      "Republic of the Maldives",
      "Dhivehi Raajjeyge Jumhooriyya"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية المالديف",
        "common": "المالديف"
      },
      "bre": {
        "official": "Republik Maldivez",
        "common": "Maldivez"
      },
      "ces": {
        "official": "Maledivská republika",
        "common": "Maledivy"
      },
      "cym": {
        "official": "Republic of the Maldives",
        "common": "Maldives"
      },
      "deu": {
        "official": "Republik Malediven",
        "common": "Malediven"
      },
      "est": {
        "official": "Maldiivi Vabariik",
        "common": "Maldiivid"
      },
      "fin": {
        "official": "Malediivien tasavalta",
        "common": "Malediivit"
      },
      "fra": {
        "official": "République des Maldives",
        "common": "Maldives"
      },
      "hrv": {
        "official": "Republika Maldivi",
        "common": "Maldivi"
      },
      "hun": {
        "official": "Maldív-szigetek",
        "common": "Maldív-szigetek"
      },
      "ita": {
        "official": "Repubblica delle Maldive",
        "common": "Maldive"
      },
      "jpn": {
        "official": "モルディブ共和国",
        "common": "モルディブ"
      },
      "kor": {
        "official": "몰디브 공화국",
        "common": "몰디브"
      },
      "nld": {
        "official": "Republiek van de Malediven",
        "common": "Maldiven"
      },
      "per": {
        "official": "جمهوری مالدیو",
        "common": "مالدیو"
      },
      "pol": {
        "official": "Republika Malediwów",
        "common": "Malediwy"
      },
      "por": {
        "official": "República das Maldivas",
        "common": "Maldivas"
      },
      "rus": {
        "official": "Республика Мальдивы",
        "common": "Мальдивы"
      },
      "slk": {
        "official": "Maldivská republika",
        "common": "Maldivy"
      },
      "spa": {
        "official": "República de las Maldivas",
        "common": "Maldivas"
      },
      "swe": {
        "official": "Republiken Maldiverna",
        "common": "Maldiverna"
      },
      "tur": {
        "official": "Maldivler Cumhuriyeti",
        "common": "Maldivler"
      },
      "urd": {
        "official": "جمہوریہ مالدیپ",
        "common": "مالدیپ"
      },
      "zho": {
        "official": "马尔代夫共和国",
        "common": "马尔代夫"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Maldivan",
        "m": "Maldivan"
      },
      "fra": {
        "f": "Maldivienne",
        "m": "Maldivien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mx.png",
      "svg": "https://flagcdn.com/mx.svg"
    },
    "cca2": "MX",
    "capital": [
      "Mexico City"
    ],
    "altSpellings": [
      "MX",
      "Mexicanos",
      "United Mexican States",
      "Estados Unidos Mexicanos"
    ],
    "translations": {
      "ara": {
        "official": "الولايات المتحدة المكسيكية",
        "common": "المسكيك"
      },
      "bre": {
        "official": "Stadoù-Unanet Mec'hiko",
        "common": "Mec'hiko"
      },
      "ces": {
        "official": "Spojené státy mexické",
        "common": "Mexiko"
      },
      "cym": {
        "official": "United Mexican States",
        "common": "Mexico"
      },
      "deu": {
        "official": "Vereinigte Mexikanische Staaten",
        "common": "Mexiko"
      },
      "est": {
        "official": "Mehhiko Ühendriigid",
        "common": "Mehhiko"
      },
      "fin": {
        "official": "Meksikon yhdysvallat",
        "common": "Meksiko"
      },
      "fra": {
        "official": "États-Unis du Mexique",
        "common": "Mexique"
      },
      "hrv": {
        "official": "Sjedinjene Meksičke Države",
        "common": "Meksiko"
      },
      "hun": {
        "official": "Mexikói Egyesült Államok",
        "common": "Mexikó"
      },
      "ita": {
        "official": "Stati Uniti del Messico",
        "common": "Messico"
      },
      "jpn": {
        "official": "メキシコ合衆国",
        "common": "メキシコ"
      },
      "kor": {
        "official": "멕시코 합중국",
        "common": "멕시코"
      },
      "nld": {
        "official": "Verenigde Mexicaanse Staten",
        "common": "Mexico"
      },
      "per": {
        "official": "ایالات متحد مکزیک",
        "common": "مکزیک"
      },
      "pol": {
        "official": "Meksykańskie Stany Zjednoczone",
        "common": "Meksyk"
      },
      "por": {
        "official": "Estados Unidos Mexicanos",
        "common": "México"
      },
      "rus": {
        "official": "Мексиканские Соединённые Штаты",
        "common": "Мексика"
      },
      "slk": {
        "official": "Spojené štášy mexické",
        "common": "Mexiko"
      },
      "spa": {
        "official": "Estados Unidos Mexicanos",
        "common": "México"
      },
      "swe": {
        "official": "Mexikos förenta stater",
        "common": "Mexiko"
      },
      "tur": {
        "official": "Birleşik Meksika Devletleri",
        "common": "Meksika"
      },
      "urd": {
        "official": "ریاستہائے متحدہ میکسیکو",
        "common": "میکسیکو"
      },
      "zho": {
        "official": "墨西哥合众国",
        "common": "墨西哥"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mexican",
        "m": "Mexican"
      },
      "fra": {
        "f": "Mexicaine",
        "m": "Mexicain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nz.png",
      "svg": "https://flagcdn.com/nz.svg"
    },
    "cca2": "NZ",
    "capital": [
      "Wellington"
    ],
    "altSpellings": [
      "NZ",
      "Aotearoa"
    ],
    "translations": {
      "ara": {
        "official": "نيوزيلندا",
        "common": "نيوزيلندا"
      },
      "bre": {
        "official": "Zeland-Nevez",
        "common": "Zeland-Nevez"
      },
      "ces": {
        "official": "Nový Zéland",
        "common": "Nový Zéland"
      },
      "cym": {
        "official": "New Zealand",
        "common": "New Zealand"
      },
      "deu": {
        "official": "Neuseeland",
        "common": "Neuseeland"
      },
      "est": {
        "official": "Uus-Meremaa",
        "common": "Uus-Meremaa"
      },
      "fin": {
        "official": "Uusi-Seelanti",
        "common": "Uusi-Seelanti"
      },
      "fra": {
        "official": "Nouvelle-Zélande",
        "common": "Nouvelle-Zélande"
      },
      "hrv": {
        "official": "Novi Zeland",
        "common": "Novi Zeland"
      },
      "hun": {
        "official": "Új-Zéland",
        "common": "Új-Zéland"
      },
      "ita": {
        "official": "Nuova Zelanda",
        "common": "Nuova Zelanda"
      },
      "jpn": {
        "official": "ニュージーランド",
        "common": "ニュージーランド"
      },
      "kor": {
        "official": "뉴질랜드",
        "common": "뉴질랜드"
      },
      "nld": {
        "official": "Nieuw Zeeland",
        "common": "Nieuw-Zeeland"
      },
      "per": {
        "official": "نیوزیلند",
        "common": "نیوزیلند"
      },
      "pol": {
        "official": "Nowa Zelandia",
        "common": "Nowa Zelandia"
      },
      "por": {
        "official": "nova Zelândia",
        "common": "Nova Zelândia"
      },
      "rus": {
        "official": "Новая Зеландия",
        "common": "Новая Зеландия"
      },
      "slk": {
        "official": "Nový Zéland",
        "common": "Nový Zéland"
      },
      "spa": {
        "official": "nueva Zelanda",
        "common": "Nueva Zelanda"
      },
      "swe": {
        "official": "Nya Zeeland",
        "common": "Nya Zeeland"
      },
      "tur": {
        "official": "Yeni Zelanda",
        "common": "Yeni Zelanda"
      },
      "urd": {
        "official": "نیوزی لینڈ",
        "common": "نیوزی لینڈ"
      },
      "zho": {
        "official": "新西兰",
        "common": "新西兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "New Zealander",
        "m": "New Zealander"
      },
      "fra": {
        "f": "Neo-Zélandaise",
        "m": "Neo-Zélandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ec.png",
      "svg": "https://flagcdn.com/ec.svg"
    },
    "cca2": "EC",
    "capital": [
      "Quito"
    ],
    "altSpellings": [
      "EC",
      "Republic of Ecuador",
      "República del Ecuador"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الإكوادور",
        "common": "الإكوادور"
      },
      "bre": {
        "official": "Republik Ecuador",
        "common": "Ecuador"
      },
      "ces": {
        "official": "Ekvádorská republika",
        "common": "Ekvádor"
      },
      "cym": {
        "official": "Gweriniaeth Ecwador",
        "common": "Ecwador"
      },
      "deu": {
        "official": "Republik Ecuador",
        "common": "Ecuador"
      },
      "est": {
        "official": "Ecuadori Vabariik",
        "common": "Ecuador"
      },
      "fin": {
        "official": "Ecuadorin tasavalta",
        "common": "Ecuador"
      },
      "fra": {
        "official": "République de l'Équateur",
        "common": "Équateur"
      },
      "hrv": {
        "official": "Republika Ekvador",
        "common": "Ekvador"
      },
      "hun": {
        "official": "Ecuadori Köztársaság",
        "common": "Ecuador"
      },
      "ita": {
        "official": "Repubblica dell'Ecuador",
        "common": "Ecuador"
      },
      "jpn": {
        "official": "エクアドル共和国",
        "common": "エクアドル"
      },
      "kor": {
        "official": "에콰도르 공화국",
        "common": "에콰도르"
      },
      "nld": {
        "official": "Republiek Ecuador",
        "common": "Ecuador"
      },
      "per": {
        "official": "جمهوری اکوادور",
        "common": "اکوادور"
      },
      "pol": {
        "official": "Ekwador",
        "common": "Ekwador"
      },
      "por": {
        "official": "República do Equador",
        "common": "Equador"
      },
      "rus": {
        "official": "Республика Эквадор",
        "common": "Эквадор"
      },
      "slk": {
        "official": "Ekvádorská republika",
        "common": "Ekvádor"
      },
      "spa": {
        "official": "República del Ecuador",
        "common": "Ecuador"
      },
      "swe": {
        "official": "Republiken Ecuador",
        "common": "Ecuador"
      },
      "tur": {
        "official": "Ekvador Cumhuriyeti",
        "common": "Ekvador"
      },
      "urd": {
        "official": "جمہوریہ ایکوڈور",
        "common": "ایکواڈور"
      },
      "zho": {
        "official": "厄瓜多尔共和国",
        "common": "厄瓜多尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ecuadorean",
        "m": "Ecuadorean"
      },
      "fra": {
        "f": "Équatorienne",
        "m": "Équatorien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/wf.png",
      "svg": "https://flagcdn.com/wf.svg"
    },
    "cca2": "WF",
    "capital": [
      "Mata-Utu"
    ],
    "altSpellings": [
      "WF",
      "Territory of the Wallis and Futuna Islands",
      "Territoire des îles Wallis et Futuna"
    ],
    "translations": {
      "ara": {
        "official": "إقليم جزر واليس وفوتونا",
        "common": "واليس وفوتونا"
      },
      "bre": {
        "official": "Tiriad Inizi Wallis ha Futuna",
        "common": "Wallis ha Futuna"
      },
      "ces": {
        "official": "Teritorium ostrovů Wallis a Futuna",
        "common": "Wallis a Futuna"
      },
      "cym": {
        "official": "Territory of the Wallis and Futuna Islands",
        "common": "Wallis and Futuna"
      },
      "deu": {
        "official": "Gebiet der Wallis und Futuna",
        "common": "Wallis und Futuna"
      },
      "est": {
        "official": "Wallise ja Futuna ala",
        "common": "Wallis ja Futuna"
      },
      "fin": {
        "official": "Wallisin ja Futunan yhteisö",
        "common": "Wallis ja Futuna"
      },
      "fra": {
        "official": "Territoire des îles Wallis et Futuna",
        "common": "Wallis-et-Futuna"
      },
      "hrv": {
        "official": "Teritoriju Wallis i Futuna",
        "common": "Wallis i Fortuna"
      },
      "hun": {
        "official": "Wallis és Futuna",
        "common": "Wallis és Futuna"
      },
      "ita": {
        "official": "Territorio delle Isole Wallis e Futuna",
        "common": "Wallis e Futuna"
      },
      "jpn": {
        "official": "ウォリス·フツナ諸島の領土",
        "common": "ウォリス・フツナ"
      },
      "kor": {
        "official": "왈리스 퓌튀나",
        "common": "왈리스 퓌튀나"
      },
      "nld": {
        "official": "Grondgebied van de Wallis en Futuna",
        "common": "Wallis en Futuna"
      },
      "per": {
        "official": "جزایر والیس و فوتونا",
        "common": "والیس و فوتونا"
      },
      "pol": {
        "official": "Terytorium Wysp Wallis i Futuna",
        "common": "Wallis i Futuna"
      },
      "por": {
        "official": "Território das Ilhas Wallis e Futuna",
        "common": "Wallis e Futuna"
      },
      "rus": {
        "official": "Территория Уоллис и Футуна острова",
        "common": "Уоллис и Футуна"
      },
      "slk": {
        "official": "Teritórium ostrovov Wallis a Futuna",
        "common": "Wallis a Futuna"
      },
      "spa": {
        "official": "Territorio de las Islas Wallis y Futuna",
        "common": "Wallis y Futuna"
      },
      "swe": {
        "official": "Territoriet Wallis- och Futunaöarna",
        "common": "Wallis- och Futunaöarna"
      },
      "tur": {
        "official": "Wallis ve Futuna Adaları Bölgesi",
        "common": "Wallis ve Futuna Adaları Bölgesi"
      },
      "urd": {
        "official": "سر زمینِ والس و فتونہ جزائر",
        "common": "والس و فتونہ"
      },
      "zho": {
        "official": "瓦利斯和富图纳群岛",
        "common": "瓦利斯和富图纳群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Wallis and Futuna Islander",
        "m": "Wallis and Futuna Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ax.png",
      "svg": "https://flagcdn.com/ax.svg"
    },
    "cca2": "AX",
    "capital": [
      "Mariehamn"
    ],
    "altSpellings": [
      "AX",
      "Aaland",
      "Aland",
      "Ahvenanmaa"
    ],
    "translations": {
      "ara": {
        "official": "جزر أولاند",
        "common": "جزر أولاند"
      },
      "bre": {
        "official": "Inizi Åland",
        "common": "Åland"
      },
      "ces": {
        "official": "Ålandské ostrovy",
        "common": "Ålandy"
      },
      "cym": {
        "official": "Åland Islands",
        "common": "Åland Islands"
      },
      "deu": {
        "official": "Åland-Inseln",
        "common": "Åland"
      },
      "est": {
        "official": "Ahvenamaa maakond",
        "common": "Ahvenamaa"
      },
      "fin": {
        "official": "Ahvenanmaan maakunta",
        "common": "Ahvenanmaa"
      },
      "fra": {
        "official": "Ahvenanmaa",
        "common": "Ahvenanmaa"
      },
      "hrv": {
        "official": "Aland Islands",
        "common": "Ålandski otoci"
      },
      "hun": {
        "official": "Åland-szigetek",
        "common": "Åland-szigetek"
      },
      "ita": {
        "official": "Isole Åland",
        "common": "Isole Aland"
      },
      "jpn": {
        "official": "オーランド諸島",
        "common": "オーランド諸島"
      },
      "kor": {
        "official": "올란드 제도",
        "common": "올란드 제도"
      },
      "nld": {
        "official": "Åland eilanden",
        "common": "Ålandeilanden"
      },
      "per": {
        "official": "جزایر الند",
        "common": "جزایر الند"
      },
      "pol": {
        "official": "Wyspy Alandzkie",
        "common": "Wyspy Alandzkie"
      },
      "por": {
        "official": "Ilhas Åland",
        "common": "Alândia"
      },
      "rus": {
        "official": "Аландские острова",
        "common": "Аландские острова"
      },
      "slk": {
        "official": "Alandské ostrovy",
        "common": "Alandy"
      },
      "spa": {
        "official": "Islas Åland",
        "common": "Alandia"
      },
      "swe": {
        "official": "Åland",
        "common": "Åland"
      },
      "tur": {
        "official": "Åland Adaları",
        "common": "Åland"
      },
      "urd": {
        "official": "جزائر اولند",
        "common": "جزائر اولند"
      },
      "zho": {
        "official": "奥兰群岛",
        "common": "奥兰群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ålandish",
        "m": "Ålandish"
      },
      "fra": {
        "f": "Ålandaise",
        "m": "Ålandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/me.png",
      "svg": "https://flagcdn.com/me.svg"
    },
    "cca2": "ME",
    "capital": [
      "Podgorica"
    ],
    "altSpellings": [
      "ME",
      "Crna Gora"
    ],
    "translations": {
      "ara": {
        "official": "الجبل الاسود",
        "common": "الجبل الاسود"
      },
      "bre": {
        "official": "Republik Montenegro",
        "common": "Montenegro"
      },
      "ces": {
        "official": "Černá Hora",
        "common": "Černá Hora"
      },
      "cym": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "deu": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "est": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "fin": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "fra": {
        "official": "Monténégro",
        "common": "Monténégro"
      },
      "hrv": {
        "official": "Crna Gora",
        "common": "Crna Gora"
      },
      "hun": {
        "official": "Montenegró",
        "common": "Montenegró"
      },
      "ita": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "jpn": {
        "official": "モンテネグロ",
        "common": "モンテネグロ"
      },
      "kor": {
        "official": "몬테네그로",
        "common": "몬테네그로"
      },
      "nld": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "per": {
        "official": "مونته‌نگرو",
        "common": "مونته‌نگرو"
      },
      "pol": {
        "official": "Czarnogóra",
        "common": "Czarnogóra"
      },
      "por": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "rus": {
        "official": "Черногория",
        "common": "Черногория"
      },
      "slk": {
        "official": "Čierna Hora",
        "common": "Čierna Hora"
      },
      "spa": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "swe": {
        "official": "Montenegro",
        "common": "Montenegro"
      },
      "tur": {
        "official": "Karadağ",
        "common": "Karadağ"
      },
      "urd": {
        "official": "مونٹینیگرو",
        "common": "مونٹینیگرو"
      },
      "zho": {
        "official": "黑山",
        "common": "黑山"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Montenegrin",
        "m": "Montenegrin"
      },
      "fra": {
        "f": "Monténégrine",
        "m": "Monténégrin"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pk.png",
      "svg": "https://flagcdn.com/pk.svg"
    },
    "cca2": "PK",
    "capital": [
      "Islamabad"
    ],
    "altSpellings": [
      "PK",
      "Pākistān",
      "Islamic Republic of Pakistan",
      "Islāmī Jumhūriya'eh Pākistān"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية باكستان الإسلامية",
        "common": "باكستان"
      },
      "bre": {
        "official": "Republik islamek Pakistan",
        "common": "Pakistan"
      },
      "ces": {
        "official": "Pákistánská islámská republika",
        "common": "Pákistán"
      },
      "cym": {
        "official": "Islamic Republic of Pakistan",
        "common": "Pakistan"
      },
      "deu": {
        "official": "Islamische Republik Pakistan",
        "common": "Pakistan"
      },
      "est": {
        "official": "Pakistani Islamivabariik",
        "common": "Pakistan"
      },
      "fin": {
        "official": "Pakistanin islamilainen tasavalta",
        "common": "Pakistan"
      },
      "fra": {
        "official": "République islamique du Pakistan",
        "common": "Pakistan"
      },
      "hrv": {
        "official": "Islamska Republika Pakistan",
        "common": "Pakistan"
      },
      "hun": {
        "official": "Pakisztán",
        "common": "Pakisztán"
      },
      "ita": {
        "official": "Repubblica islamica del Pakistan",
        "common": "Pakistan"
      },
      "jpn": {
        "official": "パキスタン",
        "common": "パキスタン"
      },
      "kor": {
        "official": "파키스탄 이슬람 공화국",
        "common": "파키스탄"
      },
      "nld": {
        "official": "Islamitische Republiek Pakistan",
        "common": "Pakistan"
      },
      "per": {
        "official": "جمهوری اسلامی پاکستان",
        "common": "پاکستان"
      },
      "pol": {
        "official": "Islamska Republika Pakistanu",
        "common": "Pakistan"
      },
      "por": {
        "official": "República Islâmica do Paquistão",
        "common": "Paquistão"
      },
      "rus": {
        "official": "Исламская Республика Пакистан",
        "common": "Пакистан"
      },
      "slk": {
        "official": "Pakistanská islamská republika",
        "common": "Pakistan"
      },
      "spa": {
        "official": "República Islámica de Pakistán",
        "common": "Pakistán"
      },
      "swe": {
        "official": "Islamiska republiken Pakistan",
        "common": "Pakistan"
      },
      "tur": {
        "official": "Pakistan İslam Cumhuriyeti",
        "common": "Pakistan"
      },
      "urd": {
        "official": "اسلامی جمہوریہ پاکستان",
        "common": "پاکستان"
      },
      "zho": {
        "official": "巴基斯坦伊斯兰共和国",
        "common": "巴基斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Pakistani",
        "m": "Pakistani"
      },
      "fra": {
        "f": "Pakistanaise",
        "m": "Pakistanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pn.png",
      "svg": "https://flagcdn.com/pn.svg"
    },
    "cca2": "PN",
    "capital": [
      "Adamstown"
    ],
    "altSpellings": [
      "PN",
      "Pitcairn",
      "Pitcairn Henderson Ducie and Oeno Islands"
    ],
    "translations": {
      "ara": {
        "official": "جزر بيتكيرن",
        "common": "جزر بيتكيرن"
      },
      "bre": {
        "official": "Inizi Pitcairn, Henderson, Ducie hag Oeno",
        "common": "Inizi Pitcairn"
      },
      "ces": {
        "official": "Pitcairnovy ostrovy",
        "common": "Pitcairnovy ostrovy"
      },
      "cym": {
        "official": "Pitcairn Group of Islands",
        "common": "Pitcairn Islands"
      },
      "deu": {
        "official": "Pitcairninseln",
        "common": "Pitcairninseln"
      },
      "est": {
        "official": "Pitcairni, Hendersoni, Ducie ja Oeno saar",
        "common": "Pitcairn"
      },
      "fin": {
        "official": "Pitcairn",
        "common": "Pitcairn"
      },
      "fra": {
        "official": "Groupe d'îles Pitcairn",
        "common": "Îles Pitcairn"
      },
      "hrv": {
        "official": "Pitcairn skupine otoka",
        "common": "Pitcairnovo otočje"
      },
      "hun": {
        "official": "Pitcairn-szigetek",
        "common": "Pitcairn-szigetek"
      },
      "ita": {
        "official": "Pitcairn gruppo di isole",
        "common": "Isole Pitcairn"
      },
      "jpn": {
        "official": "島のピトケアングループ",
        "common": "ピトケアン"
      },
      "kor": {
        "official": "핏케언 제도",
        "common": "핏케언 제도"
      },
      "nld": {
        "official": "Pitcairn groep eilanden",
        "common": "Pitcairneilanden"
      },
      "per": {
        "official": "جزایر پیت‌کرن",
        "common": "جزایر پیت‌کرن"
      },
      "pol": {
        "official": "Wyspy Pitcairn, Henderson, Ducie i Oeno",
        "common": "Pitcairn"
      },
      "por": {
        "official": "Pitcairn grupo de ilhas",
        "common": "Ilhas Pitcairn"
      },
      "rus": {
        "official": "Питкэрн группа островов",
        "common": "Острова Питкэрн"
      },
      "slk": {
        "official": "Pitcairnove ostrovy",
        "common": "Pitcairnove ostrovy"
      },
      "spa": {
        "official": "Grupo de Islas Pitcairn",
        "common": "Islas Pitcairn"
      },
      "swe": {
        "official": "Pitcairnöarna",
        "common": "Pitcairnöarna"
      },
      "tur": {
        "official": "Pitcairn, Henderson, Ducie ve Oeno Adaları",
        "common": "Pitcairn Adaları"
      },
      "urd": {
        "official": "پٹکیرن جزائر",
        "common": "جزائر پٹکیرن"
      },
      "zho": {
        "official": "皮特凯恩群岛",
        "common": "皮特凯恩群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Pitcairn Islander",
        "m": "Pitcairn Islander"
      },
      "fra": {
        "f": "Pitcairnaise",
        "m": "Pitcairnais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/zm.png",
      "svg": "https://flagcdn.com/zm.svg"
    },
    "cca2": "ZM",
    "capital": [
      "Lusaka"
    ],
    "altSpellings": [
      "ZM",
      "Republic of Zambia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية زامبيا",
        "common": "زامبيا"
      },
      "bre": {
        "official": "Republik Zambia",
        "common": "Zambia"
      },
      "ces": {
        "official": "Zambijská republika",
        "common": "Zambie"
      },
      "cym": {
        "official": "Republic of Zambia",
        "common": "Zambia"
      },
      "deu": {
        "official": "Republik Sambia",
        "common": "Sambia"
      },
      "est": {
        "official": "Sambia Vabariik",
        "common": "Sambia"
      },
      "fin": {
        "official": "Sambian tasavalta",
        "common": "Sambia"
      },
      "fra": {
        "official": "République de Zambie",
        "common": "Zambie"
      },
      "hrv": {
        "official": "Republika Zambija",
        "common": "Zambija"
      },
      "hun": {
        "official": "Zambiai Köztársaság",
        "common": "Zambia"
      },
      "ita": {
        "official": "Repubblica di Zambia",
        "common": "Zambia"
      },
      "jpn": {
        "official": "ザンビア共和国",
        "common": "ザンビア"
      },
      "kor": {
        "official": "잠비아 공화국",
        "common": "잠비아"
      },
      "nld": {
        "official": "Republiek Zambia",
        "common": "Zambia"
      },
      "per": {
        "official": "جمهوری زامبیا",
        "common": "زامبیا"
      },
      "pol": {
        "official": "Republika Zambii",
        "common": "Zambia"
      },
      "por": {
        "official": "República da Zâmbia",
        "common": "Zâmbia"
      },
      "rus": {
        "official": "Республика Замбия",
        "common": "Замбия"
      },
      "slk": {
        "official": "Zambijská republika",
        "common": "Zambia"
      },
      "spa": {
        "official": "República de Zambia",
        "common": "Zambia"
      },
      "swe": {
        "official": "Republiken Zambia",
        "common": "Zambia"
      },
      "tur": {
        "official": "Zambiya Cumhuriyeti",
        "common": "Zambiya"
      },
      "urd": {
        "official": "جمہوریہ زیمبیا",
        "common": "زیمبیا"
      },
      "zho": {
        "official": "赞比亚共和国",
        "common": "赞比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Zambian",
        "m": "Zambian"
      },
      "fra": {
        "f": "Zambienne",
        "m": "Zambien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sc.png",
      "svg": "https://flagcdn.com/sc.svg"
    },
    "cca2": "SC",
    "capital": [
      "Victoria"
    ],
    "altSpellings": [
      "SC",
      "Republic of Seychelles",
      "Repiblik Sesel",
      "République des Seychelles"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سيشل",
        "common": "سيشل"
      },
      "bre": {
        "official": "Republik Sechelez",
        "common": "Sechelez"
      },
      "ces": {
        "official": "Seychelská republika",
        "common": "Seychely"
      },
      "cym": {
        "official": "Republic of Seychelles",
        "common": "Seychelles"
      },
      "deu": {
        "official": "Republik der Seychellen",
        "common": "Seychellen"
      },
      "est": {
        "official": "Seišelli Vabariik",
        "common": "Seišellid"
      },
      "fin": {
        "official": "Seychellien tasavalta",
        "common": "Seychellit"
      },
      "fra": {
        "official": "République des Seychelles",
        "common": "Seychelles"
      },
      "hrv": {
        "official": "Republika Sejšeli",
        "common": "Sejšeli"
      },
      "hun": {
        "official": "Seychelle Köztársaság",
        "common": "Seychelle-szigetek"
      },
      "ita": {
        "official": "Repubblica delle Seychelles",
        "common": "Seychelles"
      },
      "jpn": {
        "official": "セイシェル共和国",
        "common": "セーシェル"
      },
      "kor": {
        "official": "세이셸 공화국",
        "common": "세이셸"
      },
      "nld": {
        "official": "Republiek der Seychellen",
        "common": "Seychellen"
      },
      "per": {
        "official": "جمهوری سیشل",
        "common": "سیشل"
      },
      "pol": {
        "official": "Republika Seszeli",
        "common": "Seszele"
      },
      "por": {
        "official": "República das Seychelles",
        "common": "Seicheles"
      },
      "rus": {
        "official": "Республика Сейшельские Острова",
        "common": "Сейшельские Острова"
      },
      "slk": {
        "official": "Seychelská republika",
        "common": "Seychely"
      },
      "spa": {
        "official": "República de las Seychelles",
        "common": "Seychelles"
      },
      "swe": {
        "official": "Republiken Seychellerna",
        "common": "Seychellerna"
      },
      "tur": {
        "official": "Seyşeller Cumhuriyeti",
        "common": "Seyşeller"
      },
      "urd": {
        "official": "جمہوریہ سیچیلیس",
        "common": "سیچیلیس"
      },
      "zho": {
        "official": "塞舌尔共和国",
        "common": "塞舌尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Seychellois",
        "m": "Seychellois"
      },
      "fra": {
        "f": "Seychelloise",
        "m": "Seychellois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/my.png",
      "svg": "https://flagcdn.com/my.svg"
    },
    "cca2": "MY",
    "capital": [
      "Kuala Lumpur"
    ],
    "altSpellings": [
      "MY"
    ],
    "translations": {
      "ara": {
        "official": "ماليزيا",
        "common": "ماليزيا"
      },
      "bre": {
        "official": "Malaysia",
        "common": "Malaysia"
      },
      "ces": {
        "official": "Malajsie",
        "common": "Malajsie"
      },
      "cym": {
        "official": "Malaysia",
        "common": "Malaysia"
      },
      "deu": {
        "official": "Malaysia",
        "common": "Malaysia"
      },
      "est": {
        "official": "Malaisia",
        "common": "Malaisia"
      },
      "fin": {
        "official": "Malesia",
        "common": "Malesia"
      },
      "fra": {
        "official": "Fédération de Malaisie",
        "common": "Malaisie"
      },
      "hrv": {
        "official": "Malezija",
        "common": "Malezija"
      },
      "hun": {
        "official": "Malajzia",
        "common": "Malajzia"
      },
      "ita": {
        "official": "Malaysia",
        "common": "Malesia"
      },
      "jpn": {
        "official": "マレーシア",
        "common": "マレーシア"
      },
      "kor": {
        "official": "말레이시아",
        "common": "말레이시아"
      },
      "nld": {
        "official": "Maleisië",
        "common": "Maleisië"
      },
      "per": {
        "official": "فدراسیون مالزی",
        "common": "مالزی"
      },
      "pol": {
        "official": "Malezja",
        "common": "Malezja"
      },
      "por": {
        "official": "Malásia",
        "common": "Malásia"
      },
      "rus": {
        "official": "Малайзия",
        "common": "Малайзия"
      },
      "slk": {
        "official": "Malajzia",
        "common": "Malajzia"
      },
      "spa": {
        "official": "Malasia",
        "common": "Malasia"
      },
      "swe": {
        "official": "Malaysia",
        "common": "Malaysia"
      },
      "tur": {
        "official": "Malezya",
        "common": "Malezya"
      },
      "urd": {
        "official": "ملائیشیا",
        "common": "ملائیشیا"
      },
      "zho": {
        "official": "马来西亚",
        "common": "马来西亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Malaysian",
        "m": "Malaysian"
      },
      "fra": {
        "f": "Malaisienne",
        "m": "Malaisien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/no.png",
      "svg": "https://flagcdn.com/no.svg"
    },
    "cca2": "NO",
    "capital": [
      "Oslo"
    ],
    "altSpellings": [
      "NO",
      "Norge",
      "Noreg",
      "Kingdom of Norway",
      "Kongeriket Norge",
      "Kongeriket Noreg"
    ],
    "translations": {
      "ara": {
        "official": "مملكة النرويج",
        "common": "النرويج"
      },
      "bre": {
        "official": "Rouantelezh Norvegia",
        "common": "Norvegia"
      },
      "ces": {
        "official": "Norské království",
        "common": "Norsko"
      },
      "cym": {
        "official": "Kingdom of Norway",
        "common": "Norway"
      },
      "deu": {
        "official": "Königreich Norwegen",
        "common": "Norwegen"
      },
      "est": {
        "official": "Norra Kuningriik",
        "common": "Norra"
      },
      "fin": {
        "official": "Norjan kuningaskunta",
        "common": "Norja"
      },
      "fra": {
        "official": "Royaume de Norvège",
        "common": "Norvège"
      },
      "hrv": {
        "official": "Kraljevina Norveška",
        "common": "Norveška"
      },
      "hun": {
        "official": "Norvég Királyság",
        "common": "Norvégia"
      },
      "ita": {
        "official": "Regno di Norvegia",
        "common": "Norvegia"
      },
      "jpn": {
        "official": "ノルウェー王国",
        "common": "ノルウェー"
      },
      "kor": {
        "official": "노르웨이 왕국",
        "common": "노르웨이"
      },
      "nld": {
        "official": "Koninkrijk Noorwegen",
        "common": "Noorwegen"
      },
      "per": {
        "official": "پادشاهی نروژ",
        "common": "نروژ"
      },
      "pol": {
        "official": "Królestwo Norwegii",
        "common": "Norwegia"
      },
      "por": {
        "official": "Reino da Noruega",
        "common": "Noruega"
      },
      "rus": {
        "official": "Королевство Норвегия",
        "common": "Норвегия"
      },
      "slk": {
        "official": "Nórske kráľovstvo",
        "common": "Nórsko"
      },
      "spa": {
        "official": "Reino de Noruega",
        "common": "Noruega"
      },
      "swe": {
        "official": "Konungariket Norge",
        "common": "Norge"
      },
      "tur": {
        "official": "Norveç Krallığı",
        "common": "Norveç"
      },
      "urd": {
        "official": "مملکتِ ناروے",
        "common": "ناروے"
      },
      "zho": {
        "official": "挪威王国",
        "common": "挪威"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Norwegian",
        "m": "Norwegian"
      },
      "fra": {
        "f": "Norvégienne",
        "m": "Norvégien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/uz.png",
      "svg": "https://flagcdn.com/uz.svg"
    },
    "cca2": "UZ",
    "capital": [
      "Tashkent"
    ],
    "altSpellings": [
      "UZ",
      "Republic of Uzbekistan",
      "O‘zbekiston Respublikasi",
      "Ўзбекистон Республикаси"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أوزباكستان",
        "common": "أوزباكستان"
      },
      "bre": {
        "official": "Republik Ouzbekistan",
        "common": "Ouzbekistan"
      },
      "ces": {
        "official": "Republika Uzbekistán",
        "common": "Uzbekistán"
      },
      "cym": {
        "official": "Republic of Uzbekistan",
        "common": "Uzbekistan"
      },
      "deu": {
        "official": "Republik Usbekistan",
        "common": "Usbekistan"
      },
      "est": {
        "official": "Usbekistani Vabariik",
        "common": "Usbekistan"
      },
      "fin": {
        "official": "Uzbekistanin tasavalta",
        "common": "Uzbekistan"
      },
      "fra": {
        "official": "République d'Ouzbékistan",
        "common": "Ouzbékistan"
      },
      "hrv": {
        "official": "Republika Uzbekistan",
        "common": "Uzbekistan"
      },
      "hun": {
        "official": "Üzbég Köztársaság",
        "common": "Üzbegisztán"
      },
      "ita": {
        "official": "Repubblica di Uzbekistan",
        "common": "Uzbekistan"
      },
      "jpn": {
        "official": "ウズベキスタン共和国",
        "common": "ウズベキスタン"
      },
      "kor": {
        "official": "우즈베키스탄 공화국",
        "common": "우즈베키스탄"
      },
      "nld": {
        "official": "Republiek Oezbekistan",
        "common": "Oezbekistan"
      },
      "per": {
        "official": "جمهوری ازبکستان",
        "common": "ازبکستان"
      },
      "pol": {
        "official": "Republika Uzbekistanu",
        "common": "Uzbekistan"
      },
      "por": {
        "official": "República do Usbequistão",
        "common": "Uzbequistão"
      },
      "rus": {
        "official": "Республика Узбекистан",
        "common": "Узбекистан"
      },
      "slk": {
        "official": "Uzbecká republika",
        "common": "Uzbekistan"
      },
      "spa": {
        "official": "República de Uzbekistán",
        "common": "Uzbekistán"
      },
      "swe": {
        "official": "Republiken Uzbekistan",
        "common": "Uzbekistan"
      },
      "tur": {
        "official": "Özbekistan Cumhuriyeti",
        "common": "Özbekistan"
      },
      "urd": {
        "official": "جمہوریہ ازبکستان",
        "common": "ازبکستان"
      },
      "zho": {
        "official": "乌兹别克斯坦共和国",
        "common": "乌兹别克斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Uzbekistani",
        "m": "Uzbekistani"
      },
      "fra": {
        "f": "Ouzbèke",
        "m": "Ouzbèke"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/vu.png",
      "svg": "https://flagcdn.com/vu.svg"
    },
    "cca2": "VU",
    "capital": [
      "Port Vila"
    ],
    "altSpellings": [
      "VU",
      "Republic of Vanuatu",
      "Ripablik blong Vanuatu",
      "République de Vanuatu"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية فانواتو",
        "common": "فانواتو"
      },
      "bre": {
        "official": "Republik Vanuatu",
        "common": "Vanuatu"
      },
      "ces": {
        "official": "Republika Vanuatu",
        "common": "Vanuatu"
      },
      "cym": {
        "official": "Republic of Vanuatu",
        "common": "Vanuatu"
      },
      "deu": {
        "official": "Vanuatu",
        "common": "Vanuatu"
      },
      "est": {
        "official": "Vanuatu Vabariik",
        "common": "Vanuatu"
      },
      "fin": {
        "official": "Vanuatun tasavalta",
        "common": "Vanuatu"
      },
      "fra": {
        "official": "République de Vanuatu",
        "common": "Vanuatu"
      },
      "hrv": {
        "official": "Republika Vanuatu",
        "common": "Vanuatu"
      },
      "hun": {
        "official": "Vanuatui Köztársaság",
        "common": "Vanuatu"
      },
      "ita": {
        "official": "Repubblica di Vanuatu",
        "common": "Vanuatu"
      },
      "jpn": {
        "official": "バヌアツ共和国",
        "common": "バヌアツ"
      },
      "kor": {
        "official": "바누아투 공화국",
        "common": "바누아투"
      },
      "nld": {
        "official": "Republiek Vanuatu",
        "common": "Vanuatu"
      },
      "per": {
        "official": "جمهوری وانواتو",
        "common": "وانواتو"
      },
      "pol": {
        "official": "Republika Vanuatu",
        "common": "Vanuatu"
      },
      "por": {
        "official": "República de Vanuatu",
        "common": "Vanuatu"
      },
      "rus": {
        "official": "Республика Вануату",
        "common": "Вануату"
      },
      "slk": {
        "official": "Vanuatská republika",
        "common": "Vanuatu"
      },
      "spa": {
        "official": "República de Vanuatu",
        "common": "Vanuatu"
      },
      "swe": {
        "official": "Republiken Vanuatu",
        "common": "Vanuatu"
      },
      "tur": {
        "official": "Vanuatu Cumhuriyeti",
        "common": "Vanuatu"
      },
      "urd": {
        "official": "جمہوریہ وانواتو",
        "common": "وانواتو"
      },
      "zho": {
        "official": "瓦努阿图共和国",
        "common": "瓦努阿图"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ni-Vanuatu",
        "m": "Ni-Vanuatu"
      },
      "fra": {
        "f": "Vanuatuane",
        "m": "Vanuatuan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tf.png",
      "svg": "https://flagcdn.com/tf.svg"
    },
    "cca2": "TF",
    "capital": [
      "Port-aux-Français"
    ],
    "altSpellings": [
      "TF",
      "French Southern Territories"
    ],
    "translations": {
      "ara": {
        "official": "مقاطعات وأقاليم ما وراء البحار الفرنسية",
        "common": "أراض فرنسية جنوبية وأنتارتيكية"
      },
      "bre": {
        "official": "Tiriad Douaroù Aostral hag Antarktikel Frañs",
        "common": "Douaroù Aostral hag Antarktikel Frañs"
      },
      "ces": {
        "official": "Teritorium Francouzská jižní a antarktická území",
        "common": "Francouzská jižní a antarktická území"
      },
      "cym": {
        "official": "Territory of the French Southern and Antarctic Lands",
        "common": "French Southern and Antarctic Lands"
      },
      "deu": {
        "official": "Gebiet der Französisch Süd- und Antarktisgebiete",
        "common": "Französische Süd- und Antarktisgebiete"
      },
      "est": {
        "official": "Prantsuse Lõunaalad",
        "common": "Prantsuse Lõunaalad"
      },
      "fin": {
        "official": "Ranskan eteläiset ja antarktiset alueet",
        "common": "Ranskan eteläiset ja antarktiset alueet"
      },
      "fra": {
        "official": "Territoire des Terres australes et antarctiques françaises",
        "common": "Terres australes et antarctiques françaises"
      },
      "hrv": {
        "official": "Teritoriju Francuski južni i antarktički teritoriji",
        "common": "Francuski južni i antarktički teritoriji"
      },
      "hun": {
        "official": "Francia déli és antarktiszi területek",
        "common": "Francia déli és antarktiszi területek"
      },
      "ita": {
        "official": "Territorio della australi e antartiche francesi Terre",
        "common": "Territori Francesi del Sud"
      },
      "jpn": {
        "official": "フランス領南方·南極地域の領土",
        "common": "フランス領南方・南極地域"
      },
      "kor": {
        "official": "프랑스령 남부와 남극 지역",
        "common": "프랑스령 남부와 남극 지역"
      },
      "nld": {
        "official": "Grondgebied van de Franse Zuidelijke en Antarctische gebieden",
        "common": "Franse Gebieden in de zuidelijke Indische Oceaan"
      },
      "per": {
        "official": "سرزمین‌های جنوبی و جنوبگانی فرانسه",
        "common": "سرزمین‌های جنوبی و جنوبگانی فرانسه"
      },
      "pol": {
        "official": "Francuskie Terytoria Południowe i Antarktyczne",
        "common": "Francuskie Terytoria Południowe i Antarktyczne"
      },
      "por": {
        "official": "Território do Sul e Antártica Francesa",
        "common": "Terras Austrais e Antárticas Francesas"
      },
      "rus": {
        "official": "Территория Французские Южные и Антарктические земли",
        "common": "Французские Южные и Антарктические территории"
      },
      "slk": {
        "official": "Francúzske južné a antarktické územia",
        "common": "Francúzske juŽné a antarktické územia"
      },
      "spa": {
        "official": "Territorio del Francés Tierras australes y antárticas",
        "common": "Tierras Australes y Antárticas Francesas"
      },
      "swe": {
        "official": "Franska syd- och Antarktisterritorierna",
        "common": "Franska södra territorierna"
      },
      "tur": {
        "official": "Fransız Güney ve Antarktika Toprakları",
        "common": "Fransız Güney ve Antarktika Toprakları"
      },
      "urd": {
        "official": "سرزمینِ جنوبی فرانسیسیہ و انٹارکٹیکہ",
        "common": "سرزمین جنوبی فرانسیسیہ و انٹارکٹیکا"
      },
      "zho": {
        "official": "法国南部和南极土地",
        "common": "法国南部和南极土地"
      }
    },
    "demonyms": {
      "eng": {
        "f": "French",
        "m": "French"
      },
      "fra": {
        "f": "Française",
        "m": "Français"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cx.png",
      "svg": "https://flagcdn.com/cx.svg"
    },
    "cca2": "CX",
    "capital": [
      "Flying Fish Cove"
    ],
    "altSpellings": [
      "CX",
      "Territory of Christmas Island"
    ],
    "translations": {
      "ara": {
        "official": "جزيرة كريسماس",
        "common": "جزيرة كريسماس"
      },
      "bre": {
        "official": "Tiriad Enez Christmas",
        "common": "Enez Christmas"
      },
      "ces": {
        "official": "Teritorium Vánočního ostrova",
        "common": "Vánoční ostrov"
      },
      "cym": {
        "official": "Tiriogaeth yr Ynys y Nadolig",
        "common": "Ynys y Nadolig"
      },
      "deu": {
        "official": "Gebiet der Weihnachtsinsel",
        "common": "Weihnachtsinsel"
      },
      "est": {
        "official": "Jõulusaare ala",
        "common": "Jõulusaar"
      },
      "fin": {
        "official": "Joulusaaren alue",
        "common": "Joulusaari"
      },
      "fra": {
        "official": "Territoire de l'île Christmas",
        "common": "Île Christmas"
      },
      "hrv": {
        "official": "Teritorij Božićni otok",
        "common": "Božićni otok"
      },
      "hun": {
        "official": "Karácsony-sziget",
        "common": "Karácsony-sziget"
      },
      "ita": {
        "official": "Territorio di Christmas Island",
        "common": "Isola di Natale"
      },
      "jpn": {
        "official": "クリスマス島の領土",
        "common": "クリスマス島"
      },
      "kor": {
        "official": "크리스마스 섬",
        "common": "크리스마스 섬"
      },
      "nld": {
        "official": "Grondgebied van Christmas Island",
        "common": "Christmaseiland"
      },
      "per": {
        "official": "جزیرهٔ کریسمس",
        "common": "جزیرهٔ کریسمس"
      },
      "pol": {
        "official": "Wyspa Bożego Narodzenia",
        "common": "Wyspa Bożego Narodzenia"
      },
      "por": {
        "official": "Território da Ilha Christmas",
        "common": "Ilha do Natal"
      },
      "rus": {
        "official": "Территория острова Рождества",
        "common": "Остров Рождества"
      },
      "slk": {
        "official": "Teritórium Vianočného ostrova",
        "common": "Vianočnú ostrov"
      },
      "spa": {
        "official": "Territorio de la Isla de Navidad",
        "common": "Isla de Navidad"
      },
      "swe": {
        "official": "Julön",
        "common": "Julön"
      },
      "tur": {
        "official": "Christmas Adası",
        "common": "Christmas Adası"
      },
      "urd": {
        "official": "ریاستِ جزیرہ کرسمس",
        "common": "جزیرہ کرسمس"
      },
      "zho": {
        "official": "圣诞岛",
        "common": "圣诞岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Christmas Islander",
        "m": "Christmas Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sg.png",
      "svg": "https://flagcdn.com/sg.svg"
    },
    "cca2": "SG",
    "capital": [
      "Singapore"
    ],
    "altSpellings": [
      "SG",
      "Singapura",
      "Republik Singapura",
      "新加坡共和国"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سنغافورة",
        "common": "سنغافورة"
      },
      "bre": {
        "official": "Republik Singapour",
        "common": "Singapour"
      },
      "ces": {
        "official": "Singapurská republika",
        "common": "Singapur"
      },
      "cym": {
        "official": "Republic of Singapore",
        "common": "Singapore"
      },
      "deu": {
        "official": "Republik Singapur",
        "common": "Singapur"
      },
      "est": {
        "official": "Singapuri Vabariik",
        "common": "Singapur"
      },
      "fin": {
        "official": "Singaporen tasavalta",
        "common": "Singapore"
      },
      "fra": {
        "official": "République de Singapour",
        "common": "Singapour"
      },
      "hrv": {
        "official": "Republika Singapur",
        "common": "Singapur"
      },
      "hun": {
        "official": "Szingapúri Köztársaság",
        "common": "Szingapúr"
      },
      "ita": {
        "official": "Repubblica di Singapore",
        "common": "Singapore"
      },
      "jpn": {
        "official": "シンガポール共和国",
        "common": "シンガポール"
      },
      "kor": {
        "official": "싱가포르 공화국",
        "common": "싱가포르"
      },
      "nld": {
        "official": "Republiek Singapore",
        "common": "Singapore"
      },
      "per": {
        "official": "جمهوری سنگاپور",
        "common": "سنگاپور"
      },
      "pol": {
        "official": "Republika Singapuru",
        "common": "Singapur"
      },
      "por": {
        "official": "República de Singapura",
        "common": "Singapura"
      },
      "rus": {
        "official": "Республика Сингапур",
        "common": "Сингапур"
      },
      "slk": {
        "official": "Singapurská republika",
        "common": "Singapur"
      },
      "spa": {
        "official": "República de Singapur",
        "common": "Singapur"
      },
      "swe": {
        "official": "Republiken Singapore",
        "common": "Singapore"
      },
      "tur": {
        "official": "Singapur Cumhuriyeti",
        "common": "Singapur"
      },
      "urd": {
        "official": "جمہوریہ سنگاپور",
        "common": "سنگاپور"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Singaporean",
        "m": "Singaporean"
      },
      "fra": {
        "f": "Singapourienne",
        "m": "Singapourien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sr.png",
      "svg": "https://flagcdn.com/sr.svg"
    },
    "cca2": "SR",
    "capital": [
      "Paramaribo"
    ],
    "altSpellings": [
      "SR",
      "Sarnam",
      "Sranangron",
      "Republic of Suriname",
      "Republiek Suriname"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سورينام",
        "common": "سورينام"
      },
      "bre": {
        "official": "Republik Surinam",
        "common": "Surinam"
      },
      "ces": {
        "official": "Republika Surinam",
        "common": "Surinam"
      },
      "cym": {
        "official": "Republic of Suriname",
        "common": "Suriname"
      },
      "deu": {
        "official": "Republik Suriname",
        "common": "Suriname"
      },
      "est": {
        "official": "Suriname Vabariik",
        "common": "Suriname"
      },
      "fin": {
        "official": "Surinamen tasavalta",
        "common": "Suriname"
      },
      "fra": {
        "official": "République du Suriname",
        "common": "Surinam"
      },
      "hrv": {
        "official": "Republika Surinam",
        "common": "Surinam"
      },
      "hun": {
        "official": "Suriname Köztársaság",
        "common": "Suriname"
      },
      "ita": {
        "official": "Repubblica del Suriname",
        "common": "Suriname"
      },
      "jpn": {
        "official": "スリナム共和国",
        "common": "スリナム"
      },
      "kor": {
        "official": "수리남 공화국",
        "common": "수리남"
      },
      "nld": {
        "official": "Republiek Suriname",
        "common": "Suriname"
      },
      "per": {
        "official": "جمهوری سورینام",
        "common": "سورینام"
      },
      "pol": {
        "official": "Republika Surinamu",
        "common": "Surinam"
      },
      "por": {
        "official": "República do Suriname",
        "common": "Suriname"
      },
      "rus": {
        "official": "Республика Суринам",
        "common": "Суринам"
      },
      "slk": {
        "official": "Surinamská republika",
        "common": "Surinam"
      },
      "spa": {
        "official": "República de Suriname",
        "common": "Surinam"
      },
      "swe": {
        "official": "Republiken Surinam",
        "common": "Surinam"
      },
      "tur": {
        "official": "Surinam Cumhuriyeti",
        "common": "Surinam"
      },
      "urd": {
        "official": "جمہوریہ سرینام",
        "common": "سرینام"
      },
      "zho": {
        "official": "苏里南共和国",
        "common": "苏里南"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Surinamer",
        "m": "Surinamer"
      },
      "fra": {
        "f": "Surinamaise",
        "m": "Surinamais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/eh.png",
      "svg": "https://flagcdn.com/eh.svg"
    },
    "cca2": "EH",
    "capital": [
      "El Aaiún"
    ],
    "altSpellings": [
      "EH",
      "Taneẓroft Tutrimt"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية العربية الصحراوية الديمقراطية",
        "common": "الصحراء الغربية"
      },
      "bre": {
        "official": "Republik Arab Saharaoui Demokratel",
        "common": "Sahara ar C'hornôg"
      },
      "ces": {
        "official": "Západní Sahara",
        "common": "Západní Sahara"
      },
      "cym": {
        "official": "Sahrawi Arab Democratic Republic",
        "common": "Western Sahara"
      },
      "deu": {
        "official": "Demokratische Arabische Republik Sahara",
        "common": "Westsahara"
      },
      "est": {
        "official": "Lääne-Sahara",
        "common": "Lääne-Sahara"
      },
      "fin": {
        "official": "Länsi-Sahara",
        "common": "Länsi-Sahara"
      },
      "fra": {
        "official": "République arabe sahraouie démocratique",
        "common": "Sahara Occidental"
      },
      "hrv": {
        "official": "Sahrawi Arab Demokratska Republika",
        "common": "Zapadna Sahara"
      },
      "hun": {
        "official": "Nyugat-Szahara",
        "common": "Nyugat-Szahara"
      },
      "ita": {
        "official": "Repubblica Araba Saharawi Democratica",
        "common": "Sahara Occidentale"
      },
      "jpn": {
        "official": "サハラアラブ民主共和国",
        "common": "西サハラ"
      },
      "kor": {
        "official": "사하라 아랍 민주 공화국",
        "common": "서사하라"
      },
      "nld": {
        "official": "Sahrawi Arabische Democratische Republiek",
        "common": "Westelijke Sahara"
      },
      "per": {
        "official": "صحرای غربی",
        "common": "صحرای غربی"
      },
      "pol": {
        "official": "Saharyjska Arabska Republika Demokratyczna",
        "common": "Sahara Zachodnia"
      },
      "por": {
        "official": "República Árabe Saharaui Democrática",
        "common": "Saara Ocidental"
      },
      "rus": {
        "official": "Sahrawi Арабская Демократическая Республика",
        "common": "Западная Сахара"
      },
      "slk": {
        "official": "Západná Sahara",
        "common": "Západná Sahara"
      },
      "spa": {
        "official": "República Árabe Saharaui Democrática",
        "common": "Sahara Occidental"
      },
      "swe": {
        "official": "Västsahara",
        "common": "Västsahara"
      },
      "tur": {
        "official": "Sahra Demokratik Arap Cumhuriyeti",
        "common": "Sahra Demokratik Arap Cumhuriyeti"
      },
      "urd": {
        "official": "صحراوی عرب عوامی جمہوریہ",
        "common": "مغربی صحارا"
      },
      "zho": {
        "official": "阿拉伯撒哈拉民主共和国",
        "common": "西撒哈拉"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sahrawi",
        "m": "Sahrawi"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mf.png",
      "svg": "https://flagcdn.com/mf.svg"
    },
    "cca2": "MF",
    "capital": [
      "Marigot"
    ],
    "altSpellings": [
      "MF",
      "Collectivity of Saint Martin",
      "Collectivité de Saint-Martin",
      "Saint Martin (French part)"
    ],
    "translations": {
      "ara": {
        "official": "سانت مارتن",
        "common": "سانت مارتن"
      },
      "bre": {
        "official": "Saint-Martin",
        "common": "Saint-Martin"
      },
      "ces": {
        "official": "Svatý Martin",
        "common": "Svatý Martin (Francie)"
      },
      "cym": {
        "official": "Saint Martin",
        "common": "Saint Martin"
      },
      "deu": {
        "official": "Saint-Martin",
        "common": "Saint-Martin"
      },
      "est": {
        "official": "Saint-Martini ühendus",
        "common": "Saint-Martin"
      },
      "fin": {
        "official": "Saint-Martin",
        "common": "Saint-Martin"
      },
      "fra": {
        "official": "Saint-Martin",
        "common": "Saint-Martin"
      },
      "hrv": {
        "official": "Saint Martin",
        "common": "Sveti Martin"
      },
      "hun": {
        "official": "Saint-Martin Közösség",
        "common": "Saint-Martin"
      },
      "ita": {
        "official": "saint Martin",
        "common": "Saint Martin"
      },
      "jpn": {
        "official": "サンマルタン島",
        "common": "サン・マルタン（フランス領）"
      },
      "kor": {
        "official": "생마르탱",
        "common": "생마르탱"
      },
      "nld": {
        "official": "Saint Martin",
        "common": "Saint-Martin"
      },
      "per": {
        "official": "سن مارتن",
        "common": "سن مارتن"
      },
      "pol": {
        "official": "Wspólnota Saint-Martin",
        "common": "Saint-Martin"
      },
      "por": {
        "official": "saint Martin",
        "common": "São Martinho"
      },
      "rus": {
        "official": "Сен-Мартен",
        "common": "Сен-Мартен"
      },
      "slk": {
        "official": "Saint-Martin",
        "common": "Saint-Martin"
      },
      "spa": {
        "official": "Saint Martin",
        "common": "Saint Martin"
      },
      "swe": {
        "official": "Förvaltningsområdet Saint-Martin",
        "common": "Saint-Martin"
      },
      "tur": {
        "official": "Saint Martin",
        "common": "Saint Martin"
      },
      "urd": {
        "official": "سینٹ مارٹن",
        "common": "سینٹ مارٹن"
      },
      "zho": {
        "official": "圣马丁",
        "common": "圣马丁"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint Martin Islander",
        "m": "Saint Martin Islander"
      },
      "fra": {
        "f": "Saint-Martinoise",
        "m": "Saint-Martinois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cr.png",
      "svg": "https://flagcdn.com/cr.svg"
    },
    "cca2": "CR",
    "capital": [
      "San José"
    ],
    "altSpellings": [
      "CR",
      "Republic of Costa Rica",
      "República de Costa Rica"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كوستاريكا",
        "common": "كوستاريكا"
      },
      "bre": {
        "official": "Republik Costa Rica",
        "common": "Costa Rica"
      },
      "ces": {
        "official": "Kostarická republika",
        "common": "Kostarika"
      },
      "cym": {
        "official": "Gweriniaeth Costa Rica",
        "common": "Costa Rica"
      },
      "deu": {
        "official": "Republik Costa Rica",
        "common": "Costa Rica"
      },
      "est": {
        "official": "Costa Rica Vabariik",
        "common": "Costa Rica"
      },
      "fin": {
        "official": "Costa Rican tasavalta",
        "common": "Costa Rica"
      },
      "fra": {
        "official": "République du Costa Rica",
        "common": "Costa Rica"
      },
      "hrv": {
        "official": "Republika Kostarika",
        "common": "Kostarika"
      },
      "hun": {
        "official": "Costa Rica-i Köztársaság",
        "common": "Costa Rica"
      },
      "ita": {
        "official": "Repubblica di Costa Rica",
        "common": "Costa Rica"
      },
      "jpn": {
        "official": "コスタリカ共和国",
        "common": "コスタリカ"
      },
      "kor": {
        "official": "코스타리카 공화국",
        "common": "코스타리카"
      },
      "nld": {
        "official": "Republiek Costa Rica",
        "common": "Costa Rica"
      },
      "per": {
        "official": "جمهوری کاستاریکا",
        "common": "کاستاریکا"
      },
      "pol": {
        "official": "Republika Kostaryki",
        "common": "Kostaryka"
      },
      "por": {
        "official": "República da Costa Rica",
        "common": "Costa Rica"
      },
      "rus": {
        "official": "Республика Коста-Рика",
        "common": "Коста-Рика"
      },
      "slk": {
        "official": "Kostarická republika",
        "common": "Kostarika"
      },
      "spa": {
        "official": "República de Costa Rica",
        "common": "Costa Rica"
      },
      "swe": {
        "official": "Republiken Costa Rica",
        "common": "Costa Rica"
      },
      "tur": {
        "official": "Kosta Rika Cumhuriyeti",
        "common": "Kosta Rika"
      },
      "urd": {
        "official": "جمہوریہ کوسٹاریکا",
        "common": "کوسٹاریکا"
      },
      "zho": {
        "official": "哥斯达黎加共和国",
        "common": "哥斯达黎加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Costa Rican",
        "m": "Costa Rican"
      },
      "fra": {
        "f": "Costaricaine",
        "m": "Costaricain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/hm.png",
      "svg": "https://flagcdn.com/hm.svg"
    },
    "cca2": "HM",
    "capital": [],
    "altSpellings": [
      "HM",
      "Heard Island and McDonald Islands"
    ],
    "translations": {
      "ara": {
        "official": "جزيرة هيرد وجزر ماكدونالد",
        "common": "جزيرة هيرد وجزر ماكدونالد"
      },
      "bre": {
        "official": "Enez Heard hag Inizi McDonald",
        "common": "Inizi Heard ha McDonald"
      },
      "ces": {
        "official": "Heardův ostrov a McDonaldovy ostrovy",
        "common": "Heardův ostrov a McDonaldovy ostrovy"
      },
      "cym": {
        "official": "Heard Island and McDonald Islands",
        "common": "Heard Island and McDonald Islands"
      },
      "deu": {
        "official": "Heard und McDonaldinseln",
        "common": "Heard und die McDonaldinseln"
      },
      "est": {
        "official": "Heardi ja McDonaldi saarte ala",
        "common": "Heard ja McDonald"
      },
      "fin": {
        "official": "Heard ja McDonaldinsaaret",
        "common": "Heard ja McDonaldinsaaret"
      },
      "fra": {
        "official": "Des îles Heard et McDonald",
        "common": "Îles Heard-et-MacDonald"
      },
      "hrv": {
        "official": "Otok Heard i otočje McDonald",
        "common": "Otok Heard i otočje McDonald"
      },
      "hun": {
        "official": "Heard-sziget és McDonald-szigetek",
        "common": "Heard-sziget és McDonald-szigetek"
      },
      "ita": {
        "official": "Isole Heard e McDonald",
        "common": "Isole Heard e McDonald"
      },
      "jpn": {
        "official": "ハード島とマクドナルド諸島",
        "common": "ハード島とマクドナルド諸島"
      },
      "kor": {
        "official": "허드 맥도널드 제도",
        "common": "허드 맥도널드 제도"
      },
      "nld": {
        "official": "Heard en McDonaldeilanden",
        "common": "Heard-en McDonaldeilanden"
      },
      "per": {
        "official": "جزیره هرد و جزایر مک‌دونالد",
        "common": "جزیره هرد و جزایر مک‌دونالد"
      },
      "pol": {
        "official": "Terytorium Wysp Heard i McDonalda",
        "common": "Wyspy Heard i McDonalda"
      },
      "por": {
        "official": "Ilha Heard e Ilhas McDonald",
        "common": "Ilha Heard e Ilhas McDonald"
      },
      "rus": {
        "official": "Остров Херд и острова Макдональд",
        "common": "Остров Херд и острова Макдональд"
      },
      "slk": {
        "official": "Teritórium Heardovho ostrova a Macdonaldových ostrovov",
        "common": "Heardov ostrov"
      },
      "spa": {
        "official": "Islas Heard y McDonald",
        "common": "Islas Heard y McDonald"
      },
      "swe": {
        "official": "Heard- och McDonaldöarna",
        "common": "Heard- och McDonaldöarna"
      },
      "tur": {
        "official": "Heard Adası ve McDonald Adaları",
        "common": "Heard Adası ve McDonald Adaları"
      },
      "urd": {
        "official": "جزیرہ ہرڈ و جزائر مکڈونلڈ",
        "common": "جزیرہ ہرڈ و جزائر مکڈونلڈ"
      },
      "zho": {
        "official": "赫德岛和麦当劳群岛",
        "common": "赫德岛和麦当劳群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Heard and McDonald Islander",
        "m": "Heard and McDonald Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ie.png",
      "svg": "https://flagcdn.com/ie.svg"
    },
    "cca2": "IE",
    "capital": [
      "Dublin"
    ],
    "altSpellings": [
      "IE",
      "Éire",
      "Republic of Ireland",
      "Poblacht na hÉireann"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أيرلندا",
        "common": "أيرلندا"
      },
      "bre": {
        "official": "Republik Iwerzhon",
        "common": "Iwerzhon"
      },
      "ces": {
        "official": "Irsko",
        "common": "Irsko"
      },
      "cym": {
        "official": "Republic of Ireland",
        "common": "Ireland"
      },
      "deu": {
        "official": "Republik Irland",
        "common": "Irland"
      },
      "est": {
        "official": "Iirimaa",
        "common": "Iirimaa"
      },
      "fin": {
        "official": "Irlannin tasavalta",
        "common": "Irlanti"
      },
      "fra": {
        "official": "République d'Irlande",
        "common": "Irlande"
      },
      "hrv": {
        "official": "Republika Irska",
        "common": "Irska"
      },
      "hun": {
        "official": "Ír Köztársaság",
        "common": "Írország"
      },
      "ita": {
        "official": "Repubblica d'Irlanda",
        "common": "Irlanda"
      },
      "jpn": {
        "official": "アイルランド共和国",
        "common": "アイルランド"
      },
      "kor": {
        "official": "아일랜드 공화국",
        "common": "아일랜드"
      },
      "nld": {
        "official": "Republic of Ireland",
        "common": "Ierland"
      },
      "per": {
        "official": "ایرلند",
        "common": "ایرلند"
      },
      "pol": {
        "official": "Republika Irlandii",
        "common": "Irlandia"
      },
      "por": {
        "official": "República da Irlanda",
        "common": "Irlanda"
      },
      "rus": {
        "official": "Ирландия",
        "common": "Ирландия"
      },
      "slk": {
        "official": "Írska republika",
        "common": "Írsko"
      },
      "spa": {
        "official": "República de Irlanda",
        "common": "Irlanda"
      },
      "swe": {
        "official": "Irland",
        "common": "Irland"
      },
      "tur": {
        "official": "İrlanda Cumhuriyeti",
        "common": "İrlanda"
      },
      "urd": {
        "official": "جمہوریہ جزیرہ آئرلینڈ",
        "common": "جزیرہ آئرلینڈ"
      },
      "zho": {
        "official": "爱尔兰共和国",
        "common": "爱尔兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Irish",
        "m": "Irish"
      },
      "fra": {
        "f": "Irlandaise",
        "m": "Irlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/aq.png",
      "svg": "https://flagcdn.com/aq.svg"
    },
    "cca2": "AQ",
    "capital": [],
    "altSpellings": [
      "AQ"
    ],
    "translations": {
      "ara": {
        "official": "أنتارتيكا",
        "common": "أنتارتيكا"
      },
      "bre": {
        "official": "Antarktika",
        "common": "Antarktika"
      },
      "ces": {
        "official": "Antarktida",
        "common": "Antarktida"
      },
      "cym": {
        "official": "Yr Antarctig",
        "common": "Yr Antarctig"
      },
      "deu": {
        "official": "Antarktika",
        "common": "Antarktis"
      },
      "est": {
        "official": "Antarktika",
        "common": "Antarktika"
      },
      "fin": {
        "official": "Etelämanner",
        "common": "Etelämanner"
      },
      "fra": {
        "official": "Antarctique",
        "common": "Antarctique"
      },
      "hrv": {
        "official": "Antarktika",
        "common": "Antarktika"
      },
      "hun": {
        "official": "Antarktisz",
        "common": "Antarktisz"
      },
      "ita": {
        "official": "Antartide",
        "common": "Antartide"
      },
      "jpn": {
        "official": "南極大陸",
        "common": "南極"
      },
      "kor": {
        "official": "남극",
        "common": "남극"
      },
      "nld": {
        "official": "Antarctica",
        "common": "Antarctica"
      },
      "per": {
        "official": "جنوبگان",
        "common": "جنوبگان"
      },
      "pol": {
        "official": "Antarktyka",
        "common": "Antarktyka"
      },
      "por": {
        "official": "Antártica",
        "common": "Antártida"
      },
      "rus": {
        "official": "Антарктида",
        "common": "Антарктида"
      },
      "slk": {
        "official": "Antarktída",
        "common": "Antarktída"
      },
      "spa": {
        "official": "Antártida",
        "common": "Antártida"
      },
      "swe": {
        "official": "Antarktis",
        "common": "Antarktis"
      },
      "tur": {
        "official": "Antarktika",
        "common": "Antarktika"
      },
      "urd": {
        "official": "انٹارکٹکا",
        "common": "انٹارکٹکا"
      },
      "zho": {
        "official": "南极洲",
        "common": "南极洲"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Antarctican",
        "m": "Antarctican"
      },
      "fra": {
        "f": "Antarcticaine",
        "m": "Antarcticain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/au.png",
      "svg": "https://flagcdn.com/au.svg"
    },
    "cca2": "AU",
    "capital": [
      "Canberra"
    ],
    "altSpellings": [
      "AU"
    ],
    "translations": {
      "ara": {
        "official": "كومونولث أستراليا",
        "common": "أستراليا"
      },
      "bre": {
        "official": "Kenglad Aostralia",
        "common": "Aostralia"
      },
      "ces": {
        "official": "Australské společenství",
        "common": "Austrálie"
      },
      "cym": {
        "official": "Cymanwlad Awstralia",
        "common": "Awstralia"
      },
      "deu": {
        "official": "Commonwealth Australien",
        "common": "Australien"
      },
      "est": {
        "official": "Austraalia Ühendus",
        "common": "Austraalia"
      },
      "fin": {
        "official": "Australian liittovaltio",
        "common": "Australia"
      },
      "fra": {
        "official": "Australie",
        "common": "Australie"
      },
      "hrv": {
        "official": "Commonwealth of Australia",
        "common": "Australija"
      },
      "hun": {
        "official": "Ausztrál Államszövetség",
        "common": "Ausztrália"
      },
      "ita": {
        "official": "Commonwealth dell'Australia",
        "common": "Australia"
      },
      "jpn": {
        "official": "オーストラリア連邦",
        "common": "オーストラリア"
      },
      "kor": {
        "official": "오스트레일리아 연방",
        "common": "호주"
      },
      "nld": {
        "official": "Gemenebest van Australië",
        "common": "Australië"
      },
      "per": {
        "official": "قلمرو همسود استرالیا",
        "common": "استرالیا"
      },
      "pol": {
        "official": "Związek Australijski",
        "common": "Australia"
      },
      "por": {
        "official": "Comunidade da Austrália",
        "common": "Austrália"
      },
      "rus": {
        "official": "Содружество Австралии",
        "common": "Австралия"
      },
      "slk": {
        "official": "Austrálsky zväz",
        "common": "Austrália"
      },
      "spa": {
        "official": "Mancomunidad de Australia",
        "common": "Australia"
      },
      "swe": {
        "official": "Australiska statsförbundet",
        "common": "Australien"
      },
      "tur": {
        "official": "Avustralya Federal Devleti",
        "common": "Avustralya"
      },
      "urd": {
        "official": "دولتِ مشترکہ آسٹریلیا",
        "common": "آسٹریلیا"
      },
      "zho": {
        "official": "澳大利亚联邦",
        "common": "澳大利亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Australian",
        "m": "Australian"
      },
      "fra": {
        "f": "Australienne",
        "m": "Australien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ly.png",
      "svg": "https://flagcdn.com/ly.svg"
    },
    "cca2": "LY",
    "capital": [
      "Tripoli"
    ],
    "altSpellings": [
      "LY",
      "State of Libya",
      "Dawlat Libya"
    ],
    "translations": {
      "ara": {
        "official": "دولة ليبيا",
        "common": "‏ليبيا"
      },
      "bre": {
        "official": "Stad Libia",
        "common": "Libia"
      },
      "ces": {
        "official": "Stát Libye",
        "common": "Libye"
      },
      "cym": {
        "official": "State of Libya",
        "common": "Libya"
      },
      "deu": {
        "official": "Staat Libyen",
        "common": "Libyen"
      },
      "est": {
        "official": "Liibüa",
        "common": "Liibüa"
      },
      "fin": {
        "official": "Libyan valtio",
        "common": "Libya"
      },
      "fra": {
        "official": "Grande République arabe libyenne populaire et socialiste",
        "common": "Libye"
      },
      "hrv": {
        "official": "Država Libiji",
        "common": "Libija"
      },
      "hun": {
        "official": "Líbia Állam",
        "common": "Líbia"
      },
      "ita": {
        "official": "Stato della Libia",
        "common": "Libia"
      },
      "jpn": {
        "official": "リビアの国家",
        "common": "リビア"
      },
      "kor": {
        "official": "리비아",
        "common": "리비아"
      },
      "nld": {
        "official": "Staat van Libië",
        "common": "Libië"
      },
      "per": {
        "official": "دولت لیبی",
        "common": "لیبی"
      },
      "pol": {
        "official": "Państwo Libia",
        "common": "Libia"
      },
      "por": {
        "official": "Estado da Líbia",
        "common": "Líbia"
      },
      "rus": {
        "official": "Государство Ливии",
        "common": "Ливия"
      },
      "slk": {
        "official": "Líbya",
        "common": "Líbya"
      },
      "spa": {
        "official": "Estado de Libia",
        "common": "Libia"
      },
      "swe": {
        "official": "Staten Libyen",
        "common": "Libyen"
      },
      "tur": {
        "official": "Libya Devleti",
        "common": "Libya"
      },
      "urd": {
        "official": "ریاستِ لیبیا",
        "common": "لیبیا"
      },
      "zho": {
        "official": "利比亚国",
        "common": "利比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Libyan",
        "m": "Libyan"
      },
      "fra": {
        "f": "Libyenne",
        "m": "Libyen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/qa.png",
      "svg": "https://flagcdn.com/qa.svg"
    },
    "cca2": "QA",
    "capital": [
      "Doha"
    ],
    "altSpellings": [
      "QA",
      "State of Qatar",
      "Dawlat Qaṭar"
    ],
    "translations": {
      "ara": {
        "official": "دولة قطر",
        "common": "قطر"
      },
      "bre": {
        "official": "Stad Katar",
        "common": "Katar"
      },
      "ces": {
        "official": "Stát Katar",
        "common": "Katar"
      },
      "cym": {
        "official": "State of Qatar",
        "common": "Qatar"
      },
      "deu": {
        "official": "Staat Katar",
        "common": "Katar"
      },
      "est": {
        "official": "Katari Riik",
        "common": "Katar"
      },
      "fin": {
        "official": "Qatarin valtio",
        "common": "Qatar"
      },
      "fra": {
        "official": "État du Qatar",
        "common": "Qatar"
      },
      "hrv": {
        "official": "Država Katar",
        "common": "Katar"
      },
      "hun": {
        "official": "Katari Állam",
        "common": "Katar"
      },
      "ita": {
        "official": "Stato del Qatar",
        "common": "Qatar"
      },
      "jpn": {
        "official": "カタール国",
        "common": "カタール"
      },
      "kor": {
        "official": "카타르국",
        "common": "카타르"
      },
      "nld": {
        "official": "Staat Qatar",
        "common": "Qatar"
      },
      "per": {
        "official": "دولت قطر",
        "common": "قطر"
      },
      "pol": {
        "official": "Państwo Katar",
        "common": "Katar"
      },
      "por": {
        "official": "Estado do Qatar",
        "common": "Catar"
      },
      "rus": {
        "official": "Государство Катар",
        "common": "Катар"
      },
      "slk": {
        "official": "Katarský štát",
        "common": "Katar"
      },
      "spa": {
        "official": "Estado de Qatar",
        "common": "Catar"
      },
      "swe": {
        "official": "Staten Qatar",
        "common": "Qatar"
      },
      "tur": {
        "official": "Katar Devleti",
        "common": "Katar"
      },
      "urd": {
        "official": "ریاستِ قطر",
        "common": "قطر"
      },
      "zho": {
        "official": "卡塔尔国",
        "common": "卡塔尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Qatari",
        "m": "Qatari"
      },
      "fra": {
        "f": "Qatarienne",
        "m": "Qatarien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gl.png",
      "svg": "https://flagcdn.com/gl.svg"
    },
    "cca2": "GL",
    "capital": [
      "Nuuk"
    ],
    "altSpellings": [
      "GL",
      "Grønland"
    ],
    "translations": {
      "ara": {
        "official": "جرينلاند",
        "common": "جرينلاند"
      },
      "bre": {
        "official": "Greunland",
        "common": "Greunland"
      },
      "ces": {
        "official": "Grónsko",
        "common": "Grónsko"
      },
      "cym": {
        "official": "Greenland",
        "common": "Greenland"
      },
      "deu": {
        "official": "Grönland",
        "common": "Grönland"
      },
      "est": {
        "official": "Gröönimaa",
        "common": "Gröönimaa"
      },
      "fin": {
        "official": "Groönlanti",
        "common": "Groönlanti"
      },
      "fra": {
        "official": "Groenland",
        "common": "Groenland"
      },
      "hrv": {
        "official": "Grenland",
        "common": "Grenland"
      },
      "hun": {
        "official": "Grönland",
        "common": "Grönland"
      },
      "ita": {
        "official": "Groenlandia",
        "common": "Groenlandia"
      },
      "jpn": {
        "official": "グリーンランド",
        "common": "グリーンランド"
      },
      "kor": {
        "official": "그린란드",
        "common": "그린란드"
      },
      "nld": {
        "official": "Groenland",
        "common": "Groenland"
      },
      "per": {
        "official": "گروئنلند",
        "common": "گرینلند"
      },
      "pol": {
        "official": "Grenlandia",
        "common": "Grenlandia"
      },
      "por": {
        "official": "Groenlândia",
        "common": "Gronelândia"
      },
      "rus": {
        "official": "Гренландия",
        "common": "Гренландия"
      },
      "slk": {
        "official": "Grónsko",
        "common": "Grónsko"
      },
      "spa": {
        "official": "Groenlandia",
        "common": "Groenlandia"
      },
      "swe": {
        "official": "Grönland",
        "common": "Grönland"
      },
      "tur": {
        "official": "Grönland",
        "common": "Grönland"
      },
      "urd": {
        "official": "گرین لینڈ",
        "common": "گرین لینڈ"
      },
      "zho": {
        "official": "格陵兰",
        "common": "格陵兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Greenlandic",
        "m": "Greenlandic"
      },
      "fra": {
        "f": "Groenlandaise",
        "m": "Groenlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mu.png",
      "svg": "https://flagcdn.com/mu.svg"
    },
    "cca2": "MU",
    "capital": [
      "Port Louis"
    ],
    "altSpellings": [
      "MU",
      "Republic of Mauritius",
      "République de Maurice"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية موريشيوس",
        "common": "موريشيوس"
      },
      "bre": {
        "official": "Republik Moris",
        "common": "Moris"
      },
      "ces": {
        "official": "Mauricijská republika",
        "common": "Mauricius"
      },
      "cym": {
        "official": "Republic of Mauritius",
        "common": "Mauritius"
      },
      "deu": {
        "official": "Republik Mauritius",
        "common": "Mauritius"
      },
      "est": {
        "official": "Mauritiuse Vabariik",
        "common": "Mauritius"
      },
      "fin": {
        "official": "Mauritiuksen tasavalta",
        "common": "Mauritius"
      },
      "fra": {
        "official": "République de Maurice",
        "common": "Île Maurice"
      },
      "hrv": {
        "official": "Republika Mauricijus",
        "common": "Mauricijus"
      },
      "hun": {
        "official": "Mauritiusi Köztársaság",
        "common": "Mauritius"
      },
      "ita": {
        "official": "Repubblica di Mauritius",
        "common": "Mauritius"
      },
      "jpn": {
        "official": "モーリシャス共和国",
        "common": "モーリシャス"
      },
      "kor": {
        "official": "모리셔스 공화국",
        "common": "모리셔스"
      },
      "nld": {
        "official": "Republiek Mauritius",
        "common": "Mauritius"
      },
      "per": {
        "official": "جمهوری موریس",
        "common": "موریس"
      },
      "pol": {
        "official": "Republika Mauritiusu",
        "common": "Mauritius"
      },
      "por": {
        "official": "República das Maurícias",
        "common": "Maurício"
      },
      "rus": {
        "official": "Республика Маврикий",
        "common": "Маврикий"
      },
      "slk": {
        "official": "Maurícijská republika",
        "common": "Maurícius"
      },
      "spa": {
        "official": "República de Mauricio",
        "common": "Mauricio"
      },
      "swe": {
        "official": "Republiken Mauritius",
        "common": "Mauritius"
      },
      "tur": {
        "official": "Mauritius Cumhuriyeti",
        "common": "Mauritius"
      },
      "urd": {
        "official": "جمہوریہ موریشس",
        "common": "موریشس"
      },
      "zho": {
        "official": "毛里求斯共和国",
        "common": "毛里求斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mauritian",
        "m": "Mauritian"
      },
      "fra": {
        "f": "Mauricienne",
        "m": "Mauricien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kz.png",
      "svg": "https://flagcdn.com/kz.svg"
    },
    "cca2": "KZ",
    "capital": [
      "Nur-Sultan"
    ],
    "altSpellings": [
      "KZ",
      "Qazaqstan",
      "Казахстан",
      "Republic of Kazakhstan",
      "Қазақстан Республикасы",
      "Qazaqstan Respublïkası",
      "Республика Казахстан",
      "Respublika Kazakhstan"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كازاخستان",
        "common": "كازاخستان"
      },
      "bre": {
        "official": "Republik Kazakstan",
        "common": "Kazakstan"
      },
      "ces": {
        "official": "Republika Kazachstán",
        "common": "Kazachstán"
      },
      "cym": {
        "official": "Republic of Kazakhstan",
        "common": "Kazakhstan"
      },
      "deu": {
        "official": "Republik Kasachstan",
        "common": "Kasachstan"
      },
      "est": {
        "official": "Kasahstani Vabariik",
        "common": "Kasahstan"
      },
      "fin": {
        "official": "Kazakstanin tasavalta",
        "common": "Kazakstan"
      },
      "fra": {
        "official": "République du Kazakhstan",
        "common": "Kazakhstan"
      },
      "hrv": {
        "official": "Republika Kazahstan",
        "common": "Kazahstan"
      },
      "hun": {
        "official": "Kazah Köztársaság",
        "common": "Kazahsztán"
      },
      "ita": {
        "official": "Repubblica del Kazakhstan",
        "common": "Kazakistan"
      },
      "jpn": {
        "official": "カザフスタン共和国",
        "common": "カザフスタン"
      },
      "kor": {
        "official": "카자흐스탄 공화국",
        "common": "카자흐스탄"
      },
      "nld": {
        "official": "Republiek Kazachstan",
        "common": "Kazachstan"
      },
      "per": {
        "official": "جمهوری قزاقستان",
        "common": "قزاقستان"
      },
      "pol": {
        "official": "Republika Kazachstanu",
        "common": "Kazachstan"
      },
      "por": {
        "official": "República do Cazaquistão",
        "common": "Cazaquistão"
      },
      "rus": {
        "official": "Республика Казахстан",
        "common": "Казахстан"
      },
      "slk": {
        "official": "Kazašská republika",
        "common": "Kazachstan"
      },
      "spa": {
        "official": "República de Kazajstán",
        "common": "Kazajistán"
      },
      "swe": {
        "official": "Republiken Kazakstan",
        "common": "Kazakstan"
      },
      "tur": {
        "official": "Kazakistan Cumhuriyeti",
        "common": "Kazakistan"
      },
      "urd": {
        "official": "جمہوریہ قازقستان",
        "common": "قازقستان"
      },
      "zho": {
        "official": "哈萨克斯坦共和国",
        "common": "哈萨克斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kazakhstani",
        "m": "Kazakhstani"
      },
      "fra": {
        "f": "Kazakhstanaise",
        "m": "Kazakhstanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/io.png",
      "svg": "https://flagcdn.com/io.svg"
    },
    "cca2": "IO",
    "capital": [
      "Diego Garcia"
    ],
    "altSpellings": [
      "IO"
    ],
    "translations": {
      "ara": {
        "official": "إقليم المحيط الهندي البريطاني",
        "common": "إقليم المحيط الهندي البريطاني"
      },
      "bre": {
        "official": "Tiriad breizhveurat Meurvor Indez",
        "common": "Tiriad breizhveurat Meurvor Indez"
      },
      "ces": {
        "official": "Britské indickooceánské území",
        "common": "Britské indickooceánské území"
      },
      "cym": {
        "official": "Tiriogaeth Brydeinig Cefnfor India",
        "common": "Tiriogaeth Brydeinig Cefnfor India"
      },
      "deu": {
        "official": "Britisches Territorium im Indischen Ozean",
        "common": "Britisches Territorium im Indischen Ozean"
      },
      "est": {
        "official": "Briti India ookeani ala",
        "common": "Briti India ookeani ala"
      },
      "fin": {
        "official": "Brittiläinen Intian valtameren alue",
        "common": "Brittiläinen Intian valtameren alue"
      },
      "fra": {
        "official": "Territoire britannique de l' océan Indien",
        "common": "Territoire britannique de l'océan Indien"
      },
      "hrv": {
        "official": "British Indian Ocean Territory",
        "common": "Britanski Indijskooceanski teritorij"
      },
      "hun": {
        "official": "Brit Indiai-óceáni Terület",
        "common": "Brit Indiai-óceáni Terület"
      },
      "ita": {
        "official": "Territorio britannico dell'Oceano Indiano",
        "common": "Territorio britannico dell'oceano indiano"
      },
      "jpn": {
        "official": "イギリス領インド洋地域",
        "common": "イギリス領インド洋地域"
      },
      "kor": {
        "official": "인도 공화국",
        "common": "인도"
      },
      "nld": {
        "official": "Brits Indische Oceaan Territorium",
        "common": "Britse Gebieden in de Indische Oceaan"
      },
      "per": {
        "official": "قلمرو بریتانیا در اقیانوس هند",
        "common": "قلمرو بریتانیا در اقیانوس هند"
      },
      "pol": {
        "official": "Brytyjskie Terytorium Oceanu Indyjskiego",
        "common": "Brytyjskie Terytorium Oceanu Indyjskiego"
      },
      "por": {
        "official": "British Indian Ocean Territory",
        "common": "Território Britânico do Oceano Índico"
      },
      "rus": {
        "official": "Британская территория Индийского океана",
        "common": "Британская территория в Индийском океане"
      },
      "slk": {
        "official": "Britské indickooceánske územie",
        "common": "Britské indickooceánske územie"
      },
      "spa": {
        "official": "Territorio Británico del Océano Índico",
        "common": "Territorio Británico del Océano Índico"
      },
      "swe": {
        "official": "Brittiska territoriet i Indiska Oceanen",
        "common": "Brittiska territoriet i Indiska Oceanen"
      },
      "tur": {
        "official": "Britanya Hint Okyanusu Toprakları",
        "common": "Britanya Hint Okyanusu Toprakları"
      },
      "urd": {
        "official": "برطانوی بحرہند خطہ",
        "common": "برطانوی بحرہند خطہ"
      },
      "zho": {
        "official": "英属印度洋领地",
        "common": "英属印度洋领地"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Indian",
        "m": "Indian"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/al.png",
      "svg": "https://flagcdn.com/al.svg"
    },
    "cca2": "AL",
    "capital": [
      "Tirana"
    ],
    "altSpellings": [
      "AL",
      "Shqipëri",
      "Shqipëria",
      "Shqipnia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ألبانيا",
        "common": "ألبانيا"
      },
      "bre": {
        "official": "Republik Albania",
        "common": "Albania"
      },
      "ces": {
        "official": "Albánská republika",
        "common": "Albánie"
      },
      "cym": {
        "official": "Gweriniaeth Albania",
        "common": "Albania"
      },
      "deu": {
        "official": "Republik Albanien",
        "common": "Albanien"
      },
      "est": {
        "official": "Albaania Vabariik",
        "common": "Albaania"
      },
      "fin": {
        "official": "Albanian tasavalta",
        "common": "Albania"
      },
      "fra": {
        "official": "République d'Albanie",
        "common": "Albanie"
      },
      "hrv": {
        "official": "Republika Albanija",
        "common": "Albanija"
      },
      "hun": {
        "official": "Albán Köztársaság",
        "common": "Albánia"
      },
      "ita": {
        "official": "Repubblica d'Albania",
        "common": "Albania"
      },
      "jpn": {
        "official": "アルバニア共和国",
        "common": "アルバニア"
      },
      "kor": {
        "official": "알바니아 공화국",
        "common": "알바니아"
      },
      "nld": {
        "official": "Republiek Albanië",
        "common": "Albanië"
      },
      "per": {
        "official": "جمهوری آلبانی",
        "common": "آلبانی"
      },
      "pol": {
        "official": "Republika Albanii",
        "common": "Albania"
      },
      "por": {
        "official": "República da Albânia",
        "common": "Albânia"
      },
      "rus": {
        "official": "Республика Албания",
        "common": "Албания"
      },
      "slk": {
        "official": "Albánska republika",
        "common": "Albánsko"
      },
      "spa": {
        "official": "República de Albania",
        "common": "Albania"
      },
      "swe": {
        "official": "Republiken Albanien",
        "common": "Albanien"
      },
      "tur": {
        "official": "Arnavutluk Cumhuriyeti",
        "common": "Arnavutluk"
      },
      "urd": {
        "official": "جمہوریہ البانیا",
        "common": "البانیا"
      },
      "zho": {
        "official": "阿尔巴尼亚共和国",
        "common": "阿尔巴尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Albanian",
        "m": "Albanian"
      },
      "fra": {
        "f": "Albanaise",
        "m": "Albanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bh.png",
      "svg": "https://flagcdn.com/bh.svg"
    },
    "cca2": "BH",
    "capital": [
      "Manama"
    ],
    "altSpellings": [
      "BH",
      "Kingdom of Bahrain",
      "Mamlakat al-Baḥrayn"
    ],
    "translations": {
      "ara": {
        "official": "مملكة البحرين",
        "common": "‏البحرين"
      },
      "bre": {
        "official": "Rouantelezh Bahrein",
        "common": "Bahrein"
      },
      "ces": {
        "official": "Království Bahrajn",
        "common": "Bahrajn"
      },
      "cym": {
        "official": "Teyrnas Bahrein",
        "common": "Bahrain"
      },
      "deu": {
        "official": "Königreich Bahrain",
        "common": "Bahrain"
      },
      "est": {
        "official": "Bahreini Kuningriik",
        "common": "Bahrein"
      },
      "fin": {
        "official": "Bahrainin kuningaskunta",
        "common": "Bahrain"
      },
      "fra": {
        "official": "Royaume de Bahreïn",
        "common": "Bahreïn"
      },
      "hrv": {
        "official": "Kraljevina Bahrein",
        "common": "Bahrein"
      },
      "hun": {
        "official": "Bahreini Királyság",
        "common": "Bahrein"
      },
      "ita": {
        "official": "Regno del Bahrain",
        "common": "Bahrein"
      },
      "jpn": {
        "official": "バーレーン王国",
        "common": "バーレーン"
      },
      "kor": {
        "official": "바레인 왕국",
        "common": "바레인"
      },
      "nld": {
        "official": "Koninkrijk Bahrein",
        "common": "Bahrein"
      },
      "per": {
        "official": "پادشاهی بحرین",
        "common": "بحرین"
      },
      "pol": {
        "official": "Królestwo Bahrajnu",
        "common": "Bahrajn"
      },
      "por": {
        "official": "Reino do Bahrein",
        "common": "Bahrein"
      },
      "rus": {
        "official": "Королевство Бахрейн",
        "common": "Бахрейн"
      },
      "slk": {
        "official": "Bahrajnské kráľovstvo",
        "common": "Bahrajn"
      },
      "spa": {
        "official": "Reino de Bahrein",
        "common": "Bahrein"
      },
      "swe": {
        "official": "Konungariket Bahrain",
        "common": "Bahrain"
      },
      "tur": {
        "official": "Bahreyn Krallığı",
        "common": "Bahreyn"
      },
      "urd": {
        "official": "مملکتِ بحرین",
        "common": "بحرین"
      },
      "zho": {
        "official": "巴林王国",
        "common": "巴林"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bahraini",
        "m": "Bahraini"
      },
      "fra": {
        "f": "Bahreïnienne",
        "m": "Bahreïnien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pg.png",
      "svg": "https://flagcdn.com/pg.svg"
    },
    "cca2": "PG",
    "capital": [
      "Port Moresby"
    ],
    "altSpellings": [
      "PG",
      "Independent State of Papua New Guinea",
      "Independen Stet bilong Papua Niugini"
    ],
    "translations": {
      "ara": {
        "official": "دولة بابوا غينيا الجديدة",
        "common": "بابوا غينيا الجديدة"
      },
      "bre": {
        "official": "Stad dizalc'h Papoua-Ginea Nevez",
        "common": "Papoua-Ginea Nevez"
      },
      "ces": {
        "official": "Nezávislý stát Papua Nová Guinea",
        "common": "Papua-Nová Guinea"
      },
      "cym": {
        "official": "Independent State of Papua New Guinea",
        "common": "Papua New Guinea"
      },
      "deu": {
        "official": "Unabhängiger Staat Papua-Neuguinea",
        "common": "Papua-Neuguinea"
      },
      "est": {
        "official": "Paapua Uus-Guinea Iseseisvusriik",
        "common": "Paapua Uus-Guinea"
      },
      "fin": {
        "official": "Papua-Uuden-Guinean Itsenäinen valtio",
        "common": "Papua-Uusi-Guinea"
      },
      "fra": {
        "official": "État indépendant de Papouasie-Nouvelle-Guinée",
        "common": "Papouasie-Nouvelle-Guinée"
      },
      "hrv": {
        "official": "Nezavisna Država Papui Novoj Gvineji",
        "common": "Papua Nova Gvineja"
      },
      "hun": {
        "official": "Pápua Új-Guinea Független Állam",
        "common": "Pápua Új-Guinea"
      },
      "ita": {
        "official": "Stato indipendente di Papua Nuova Guinea",
        "common": "Papua Nuova Guinea"
      },
      "jpn": {
        "official": "パプアニューギニア独立国",
        "common": "パプアニューギニア"
      },
      "kor": {
        "official": "파푸아뉴기니 독립국",
        "common": "파푸아뉴기니"
      },
      "nld": {
        "official": "Onafhankelijke Staat Papoea -Nieuw-Guinea",
        "common": "Papoea-Nieuw-Guinea"
      },
      "per": {
        "official": "مملکت مستقل پاپوآ گینهٔ نو",
        "common": "پاپوآ گینه نو"
      },
      "pol": {
        "official": "Niezależne Państwo Papui-Nowej Gwinei",
        "common": "Papua-Nowa Gwinea"
      },
      "por": {
        "official": "Estado Independente da Papua Nova Guiné",
        "common": "Papua Nova Guiné"
      },
      "rus": {
        "official": "Независимое Государство Папуа-Новой Гвинеи",
        "common": "Папуа — Новая Гвинея"
      },
      "slk": {
        "official": "Nezávislý štát Papua-Nová Guinea",
        "common": "Papua-Nová Guinea"
      },
      "spa": {
        "official": "Estado Independiente de Papúa Nueva Guinea",
        "common": "Papúa Nueva Guinea"
      },
      "swe": {
        "official": "Den oberoende staten Papua Nya Guinea",
        "common": "Papua Nya Guinea"
      },
      "tur": {
        "official": "Papua Yeni Gine Bağımsız Devleti",
        "common": "Papua Yeni Gine"
      },
      "urd": {
        "official": "آزاد ریاستِ پاپوا نیو گنی",
        "common": "پاپوا نیو گنی"
      },
      "zho": {
        "official": "巴布亚新几内亚",
        "common": "巴布亚新几内亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Papua New Guinean",
        "m": "Papua New Guinean"
      },
      "fra": {
        "f": "Papouasienne",
        "m": "Papouasien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bi.png",
      "svg": "https://flagcdn.com/bi.svg"
    },
    "cca2": "BI",
    "capital": [
      "Gitega"
    ],
    "altSpellings": [
      "BI",
      "Republic of Burundi",
      "Republika y'Uburundi",
      "République du Burundi"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بوروندي",
        "common": "بوروندي"
      },
      "bre": {
        "official": "Republik Burundi",
        "common": "Burundi"
      },
      "ces": {
        "official": "Burundská republika",
        "common": "Burundi"
      },
      "cym": {
        "official": "Gweriniaeth Bwrwndi",
        "common": "Bwrwndi"
      },
      "deu": {
        "official": "Republik Burundi",
        "common": "Burundi"
      },
      "est": {
        "official": "Burundi Vabariik",
        "common": "Burundi"
      },
      "fin": {
        "official": "Burundin tasavalta",
        "common": "Burundi"
      },
      "fra": {
        "official": "République du Burundi",
        "common": "Burundi"
      },
      "hrv": {
        "official": "Burundi",
        "common": "Burundi"
      },
      "hun": {
        "official": "Burundi",
        "common": "Burundi"
      },
      "ita": {
        "official": "Repubblica del Burundi",
        "common": "Burundi"
      },
      "jpn": {
        "official": "ブルンジ共和国",
        "common": "ブルンジ"
      },
      "kor": {
        "official": "부룬디",
        "common": "부룬디"
      },
      "nld": {
        "official": "Republiek Burundi",
        "common": "Burundi"
      },
      "per": {
        "official": "جمهوری بوروندی",
        "common": "بوروندی"
      },
      "pol": {
        "official": "Republika Burundi",
        "common": "Burundi"
      },
      "por": {
        "official": "República do Burundi",
        "common": "Burundi"
      },
      "rus": {
        "official": "Республика Бурунди",
        "common": "Бурунди"
      },
      "slk": {
        "official": "Burundská republika",
        "common": "Burundi"
      },
      "spa": {
        "official": "República de Burundi",
        "common": "Burundi"
      },
      "swe": {
        "official": "Republiken Burundi",
        "common": "Burundi"
      },
      "tur": {
        "official": "Burundi Cumhuriyeti",
        "common": "Burundi"
      },
      "urd": {
        "official": "جمہوریہ برونڈی",
        "common": "برونڈی"
      },
      "zho": {
        "official": "布隆迪共和国",
        "common": "布隆迪"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Burundian",
        "m": "Burundian"
      },
      "fra": {
        "f": "Burundaise",
        "m": "Burundais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/in.png",
      "svg": "https://flagcdn.com/in.svg"
    },
    "cca2": "IN",
    "capital": [
      "New Delhi"
    ],
    "altSpellings": [
      "IN",
      "Bhārat",
      "Republic of India",
      "Bharat Ganrajya",
      "இந்தியா"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الهند",
        "common": "الهند"
      },
      "bre": {
        "official": "Republik India",
        "common": "India"
      },
      "ces": {
        "official": "Indická republika",
        "common": "Indie"
      },
      "cym": {
        "official": "Republic of India",
        "common": "India"
      },
      "deu": {
        "official": "Republik Indien",
        "common": "Indien"
      },
      "est": {
        "official": "India Vabariik",
        "common": "India"
      },
      "fin": {
        "official": "Intian tasavalta",
        "common": "Intia"
      },
      "fra": {
        "official": "République de l'Inde",
        "common": "Inde"
      },
      "hrv": {
        "official": "Republika Indija",
        "common": "Indija"
      },
      "hun": {
        "official": "Indiai Köztársaság",
        "common": "India"
      },
      "ita": {
        "official": "Repubblica dell'India",
        "common": "India"
      },
      "jpn": {
        "official": "インド共和国",
        "common": "インド"
      },
      "kor": {
        "official": "인도 공화국",
        "common": "인도"
      },
      "nld": {
        "official": "Republiek India",
        "common": "India"
      },
      "per": {
        "official": "جمهوری هندوستان",
        "common": "هند"
      },
      "pol": {
        "official": "Republika Indii",
        "common": "Indie"
      },
      "por": {
        "official": "República da Índia",
        "common": "Índia"
      },
      "rus": {
        "official": "Республика Индия",
        "common": "Индия"
      },
      "slk": {
        "official": "Indická republika",
        "common": "India"
      },
      "spa": {
        "official": "República de la India",
        "common": "India"
      },
      "swe": {
        "official": "Republiken Indien",
        "common": "Indien"
      },
      "tur": {
        "official": "Hindistan Cumhuriyeti",
        "common": "Hindistan"
      },
      "urd": {
        "official": "جمہوریہ بھارت",
        "common": "بھارت"
      },
      "zho": {
        "official": "印度共和国",
        "common": "印度"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Indian",
        "m": "Indian"
      },
      "fra": {
        "f": "Indienne",
        "m": "Indien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/uy.png",
      "svg": "https://flagcdn.com/uy.svg"
    },
    "cca2": "UY",
    "capital": [
      "Montevideo"
    ],
    "altSpellings": [
      "UY",
      "Oriental Republic of Uruguay",
      "República Oriental del Uruguay"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الأوروغواي الشرقية",
        "common": "الأوروغواي"
      },
      "bre": {
        "official": "Republik Reter Uruguay",
        "common": "Uruguay"
      },
      "ces": {
        "official": "Uruguayská východní republika",
        "common": "Uruguay"
      },
      "cym": {
        "official": "Oriental Republic of Uruguay",
        "common": "Uruguay"
      },
      "deu": {
        "official": "Republik Östlich des Uruguay",
        "common": "Uruguay"
      },
      "est": {
        "official": "Uruguay Idavabariik",
        "common": "Uruguay"
      },
      "fin": {
        "official": "Uruguayn itäinen tasavalta",
        "common": "Uruguay"
      },
      "fra": {
        "official": "République orientale de l'Uruguay",
        "common": "Uruguay"
      },
      "hrv": {
        "official": "Orijentalna Republika Urugvaj",
        "common": "Urugvaj"
      },
      "hun": {
        "official": "Uruguayi Keleti Köztársaság",
        "common": "Uruguay"
      },
      "ita": {
        "official": "Repubblica Orientale dell'Uruguay",
        "common": "Uruguay"
      },
      "jpn": {
        "official": "ウルグアイ東方共和国",
        "common": "ウルグアイ"
      },
      "kor": {
        "official": "우루과이 동방 공화국",
        "common": "우루과이"
      },
      "nld": {
        "official": "Oosterse Republiek Uruguay",
        "common": "Uruguay"
      },
      "per": {
        "official": "جمهوری اروگوئه",
        "common": "اروگوئه"
      },
      "pol": {
        "official": "Wschodnia Republika Urugwaju",
        "common": "Urugwaj"
      },
      "por": {
        "official": "República Oriental do Uruguai",
        "common": "Uruguai"
      },
      "rus": {
        "official": "Восточной Республики Уругвай",
        "common": "Уругвай"
      },
      "slk": {
        "official": "Uruguajská východná republika",
        "common": "Uruguaj"
      },
      "spa": {
        "official": "República Oriental del Uruguay",
        "common": "Uruguay"
      },
      "swe": {
        "official": "Republiken Uruguay",
        "common": "Uruguay"
      },
      "tur": {
        "official": "Uruguay Doğu Cumhuriyeti",
        "common": "Uruguay"
      },
      "urd": {
        "official": "جمہوریہ شرقیہ یوراگوئے",
        "common": "یوراگوئے"
      },
      "zho": {
        "official": "乌拉圭东岸共和国",
        "common": "乌拉圭"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Uruguayan",
        "m": "Uruguayan"
      },
      "fra": {
        "f": "Uruguayenne",
        "m": "Uruguayen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lc.png",
      "svg": "https://flagcdn.com/lc.svg"
    },
    "cca2": "LC",
    "capital": [
      "Castries"
    ],
    "altSpellings": [
      "LC"
    ],
    "translations": {
      "ara": {
        "official": "سانت لوسيا",
        "common": "سانت لوسيا"
      },
      "bre": {
        "official": "Santez-Lusia",
        "common": "Santez-Lusia"
      },
      "ces": {
        "official": "Svatá Lucie",
        "common": "Svatá Lucie"
      },
      "cym": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "deu": {
        "official": "St. Lucia",
        "common": "St. Lucia"
      },
      "est": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "fin": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "fra": {
        "official": "Sainte-Lucie",
        "common": "Sainte-Lucie"
      },
      "hrv": {
        "official": "Sveta Lucija",
        "common": "Sveta Lucija"
      },
      "hun": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "ita": {
        "official": "Santa Lucia",
        "common": "Santa Lucia"
      },
      "jpn": {
        "official": "セントルシア",
        "common": "セントルシア"
      },
      "kor": {
        "official": "세인트루시아",
        "common": "세인트루시아"
      },
      "nld": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "per": {
        "official": "سنت لوسیا",
        "common": "سنت لوسیا"
      },
      "pol": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "por": {
        "official": "Santa Lúcia",
        "common": "Santa Lúcia"
      },
      "rus": {
        "official": "Сент-Люсия",
        "common": "Сент-Люсия"
      },
      "slk": {
        "official": "Svätá Lucia",
        "common": "Svätá Lucia"
      },
      "spa": {
        "official": "Santa Lucía",
        "common": "Santa Lucía"
      },
      "swe": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "tur": {
        "official": "Saint Lucia",
        "common": "Saint Lucia"
      },
      "urd": {
        "official": "سینٹ لوسیا",
        "common": "سینٹ لوسیا"
      },
      "zho": {
        "official": "圣卢西亚",
        "common": "圣卢西亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint Lucian",
        "m": "Saint Lucian"
      },
      "fra": {
        "f": "Saint-Lucienne",
        "m": "Saint-Lucien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bb.png",
      "svg": "https://flagcdn.com/bb.svg"
    },
    "cca2": "BB",
    "capital": [
      "Bridgetown"
    ],
    "altSpellings": [
      "BB"
    ],
    "translations": {
      "ara": {
        "official": "باربادوس",
        "common": "باربادوس"
      },
      "bre": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "ces": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "cym": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "deu": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "est": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "fin": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "fra": {
        "official": "Barbade",
        "common": "Barbade"
      },
      "hrv": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "hun": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "ita": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "jpn": {
        "official": "バルバドス",
        "common": "バルバドス"
      },
      "kor": {
        "official": "바베이도스",
        "common": "바베이도스"
      },
      "nld": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "per": {
        "official": "باربادوس",
        "common": "باربادوس"
      },
      "pol": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "por": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "rus": {
        "official": "Барбадос",
        "common": "Барбадос"
      },
      "slk": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "spa": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "swe": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "tur": {
        "official": "Barbados",
        "common": "Barbados"
      },
      "urd": {
        "official": "بارباڈوس",
        "common": "بارباڈوس"
      },
      "zho": {
        "official": "巴巴多斯",
        "common": "巴巴多斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Barbadian",
        "m": "Barbadian"
      },
      "fra": {
        "f": "Barbadienne",
        "m": "Barbadien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nc.png",
      "svg": "https://flagcdn.com/nc.svg"
    },
    "cca2": "NC",
    "capital": [
      "Nouméa"
    ],
    "altSpellings": [
      "NC"
    ],
    "translations": {
      "ara": {
        "official": "كاليدونيا الجديدة",
        "common": "كاليدونيا الجديدة"
      },
      "bre": {
        "official": "Kaledonia-Nevez",
        "common": "Kaledonia-Nevez"
      },
      "ces": {
        "official": "Nová Kaledonie",
        "common": "Nová Kaledonie"
      },
      "cym": {
        "official": "New Caledonia",
        "common": "New Caledonia"
      },
      "deu": {
        "official": "Neukaledonien",
        "common": "Neukaledonien"
      },
      "est": {
        "official": "Uus-Kaledoonia",
        "common": "Uus-Kaledoonia"
      },
      "fin": {
        "official": "Uusi-Kaledonia",
        "common": "Uusi-Kaledonia"
      },
      "fra": {
        "official": "Nouvelle-Calédonie",
        "common": "Nouvelle-Calédonie"
      },
      "hrv": {
        "official": "Nova Kaledonija",
        "common": "Nova Kaledonija"
      },
      "hun": {
        "official": "Új-Kaledónia",
        "common": "Új-Kaledónia"
      },
      "ita": {
        "official": "Nuova Caledonia",
        "common": "Nuova Caledonia"
      },
      "jpn": {
        "official": "ニューカレドニア",
        "common": "ニューカレドニア"
      },
      "kor": {
        "official": "누벨칼레도니",
        "common": "누벨칼레도니"
      },
      "nld": {
        "official": "nieuw -Caledonië",
        "common": "Nieuw-Caledonië"
      },
      "per": {
        "official": "کالدونیای جدید",
        "common": "کالدونیای جدید"
      },
      "pol": {
        "official": "Nowa Kaledonia",
        "common": "Nowa Kaledonia"
      },
      "por": {
        "official": "New Caledonia",
        "common": "Nova Caledónia"
      },
      "rus": {
        "official": "Новая Каледония",
        "common": "Новая Каледония"
      },
      "slk": {
        "official": "Nová Kaledónia",
        "common": "Nová Kaledónia"
      },
      "spa": {
        "official": "nueva Caledonia",
        "common": "Nueva Caledonia"
      },
      "swe": {
        "official": "Nya Kaledonien",
        "common": "Nya Kaledonien"
      },
      "tur": {
        "official": "Yeni Kaledonya",
        "common": "Yeni Kaledonya"
      },
      "urd": {
        "official": "نیو کیلیڈونیا",
        "common": "نیو کیلیڈونیا"
      },
      "zho": {
        "official": "新喀里多尼亚",
        "common": "新喀里多尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "New Caledonian",
        "m": "New Caledonian"
      },
      "fra": {
        "f": "Néo-Calédonienne",
        "m": "Néo-Calédonien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lv.png",
      "svg": "https://flagcdn.com/lv.svg"
    },
    "cca2": "LV",
    "capital": [
      "Riga"
    ],
    "altSpellings": [
      "LV",
      "Republic of Latvia",
      "Latvijas Republika"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية لاتفيا",
        "common": "لاتفيا"
      },
      "bre": {
        "official": "Republik Latvia",
        "common": "Latvia"
      },
      "ces": {
        "official": "Lotyšská republika",
        "common": "Lotyšsko"
      },
      "cym": {
        "official": "Republic of Latvia",
        "common": "Latvia"
      },
      "deu": {
        "official": "Republik Lettland",
        "common": "Lettland"
      },
      "est": {
        "official": "Läti Vabariik",
        "common": "Läti"
      },
      "fin": {
        "official": "Latvian tasavalta",
        "common": "Latvia"
      },
      "fra": {
        "official": "République de Lettonie",
        "common": "Lettonie"
      },
      "hrv": {
        "official": "Republika Latvija",
        "common": "Latvija"
      },
      "hun": {
        "official": "Lett Köztársaság",
        "common": "Lettország"
      },
      "ita": {
        "official": "Repubblica di Lettonia",
        "common": "Lettonia"
      },
      "jpn": {
        "official": "ラトビア共和国",
        "common": "ラトビア"
      },
      "kor": {
        "official": "라트비아 공화국",
        "common": "라트비아"
      },
      "nld": {
        "official": "Republiek Letland",
        "common": "Letland"
      },
      "per": {
        "official": "جمهوری لتونی",
        "common": "لتونی"
      },
      "pol": {
        "official": "Republika Łotewska",
        "common": "Łotwa"
      },
      "por": {
        "official": "República da Letónia",
        "common": "Letónia"
      },
      "rus": {
        "official": "Латвийская Республика",
        "common": "Латвия"
      },
      "slk": {
        "official": "Lotyšská republika",
        "common": "Lotyšsko"
      },
      "spa": {
        "official": "República de Letonia",
        "common": "Letonia"
      },
      "swe": {
        "official": "Republiken Lettland",
        "common": "Lettland"
      },
      "tur": {
        "official": "Letonya Cumhuriyeti",
        "common": "Letonya"
      },
      "urd": {
        "official": "جمہوریہ لٹویا",
        "common": "لٹویا"
      },
      "zho": {
        "official": "拉脱维亚共和国",
        "common": "拉脱维亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Latvian",
        "m": "Latvian"
      },
      "fra": {
        "f": "Lettone",
        "m": "Letton"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ee.png",
      "svg": "https://flagcdn.com/ee.svg"
    },
    "cca2": "EE",
    "capital": [
      "Tallinn"
    ],
    "altSpellings": [
      "EE",
      "Eesti",
      "Republic of Estonia",
      "Eesti Vabariik"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية إستونيا",
        "common": "إستونيا"
      },
      "bre": {
        "official": "Republik Estonia",
        "common": "Estonia"
      },
      "ces": {
        "official": "Estonská republika",
        "common": "Estonsko"
      },
      "cym": {
        "official": "Gweriniaeth Estonia",
        "common": "Estonia"
      },
      "deu": {
        "official": "Republik Estland",
        "common": "Estland"
      },
      "est": {
        "official": "Eesti Vabariik",
        "common": "Eesti"
      },
      "fin": {
        "official": "Viron tasavalta",
        "common": "Viro"
      },
      "fra": {
        "official": "République d'Estonie",
        "common": "Estonie"
      },
      "hrv": {
        "official": "Republika Estonija",
        "common": "Estonija"
      },
      "hun": {
        "official": "Észt Köztársaság",
        "common": "Észtország"
      },
      "ita": {
        "official": "Repubblica di Estonia",
        "common": "Estonia"
      },
      "jpn": {
        "official": "エストニア共和国",
        "common": "エストニア"
      },
      "kor": {
        "official": "에스토니아 공화국",
        "common": "에스토니아"
      },
      "nld": {
        "official": "Republiek Estland",
        "common": "Estland"
      },
      "per": {
        "official": "جمهوری استونی",
        "common": "اِستونی"
      },
      "pol": {
        "official": "Republika Estońska",
        "common": "Estonia"
      },
      "por": {
        "official": "República da Estónia",
        "common": "Estónia"
      },
      "rus": {
        "official": "Эстонская Республика",
        "common": "Эстония"
      },
      "slk": {
        "official": "Estónska republika",
        "common": "Estónsko"
      },
      "spa": {
        "official": "República de Estonia",
        "common": "Estonia"
      },
      "swe": {
        "official": "Republiken Estland",
        "common": "Estland"
      },
      "tur": {
        "official": "Estonya Cumhuriyeti",
        "common": "Estonya"
      },
      "urd": {
        "official": "جمہوریہ اسٹونیا",
        "common": "اسٹونیا"
      },
      "zho": {
        "official": "爱沙尼亚共和国",
        "common": "爱沙尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Estonian",
        "m": "Estonian"
      },
      "fra": {
        "f": "Estonienne",
        "m": "Estonien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nu.png",
      "svg": "https://flagcdn.com/nu.svg"
    },
    "cca2": "NU",
    "capital": [
      "Alofi"
    ],
    "altSpellings": [
      "NU"
    ],
    "translations": {
      "ara": {
        "official": "نييوي",
        "common": "نييوي"
      },
      "bre": {
        "official": "Niue",
        "common": "Niue"
      },
      "ces": {
        "official": "Niue",
        "common": "Niue"
      },
      "cym": {
        "official": "Niue",
        "common": "Niue"
      },
      "deu": {
        "official": "Niue",
        "common": "Niue"
      },
      "est": {
        "official": "Niue",
        "common": "Niue"
      },
      "fin": {
        "official": "Niue",
        "common": "Niue"
      },
      "fra": {
        "official": "Niue",
        "common": "Niue"
      },
      "hrv": {
        "official": "Niue",
        "common": "Niue"
      },
      "hun": {
        "official": "Niue",
        "common": "Niue"
      },
      "ita": {
        "official": "Niue",
        "common": "Niue"
      },
      "jpn": {
        "official": "ニウエ",
        "common": "ニウエ"
      },
      "kor": {
        "official": "니우에",
        "common": "니우에"
      },
      "nld": {
        "official": "Niue",
        "common": "Niue"
      },
      "per": {
        "official": "نیووی",
        "common": "نیووی"
      },
      "pol": {
        "official": "Niue",
        "common": "Niue"
      },
      "por": {
        "official": "Niue",
        "common": "Niue"
      },
      "rus": {
        "official": "Ниуэ",
        "common": "Ниуэ"
      },
      "slk": {
        "official": "Niue",
        "common": "Niue"
      },
      "spa": {
        "official": "Niue",
        "common": "Niue"
      },
      "swe": {
        "official": "Niue",
        "common": "Niue"
      },
      "tur": {
        "official": "Niue",
        "common": "Niue"
      },
      "urd": {
        "official": "نیووے",
        "common": "نیووے"
      },
      "zho": {
        "official": "纽埃",
        "common": "纽埃"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Niuean",
        "m": "Niuean"
      },
      "fra": {
        "f": "Niuéenne",
        "m": "Niuéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bl.png",
      "svg": "https://flagcdn.com/bl.svg"
    },
    "cca2": "BL",
    "capital": [
      "Gustavia"
    ],
    "altSpellings": [
      "BL",
      "St. Barthelemy",
      "Collectivity of Saint Barthélemy",
      "Collectivité de Saint-Barthélemy"
    ],
    "translations": {
      "ara": {
        "official": "التجمع الإقليمي لسانت بارتيليمي",
        "common": "سان بارتليمي"
      },
      "bre": {
        "official": "Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "ces": {
        "official": "Svatý Bartoloměj",
        "common": "Svatý Bartoloměj"
      },
      "cym": {
        "official": "Collectivity of Saint Barthélemy",
        "common": "Saint Barthélemy"
      },
      "deu": {
        "official": "Gebietskörperschaft Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "est": {
        "official": "Saint-Barthélemy territoriaalühendus",
        "common": "Saint-Barthélemy"
      },
      "fin": {
        "official": "Saint-Barthélemyn yhteisö",
        "common": "Saint-Barthélemy"
      },
      "fra": {
        "official": "Collectivité de Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "hrv": {
        "official": "Kolektivnost sv Barthélemy",
        "common": "Saint Barthélemy"
      },
      "hun": {
        "official": "Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "ita": {
        "official": "Collettività di Saint Barthélemy",
        "common": "Antille Francesi"
      },
      "jpn": {
        "official": "サン·バルテルミー島の集合体",
        "common": "サン・バルテルミー"
      },
      "kor": {
        "official": "생바르텔레미",
        "common": "생바르텔레미"
      },
      "nld": {
        "official": "Gemeenschap Saint Barthélemy",
        "common": "Saint Barthélemy"
      },
      "per": {
        "official": "سن بارتلمی",
        "common": "سن بارتلمی"
      },
      "pol": {
        "official": "Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "por": {
        "official": "Coletividade de Saint Barthélemy",
        "common": "São Bartolomeu"
      },
      "rus": {
        "official": "Коллективность Санкт -Бартельми",
        "common": "Сен-Бартелеми"
      },
      "slk": {
        "official": "Svätý Bartolomej",
        "common": "Svätý Bartolomej"
      },
      "spa": {
        "official": "Colectividad de San Barthélemy",
        "common": "San Bartolomé"
      },
      "swe": {
        "official": "Saint-Barthélemy",
        "common": "Saint-Barthélemy"
      },
      "tur": {
        "official": "Saint Barthélemy",
        "common": "Saint Barthélemy"
      },
      "urd": {
        "official": "سینٹ بارتھیملے",
        "common": "سینٹ بارتھیملے"
      },
      "zho": {
        "official": "圣巴泰勒米集体",
        "common": "圣巴泰勒米"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint Barthélemy Islander",
        "m": "Saint Barthélemy Islander"
      },
      "fra": {
        "f": "Barthéloméenne",
        "m": "Barthéloméen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pr.png",
      "svg": "https://flagcdn.com/pr.svg"
    },
    "cca2": "PR",
    "capital": [
      "San Juan"
    ],
    "altSpellings": [
      "PR",
      "Commonwealth of Puerto Rico",
      "Estado Libre Asociado de Puerto Rico"
    ],
    "translations": {
      "ara": {
        "official": "كومنولث بويرتوريكو",
        "common": "بويرتوريكو"
      },
      "bre": {
        "official": "Kenglad Puerto Rico",
        "common": "Puerto Rico"
      },
      "ces": {
        "official": "Portoriko",
        "common": "Portoriko"
      },
      "cym": {
        "official": "Commonwealth of Puerto Rico",
        "common": "Puerto Rico"
      },
      "deu": {
        "official": "Freistaat Puerto Rico",
        "common": "Puerto Rico"
      },
      "est": {
        "official": "Puerto Rico Ühendus",
        "common": "Puerto Rico"
      },
      "fin": {
        "official": "Puerto Rico",
        "common": "Puerto Rico"
      },
      "fra": {
        "official": "Porto Rico",
        "common": "Porto Rico"
      },
      "hrv": {
        "official": "Zajednica Puerto Rico",
        "common": "Portoriko"
      },
      "hun": {
        "official": "Puerto Rico",
        "common": "Puerto Rico"
      },
      "ita": {
        "official": "Commonwealth di Porto Rico",
        "common": "Porto Rico"
      },
      "jpn": {
        "official": "プエルトリコのコモンウェルス",
        "common": "プエルトリコ"
      },
      "kor": {
        "official": "푸에르토리코",
        "common": "푸에르토리코"
      },
      "nld": {
        "official": "Gemenebest van Puerto Rico",
        "common": "Puerto Rico"
      },
      "per": {
        "official": "قلمرو همسود پورتوریکو",
        "common": "پورتوریکو"
      },
      "pol": {
        "official": "Wolne Stowarzyszone Państwo Portoryko",
        "common": "Portoryko"
      },
      "por": {
        "official": "Commonwealth of Puerto Rico",
        "common": "Porto Rico"
      },
      "rus": {
        "official": "Содружество Пуэрто-Рико",
        "common": "Пуэрто-Рико"
      },
      "slk": {
        "official": "Portorické spoločenstvo",
        "common": "Portoriko"
      },
      "spa": {
        "official": "Asociado de Puerto Rico",
        "common": "Puerto Rico"
      },
      "swe": {
        "official": "Puerto Rico",
        "common": "Puerto Rico"
      },
      "tur": {
        "official": "Porto Riko Topluluğu",
        "common": "Porto Riko"
      },
      "urd": {
        "official": " دولتِ مشترکہ پورٹو ریکو",
        "common": "پورٹو ریکو"
      },
      "zho": {
        "official": "波多黎各联邦",
        "common": "波多黎各"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Puerto Rican",
        "m": "Puerto Rican"
      },
      "fra": {
        "f": "Portoricaine",
        "m": "Portoricain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gd.png",
      "svg": "https://flagcdn.com/gd.svg"
    },
    "cca2": "GD",
    "capital": [
      "St. George's"
    ],
    "altSpellings": [
      "GD"
    ],
    "translations": {
      "ara": {
        "official": "غرينادا",
        "common": "غرينادا"
      },
      "bre": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "ces": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "cym": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "deu": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "est": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "fin": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "fra": {
        "official": "Grenade",
        "common": "Grenade"
      },
      "hrv": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "hun": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "ita": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "jpn": {
        "official": "グレナダ",
        "common": "グレナダ"
      },
      "kor": {
        "official": "그레나다",
        "common": "그레나다"
      },
      "nld": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "per": {
        "official": "گرنادا",
        "common": "گرنادا"
      },
      "pol": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "por": {
        "official": "Grenada",
        "common": "Granada"
      },
      "rus": {
        "official": "Гренада",
        "common": "Гренада"
      },
      "slk": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "spa": {
        "official": "Granada",
        "common": "Grenada"
      },
      "swe": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "tur": {
        "official": "Grenada",
        "common": "Grenada"
      },
      "urd": {
        "official": "گریناڈا",
        "common": "گریناڈا"
      },
      "zho": {
        "official": "格林纳达",
        "common": "格林纳达"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Grenadian",
        "m": "Grenadian"
      },
      "fra": {
        "f": "Grenadienne",
        "m": "Grenadien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mo.png",
      "svg": "https://flagcdn.com/mo.svg"
    },
    "cca2": "MO",
    "capital": [],
    "altSpellings": [
      "MO",
      "澳门",
      "Macao",
      "Macao Special Administrative Region of the People's Republic of China",
      "中華人民共和國澳門特別行政區",
      "Região Administrativa Especial de Macau da República Popular da China"
    ],
    "translations": {
      "ara": {
        "official": "منطقة ماكاو الإدارية التابعة لجمهورية الصين الشعبية",
        "common": "ماكاو"
      },
      "bre": {
        "official": "Rannvro velestradurel arbennik Makao eus Republik pobl Sina",
        "common": "Makao"
      },
      "ces": {
        "official": "Zvláštní správní oblast Čínské lidové republiky Macao",
        "common": "Macao"
      },
      "cym": {
        "official": "Macao Special Administrative Region of the People's Republic of China",
        "common": "Macau"
      },
      "deu": {
        "official": "Sonderverwaltungsregion Macau der Volksrepublik China",
        "common": "Macao"
      },
      "est": {
        "official": "Macau erihalduspiirkond",
        "common": "Macau"
      },
      "fin": {
        "official": "Macaon Kiinan kansantasavallan erityishallintoalue",
        "common": "Macao"
      },
      "fra": {
        "official": "Région administrative spéciale de Macao de la République populaire de Chine",
        "common": "Macao"
      },
      "hrv": {
        "official": "Makao Posebnog upravnog područjaNarodne Republike Kine",
        "common": "Makao"
      },
      "hun": {
        "official": "Makaó",
        "common": "Makaó"
      },
      "ita": {
        "official": "Macao Regione amministrativa speciale della Repubblica Popolare Cinese",
        "common": "Macao"
      },
      "jpn": {
        "official": "中華人民共和国マカオ特別行政区",
        "common": "マカオ"
      },
      "kor": {
        "official": "중화인민공화국 마카오 특별행정구",
        "common": "마카오"
      },
      "nld": {
        "official": "Speciale Administratieve Regio Macau van de Volksrepubliek China",
        "common": "Macao"
      },
      "per": {
        "official": "ماکائو",
        "common": "ماکائو"
      },
      "pol": {
        "official": "Specjalny Region Administracyjny Chińskiej Republiki Ludowej Makau",
        "common": "Makau"
      },
      "por": {
        "official": "Macau Região Administrativa Especial da República Popular da China",
        "common": "Macau"
      },
      "rus": {
        "official": "Специальный административный район Макао Китайской Народной Республики Китай",
        "common": "Макао"
      },
      "slk": {
        "official": "Macao, Špeciàlna administratívna oblasŦ",
        "common": "Macao"
      },
      "spa": {
        "official": "Macao, Región Administrativa Especial de la República Popular China",
        "common": "Macao"
      },
      "swe": {
        "official": "Macao",
        "common": "Macao"
      },
      "tur": {
        "official": "Çin Halk Cumhuriyeti Makao Özel İdari Bölgesi",
        "common": "Makao"
      },
      "urd": {
        "official": "مکاؤ عوامی جمہوریہ چین کا خصوصی انتظامی علاقہ",
        "common": "مکاؤ"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Macanese",
        "m": "Macanese"
      },
      "fra": {
        "f": "Macanaise",
        "m": "Macanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cy.png",
      "svg": "https://flagcdn.com/cy.svg"
    },
    "cca2": "CY",
    "capital": [
      "Nicosia"
    ],
    "altSpellings": [
      "CY",
      "Kýpros",
      "Kıbrıs",
      "Republic of Cyprus",
      "Κυπριακή Δημοκρατία",
      "Kıbrıs Cumhuriyeti"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية قبرص",
        "common": "قبرص"
      },
      "bre": {
        "official": "Republik Kiprenez",
        "common": "Kiprenez"
      },
      "ces": {
        "official": "Kyperská republika",
        "common": "Kypr"
      },
      "cym": {
        "official": "Gweriniaeth Cyprus",
        "common": "Cyprus"
      },
      "deu": {
        "official": "Republik Zypern",
        "common": "Zypern"
      },
      "est": {
        "official": "Küprose Vabariik",
        "common": "Küpros"
      },
      "fin": {
        "official": "Kyproksen tasavalta",
        "common": "Kypros"
      },
      "fra": {
        "official": "République de Chypre",
        "common": "Chypre"
      },
      "hrv": {
        "official": "Republika Cipar",
        "common": "Cipar"
      },
      "hun": {
        "official": "Ciprusi Köztársaság",
        "common": "Ciprus"
      },
      "ita": {
        "official": "Repubblica di Cipro",
        "common": "Cipro"
      },
      "jpn": {
        "official": "キプロス共和国",
        "common": "キプロス"
      },
      "kor": {
        "official": "키프로스 공화국",
        "common": "키프로스"
      },
      "nld": {
        "official": "Republiek Cyprus",
        "common": "Cyprus"
      },
      "per": {
        "official": "جمهوری قبرس",
        "common": "قِبرِس"
      },
      "pol": {
        "official": "Republika Cypryjska",
        "common": "Cypr"
      },
      "por": {
        "official": "República de Chipre",
        "common": "Chipre"
      },
      "rus": {
        "official": "Республика Кипр",
        "common": "Кипр"
      },
      "slk": {
        "official": "Cyperská republika",
        "common": "Cyprus"
      },
      "spa": {
        "official": "República de Chipre",
        "common": "Chipre"
      },
      "swe": {
        "official": "Republiken Cypern",
        "common": "Cypern"
      },
      "tur": {
        "official": "Kıbrıs Cumhuriyeti",
        "common": "Kıbrıs"
      },
      "urd": {
        "official": "جمہوریہ قبرص",
        "common": "قبرص"
      },
      "zho": {
        "official": "塞浦路斯共和国",
        "common": "塞浦路斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cypriot",
        "m": "Cypriot"
      },
      "fra": {
        "f": "Chypriote",
        "m": "Chypriote"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/im.png",
      "svg": "https://flagcdn.com/im.svg"
    },
    "cca2": "IM",
    "capital": [
      "Douglas"
    ],
    "altSpellings": [
      "IM",
      "Ellan Vannin",
      "Mann",
      "Mannin"
    ],
    "translations": {
      "ara": {
        "official": "جزيرة مان",
        "common": "جزيرة مان"
      },
      "bre": {
        "official": "Enez Vanav",
        "common": "Enez Vanav"
      },
      "ces": {
        "official": "Ostrov Man",
        "common": "Ostrov Man"
      },
      "cym": {
        "official": "Isle of Man",
        "common": "Isle of Man"
      },
      "deu": {
        "official": "Isle of Man",
        "common": "Insel Man"
      },
      "est": {
        "official": "Mani saar",
        "common": "Mani saar"
      },
      "fin": {
        "official": "Mansaari",
        "common": "Mansaari"
      },
      "fra": {
        "official": "Isle of Man",
        "common": "Île de Man"
      },
      "hrv": {
        "official": "Mana ostrvo",
        "common": "Otok Man"
      },
      "hun": {
        "official": "Man",
        "common": "Man"
      },
      "ita": {
        "official": "Isola di Man",
        "common": "Isola di Man"
      },
      "jpn": {
        "official": "マン島",
        "common": "マン島"
      },
      "kor": {
        "official": "맨섬",
        "common": "맨섬"
      },
      "nld": {
        "official": "Isle of Man",
        "common": "Isle of Man"
      },
      "per": {
        "official": "جزیرهٔ مَن",
        "common": "جزیرهٔ مَن"
      },
      "pol": {
        "official": "Wyspa Man",
        "common": "Wyspa Man"
      },
      "por": {
        "official": "Isle of Man",
        "common": "Ilha de Man"
      },
      "rus": {
        "official": "Остров Мэн",
        "common": "Остров Мэн"
      },
      "slk": {
        "official": "Ostrov Man",
        "common": "Man"
      },
      "spa": {
        "official": "Isla de Man",
        "common": "Isla de Man"
      },
      "swe": {
        "official": "Isle of Man",
        "common": "Isle of Man"
      },
      "tur": {
        "official": "Man Adası",
        "common": "Man Adası"
      },
      "urd": {
        "official": "آئل آف مین",
        "common": "آئل آف مین"
      },
      "zho": {
        "official": "马恩岛",
        "common": "马恩岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Manx",
        "m": "Manx"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gr.png",
      "svg": "https://flagcdn.com/gr.svg"
    },
    "cca2": "GR",
    "capital": [
      "Athens"
    ],
    "altSpellings": [
      "GR",
      "Elláda",
      "Hellenic Republic",
      "Ελληνική Δημοκρατία"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية الهيلينية",
        "common": "اليونان"
      },
      "bre": {
        "official": "Republik Hellenek",
        "common": "Gres"
      },
      "ces": {
        "official": "Řecká republika",
        "common": "Řecko"
      },
      "cym": {
        "official": "Hellenic Republic",
        "common": "Greece"
      },
      "deu": {
        "official": "Hellenische Republik",
        "common": "Griechenland"
      },
      "est": {
        "official": "Kreeka Vabariik",
        "common": "Kreeka"
      },
      "fin": {
        "official": "Helleenien tasavalta",
        "common": "Kreikka"
      },
      "fra": {
        "official": "République hellénique",
        "common": "Grèce"
      },
      "hrv": {
        "official": "Helenska Republika",
        "common": "Grčka"
      },
      "hun": {
        "official": "Görög Köztársaság",
        "common": "Görögország"
      },
      "ita": {
        "official": "Repubblica ellenica",
        "common": "Grecia"
      },
      "jpn": {
        "official": "ギリシャ共和国",
        "common": "ギリシャ"
      },
      "kor": {
        "official": "그리스 공화국",
        "common": "그리스"
      },
      "nld": {
        "official": "Helleense Republiek",
        "common": "Griekenland"
      },
      "per": {
        "official": "جمهوری یونان",
        "common": "یونان"
      },
      "pol": {
        "official": "Republika Grecka",
        "common": "Grecja"
      },
      "por": {
        "official": "República Helénica",
        "common": "Grécia"
      },
      "rus": {
        "official": "Греческая Республика",
        "common": "Греция"
      },
      "slk": {
        "official": "Grécka republika",
        "common": "Greécko"
      },
      "spa": {
        "official": "República Helénica",
        "common": "Grecia"
      },
      "swe": {
        "official": "Republiken Grekland",
        "common": "Grekland"
      },
      "tur": {
        "official": "Helen Cumhuriyeti",
        "common": "Yunanistan"
      },
      "urd": {
        "official": "جمہوریہ ہیلینیہ",
        "common": "یونان"
      },
      "zho": {
        "official": "希腊共和国",
        "common": "希腊"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Greek",
        "m": "Greek"
      },
      "fra": {
        "f": "Grecque",
        "m": "Grec"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sy.png",
      "svg": "https://flagcdn.com/sy.svg"
    },
    "cca2": "SY",
    "capital": [
      "Damascus"
    ],
    "altSpellings": [
      "SY",
      "Syrian Arab Republic",
      "Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية العربية السورية",
        "common": "سوريا"
      },
      "bre": {
        "official": "Republik Arab Siriat",
        "common": "Siria"
      },
      "ces": {
        "official": "Syrská arabská republika",
        "common": "Sýrie"
      },
      "cym": {
        "official": "Syrian Arab Republic",
        "common": "Syria"
      },
      "deu": {
        "official": "Arabische Republik Syrien",
        "common": "Syrien"
      },
      "est": {
        "official": "Süüria Araabia Vabariik",
        "common": "Süüria"
      },
      "fin": {
        "official": "Syyrian arabitasavalta",
        "common": "Syyria"
      },
      "fra": {
        "official": "République arabe syrienne",
        "common": "Syrie"
      },
      "hrv": {
        "official": "Sirijska Arapska Republika",
        "common": "Sirija"
      },
      "hun": {
        "official": "Szíriai Arab Köztársaság",
        "common": "Szíria"
      },
      "ita": {
        "official": "Repubblica araba siriana",
        "common": "Siria"
      },
      "jpn": {
        "official": "シリアアラブ共和国",
        "common": "シリア・アラブ共和国"
      },
      "kor": {
        "official": "시리아 아랍 공화국",
        "common": "시리아"
      },
      "nld": {
        "official": "Syrische Arabische Republiek",
        "common": "Syrië"
      },
      "per": {
        "official": "جمهوری عربی سوریه",
        "common": "سوریه"
      },
      "pol": {
        "official": "Syryjska Republika Arabska",
        "common": "Syria"
      },
      "por": {
        "official": "República Árabe Síria",
        "common": "Síria"
      },
      "rus": {
        "official": "Сирийская Арабская Республика",
        "common": "Сирия"
      },
      "slk": {
        "official": "Sýrska arabská republika",
        "common": "Sýria"
      },
      "spa": {
        "official": "República Árabe Siria",
        "common": "Siria"
      },
      "swe": {
        "official": "Syriska arabiska republiken",
        "common": "Syrien"
      },
      "tur": {
        "official": "Suriye Arap Cumhuriyeti",
        "common": "Suriye"
      },
      "urd": {
        "official": "عرب جمہوریہ سوریہ",
        "common": "سوریہ"
      },
      "zho": {
        "official": "叙利亚阿拉伯共和国",
        "common": "叙利亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Syrian",
        "m": "Syrian"
      },
      "fra": {
        "f": "Syrienne",
        "m": "Syrien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lt.png",
      "svg": "https://flagcdn.com/lt.svg"
    },
    "cca2": "LT",
    "capital": [
      "Vilnius"
    ],
    "altSpellings": [
      "LT",
      "Republic of Lithuania",
      "Lietuvos Respublika"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ليتوانيا",
        "common": "ليتوانيا"
      },
      "bre": {
        "official": "Republik Lituania",
        "common": "Lituania"
      },
      "ces": {
        "official": "Litevská republika",
        "common": "Litva"
      },
      "cym": {
        "official": "Republic of Lithuania",
        "common": "Lithuania"
      },
      "deu": {
        "official": "Republik Litauen",
        "common": "Litauen"
      },
      "est": {
        "official": "Leedu Vabariik",
        "common": "Leedu"
      },
      "fin": {
        "official": "Liettuan tasavalta",
        "common": "Liettua"
      },
      "fra": {
        "official": "République de Lituanie",
        "common": "Lituanie"
      },
      "hrv": {
        "official": "Republika Litva",
        "common": "Litva"
      },
      "hun": {
        "official": "Litván Köztársaság",
        "common": "Litvánia"
      },
      "ita": {
        "official": "Repubblica di Lituania",
        "common": "Lituania"
      },
      "jpn": {
        "official": "リトアニア共和国",
        "common": "リトアニア"
      },
      "kor": {
        "official": "리투아니아 공화국",
        "common": "리투아니아"
      },
      "nld": {
        "official": "Republiek Litouwen",
        "common": "Litouwen"
      },
      "per": {
        "official": "لیتوانیایی‌ها",
        "common": "لیتوانیایی‌ها"
      },
      "pol": {
        "official": "Republika Litewska",
        "common": "Litwa"
      },
      "por": {
        "official": "República da Lituânia",
        "common": "Lituânia"
      },
      "rus": {
        "official": "Литовская Республика",
        "common": "Литва"
      },
      "slk": {
        "official": "Litovská republika",
        "common": "Litva"
      },
      "spa": {
        "official": "República de Lituania",
        "common": "Lituania"
      },
      "swe": {
        "official": "Republiken Litauen",
        "common": "Litauen"
      },
      "tur": {
        "official": "Litvanya Cumhuriyeti",
        "common": "Litvanya"
      },
      "urd": {
        "official": "جمہوریہ لتھووینیا",
        "common": "لتھووینیا"
      },
      "zho": {
        "official": "立陶宛共和国",
        "common": "立陶宛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Lithuanian",
        "m": "Lithuanian"
      },
      "fra": {
        "f": "Lituanienne",
        "m": "Lituanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cw.png",
      "svg": "https://flagcdn.com/cw.svg"
    },
    "cca2": "CW",
    "capital": [
      "Willemstad"
    ],
    "altSpellings": [
      "CW",
      "Curacao",
      "Kòrsou",
      "Country of Curaçao",
      "Land Curaçao",
      "Pais Kòrsou"
    ],
    "translations": {
      "ara": {
        "official": "دولة كوراساو",
        "common": "كوراساو"
      },
      "bre": {
        "official": "Bro Curaçao",
        "common": "Curaçao"
      },
      "ces": {
        "official": "Autonomní země Curaçao",
        "common": "Curaçao"
      },
      "cym": {
        "official": "Country of Curaçao",
        "common": "Curaçao"
      },
      "deu": {
        "official": "Land Curaçao",
        "common": "Curaçao"
      },
      "est": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "fin": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "fra": {
        "official": "Pays de Curaçao",
        "common": "Curaçao"
      },
      "hun": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "ita": {
        "official": "Paese di Curaçao",
        "common": "Curaçao"
      },
      "kor": {
        "official": "퀴라소",
        "common": "퀴라소"
      },
      "nld": {
        "official": "Land Curaçao",
        "common": "Curaçao"
      },
      "per": {
        "official": "کوراسائو",
        "common": "کوراسائو"
      },
      "pol": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "por": {
        "official": "País de Curaçao",
        "common": "ilha da Curação"
      },
      "rus": {
        "official": "Страна Кюрасао",
        "common": "Кюрасао"
      },
      "slk": {
        "official": "Curacao",
        "common": "Curacao"
      },
      "spa": {
        "official": "País de Curazao",
        "common": "Curazao"
      },
      "swe": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "tur": {
        "official": "Curaçao",
        "common": "Curaçao"
      },
      "urd": {
        "official": "مملکتِ کیوراساؤ",
        "common": "کیوراساؤ"
      },
      "zho": {
        "official": "库拉索",
        "common": "库拉索"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Curaçaoan",
        "m": "Curaçaoan"
      },
      "fra": {
        "f": "Curacienne",
        "m": "Curacien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/dm.png",
      "svg": "https://flagcdn.com/dm.svg"
    },
    "cca2": "DM",
    "capital": [
      "Roseau"
    ],
    "altSpellings": [
      "DM",
      "Dominique",
      "Wai‘tu kubuli",
      "Commonwealth of Dominica"
    ],
    "translations": {
      "ara": {
        "official": "كومونولث دومينيكا",
        "common": "دومينيكا"
      },
      "bre": {
        "official": "Kenglad Dominika",
        "common": "Dominika"
      },
      "ces": {
        "official": "Dominikánské společenství",
        "common": "Dominika"
      },
      "cym": {
        "official": "Cymanwlad Dominica",
        "common": "Dominica"
      },
      "deu": {
        "official": "Commonwealth von Dominica",
        "common": "Dominica"
      },
      "est": {
        "official": "Dominica Ühendus",
        "common": "Dominica"
      },
      "fin": {
        "official": "Dominican liittovaltio",
        "common": "Dominica"
      },
      "fra": {
        "official": "Commonwealth de la Dominique",
        "common": "Dominique"
      },
      "hrv": {
        "official": "Zajednica Dominika",
        "common": "Dominika"
      },
      "hun": {
        "official": "Dominikai Közösség",
        "common": "Dominikai Közösség"
      },
      "ita": {
        "official": "Commonwealth di Dominica",
        "common": "Dominica"
      },
      "jpn": {
        "official": "ドミニカ国",
        "common": "ドミニカ国"
      },
      "kor": {
        "official": "도미니카 공화국",
        "common": "도미니카 공화국"
      },
      "nld": {
        "official": "Gemenebest Dominica",
        "common": "Dominica"
      },
      "per": {
        "official": "قلمرو همسود دومینیکا",
        "common": "دومینیکا"
      },
      "pol": {
        "official": "Wspólnota Dominiki",
        "common": "Dominika"
      },
      "por": {
        "official": "Comunidade da Dominica",
        "common": "Dominica"
      },
      "rus": {
        "official": "Содружество Доминики",
        "common": "Доминика"
      },
      "slk": {
        "official": "Dominické spoločenstvo",
        "common": "Dominika"
      },
      "spa": {
        "official": "Mancomunidad de Dominica",
        "common": "Dominica"
      },
      "swe": {
        "official": "Samväldet Dominica",
        "common": "Dominica"
      },
      "tur": {
        "official": "Dominika Topluluğu",
        "common": "Dominika"
      },
      "urd": {
        "official": "دولتِ مشترکہ ڈومینیکا",
        "common": "ڈومینیکا"
      },
      "zho": {
        "official": "多米尼加共和国",
        "common": "多米尼加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Dominican",
        "m": "Dominican"
      },
      "fra": {
        "f": "Dominiquaise",
        "m": "Dominiquais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ne.png",
      "svg": "https://flagcdn.com/ne.svg"
    },
    "cca2": "NE",
    "capital": [
      "Niamey"
    ],
    "altSpellings": [
      "NE",
      "Nijar"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية النيجر",
        "common": "النيجر"
      },
      "bre": {
        "official": "Republik Niger",
        "common": "Niger"
      },
      "ces": {
        "official": "Nigerská republika",
        "common": "Niger"
      },
      "cym": {
        "official": "Republic of Niger",
        "common": "Niger"
      },
      "deu": {
        "official": "Republik Niger",
        "common": "Niger"
      },
      "est": {
        "official": "Nigeri Vabariik",
        "common": "Niger"
      },
      "fin": {
        "official": "Nigerin tasavalta",
        "common": "Niger"
      },
      "fra": {
        "official": "République du Niger",
        "common": "Niger"
      },
      "hrv": {
        "official": "Republika Niger",
        "common": "Niger"
      },
      "hun": {
        "official": "Nigeri Köztársaság",
        "common": "Niger"
      },
      "ita": {
        "official": "Repubblica del Niger",
        "common": "Niger"
      },
      "jpn": {
        "official": "ニジェール共和国",
        "common": "ニジェール"
      },
      "kor": {
        "official": "니제르 공화국",
        "common": "니제르"
      },
      "nld": {
        "official": "Republiek Niger",
        "common": "Niger"
      },
      "per": {
        "official": "جمهوری نیجر",
        "common": "نیجر"
      },
      "pol": {
        "official": "Republika Nigru",
        "common": "Niger"
      },
      "por": {
        "official": "República do Níger",
        "common": "Níger"
      },
      "rus": {
        "official": "Республика Нигер",
        "common": "Нигер"
      },
      "slk": {
        "official": "Nigérská republika",
        "common": "Niger"
      },
      "spa": {
        "official": "República de Níger",
        "common": "Níger"
      },
      "swe": {
        "official": "Republiken Niger",
        "common": "Niger"
      },
      "tur": {
        "official": "Nijer Cumhuriyeti",
        "common": "Nijer"
      },
      "urd": {
        "official": "جمہوریہ نائجر",
        "common": "نائجر"
      },
      "zho": {
        "official": "尼日尔共和国",
        "common": "尼日尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Nigerien",
        "m": "Nigerien"
      },
      "fra": {
        "f": "Nigérienne",
        "m": "Nigérien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kg.png",
      "svg": "https://flagcdn.com/kg.svg"
    },
    "cca2": "KG",
    "capital": [
      "Bishkek"
    ],
    "altSpellings": [
      "KG",
      "Киргизия",
      "Kyrgyz Republic",
      "Кыргыз Республикасы",
      "Kyrgyz Respublikasy"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية القيرغيزية",
        "common": "قيرغيزستان"
      },
      "bre": {
        "official": "Republik Kirgiz",
        "common": "Kirgizstan"
      },
      "ces": {
        "official": "Kyrgyzská republika",
        "common": "Kyrgyzstán"
      },
      "cym": {
        "official": "Kyrgyz Republic",
        "common": "Kyrgyzstan"
      },
      "deu": {
        "official": "Kirgisische Republik",
        "common": "Kirgisistan"
      },
      "est": {
        "official": "Kirgiisi Vabariik",
        "common": "Kõrgõzstan"
      },
      "fin": {
        "official": "Kirgisian tasavalta",
        "common": "Kirgisia"
      },
      "fra": {
        "official": "République kirghize",
        "common": "Kirghizistan"
      },
      "hrv": {
        "official": "Kirgistanu",
        "common": "Kirgistan"
      },
      "hun": {
        "official": "Kirgiz Köztársaság",
        "common": "Kirgizisztán"
      },
      "ita": {
        "official": "Kirghizistan",
        "common": "Kirghizistan"
      },
      "jpn": {
        "official": "キルギス共和国",
        "common": "キルギス"
      },
      "kor": {
        "official": "키르기스 공화국",
        "common": "키르기스스탄"
      },
      "nld": {
        "official": "Kirgizische Republiek",
        "common": "Kirgizië"
      },
      "per": {
        "official": "جمهوری قِرقیزستان",
        "common": "قرقیزستان"
      },
      "pol": {
        "official": "Republika Kirgiska",
        "common": "Kirgistan"
      },
      "por": {
        "official": "República do Quirguistão",
        "common": "Quirguistão"
      },
      "rus": {
        "official": "Кыргызская Республика",
        "common": "Киргизия"
      },
      "slk": {
        "official": "Kirgizská republika",
        "common": "Kirgizsko"
      },
      "spa": {
        "official": "República Kirguisa",
        "common": "Kirguizistán"
      },
      "swe": {
        "official": "Republiken Kirgizistan",
        "common": "Kirgizistan"
      },
      "tur": {
        "official": "Kırgızistan Cumhuriyeti",
        "common": "Kırgızistan"
      },
      "urd": {
        "official": "جمہوریہ کرغیزستان",
        "common": "کرغیزستان"
      },
      "zho": {
        "official": "吉尔吉斯斯坦共和国",
        "common": "吉尔吉斯斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kirghiz",
        "m": "Kirghiz"
      },
      "fra": {
        "f": "Kirghize",
        "m": "Kirghize"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tt.png",
      "svg": "https://flagcdn.com/tt.svg"
    },
    "cca2": "TT",
    "capital": [
      "Port of Spain"
    ],
    "altSpellings": [
      "TT",
      "Republic of Trinidad and Tobago"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ترينيداد وتوباغو",
        "common": "ترينيداد وتوباغو"
      },
      "bre": {
        "official": "Republik Trinidad ha Tobago",
        "common": "Trinidad ha Tobago"
      },
      "ces": {
        "official": "Republika Trinidad a Tobago",
        "common": "Trinidad a Tobago"
      },
      "cym": {
        "official": "Republic of Trinidad and Tobago",
        "common": "Trinidad and Tobago"
      },
      "deu": {
        "official": "Republik Trinidad und Tobago",
        "common": "Trinidad und Tobago"
      },
      "est": {
        "official": "Trinidadi ja Tobago Vabariik",
        "common": "Trinidad ja Tobago"
      },
      "fin": {
        "official": "Trinidadin ja Tobagon tasavalta",
        "common": "Trinidad ja Tobago"
      },
      "fra": {
        "official": "République de Trinité-et-Tobago",
        "common": "Trinité-et-Tobago"
      },
      "hrv": {
        "official": "Republika Trinidad i Tobago",
        "common": "Trinidad i Tobago"
      },
      "hun": {
        "official": "Trinidad és Tobago Köztársaság",
        "common": "Trinidad és Tobago"
      },
      "ita": {
        "official": "Repubblica di Trinidad e Tobago",
        "common": "Trinidad e Tobago"
      },
      "jpn": {
        "official": "トリニダード·トバゴ共和国",
        "common": "トリニダード・トバゴ"
      },
      "kor": {
        "official": "트리니다드 토바고 공화국",
        "common": "트리니다드 토바고"
      },
      "nld": {
        "official": "Republiek Trinidad en Tobago",
        "common": "Trinidad en Tobago"
      },
      "per": {
        "official": "جمهوری ترینیداد و توباگو",
        "common": "ترینیداد و توباگو"
      },
      "pol": {
        "official": "Trynidad i Tobago",
        "common": "Trynidad i Tobago"
      },
      "por": {
        "official": "República de Trinidad e Tobago",
        "common": "Trinidade e Tobago"
      },
      "rus": {
        "official": "Республика Тринидад и Тобаго",
        "common": "Тринидад и Тобаго"
      },
      "slk": {
        "official": "Republika Trinidad a Tobaga",
        "common": "Trinidad a Tobago"
      },
      "spa": {
        "official": "República de Trinidad y Tobago",
        "common": "Trinidad y Tobago"
      },
      "swe": {
        "official": "Republiken Trinidad och Tobago",
        "common": "Trinidad och Tobago"
      },
      "tur": {
        "official": "Trinidad ve Tobago",
        "common": "Trinidad ve Tobago"
      },
      "urd": {
        "official": "جمہوریہ ٹرینیڈاڈ و ٹوباگو",
        "common": "ٹرینیڈاڈ و ٹوباگو"
      },
      "zho": {
        "official": "特立尼达和多巴哥共和国",
        "common": "特立尼达和多巴哥"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Trinidadian",
        "m": "Trinidadian"
      },
      "fra": {
        "f": "Trinidadienne",
        "m": "Trinidadien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tg.png",
      "svg": "https://flagcdn.com/tg.svg"
    },
    "cca2": "TG",
    "capital": [
      "Lomé"
    ],
    "altSpellings": [
      "TG",
      "Togolese",
      "Togolese Republic",
      "République Togolaise"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية توغو",
        "common": "توغو"
      },
      "bre": {
        "official": "Republik Togoat",
        "common": "Togo"
      },
      "ces": {
        "official": "Republika Togo",
        "common": "Togo"
      },
      "cym": {
        "official": "Togolese Republic",
        "common": "Togo"
      },
      "deu": {
        "official": "Republik Togo",
        "common": "Togo"
      },
      "est": {
        "official": "Togo Vabariik",
        "common": "Togo"
      },
      "fin": {
        "official": "Togon tasavalta",
        "common": "Togo"
      },
      "fra": {
        "official": "République togolaise",
        "common": "Togo"
      },
      "hrv": {
        "official": "Togolese Republika",
        "common": "Togo"
      },
      "hun": {
        "official": "Togói Köztársaság",
        "common": "Togo"
      },
      "ita": {
        "official": "Repubblica del Togo",
        "common": "Togo"
      },
      "jpn": {
        "official": "トーゴ共和国",
        "common": "トーゴ"
      },
      "kor": {
        "official": "토고 공화국",
        "common": "토고"
      },
      "nld": {
        "official": "Republiek Togo",
        "common": "Togo"
      },
      "per": {
        "official": "جمهوری توگو",
        "common": "توگو"
      },
      "pol": {
        "official": "Republika Togijska",
        "common": "Togo"
      },
      "por": {
        "official": "República do Togo",
        "common": "Togo"
      },
      "rus": {
        "official": "Того Республика",
        "common": "Того"
      },
      "slk": {
        "official": "Togská republika",
        "common": "Togo"
      },
      "spa": {
        "official": "República de Togo",
        "common": "Togo"
      },
      "swe": {
        "official": "Republiken Togo",
        "common": "Togo"
      },
      "tur": {
        "official": "Togo Cumhuriyeti",
        "common": "Togo"
      },
      "urd": {
        "official": "جمہوریہ ٹوگو",
        "common": "ٹوگو"
      },
      "zho": {
        "official": "多哥共和国",
        "common": "多哥"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Togolese",
        "m": "Togolese"
      },
      "fra": {
        "f": "Togolaise",
        "m": "Togolais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ps.png",
      "svg": "https://flagcdn.com/ps.svg"
    },
    "cca2": "PS",
    "capital": [
      "Ramallah",
      "Jerusalem"
    ],
    "altSpellings": [
      "PS",
      "Palestine, State of",
      "State of Palestine",
      "Dawlat Filasṭin"
    ],
    "translations": {
      "ara": {
        "official": "دولة فلسطين",
        "common": "فلسطين"
      },
      "bre": {
        "official": "Stad Palestina",
        "common": "Palestina"
      },
      "ces": {
        "official": "Stát Palestina",
        "common": "Palestina"
      },
      "cym": {
        "official": "State of Palestine",
        "common": "Palestine"
      },
      "deu": {
        "official": "Staat Palästina",
        "common": "Palästina"
      },
      "est": {
        "official": "Palestiina Riik",
        "common": "Palestiina"
      },
      "fin": {
        "official": "Palestiinan valtio",
        "common": "Palestiina"
      },
      "fra": {
        "official": "État de Palestine",
        "common": "Palestine"
      },
      "hrv": {
        "official": "State of Palestine",
        "common": "Palestina"
      },
      "hun": {
        "official": "Palesztin Autonómia",
        "common": "Palesztina"
      },
      "ita": {
        "official": "Stato di Palestina",
        "common": "Palestina"
      },
      "jpn": {
        "official": "パレスチナ自治政府",
        "common": "パレスチナ"
      },
      "kor": {
        "official": "팔레스타인국",
        "common": "팔레스타인"
      },
      "nld": {
        "official": "Staat Palestina",
        "common": "Palestijnse gebieden"
      },
      "per": {
        "official": "دولت فلسطین",
        "common": "فلسطین"
      },
      "pol": {
        "official": "Państwo Palestyna",
        "common": "Palestyna"
      },
      "por": {
        "official": "Estado da Palestina",
        "common": "Palestina"
      },
      "rus": {
        "official": "Государство Палестина",
        "common": "Палестина"
      },
      "slk": {
        "official": "Palestínsky štát",
        "common": "Palestína"
      },
      "spa": {
        "official": "Estado de Palestina",
        "common": "Palestina"
      },
      "swe": {
        "official": "Palestina",
        "common": "Palestina"
      },
      "tur": {
        "official": "Filistin Devleti",
        "common": "Filistin"
      },
      "urd": {
        "official": "ریاستِ فلسطین",
        "common": "فلسطین"
      },
      "zho": {
        "official": "巴勒斯坦国",
        "common": "巴勒斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Palestinian",
        "m": "Palestinian"
      },
      "fra": {
        "f": "Palestinienne",
        "m": "Palestinien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mg.png",
      "svg": "https://flagcdn.com/mg.svg"
    },
    "cca2": "MG",
    "capital": [
      "Antananarivo"
    ],
    "altSpellings": [
      "MG",
      "Republic of Madagascar",
      "Repoblikan'i Madagasikara",
      "République de Madagascar"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مدغشقر",
        "common": "مدغشقر"
      },
      "bre": {
        "official": "Republik Madagaskar",
        "common": "Madagaskar"
      },
      "ces": {
        "official": "Madagaskarská republika",
        "common": "Madagaskar"
      },
      "cym": {
        "official": "Republic of Madagascar",
        "common": "Madagascar"
      },
      "deu": {
        "official": "Republik Madagaskar",
        "common": "Madagaskar"
      },
      "est": {
        "official": "Madagaskari Vabariik",
        "common": "Madagaskar"
      },
      "fin": {
        "official": "Madagaskarin tasavalta",
        "common": "Madagaskar"
      },
      "fra": {
        "official": "République de Madagascar",
        "common": "Madagascar"
      },
      "hrv": {
        "official": "Republika Madagaskar",
        "common": "Madagaskar"
      },
      "hun": {
        "official": "Madagaszkári Köztársaság",
        "common": "Madagaszkár"
      },
      "ita": {
        "official": "Repubblica del Madagascar",
        "common": "Madagascar"
      },
      "jpn": {
        "official": "マダガスカル共和国",
        "common": "マダガスカル"
      },
      "kor": {
        "official": "마다가스카르 공화국",
        "common": "마다가스카르"
      },
      "nld": {
        "official": "Republiek Madagaskar",
        "common": "Madagaskar"
      },
      "per": {
        "official": "جمهوری ماداگاسکار",
        "common": "ماداگاسکار"
      },
      "pol": {
        "official": "Republika Madagaskaru",
        "common": "Madagaskar"
      },
      "por": {
        "official": "República de Madagáscar",
        "common": "Madagáscar"
      },
      "rus": {
        "official": "Республика Мадагаскар",
        "common": "Мадагаскар"
      },
      "slk": {
        "official": "Madagaskarská republika",
        "common": "Madagaskar"
      },
      "spa": {
        "official": "República de Madagascar",
        "common": "Madagascar"
      },
      "swe": {
        "official": "Republiken Madagaskar",
        "common": "Madagaskar"
      },
      "tur": {
        "official": "Madagaskar Cumhuriyeti",
        "common": "Madagaskar"
      },
      "urd": {
        "official": "جمہوریہ مڈغاسکر",
        "common": "مڈغاسکر"
      },
      "zho": {
        "official": "马达加斯加共和国",
        "common": "马达加斯加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Malagasy",
        "m": "Malagasy"
      },
      "fra": {
        "f": "Malgache",
        "m": "Malgache"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/hr.png",
      "svg": "https://flagcdn.com/hr.svg"
    },
    "cca2": "HR",
    "capital": [
      "Zagreb"
    ],
    "altSpellings": [
      "HR",
      "Hrvatska",
      "Republic of Croatia",
      "Republika Hrvatska"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كرواتيا",
        "common": "كرواتيا"
      },
      "bre": {
        "official": "Republik Kroatia",
        "common": "Kroatia"
      },
      "ces": {
        "official": "Chorvatská republika",
        "common": "Chorvatsko"
      },
      "cym": {
        "official": "Gweriniaeth Croatia",
        "common": "Croatia"
      },
      "deu": {
        "official": "Republik Kroatien",
        "common": "Kroatien"
      },
      "est": {
        "official": "Horvaatia Vabariik",
        "common": "Horvaatia"
      },
      "fin": {
        "official": "Kroatian tasavalta",
        "common": "Kroatia"
      },
      "fra": {
        "official": "République de Croatie",
        "common": "Croatie"
      },
      "hrv": {
        "official": "Republika Hrvatska",
        "common": "Hrvatska"
      },
      "hun": {
        "official": "Horvát Köztársaság",
        "common": "Horvátország"
      },
      "ita": {
        "official": "Repubblica di Croazia",
        "common": "Croazia"
      },
      "jpn": {
        "official": "クロアチア共和国",
        "common": "クロアチア"
      },
      "kor": {
        "official": "크로아티아 공화국",
        "common": "크로아티아"
      },
      "nld": {
        "official": "Republiek Kroatië",
        "common": "Kroatië"
      },
      "per": {
        "official": "جمهوری کرواسی",
        "common": "کرُواسی"
      },
      "pol": {
        "official": "Republika Chorwacji",
        "common": "Chorwacja"
      },
      "por": {
        "official": "República da Croácia",
        "common": "Croácia"
      },
      "rus": {
        "official": "Республика Хорватия",
        "common": "Хорватия"
      },
      "slk": {
        "official": "Chorvátska republika",
        "common": "Chorvátsko"
      },
      "spa": {
        "official": "República de Croacia",
        "common": "Croacia"
      },
      "swe": {
        "official": "Republiken Kroatien",
        "common": "Kroatien"
      },
      "tur": {
        "official": "Hırvatistan Cumhuriyeti",
        "common": "Hırvatistan"
      },
      "urd": {
        "official": "جمہوریہ کرویئشا",
        "common": "کرویئشا"
      },
      "zho": {
        "official": "克罗地亚共和国",
        "common": "克罗地亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Croatian",
        "m": "Croatian"
      },
      "fra": {
        "f": "Croate",
        "m": "Croate"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fo.png",
      "svg": "https://flagcdn.com/fo.svg"
    },
    "cca2": "FO",
    "capital": [
      "Tórshavn"
    ],
    "altSpellings": [
      "FO",
      "Føroyar",
      "Færøerne"
    ],
    "translations": {
      "ara": {
        "official": "جزر فارو",
        "common": "جزر فارو"
      },
      "bre": {
        "official": "Inizi Faero",
        "common": "Inizi Faero"
      },
      "ces": {
        "official": "Faerské ostrovy",
        "common": "Faerské ostrovy"
      },
      "cym": {
        "official": "Faroe Islands",
        "common": "Faroe Islands"
      },
      "deu": {
        "official": "Färöer",
        "common": "Färöer-Inseln"
      },
      "est": {
        "official": "Fääri saared",
        "common": "Fääri saared"
      },
      "fin": {
        "official": "Färsaaret",
        "common": "Färsaaret"
      },
      "fra": {
        "official": "Îles Féroé",
        "common": "Îles Féroé"
      },
      "hrv": {
        "official": "Farski Otoci",
        "common": "Farski Otoci"
      },
      "hun": {
        "official": "Feröer",
        "common": "Feröer"
      },
      "ita": {
        "official": "Isole Faroe",
        "common": "Isole Far Oer"
      },
      "jpn": {
        "official": "フェロー諸島",
        "common": "フェロー諸島"
      },
      "kor": {
        "official": "페로 제도",
        "common": "페로 제도"
      },
      "nld": {
        "official": "Faeröer",
        "common": "Faeröer"
      },
      "per": {
        "official": "جزایر فاروئه",
        "common": "جزایر فاروئه"
      },
      "pol": {
        "official": "Wyspy Owcze",
        "common": "Wyspy Owcze"
      },
      "por": {
        "official": "Ilhas Faroe",
        "common": "Ilhas Faroé"
      },
      "rus": {
        "official": "Фарерские острова",
        "common": "Фарерские острова"
      },
      "slk": {
        "official": "Faerské ostrovy",
        "common": "Faerské ostrovy"
      },
      "spa": {
        "official": "Islas Feroe",
        "common": "Islas Faroe"
      },
      "swe": {
        "official": "Färöarna",
        "common": "Färöarna"
      },
      "tur": {
        "official": "Faroe Adaları",
        "common": "Faroe Adaları"
      },
      "urd": {
        "official": "جزائر فارو",
        "common": "جزائر فارو"
      },
      "zho": {
        "official": "法罗群岛",
        "common": "法罗群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Faroese",
        "m": "Faroese"
      },
      "fra": {
        "f": "Féroïenne",
        "m": "Féroïen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ht.png",
      "svg": "https://flagcdn.com/ht.svg"
    },
    "cca2": "HT",
    "capital": [
      "Port-au-Prince"
    ],
    "altSpellings": [
      "HT",
      "Republic of Haiti",
      "République d'Haïti",
      "Repiblik Ayiti"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية هايتي",
        "common": "هايتي"
      },
      "bre": {
        "official": "Republik Haiti",
        "common": "Haiti"
      },
      "ces": {
        "official": "Republika Haiti",
        "common": "Haiti"
      },
      "cym": {
        "official": "Republic of Haiti",
        "common": "Haiti"
      },
      "deu": {
        "official": "Republik Haiti",
        "common": "Haiti"
      },
      "est": {
        "official": "Haiti Vabariik",
        "common": "Haiti"
      },
      "fin": {
        "official": "Haitin tasavalta",
        "common": "Haiti"
      },
      "fra": {
        "official": "République d'Haïti",
        "common": "Haïti"
      },
      "hrv": {
        "official": "Republika Haiti",
        "common": "Haiti"
      },
      "hun": {
        "official": "Haiti Köztársaság",
        "common": "Haiti"
      },
      "ita": {
        "official": "Repubblica di Haiti",
        "common": "Haiti"
      },
      "jpn": {
        "official": "ハイチ共和国",
        "common": "ハイチ"
      },
      "kor": {
        "official": "아이티 공화국",
        "common": "아이티"
      },
      "nld": {
        "official": "Republiek Haïti",
        "common": "Haïti"
      },
      "per": {
        "official": "جمهوری هائیتی",
        "common": "هائیتی"
      },
      "pol": {
        "official": "Republika Haiti",
        "common": "Haiti"
      },
      "por": {
        "official": "República do Haiti",
        "common": "Haiti"
      },
      "rus": {
        "official": "Республика Гаити",
        "common": "Гаити"
      },
      "slk": {
        "official": "Haitská republika",
        "common": "Haiti"
      },
      "spa": {
        "official": "República de Haití",
        "common": "Haití"
      },
      "swe": {
        "official": "Republiken Haiti",
        "common": "Haiti"
      },
      "tur": {
        "official": "Haiti Cumhuriyeti",
        "common": "Haiti"
      },
      "urd": {
        "official": "جمہوریہ ہیٹی",
        "common": "ہیٹی"
      },
      "zho": {
        "official": "海地共和国",
        "common": "海地"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Haitian",
        "m": "Haitian"
      },
      "fra": {
        "f": "Haïtienne",
        "m": "Haïtien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ms.png",
      "svg": "https://flagcdn.com/ms.svg"
    },
    "cca2": "MS",
    "capital": [
      "Plymouth"
    ],
    "altSpellings": [
      "MS"
    ],
    "translations": {
      "ara": {
        "official": "مونتسرات",
        "common": "مونتسرات"
      },
      "bre": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "ces": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "cym": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "deu": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "est": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "fin": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "fra": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "hrv": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "hun": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "ita": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "jpn": {
        "official": "モントセラト",
        "common": "モントセラト"
      },
      "kor": {
        "official": "몬트세랫",
        "common": "몬트세랫"
      },
      "nld": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "per": {
        "official": "مونتسرات",
        "common": "مونتسرات"
      },
      "pol": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "por": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "rus": {
        "official": "Монтсеррат",
        "common": "Монтсеррат"
      },
      "slk": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "spa": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "swe": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "tur": {
        "official": "Montserrat",
        "common": "Montserrat"
      },
      "urd": {
        "official": "مانٹسریٹ",
        "common": "مانٹسریٹ"
      },
      "zho": {
        "official": "蒙特塞拉特",
        "common": "蒙特塞拉特"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Montserratian",
        "m": "Montserratian"
      },
      "fra": {
        "f": "Montserratienne",
        "m": "Montserratien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ck.png",
      "svg": "https://flagcdn.com/ck.svg"
    },
    "cca2": "CK",
    "capital": [
      "Avarua"
    ],
    "altSpellings": [
      "CK",
      "Kūki 'Āirani"
    ],
    "translations": {
      "ara": {
        "official": "جزر كوك",
        "common": "جزر كوك"
      },
      "bre": {
        "official": "Inizi Cook",
        "common": "Inizi Cook"
      },
      "ces": {
        "official": "Cookovy ostrovy",
        "common": "Cookovy ostrovy"
      },
      "cym": {
        "official": "Ynysoedd Cook",
        "common": "Ynysoedd Cook"
      },
      "deu": {
        "official": "Cookinseln",
        "common": "Cookinseln"
      },
      "est": {
        "official": "Cooki saared",
        "common": "Cooki saared"
      },
      "fin": {
        "official": "Cookinsaaret",
        "common": "Cookinsaaret"
      },
      "fra": {
        "official": "Îles Cook",
        "common": "Îles Cook"
      },
      "hrv": {
        "official": "Cook Islands",
        "common": "Cookovo Otočje"
      },
      "hun": {
        "official": "Cook-szigetek",
        "common": "Cook-szigetek"
      },
      "ita": {
        "official": "Isole Cook",
        "common": "Isole Cook"
      },
      "jpn": {
        "official": "クック諸島",
        "common": "クック諸島"
      },
      "kor": {
        "official": "쿡 제도",
        "common": "쿡 제도"
      },
      "nld": {
        "official": "Cook eilanden",
        "common": "Cookeilanden"
      },
      "per": {
        "official": "جزایر کوک",
        "common": "جزایر کوک"
      },
      "pol": {
        "official": "Wyspy Cooka",
        "common": "Wyspy Cooka"
      },
      "por": {
        "official": "Ilhas Cook",
        "common": "Ilhas Cook"
      },
      "rus": {
        "official": "острова Кука",
        "common": "Острова Кука"
      },
      "slk": {
        "official": "Cookove ostrovy",
        "common": "Cookove ostrovy"
      },
      "spa": {
        "official": "Islas Cook",
        "common": "Islas Cook"
      },
      "swe": {
        "official": "Cooköarna",
        "common": "Cooköarna"
      },
      "tur": {
        "official": "Cook Adaları",
        "common": "Cook Adaları"
      },
      "urd": {
        "official": "جزائر کک",
        "common": "جزائر کک"
      },
      "zho": {
        "official": "库克群岛",
        "common": "库克群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cook Islander",
        "m": "Cook Islander"
      },
      "fra": {
        "f": "Cookienne",
        "m": "Cookien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tl.png",
      "svg": "https://flagcdn.com/tl.svg"
    },
    "cca2": "TL",
    "capital": [
      "Dili"
    ],
    "altSpellings": [
      "TL",
      "East Timor",
      "Democratic Republic of Timor-Leste",
      "República Democrática de Timor-Leste",
      "Repúblika Demokrátika Timór-Leste",
      "Timór Lorosa'e",
      "Timor Lorosae"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية تيمور الشرقية الديمقراطية",
        "common": "تيمور الشرقية"
      },
      "bre": {
        "official": "Republik demakratel Timor ar Reter",
        "common": "Timor ar Reter"
      },
      "ces": {
        "official": "Demokratická republika Východní Timor",
        "common": "Východní Timor"
      },
      "cym": {
        "official": "Democratic Republic of Timor-Leste",
        "common": "Timor-Leste"
      },
      "deu": {
        "official": "Demokratische Republik Timor-Leste",
        "common": "Osttimor"
      },
      "est": {
        "official": "Timor-Leste Demokraatlik Vabariik",
        "common": "Ida-Timor"
      },
      "fin": {
        "official": "Itä-Timorin demokraattinen tasavalta",
        "common": "Itä-Timor"
      },
      "fra": {
        "official": "République démocratique du Timor oriental",
        "common": "Timor oriental"
      },
      "hrv": {
        "official": "Demokratska Republika Timor-Leste",
        "common": "Istočni Timor"
      },
      "hun": {
        "official": "Kelet-timori Demokratikus Köztársaság",
        "common": "Kelet-Timor"
      },
      "ita": {
        "official": "Repubblica Democratica di Timor Est",
        "common": "Timor Est"
      },
      "jpn": {
        "official": "東ティモール民主共和国",
        "common": "東ティモール"
      },
      "kor": {
        "official": "동티모르 민주 공화국",
        "common": "동티모르"
      },
      "nld": {
        "official": "Democratische Republiek Oost-Timor",
        "common": "Oost-Timor"
      },
      "per": {
        "official": "جمهوری دموکراتیک تیمور شرقی",
        "common": "تیمور شرقی"
      },
      "pol": {
        "official": "Demokratyczna Republika Timoru Wschodniego",
        "common": "Timor Wschodni"
      },
      "por": {
        "official": "República Democrática de Timor-Leste",
        "common": "Timor-Leste"
      },
      "rus": {
        "official": "Демократическая Республика Тимор -Лешти",
        "common": "Восточный Тимор"
      },
      "slk": {
        "official": "Východotimorská demokratická republika",
        "common": "Východný Timor"
      },
      "spa": {
        "official": "República Democrática de Timor-Leste",
        "common": "Timor Oriental"
      },
      "swe": {
        "official": "Demokratiska republiken Östtimor",
        "common": "Östtimor"
      },
      "tur": {
        "official": "Doğu Timor Demokratik Cumhuriyeti",
        "common": "Doğu Timor"
      },
      "urd": {
        "official": "جمہوری جمہوریہ مشرقی تیمور",
        "common": "مشرقی تیمور"
      },
      "zho": {
        "official": "东帝汶民主共和国",
        "common": "东帝汶"
      }
    },
    "demonyms": {
      "eng": {
        "f": "East Timorese",
        "m": "East Timorese"
      },
      "fra": {
        "f": "Est-timoraise",
        "m": "Est-timorais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mq.png",
      "svg": "https://flagcdn.com/mq.svg"
    },
    "cca2": "MQ",
    "capital": [
      "Fort-de-France"
    ],
    "altSpellings": [
      "MQ"
    ],
    "translations": {
      "ara": {
        "official": "مارتينيك",
        "common": "مارتينيك"
      },
      "bre": {
        "official": "Martinik",
        "common": "Martinik"
      },
      "ces": {
        "official": "Martinik",
        "common": "Martinik"
      },
      "cym": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "deu": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "est": {
        "official": "Martinique’i departemang",
        "common": "Martinique"
      },
      "fin": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "fra": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "hrv": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "hun": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "ita": {
        "official": "Martinique",
        "common": "Martinica"
      },
      "jpn": {
        "official": "マルティニーク島",
        "common": "マルティニーク"
      },
      "kor": {
        "official": "마르티니크",
        "common": "마르티니크"
      },
      "nld": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "per": {
        "official": "مارتینیک",
        "common": "مارتینیک"
      },
      "pol": {
        "official": "Martynika",
        "common": "Martynika"
      },
      "por": {
        "official": "Martinique",
        "common": "Martinica"
      },
      "rus": {
        "official": "Мартиника",
        "common": "Мартиника"
      },
      "slk": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "spa": {
        "official": "Martinica",
        "common": "Martinica"
      },
      "swe": {
        "official": "Martinique",
        "common": "Martinique"
      },
      "tur": {
        "official": "Martinik",
        "common": "Martinik"
      },
      "urd": {
        "official": "مارٹینیک",
        "common": "مارٹینیک"
      },
      "zho": {
        "official": "马提尼克",
        "common": "马提尼克"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Martinican",
        "m": "Martinican"
      },
      "fra": {
        "f": "Martiniquaise",
        "m": "Martiniquais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cu.png",
      "svg": "https://flagcdn.com/cu.svg"
    },
    "cca2": "CU",
    "capital": [
      "Havana"
    ],
    "altSpellings": [
      "CU",
      "Republic of Cuba",
      "República de Cuba"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كوبا",
        "common": "كوبا"
      },
      "bre": {
        "official": "Republik Kuba",
        "common": "Kuba"
      },
      "ces": {
        "official": "Kubánská republika",
        "common": "Kuba"
      },
      "cym": {
        "official": "Gweriniaeth Ciwba",
        "common": "Ciwba"
      },
      "deu": {
        "official": "Republik Kuba",
        "common": "Kuba"
      },
      "est": {
        "official": "Kuuba Vabariik",
        "common": "Kuuba"
      },
      "fin": {
        "official": "Kuuban tasavalta",
        "common": "Kuuba"
      },
      "fra": {
        "official": "République de Cuba",
        "common": "Cuba"
      },
      "hrv": {
        "official": "Republika Kuba",
        "common": "Kuba"
      },
      "hun": {
        "official": "Kubai Köztársaság",
        "common": "Kuba"
      },
      "ita": {
        "official": "Repubblica di Cuba",
        "common": "Cuba"
      },
      "jpn": {
        "official": "キューバ共和国",
        "common": "キューバ"
      },
      "kor": {
        "official": "쿠바 공화국",
        "common": "쿠바"
      },
      "nld": {
        "official": "Republiek Cuba",
        "common": "Cuba"
      },
      "per": {
        "official": "جمهوری کوبا",
        "common": "کوبا"
      },
      "pol": {
        "official": "Republika Kuby",
        "common": "Kuba"
      },
      "por": {
        "official": "República de Cuba",
        "common": "Cuba"
      },
      "rus": {
        "official": "Республика Куба",
        "common": "Куба"
      },
      "slk": {
        "official": "Kubánska republika",
        "common": "Kuba"
      },
      "spa": {
        "official": "República de Cuba",
        "common": "Cuba"
      },
      "swe": {
        "official": "Republiken Kuba",
        "common": "Kuba"
      },
      "tur": {
        "official": "Küba Cumhuriyeti",
        "common": "Küba"
      },
      "urd": {
        "official": "جمہوریہ کیوبا",
        "common": "کیوبا"
      },
      "zho": {
        "official": "古巴共和国",
        "common": "古巴"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cuban",
        "m": "Cuban"
      },
      "fra": {
        "f": "Cubaine",
        "m": "Cubain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tw.png",
      "svg": "https://flagcdn.com/tw.svg"
    },
    "cca2": "TW",
    "capital": [
      "Taipei"
    ],
    "altSpellings": [
      "TW",
      "Táiwān",
      "Republic of China",
      "中華民國",
      "Zhōnghuá Mínguó",
      "Chinese Taipei"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الصين (تايوان)",
        "common": "تايوان"
      },
      "bre": {
        "official": "Republik Sina (Taiwan)",
        "common": "Taiwan"
      },
      "ces": {
        "official": "Čínská republika",
        "common": "Tchaj-wan"
      },
      "cym": {
        "official": "Republic of China (Taiwan)",
        "common": "Taiwan"
      },
      "deu": {
        "official": "Republik China (Taiwan)",
        "common": "Taiwan"
      },
      "est": {
        "official": "Taiwani",
        "common": "Taiwan"
      },
      "fin": {
        "official": "Kiinan tasavalta",
        "common": "Taiwan"
      },
      "fra": {
        "official": "République de Chine (Taïwan)",
        "common": "Taïwan"
      },
      "hrv": {
        "official": "Republika Kina",
        "common": "Tajvan"
      },
      "hun": {
        "official": "Kínai Köztársaság",
        "common": "Tajvan"
      },
      "ita": {
        "official": "Repubblica cinese (Taiwan)",
        "common": "Taiwan"
      },
      "jpn": {
        "official": "中華民国",
        "common": "台湾"
      },
      "kor": {
        "official": "중화민국",
        "common": "대만"
      },
      "nld": {
        "official": "Republiek China (Taiwan)",
        "common": "Taiwan"
      },
      "per": {
        "official": "جمهوری چین",
        "common": "تایوان"
      },
      "pol": {
        "official": "Republika Chińska (Tajwan)",
        "common": "Tajwan"
      },
      "por": {
        "official": "República da China",
        "common": "Ilha Formosa"
      },
      "rus": {
        "official": "Китайская Республика",
        "common": "Тайвань"
      },
      "slk": {
        "official": "Čínska republika",
        "common": "Taiwan"
      },
      "spa": {
        "official": "República de China en Taiwán",
        "common": "Taiwán"
      },
      "swe": {
        "official": "Republiken Kina",
        "common": "Taiwan"
      },
      "tur": {
        "official": "Çin Cumhuriyeti (Tayvan)",
        "common": "Tayvan"
      },
      "urd": {
        "official": "جمہوریہ چین (تائیوان)",
        "common": "تائیوان"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Taiwanese",
        "m": "Taiwanese"
      },
      "fra": {
        "f": "Taïwanaise",
        "m": "Taïwanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sz.png",
      "svg": "https://flagcdn.com/sz.svg"
    },
    "cca2": "SZ",
    "capital": [
      "Mbabane"
    ],
    "altSpellings": [
      "SZ",
      "Swaziland",
      "weSwatini",
      "Swatini",
      "Ngwane",
      "Kingdom of Eswatini",
      "Umbuso weSwatini"
    ],
    "translations": {
      "ara": {
        "official": "مملكة إسواتيني",
        "common": "إسواتيني"
      },
      "bre": {
        "official": "Rouantelezh Eswatini",
        "common": "Eswatini"
      },
      "ces": {
        "official": "Svazijské království",
        "common": "Svazijsko"
      },
      "cym": {
        "official": "Kingdom of Eswatini",
        "common": "Eswatini"
      },
      "deu": {
        "official": "Königreich Eswatini",
        "common": "Swasiland"
      },
      "est": {
        "official": "eSwatini Kuningriik",
        "common": "Svaasimaa"
      },
      "fin": {
        "official": "Swazimaan kuningaskunta",
        "common": "Swazimaa"
      },
      "fra": {
        "official": "Royaume d’Eswatini",
        "common": "Swaziland"
      },
      "hrv": {
        "official": "Kraljevina eSwatini",
        "common": "Svazi"
      },
      "hun": {
        "official": "Szváziföldi Királyság",
        "common": "Szváziföld"
      },
      "ita": {
        "official": "Regno di eSwatini",
        "common": "Swaziland"
      },
      "jpn": {
        "official": "スワジランド王国",
        "common": "スワジランド"
      },
      "kor": {
        "official": "에스와티니 왕국",
        "common": "에스와티니"
      },
      "nld": {
        "official": "Koninkrijk eSwatini",
        "common": "Swaziland"
      },
      "per": {
        "official": "پادشاهی سوازیلند",
        "common": "اسواتینی"
      },
      "pol": {
        "official": "Królestwo Suazi",
        "common": "Suazi"
      },
      "por": {
        "official": "Reino de eSwatini",
        "common": "Suazilândia"
      },
      "rus": {
        "official": "Королевство Свазиленд",
        "common": "Свазиленд"
      },
      "slk": {
        "official": "Svazijské kráľovstvo",
        "common": "Svazijsko"
      },
      "spa": {
        "official": "Reino de eSwatini",
        "common": "Suazilandia"
      },
      "swe": {
        "official": "Konungariket Eswatini",
        "common": "Swaziland"
      },
      "tur": {
        "official": "Esvatini Krallığı",
        "common": "Esvatini"
      },
      "urd": {
        "official": "مملکتِ سوازی لینڈ",
        "common": "سوازی لینڈ"
      },
      "zho": {
        "official": "斯威士兰王国",
        "common": "斯威士兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Swazi",
        "m": "Swazi"
      },
      "fra": {
        "f": "Swazie",
        "m": "Swazie"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ua.png",
      "svg": "https://flagcdn.com/ua.svg"
    },
    "cca2": "UA",
    "capital": [
      "Kyiv"
    ],
    "altSpellings": [
      "UA",
      "Ukrayina"
    ],
    "translations": {
      "ara": {
        "official": "أوكرانيا",
        "common": "أوكرانيا"
      },
      "bre": {
        "official": "Ukraina",
        "common": "Ukraina"
      },
      "ces": {
        "official": "Ukrajina",
        "common": "Ukrajina"
      },
      "cym": {
        "official": "Ukraine",
        "common": "Ukraine"
      },
      "deu": {
        "official": "Ukraine",
        "common": "Ukraine"
      },
      "est": {
        "official": "Ukraina",
        "common": "Ukraina"
      },
      "fin": {
        "official": "Ukraina",
        "common": "Ukraina"
      },
      "fra": {
        "official": "Ukraine",
        "common": "Ukraine"
      },
      "hrv": {
        "official": "Ukrajina",
        "common": "Ukrajina"
      },
      "hun": {
        "official": "Ukrajna",
        "common": "Ukrajna"
      },
      "ita": {
        "official": "Ucraina",
        "common": "Ucraina"
      },
      "jpn": {
        "official": "ウクライナ",
        "common": "ウクライナ"
      },
      "kor": {
        "official": "우크라이나",
        "common": "우크라이나"
      },
      "nld": {
        "official": "Oekraïne",
        "common": "Oekraïne"
      },
      "per": {
        "official": "اوکراین",
        "common": "اوکراین"
      },
      "pol": {
        "official": "Ukraina",
        "common": "Ukraina"
      },
      "por": {
        "official": "Ucrânia",
        "common": "Ucrânia"
      },
      "rus": {
        "official": "Украина",
        "common": "Украина"
      },
      "slk": {
        "official": "Ukrajina",
        "common": "Ukrajina"
      },
      "spa": {
        "official": "Ucrania",
        "common": "Ucrania"
      },
      "swe": {
        "official": "Ukraina",
        "common": "Ukraina"
      },
      "tur": {
        "official": "Ukrayna",
        "common": "Ukrayna"
      },
      "urd": {
        "official": "یوکرین",
        "common": "یوکرین"
      },
      "zho": {
        "official": "乌克兰",
        "common": "乌克兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ukrainian",
        "m": "Ukrainian"
      },
      "fra": {
        "f": "Ukrainienne",
        "m": "Ukrainien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bm.png",
      "svg": "https://flagcdn.com/bm.svg"
    },
    "cca2": "BM",
    "capital": [
      "Hamilton"
    ],
    "altSpellings": [
      "BM",
      "The Islands of Bermuda",
      "The Bermudas",
      "Somers Isles"
    ],
    "translations": {
      "ara": {
        "official": "برمودا",
        "common": "برمودا"
      },
      "bre": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "ces": {
        "official": "Bermudské ostrovy",
        "common": "Bermudy"
      },
      "cym": {
        "official": "Bermiwda",
        "common": "Bermiwda"
      },
      "deu": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "est": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "fin": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "fra": {
        "official": "Bermudes",
        "common": "Bermudes"
      },
      "hrv": {
        "official": "Bermuda",
        "common": "Bermudi"
      },
      "hun": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "ita": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "jpn": {
        "official": "バミューダ",
        "common": "バミューダ"
      },
      "kor": {
        "official": "버뮤다",
        "common": "버뮤다"
      },
      "nld": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "per": {
        "official": "جزایر برمودا",
        "common": "برمودا"
      },
      "pol": {
        "official": "Bermudy",
        "common": "Bermudy"
      },
      "por": {
        "official": "Bermudas",
        "common": "Bermudas"
      },
      "rus": {
        "official": "Бермудские острова",
        "common": "Бермудские Острова"
      },
      "slk": {
        "official": "Bermudy",
        "common": "Bermudy"
      },
      "spa": {
        "official": "Bermuda",
        "common": "Bermudas"
      },
      "swe": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "tur": {
        "official": "Bermuda",
        "common": "Bermuda"
      },
      "urd": {
        "official": "برمودا",
        "common": "برمودا"
      },
      "zho": {
        "official": "百慕大",
        "common": "百慕大"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bermudian",
        "m": "Bermudian"
      },
      "fra": {
        "f": "Bermudienne",
        "m": "Bermudien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kr.png",
      "svg": "https://flagcdn.com/kr.svg"
    },
    "cca2": "KR",
    "capital": [
      "Seoul"
    ],
    "altSpellings": [
      "KR",
      "Korea, Republic of",
      "Republic of Korea",
      "남한",
      "남조선"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كوريا",
        "common": "كوريا الجنوبية"
      },
      "bre": {
        "official": "Republik Korea",
        "common": "Korea ar Su"
      },
      "ces": {
        "official": "Korejská republika",
        "common": "Jižní Korea"
      },
      "cym": {
        "official": "Republic of Korea",
        "common": "South Korea"
      },
      "deu": {
        "official": "Republik Korea",
        "common": "Südkorea"
      },
      "est": {
        "official": "Korea Vabariik",
        "common": "Lõuna-Korea"
      },
      "fin": {
        "official": "Korean tasavalta",
        "common": "Etelä-Korea"
      },
      "fra": {
        "official": "République de Corée",
        "common": "Corée du Sud"
      },
      "hrv": {
        "official": "Republika Koreja",
        "common": "Južna Koreja"
      },
      "hun": {
        "official": "Koreai Köztársaság",
        "common": "Dél-Korea"
      },
      "ita": {
        "official": "Repubblica di Corea",
        "common": "Corea del Sud"
      },
      "jpn": {
        "official": "大韓民国",
        "common": "韓国"
      },
      "kor": {
        "official": "대한민국",
        "common": "한국"
      },
      "nld": {
        "official": "Republiek Korea",
        "common": "Zuid-Korea"
      },
      "per": {
        "official": "جمهوری کره",
        "common": "کرهٔ جنوبی"
      },
      "pol": {
        "official": "Republika Korei",
        "common": "Korea Południowa"
      },
      "por": {
        "official": "República da Coreia",
        "common": "Coreia do Sul"
      },
      "rus": {
        "official": "Республика Корея",
        "common": "Южная Корея"
      },
      "slk": {
        "official": "Kórejská republika",
        "common": "Južná Kórea"
      },
      "spa": {
        "official": "República de Corea",
        "common": "Corea del Sur"
      },
      "swe": {
        "official": "Republiken Korea",
        "common": "Sydkorea"
      },
      "tur": {
        "official": "Kore Cumhuriyeti",
        "common": "Güney Kore"
      },
      "urd": {
        "official": "جمہوریہ کوریا ",
        "common": "جنوبی کوریا"
      },
      "zho": {
        "official": "大韩民国",
        "common": "韩国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "South Korean",
        "m": "South Korean"
      },
      "fra": {
        "f": "Sud-coréenne",
        "m": "Sud-coréen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pe.png",
      "svg": "https://flagcdn.com/pe.svg"
    },
    "cca2": "PE",
    "capital": [
      "Lima"
    ],
    "altSpellings": [
      "PE",
      "Republic of Peru",
      "República del Perú"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بيرو",
        "common": "بيرو"
      },
      "bre": {
        "official": "Republik Perou",
        "common": "Perou"
      },
      "ces": {
        "official": "Peruánská republika",
        "common": "Peru"
      },
      "cym": {
        "official": "Republic of Peru",
        "common": "Peru"
      },
      "deu": {
        "official": "Republik Peru",
        "common": "Peru"
      },
      "est": {
        "official": "Peruu Vabariik",
        "common": "Peruu"
      },
      "fin": {
        "official": "Perun tasavalta",
        "common": "Peru"
      },
      "fra": {
        "official": "République du Pérou",
        "common": "Pérou"
      },
      "hrv": {
        "official": "Republika Peru",
        "common": "Peru"
      },
      "hun": {
        "official": "Perui Köztársaság",
        "common": "Peru"
      },
      "ita": {
        "official": "Repubblica del Perù",
        "common": "Perù"
      },
      "jpn": {
        "official": "ペルー共和国",
        "common": "ペルー"
      },
      "kor": {
        "official": "페루 공화국",
        "common": "페루"
      },
      "nld": {
        "official": "Republiek Peru",
        "common": "Peru"
      },
      "per": {
        "official": "جمهوری پرو",
        "common": "پرو"
      },
      "pol": {
        "official": "Republika Peru",
        "common": "Peru"
      },
      "por": {
        "official": "República do Peru",
        "common": "Perú"
      },
      "rus": {
        "official": "Республика Перу",
        "common": "Перу"
      },
      "slk": {
        "official": "Peruánska republika",
        "common": "Peru"
      },
      "spa": {
        "official": "República de Perú",
        "common": "Perú"
      },
      "swe": {
        "official": "Republiken Peru",
        "common": "Peru"
      },
      "tur": {
        "official": "Peru Cumhuriyeti",
        "common": "Peru"
      },
      "urd": {
        "official": "جمہوریہ پیرو",
        "common": "پیرو"
      },
      "zho": {
        "official": "秘鲁共和国",
        "common": "秘鲁"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Peruvian",
        "m": "Peruvian"
      },
      "fra": {
        "f": "Péruvienne",
        "m": "Péruvien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/iq.png",
      "svg": "https://flagcdn.com/iq.svg"
    },
    "cca2": "IQ",
    "capital": [
      "Baghdad"
    ],
    "altSpellings": [
      "IQ",
      "Republic of Iraq",
      "Jumhūriyyat al-‘Irāq"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية العراق",
        "common": "العراق"
      },
      "bre": {
        "official": "Republik Irak",
        "common": "Irak"
      },
      "ces": {
        "official": "Irácká republika",
        "common": "Irák"
      },
      "cym": {
        "official": "Republic of Iraq",
        "common": "Iraq"
      },
      "deu": {
        "official": "Republik Irak",
        "common": "Irak"
      },
      "est": {
        "official": "Iraagi Vabariik",
        "common": "Iraak"
      },
      "fin": {
        "official": "Irakin tasavalta",
        "common": "Irak"
      },
      "fra": {
        "official": "République d'Irak",
        "common": "Irak"
      },
      "hrv": {
        "official": "Republika Irak",
        "common": "Irak"
      },
      "hun": {
        "official": "Iraki Köztársaság",
        "common": "Irak"
      },
      "ita": {
        "official": "Repubblica dell'Iraq",
        "common": "Iraq"
      },
      "jpn": {
        "official": "イラク共和国",
        "common": "イラク"
      },
      "kor": {
        "official": "이라크 공화국",
        "common": "이라크"
      },
      "nld": {
        "official": "Republiek Irak",
        "common": "Irak"
      },
      "per": {
        "official": "جمهوری عراق",
        "common": "عراق"
      },
      "pol": {
        "official": "Republika Iraku",
        "common": "Irak"
      },
      "por": {
        "official": "República do Iraque",
        "common": "Iraque"
      },
      "rus": {
        "official": "Республика Ирак",
        "common": "Ирак"
      },
      "slk": {
        "official": "Iracká republika",
        "common": "Irak"
      },
      "spa": {
        "official": "República de Irak",
        "common": "Irak"
      },
      "swe": {
        "official": "Republiken Irak",
        "common": "Irak"
      },
      "tur": {
        "official": "Irak Cumhuriyeti",
        "common": "Irak"
      },
      "urd": {
        "official": "جمہوریہ عراق",
        "common": "عراق"
      },
      "zho": {
        "official": "伊拉克共和国",
        "common": "伊拉克"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Iraqi",
        "m": "Iraqi"
      },
      "fra": {
        "f": "Irakienne",
        "m": "Irakien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/md.png",
      "svg": "https://flagcdn.com/md.svg"
    },
    "cca2": "MD",
    "capital": [
      "Chișinău"
    ],
    "altSpellings": [
      "MD",
      "Moldova, Republic of",
      "Republic of Moldova",
      "Republica Moldova"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مولدوڤا",
        "common": "مولدوڤا"
      },
      "bre": {
        "official": "Republik Moldova",
        "common": "Moldova"
      },
      "ces": {
        "official": "Moldavská republika",
        "common": "Moldavsko"
      },
      "cym": {
        "official": "Republic of Moldova",
        "common": "Moldova"
      },
      "deu": {
        "official": "Republik Moldau",
        "common": "Moldawien"
      },
      "est": {
        "official": "Moldova Vabariik",
        "common": "Moldova"
      },
      "fin": {
        "official": "Moldovan tasavalta",
        "common": "Moldova"
      },
      "fra": {
        "official": "République de Moldavie",
        "common": "Moldavie"
      },
      "hrv": {
        "official": "Moldavija",
        "common": "Moldova"
      },
      "hun": {
        "official": "Moldovai Köztársaság",
        "common": "Moldova"
      },
      "ita": {
        "official": "Repubblica di Moldova",
        "common": "Moldavia"
      },
      "jpn": {
        "official": "モルドバ共和国",
        "common": "モルドバ共和国"
      },
      "kor": {
        "official": "몰도바 공화국",
        "common": "몰도바"
      },
      "nld": {
        "official": "Republiek Moldavië",
        "common": "Moldavië"
      },
      "per": {
        "official": "جمهوری مولداوی",
        "common": "مولداوی"
      },
      "pol": {
        "official": "Republika Mołdawii",
        "common": "Mołdawia"
      },
      "por": {
        "official": "República da Moldávia",
        "common": "Moldávia"
      },
      "rus": {
        "official": "Молдова",
        "common": "Молдавия"
      },
      "slk": {
        "official": "Moldavská republika",
        "common": "Moldavsko"
      },
      "spa": {
        "official": "República de Moldova",
        "common": "Moldavia"
      },
      "swe": {
        "official": "Republiken Moldavien",
        "common": "Moldavien"
      },
      "tur": {
        "official": "Moldova Cumhuriyeti",
        "common": "Moldova"
      },
      "urd": {
        "official": "جمہوریہ مالدووا",
        "common": "مالدووا"
      },
      "zho": {
        "official": "摩尔多瓦共和国",
        "common": "摩尔多瓦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Moldovan",
        "m": "Moldovan"
      },
      "fra": {
        "f": "Moldave",
        "m": "Moldave"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sm.png",
      "svg": "https://flagcdn.com/sm.svg"
    },
    "cca2": "SM",
    "capital": [
      "City of San Marino"
    ],
    "altSpellings": [
      "SM",
      "Republic of San Marino",
      "Repubblica di San Marino"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سان مارينو",
        "common": "سان مارينو"
      },
      "bre": {
        "official": "Republik San Marino",
        "common": "San Marino"
      },
      "ces": {
        "official": "Republika San Marino",
        "common": "San Marino"
      },
      "cym": {
        "official": "Republic of San Marino",
        "common": "San Marino"
      },
      "deu": {
        "official": "Republik San Marino",
        "common": "San Marino"
      },
      "est": {
        "official": "San Marino Vabariik",
        "common": "San Marino"
      },
      "fin": {
        "official": "San Marinon seesteinen tasavalta",
        "common": "San Marino"
      },
      "fra": {
        "official": "République de Saint-Marin",
        "common": "Saint-Marin"
      },
      "hrv": {
        "official": "Većina Serene Republika San Marino",
        "common": "San Marino"
      },
      "hun": {
        "official": "San Marino Köztársaság",
        "common": "San Marino"
      },
      "ita": {
        "official": "Serenissima Repubblica di San Marino",
        "common": "San Marino"
      },
      "jpn": {
        "official": "サンマリノのほとんどセリーヌ共和国",
        "common": "サンマリノ"
      },
      "kor": {
        "official": "산마리노 공화국",
        "common": "산마리노"
      },
      "nld": {
        "official": "Meest Serene Republiek San Marino",
        "common": "San Marino"
      },
      "per": {
        "official": "جمهوری سان مارینو",
        "common": "سان مارینو"
      },
      "pol": {
        "official": "Republika San Marino",
        "common": "San Marino"
      },
      "por": {
        "official": "Sereníssima República de San Marino",
        "common": "San Marino"
      },
      "rus": {
        "official": "Большинство Serene Республика Сан-Марино",
        "common": "Сан-Марино"
      },
      "slk": {
        "official": "Sanmarínska republika",
        "common": "San Maríno"
      },
      "spa": {
        "official": "Serenísima República de San Marino",
        "common": "San Marino"
      },
      "swe": {
        "official": "Republiken San Marino",
        "common": "San Marino"
      },
      "tur": {
        "official": "San Marino Cumhuriyeti",
        "common": "San Marino"
      },
      "urd": {
        "official": "جمہوریہ سان مارینو",
        "common": "سان مارینو"
      },
      "zho": {
        "official": "圣马力诺共和国",
        "common": "圣马力诺"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sammarinese",
        "m": "Sammarinese"
      },
      "fra": {
        "f": "Saint-Marinaise",
        "m": "Saint-Marinais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ve.png",
      "svg": "https://flagcdn.com/ve.svg"
    },
    "cca2": "VE",
    "capital": [
      "Caracas"
    ],
    "altSpellings": [
      "VE",
      "Bolivarian Republic of Venezuela",
      "Venezuela, Bolivarian Republic of",
      "República Bolivariana de Venezuela"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية فنزويلا البوليفارية",
        "common": "فنزويلا"
      },
      "bre": {
        "official": "Republik Volivarian Venezuela",
        "common": "Venezuela"
      },
      "ces": {
        "official": "Bolívarská republika Venezuela",
        "common": "Venezuela"
      },
      "cym": {
        "official": "Bolivarian Republic of Venezuela",
        "common": "Venezuela"
      },
      "deu": {
        "official": "Bolivarische Republik Venezuela",
        "common": "Venezuela"
      },
      "est": {
        "official": "Venezuela Bolívari Vabariik",
        "common": "Venezuela"
      },
      "fin": {
        "official": "Venezuelan bolivariaainen tasavalta",
        "common": "Venezuela"
      },
      "fra": {
        "official": "République bolivarienne du Venezuela",
        "common": "Venezuela"
      },
      "hrv": {
        "official": "BOLIVARIJANSKA Republika Venezuela",
        "common": "Venezuela"
      },
      "hun": {
        "official": "Venezuelai Bolivári Köztársaság",
        "common": "Venezuela"
      },
      "ita": {
        "official": "Repubblica Bolivariana del Venezuela",
        "common": "Venezuela"
      },
      "jpn": {
        "official": "ベネズエラ·ボリバル共和国",
        "common": "ベネズエラ・ボリバル共和国"
      },
      "kor": {
        "official": "베네수엘라 볼리바르 공화국",
        "common": "베네수엘라"
      },
      "nld": {
        "official": "Bolivariaanse Republiek Venezuela",
        "common": "Venezuela"
      },
      "per": {
        "official": "جمهوری بولیواری ونزوئلا",
        "common": "ونزوئلا"
      },
      "pol": {
        "official": "Boliwariańska Republika Wenezueli",
        "common": "Wenezuela"
      },
      "por": {
        "official": "República Bolivariana da Venezuela",
        "common": "Venezuela"
      },
      "rus": {
        "official": "Боливарианская Республика Венесуэла",
        "common": "Венесуэла"
      },
      "slk": {
        "official": "Venezuelská bolívarovská republika",
        "common": "Venezuela"
      },
      "spa": {
        "official": "República Bolivariana de Venezuela",
        "common": "Venezuela"
      },
      "swe": {
        "official": "Bolivarianska republiken Venezuela",
        "common": "Venezuela"
      },
      "tur": {
        "official": "Bolivarcı Venezuela Cumhuriyeti",
        "common": "Venezuela"
      },
      "urd": {
        "official": "جمہوریہ وینیزویلا",
        "common": "وینیزویلا"
      },
      "zho": {
        "official": "委内瑞拉玻利瓦尔共和国",
        "common": "委内瑞拉"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Venezuelan",
        "m": "Venezuelan"
      },
      "fra": {
        "f": "Vénézuélienne",
        "m": "Vénézuélien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gy.png",
      "svg": "https://flagcdn.com/gy.svg"
    },
    "cca2": "GY",
    "capital": [
      "Georgetown"
    ],
    "altSpellings": [
      "GY",
      "Co-operative Republic of Guyana"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غيانا التعاونية",
        "common": "غيانا"
      },
      "bre": {
        "official": "Republik Kevelourel Gwiana",
        "common": "Guyana"
      },
      "ces": {
        "official": "Kooperativní republika Guyana",
        "common": "Guyana"
      },
      "cym": {
        "official": "Co-operative Republic of Guyana",
        "common": "Guyana"
      },
      "deu": {
        "official": "Kooperative Republik Guyana",
        "common": "Guyana"
      },
      "est": {
        "official": "Guyana Vabariik",
        "common": "Guyana"
      },
      "fin": {
        "official": "Guayanan osuustoiminnallinen tasavalta",
        "common": "Guayana"
      },
      "fra": {
        "official": "République coopérative de Guyana",
        "common": "Guyana"
      },
      "hrv": {
        "official": "Zadruga Republika Gvajana",
        "common": "Gvajana"
      },
      "hun": {
        "official": "Guyanai Szövetkezeti Köztársaság",
        "common": "Guyana"
      },
      "ita": {
        "official": "Co -operative Republic of Guyana",
        "common": "Guyana"
      },
      "jpn": {
        "official": "ガイアナの協同共和国",
        "common": "ガイアナ"
      },
      "kor": {
        "official": "가이아나 협동 공화국",
        "common": "가이아나"
      },
      "nld": {
        "official": "Coöperatieve Republiek Guyana",
        "common": "Guyana"
      },
      "per": {
        "official": "جمهوری تعاونی گویان",
        "common": "گویان"
      },
      "pol": {
        "official": "Kooperacyjna Republika Gujany",
        "common": "Gujana"
      },
      "por": {
        "official": "Co -operative República da Guiana",
        "common": "Guiana"
      },
      "rus": {
        "official": "Кооперативная Республика Гайана",
        "common": "Гайана"
      },
      "slk": {
        "official": "Guyanská kooperatívna republika",
        "common": "Guyana"
      },
      "spa": {
        "official": "República Cooperativa de Guyana",
        "common": "Guyana"
      },
      "swe": {
        "official": "Kooperativa republiken Guyana",
        "common": "Guyana"
      },
      "tur": {
        "official": "Guyana Kooperatif Cumhuriyeti",
        "common": "Guyana"
      },
      "urd": {
        "official": "تعاونی جمہوریہ گیانا",
        "common": "گیانا"
      },
      "zho": {
        "official": "圭亚那共和国",
        "common": "圭亚那"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guyanese",
        "m": "Guyanese"
      },
      "fra": {
        "f": "Guyanienne",
        "m": "Guyanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ky.png",
      "svg": "https://flagcdn.com/ky.svg"
    },
    "cca2": "KY",
    "capital": [
      "George Town"
    ],
    "altSpellings": [
      "KY"
    ],
    "translations": {
      "ara": {
        "official": "جزر كايمان",
        "common": "جزر كايمان"
      },
      "bre": {
        "official": "Inizi Cayman",
        "common": "Inizi Cayman"
      },
      "ces": {
        "official": "Kajmanské ostrovy",
        "common": "Kajmanské ostrovy"
      },
      "cym": {
        "official": "Ynysoedd Cayman",
        "common": "Ynysoedd Cayman"
      },
      "deu": {
        "official": "Cayman-Inseln",
        "common": "Kaimaninseln"
      },
      "est": {
        "official": "Kaimanisaared",
        "common": "Kaimanisaared"
      },
      "fin": {
        "official": "Caymansaaret",
        "common": "Caymansaaret"
      },
      "fra": {
        "official": "Îles Caïmans",
        "common": "Îles Caïmans"
      },
      "hrv": {
        "official": "Kajmanski otoci",
        "common": "Kajmanski otoci"
      },
      "hun": {
        "official": "Kajmán-szigetek",
        "common": "Kajmán-szigetek"
      },
      "ita": {
        "official": "Isole Cayman",
        "common": "Isole Cayman"
      },
      "jpn": {
        "official": "ケイマン諸島",
        "common": "ケイマン諸島"
      },
      "kor": {
        "official": "케이맨 제도",
        "common": "케이맨 제도"
      },
      "nld": {
        "official": "Caymaneilanden",
        "common": "Caymaneilanden"
      },
      "per": {
        "official": "جزایر کیمن",
        "common": "جزایر کیمن"
      },
      "pol": {
        "official": "Kajmany",
        "common": "Kajmany"
      },
      "por": {
        "official": "Ilhas Cayman",
        "common": "Ilhas Caimão"
      },
      "rus": {
        "official": "Каймановы острова",
        "common": "Каймановы острова"
      },
      "slk": {
        "official": "Kajmanie ostrovy",
        "common": "Kajmanie ostrovy"
      },
      "spa": {
        "official": "Islas Caimán",
        "common": "Islas Caimán"
      },
      "swe": {
        "official": "Caymanöarna",
        "common": "Caymanöarna"
      },
      "tur": {
        "official": "Cayman Adaları",
        "common": "Cayman Adaları"
      },
      "urd": {
        "official": "جزائر کیمین",
        "common": "جزائر کیمین"
      },
      "zho": {
        "official": "开曼群岛",
        "common": "开曼群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Caymanian",
        "m": "Caymanian"
      },
      "fra": {
        "f": "Caïmanienne",
        "m": "Caïmanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kh.png",
      "svg": "https://flagcdn.com/kh.svg"
    },
    "cca2": "KH",
    "capital": [
      "Phnom Penh"
    ],
    "altSpellings": [
      "KH",
      "Kingdom of Cambodia"
    ],
    "translations": {
      "ara": {
        "official": "مملكة كمبوديا",
        "common": "كمبوديا"
      },
      "bre": {
        "official": "Rouantelezh Kambodja",
        "common": "Kambodja"
      },
      "ces": {
        "official": "Kambodžské království",
        "common": "Kambodža"
      },
      "cym": {
        "official": "Teyrnas Cambodia",
        "common": "Cambodia"
      },
      "deu": {
        "official": "Königreich Kambodscha",
        "common": "Kambodscha"
      },
      "est": {
        "official": "Kambodža Kuningriik",
        "common": "Kambodža"
      },
      "fin": {
        "official": "Kambodžan kuningaskunta",
        "common": "Kambodža"
      },
      "fra": {
        "official": "Royaume du Cambodge",
        "common": "Cambodge"
      },
      "hrv": {
        "official": "Kraljevina Kambodža",
        "common": "Kambodža"
      },
      "hun": {
        "official": "Kambodzsai Királyság",
        "common": "Kambodzsa"
      },
      "ita": {
        "official": "Regno di Cambogia",
        "common": "Cambogia"
      },
      "jpn": {
        "official": "カンボジア王国",
        "common": "カンボジア"
      },
      "kor": {
        "official": "캄보디아 왕국",
        "common": "캄보디아"
      },
      "nld": {
        "official": "Koninkrijk Cambodja",
        "common": "Cambodja"
      },
      "per": {
        "official": "پادشاهی کامبوج",
        "common": "کامبوج"
      },
      "pol": {
        "official": "Królestwo Kambodży",
        "common": "Kambodża"
      },
      "por": {
        "official": "Reino do Camboja",
        "common": "Camboja"
      },
      "rus": {
        "official": "Королевство Камбоджа",
        "common": "Камбоджа"
      },
      "slk": {
        "official": "Kamboǆské kráľovstvo",
        "common": "Kamboǆa"
      },
      "spa": {
        "official": "Reino de Camboya",
        "common": "Camboya"
      },
      "swe": {
        "official": "Konungariket Kambodja",
        "common": "Kambodja"
      },
      "tur": {
        "official": "Kamboçya Krallığı",
        "common": "Kamboçya"
      },
      "urd": {
        "official": "مملکتِ کمبوڈیا",
        "common": "کمبوڈیا"
      },
      "zho": {
        "official": "柬埔寨王国",
        "common": "柬埔寨"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cambodian",
        "m": "Cambodian"
      },
      "fra": {
        "f": "Cambodgienne",
        "m": "Cambodgien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cc.png",
      "svg": "https://flagcdn.com/cc.svg"
    },
    "cca2": "CC",
    "capital": [
      "West Island"
    ],
    "altSpellings": [
      "CC",
      "Keeling Islands",
      "Cocos Islands"
    ],
    "translations": {
      "ara": {
        "official": "إقليم جزر كوكوس",
        "common": "جزر كوكوس"
      },
      "bre": {
        "official": "Tiriad Inizi Cocos (Keeling)",
        "common": "Inizi Cocos (Keeling)"
      },
      "ces": {
        "official": "Kokosové ostrovy",
        "common": "Kokosové ostrovy"
      },
      "cym": {
        "official": "Tiriogaeth yr Ynysoedd Cocos (Keeling)",
        "common": "Ynysoedd Cocos"
      },
      "deu": {
        "official": "Gebiet der Kokos- (Keeling-) Inseln",
        "common": "Kokosinseln"
      },
      "est": {
        "official": "Kookossaarte ala",
        "common": "Kookossaared"
      },
      "fin": {
        "official": "Kookossaaret",
        "common": "Kookossaaret"
      },
      "fra": {
        "official": "Territoire des îles Cocos (Keeling)",
        "common": "Îles Cocos"
      },
      "hrv": {
        "official": "Teritoriju Kokosovi (Keeling) Islands",
        "common": "Kokosovi Otoci"
      },
      "hun": {
        "official": "Kókusz-szigetek",
        "common": "Kókusz-szigetek"
      },
      "ita": {
        "official": "Territorio della (Keeling) Isole Cocos",
        "common": "Isole Cocos e Keeling"
      },
      "jpn": {
        "official": "ココス諸島の領土",
        "common": "ココス（キーリング）諸島"
      },
      "kor": {
        "official": "코코스 제도",
        "common": "코코스 제도"
      },
      "nld": {
        "official": "Grondgebied van de Eilanden Cocos (Keeling )",
        "common": "Cocoseilanden"
      },
      "per": {
        "official": "جزایر کوکوس",
        "common": "جزایر کوکوس"
      },
      "pol": {
        "official": "Wyspy Kokosowe",
        "common": "Wyspy Kokosowe"
      },
      "por": {
        "official": "Território dos Cocos (Keeling)",
        "common": "Ilhas Cocos (Keeling)"
      },
      "rus": {
        "official": "Территория Кокосовые (Килинг) острова",
        "common": "Кокосовые острова"
      },
      "slk": {
        "official": "Kokosové ostrovy",
        "common": "Kokosové ostrovy"
      },
      "spa": {
        "official": "Territorio de los (Keeling) Islas Cocos",
        "common": "Islas Cocos o Islas Keeling"
      },
      "swe": {
        "official": "Kokosöarna",
        "common": "Kokosöarna"
      },
      "tur": {
        "official": "Cocos (Keeling) Adaları",
        "common": "Cocos (Keeling) Adaları"
      },
      "urd": {
        "official": "جزائر (کیلنگ) کوکوس",
        "common": "جزائر کوکوس"
      },
      "zho": {
        "official": "科科斯",
        "common": "科科斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cocos Islander",
        "m": "Cocos Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mt.png",
      "svg": "https://flagcdn.com/mt.svg"
    },
    "cca2": "MT",
    "capital": [
      "Valletta"
    ],
    "altSpellings": [
      "MT",
      "Republic of Malta",
      "Repubblika ta' Malta"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مالطا",
        "common": "مالطا"
      },
      "bre": {
        "official": "Republik Malta",
        "common": "Malta"
      },
      "ces": {
        "official": "Maltská republika",
        "common": "Malta"
      },
      "cym": {
        "official": "Republic of Malta",
        "common": "Malta"
      },
      "deu": {
        "official": "Republik Malta",
        "common": "Malta"
      },
      "est": {
        "official": "Malta Vabariik",
        "common": "Malta"
      },
      "fin": {
        "official": "Maltan tasavalta",
        "common": "Malta"
      },
      "fra": {
        "official": "République de Malte",
        "common": "Malte"
      },
      "hrv": {
        "official": "Republika Malta",
        "common": "Malta"
      },
      "hun": {
        "official": "Máltai Köztársaság",
        "common": "Málta"
      },
      "ita": {
        "official": "Repubblica di Malta",
        "common": "Malta"
      },
      "jpn": {
        "official": "マルタ共和国",
        "common": "マルタ"
      },
      "kor": {
        "official": "몰타 공화국",
        "common": "몰타"
      },
      "nld": {
        "official": "Republiek Malta",
        "common": "Malta"
      },
      "per": {
        "official": "جمهوری مالت",
        "common": "مالت"
      },
      "pol": {
        "official": "Republika Malty",
        "common": "Malta"
      },
      "por": {
        "official": "República de Malta",
        "common": "Malta"
      },
      "rus": {
        "official": "Республика Мальта",
        "common": "Мальта"
      },
      "slk": {
        "official": "Maltská republika",
        "common": "Malta"
      },
      "spa": {
        "official": "República de Malta",
        "common": "Malta"
      },
      "swe": {
        "official": "Republiken Malta",
        "common": "Malta"
      },
      "tur": {
        "official": "Malta Cumhuriyeti",
        "common": "Malta"
      },
      "urd": {
        "official": "جمہوریہ مالٹا",
        "common": "مالٹا"
      },
      "zho": {
        "official": "马耳他共和国",
        "common": "马耳他"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Maltese",
        "m": "Maltese"
      },
      "fra": {
        "f": "Maltaise",
        "m": "Maltais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lk.png",
      "svg": "https://flagcdn.com/lk.svg"
    },
    "cca2": "LK",
    "capital": [
      "Sri Jayawardenepura Kotte"
    ],
    "altSpellings": [
      "LK",
      "ilaṅkai",
      "Democratic Socialist Republic of Sri Lanka"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سريلانكا الديمقراطية الشعبية",
        "common": "سريلانكا"
      },
      "bre": {
        "official": "Republik Demokratel ha Sokialour Sri Lanka",
        "common": "Sri Lanka"
      },
      "ces": {
        "official": "Srílanská demokratická socialistická republika",
        "common": "Srí Lanka"
      },
      "cym": {
        "official": "Democratic Socialist Republic of Sri Lanka",
        "common": "Sri Lanka"
      },
      "deu": {
        "official": "Demokratische Sozialistische Republik Sri Lanka",
        "common": "Sri Lanka"
      },
      "est": {
        "official": "Sri Lanka Demokraatlik Sotsialistlik Vabariik",
        "common": "Sri Lanka"
      },
      "fin": {
        "official": "Sri Lankan demokraattinen sosialistinen tasavalta",
        "common": "Sri Lanka"
      },
      "fra": {
        "official": "République démocratique socialiste du Sri Lanka",
        "common": "Sri Lanka"
      },
      "hrv": {
        "official": "Demokratska Socijalističke Republike Šri Lanke",
        "common": "Šri Lanka"
      },
      "hun": {
        "official": "Srí Lanka-i Demokratikus Szocialista Köztársaság",
        "common": "Srí Lanka"
      },
      "ita": {
        "official": "Repubblica democratica socialista dello Sri Lanka",
        "common": "Sri Lanka"
      },
      "jpn": {
        "official": "スリランカ民主社会主義共和国",
        "common": "スリランカ"
      },
      "kor": {
        "official": "스리랑카 민주 사회주의 공화국",
        "common": "스리랑카"
      },
      "nld": {
        "official": "Democratische Socialistische Republiek Sri Lanka",
        "common": "Sri Lanka"
      },
      "per": {
        "official": "جمهوری دموکراتیک سوسیالیستی سری‌لانکا",
        "common": "سری‌لانکا"
      },
      "pol": {
        "official": "Demokratyczno-Socjalistyczna Republika Sri Lanki",
        "common": "Sri Lanka"
      },
      "por": {
        "official": "República Democrática Socialista do Sri Lanka",
        "common": "Sri Lanka"
      },
      "rus": {
        "official": "Демократическая Социалистическая Республика Шри-Ланка",
        "common": "Шри-Ланка"
      },
      "slk": {
        "official": "Srílanská demokratická socialistická republika",
        "common": "Srí Lanka"
      },
      "spa": {
        "official": "República Democrática Socialista de Sri Lanka",
        "common": "Sri Lanka"
      },
      "swe": {
        "official": "Demokratiska socialistiska republiken Sri Lanka",
        "common": "Sri Lanka"
      },
      "tur": {
        "official": "Sri Lanka Demokratik Sosyalist Cumhuriyeti",
        "common": "Sri Lanka"
      },
      "urd": {
        "official": "جمہوری و اشتراکی جمہوریہ سری لنکا",
        "common": "سری لنکا"
      },
      "zho": {
        "official": "斯里兰卡民主社会主义共和国",
        "common": "斯里兰卡"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sri Lankan",
        "m": "Sri Lankan"
      },
      "fra": {
        "f": "Sri-lankaise",
        "m": "Sri-lankais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/il.png",
      "svg": "https://flagcdn.com/il.svg"
    },
    "cca2": "IL",
    "capital": [
      "Jerusalem"
    ],
    "altSpellings": [
      "IL",
      "State of Israel",
      "Medīnat Yisrā'el"
    ],
    "translations": {
      "ara": {
        "official": "دولة إسرائيل",
        "common": "إسرائيل"
      },
      "bre": {
        "official": "Stad Israel",
        "common": "Israel"
      },
      "ces": {
        "official": "Stát Izrael",
        "common": "Izrael"
      },
      "cym": {
        "official": "State of Israel",
        "common": "Israel"
      },
      "deu": {
        "official": "Staat Israel",
        "common": "Israel"
      },
      "est": {
        "official": "Iisraeli Riik",
        "common": "Iisrael"
      },
      "fin": {
        "official": "Israelin valtio",
        "common": "Israel"
      },
      "fra": {
        "official": "État d'Israël",
        "common": "Israël"
      },
      "hrv": {
        "official": "Država Izrael",
        "common": "Izrael"
      },
      "hun": {
        "official": "Izrael",
        "common": "Izrael"
      },
      "ita": {
        "official": "Stato di Israele",
        "common": "Israele"
      },
      "jpn": {
        "official": "イスラエル国",
        "common": "イスラエル"
      },
      "kor": {
        "official": "이스라엘국",
        "common": "이스라엘"
      },
      "nld": {
        "official": "Staat Israël",
        "common": "Israël"
      },
      "per": {
        "official": "فلسطين اشغالی",
        "common": "فلسطين اشغالی"
      },
      "pol": {
        "official": "Państwo Izrael",
        "common": "Izrael"
      },
      "por": {
        "official": "Estado de Israel",
        "common": "Israel"
      },
      "rus": {
        "official": "Государство Израиль",
        "common": "Израиль"
      },
      "slk": {
        "official": "Izraelský štát",
        "common": "Izrael"
      },
      "spa": {
        "official": "Estado de Israel",
        "common": "Israel"
      },
      "swe": {
        "official": "Staten Israel",
        "common": "Israel"
      },
      "tur": {
        "official": "İsrail Devleti",
        "common": "İsrail"
      },
      "urd": {
        "official": "ریاستِ اسرائیل",
        "common": "اسرائیل"
      },
      "zho": {
        "official": "以色列国",
        "common": "以色列"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Israeli",
        "m": "Israeli"
      },
      "fra": {
        "f": "Israélienne",
        "m": "Israélien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sd.png",
      "svg": "https://flagcdn.com/sd.svg"
    },
    "cca2": "SD",
    "capital": [
      "Khartoum"
    ],
    "altSpellings": [
      "SD",
      "Republic of the Sudan",
      "Jumhūrīyat as-Sūdān"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية السودان",
        "common": "السودان"
      },
      "bre": {
        "official": "Republik Soudan",
        "common": "Soudan"
      },
      "ces": {
        "official": "Súdánská republika",
        "common": "Súdán"
      },
      "cym": {
        "official": "Republic of the Sudan",
        "common": "Sudan"
      },
      "deu": {
        "official": "Republik Sudan",
        "common": "Sudan"
      },
      "est": {
        "official": "Sudaani Vabariik",
        "common": "Sudaan"
      },
      "fin": {
        "official": "Sudanin tasavalta",
        "common": "Sudan"
      },
      "fra": {
        "official": "République du Soudan",
        "common": "Soudan"
      },
      "hrv": {
        "official": "Republika Sudan",
        "common": "Sudan"
      },
      "hun": {
        "official": "Szudáni Köztársaság",
        "common": "Szudán"
      },
      "ita": {
        "official": "Repubblica del Sudan",
        "common": "Sudan"
      },
      "jpn": {
        "official": "スーダン共和国",
        "common": "スーダン"
      },
      "kor": {
        "official": "수단 공화국",
        "common": "수단"
      },
      "nld": {
        "official": "Republiek Soedan",
        "common": "Soedan"
      },
      "per": {
        "official": "جمهوری سودان",
        "common": "سودان"
      },
      "pol": {
        "official": "Republika Sudanu",
        "common": "Sudan"
      },
      "por": {
        "official": "República do Sudão",
        "common": "Sudão"
      },
      "rus": {
        "official": "Республика Судан",
        "common": "Судан"
      },
      "slk": {
        "official": "Sudánska republika",
        "common": "Sudán"
      },
      "spa": {
        "official": "República de Sudán",
        "common": "Sudán"
      },
      "swe": {
        "official": "Republiken Sudan",
        "common": "Sudan"
      },
      "tur": {
        "official": "Sudan Cumhuriyeti",
        "common": "Sudan"
      },
      "urd": {
        "official": "جمہوریہ سودان",
        "common": "سودان"
      },
      "zho": {
        "official": "苏丹共和国",
        "common": "苏丹"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sudanese",
        "m": "Sudanese"
      },
      "fra": {
        "f": "Soudanaise",
        "m": "Soudanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/vi.png",
      "svg": "https://flagcdn.com/vi.svg"
    },
    "cca2": "VI",
    "capital": [
      "Charlotte Amalie"
    ],
    "altSpellings": [
      "VI",
      "Virgin Islands, U.S."
    ],
    "translations": {
      "ara": {
        "official": "جزر العذراء الامريكية",
        "common": "جزر العذراء الامريكية"
      },
      "bre": {
        "official": "Inizi Gwerc'h ar Stadoù-Unanet",
        "common": "Inizi Gwerc'h ar Stadoù-Unanet"
      },
      "ces": {
        "official": "Americké Panenské ostrovy",
        "common": "Americké Panenské ostrovy"
      },
      "cym": {
        "official": "Virgin Islands of the United States",
        "common": "United States Virgin Islands"
      },
      "deu": {
        "official": "Amerikanische Jungferninseln",
        "common": "Amerikanische Jungferninseln"
      },
      "est": {
        "official": "Ühendriikide Neitsisaared",
        "common": "Neitsisaared, USA"
      },
      "fin": {
        "official": "Yhdysvaltain Neitsytsaaret",
        "common": "Neitsytsaaret"
      },
      "fra": {
        "official": "Îles Vierges des États-Unis",
        "common": "Îles Vierges des États-Unis"
      },
      "hrv": {
        "official": "Djevičanski Otoci SAD",
        "common": "Američki Djevičanski Otoci"
      },
      "hun": {
        "official": "Amerikai Virgin-szigetek",
        "common": "Amerikai Virgin-szigetek"
      },
      "ita": {
        "official": "Isole Vergini degli Stati Uniti",
        "common": "Isole Vergini americane"
      },
      "jpn": {
        "official": "米国のバージン諸島",
        "common": "アメリカ領ヴァージン諸島"
      },
      "kor": {
        "official": "미국령 버진아일랜드",
        "common": "미국령 버진아일랜드"
      },
      "nld": {
        "official": "Maagdeneilanden van de Verenigde Staten",
        "common": "Amerikaanse Maagdeneilanden"
      },
      "per": {
        "official": "جزایر ویرجین ایالات متحده آمریکا",
        "common": "جزایر ویرجین ایالات متحده آمریکا"
      },
      "pol": {
        "official": "Wyspy Dziewicze Stanów Zjednoczonych",
        "common": "Wyspy Dziewicze Stanów Zjednoczonych"
      },
      "por": {
        "official": "Ilhas Virgens dos Estados Unidos",
        "common": "Ilhas Virgens dos Estados Unidos"
      },
      "rus": {
        "official": "Виргинские острова Соединенных Штатов",
        "common": "Виргинские Острова"
      },
      "slk": {
        "official": "Americké Panenské ostrovy",
        "common": "Americké Panenské ostrovy"
      },
      "spa": {
        "official": "Islas Vírgenes de los Estados Unidos",
        "common": "Islas Vírgenes de los Estados Unidos"
      },
      "swe": {
        "official": "Amerikanska Jungfruöarna",
        "common": "Amerikanska Jungfruöarna"
      },
      "tur": {
        "official": "Amerika Birleşik Devletleri Virjin Adaları",
        "common": "ABD Virjin Adaları"
      },
      "urd": {
        "official": "امریکی جزائر ورجن",
        "common": "امریکی جزائر ورجن"
      },
      "zho": {
        "official": "美属维尔京群岛",
        "common": "美属维尔京群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Virgin Islander",
        "m": "Virgin Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/er.png",
      "svg": "https://flagcdn.com/er.svg"
    },
    "cca2": "ER",
    "capital": [
      "Asmara"
    ],
    "altSpellings": [
      "ER",
      "State of Eritrea",
      "ሃገረ ኤርትራ",
      "Dawlat Iritriyá",
      "ʾErtrā",
      "Iritriyā"
    ],
    "translations": {
      "ara": {
        "official": "دولة إريتريا",
        "common": "إريتريا"
      },
      "bre": {
        "official": "Stad Eritrea",
        "common": "Eritrea"
      },
      "ces": {
        "official": "Stát Eritrea",
        "common": "Eritrea"
      },
      "cym": {
        "official": "Gwladwriaeth Eritrea",
        "common": "Eritrea"
      },
      "deu": {
        "official": "Staat Eritrea",
        "common": "Eritrea"
      },
      "est": {
        "official": "Eritrea Riik",
        "common": "Eritrea"
      },
      "fin": {
        "official": "Eritrean valtio",
        "common": "Eritrea"
      },
      "fra": {
        "official": "État d'Érythrée",
        "common": "Érythrée"
      },
      "hrv": {
        "official": "Država Eritreji",
        "common": "Eritreja"
      },
      "hun": {
        "official": "Eritrea",
        "common": "Eritrea"
      },
      "ita": {
        "official": "Stato di Eritrea",
        "common": "Eritrea"
      },
      "jpn": {
        "official": "エリトリア国",
        "common": "エリトリア"
      },
      "kor": {
        "official": "에리트레아국",
        "common": "에리트레아"
      },
      "nld": {
        "official": "Staat Eritrea",
        "common": "Eritrea"
      },
      "per": {
        "official": "جمهوری اریتره",
        "common": "اریتره"
      },
      "pol": {
        "official": "Państwo Erytrea",
        "common": "Erytrea"
      },
      "por": {
        "official": "Estado da Eritreia",
        "common": "Eritreia"
      },
      "rus": {
        "official": "Государство Эритрея",
        "common": "Эритрея"
      },
      "slk": {
        "official": "Eritrejský štát",
        "common": "Eritrea"
      },
      "spa": {
        "official": "Estado de Eritrea",
        "common": "Eritrea"
      },
      "swe": {
        "official": "Staten Eritrea",
        "common": "Eritrea"
      },
      "tur": {
        "official": "Eritre Devleti",
        "common": "Eritre"
      },
      "urd": {
        "official": "ریاستِ ارتریا",
        "common": "ارتریا"
      },
      "zho": {
        "official": "厄立特里亚",
        "common": "厄立特里亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Eritrean",
        "m": "Eritrean"
      },
      "fra": {
        "f": "Érythréenne",
        "m": "Érythréen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ml.png",
      "svg": "https://flagcdn.com/ml.svg"
    },
    "cca2": "ML",
    "capital": [
      "Bamako"
    ],
    "altSpellings": [
      "ML",
      "Republic of Mali",
      "République du Mali"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مالي",
        "common": "مالي"
      },
      "bre": {
        "official": "Republik Mali",
        "common": "Mali"
      },
      "ces": {
        "official": "Republika Mali",
        "common": "Mali"
      },
      "cym": {
        "official": "Republic of Mali",
        "common": "Mali"
      },
      "deu": {
        "official": "Republik Mali",
        "common": "Mali"
      },
      "est": {
        "official": "Mali Vabariik",
        "common": "Mali"
      },
      "fin": {
        "official": "Malin tasavalta",
        "common": "Mali"
      },
      "fra": {
        "official": "République du Mali",
        "common": "Mali"
      },
      "hrv": {
        "official": "Republika Mali",
        "common": "Mali"
      },
      "hun": {
        "official": "Mali Köztársaság",
        "common": "Mali"
      },
      "ita": {
        "official": "Repubblica del Mali",
        "common": "Mali"
      },
      "jpn": {
        "official": "マリ共和国",
        "common": "マリ"
      },
      "kor": {
        "official": "말리 공화국",
        "common": "말리"
      },
      "nld": {
        "official": "Republiek Mali",
        "common": "Mali"
      },
      "per": {
        "official": "جمهوری مالی",
        "common": "مالی"
      },
      "pol": {
        "official": "Republika Mali",
        "common": "Mali"
      },
      "por": {
        "official": "República do Mali",
        "common": "Mali"
      },
      "rus": {
        "official": "Республика Мали",
        "common": "Мали"
      },
      "slk": {
        "official": "Malijská republika",
        "common": "Mali"
      },
      "spa": {
        "official": "República de Malí",
        "common": "Mali"
      },
      "swe": {
        "official": "Republiken Mali",
        "common": "Mali"
      },
      "tur": {
        "official": "Mali Cumhuriyeti",
        "common": "Mali"
      },
      "urd": {
        "official": "جمہوریہ مالی",
        "common": "مالی"
      },
      "zho": {
        "official": "马里共和国",
        "common": "马里"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Malian",
        "m": "Malian"
      },
      "fra": {
        "f": "Malienne",
        "m": "Malien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/so.png",
      "svg": "https://flagcdn.com/so.svg"
    },
    "cca2": "SO",
    "capital": [
      "Mogadishu"
    ],
    "altSpellings": [
      "SO",
      "aṣ-Ṣūmāl",
      "Federal Republic of Somalia",
      "Jamhuuriyadda Federaalka Soomaaliya",
      "Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الصومال الفيدرالية",
        "common": "الصومال"
      },
      "bre": {
        "official": "Republik Kevreadel Somalia",
        "common": "Somalia"
      },
      "ces": {
        "official": "Somálská demokratická republika",
        "common": "Somálsko"
      },
      "cym": {
        "official": "Federal Republic of Somalia",
        "common": "Somalia"
      },
      "deu": {
        "official": "Bundesrepublik Somalia",
        "common": "Somalia"
      },
      "est": {
        "official": "Somaalia Liitvabariik",
        "common": "Somaalia"
      },
      "fin": {
        "official": "Somalian liittotasavalta",
        "common": "Somalia"
      },
      "fra": {
        "official": "République fédérale de Somalie",
        "common": "Somalie"
      },
      "hrv": {
        "official": "Savezna Republika Somaliji",
        "common": "Somalija"
      },
      "hun": {
        "official": "Szomáli Szövetségi Köztársaság",
        "common": "Szomália"
      },
      "ita": {
        "official": "Repubblica federale di Somalia",
        "common": "Somalia"
      },
      "jpn": {
        "official": "ソマリア連邦共和国",
        "common": "ソマリア"
      },
      "kor": {
        "official": " 소말리아 연방 공화국",
        "common": "소말리아"
      },
      "nld": {
        "official": "Federale Republiek Somalië",
        "common": "Somalië"
      },
      "per": {
        "official": "جمهوری فدرال سومالی",
        "common": "سومالی"
      },
      "pol": {
        "official": "Federalna Republika Somalii",
        "common": "Somalia"
      },
      "por": {
        "official": "República Federal da Somália",
        "common": "Somália"
      },
      "rus": {
        "official": "Федеративная Республика Сомали",
        "common": "Сомали"
      },
      "slk": {
        "official": "Somálska federatívna republika",
        "common": "Somálsko"
      },
      "spa": {
        "official": "República Federal de Somalia",
        "common": "Somalia"
      },
      "swe": {
        "official": "Förbundsrepubliken Somalia",
        "common": "Somalia"
      },
      "tur": {
        "official": "Somali Federal Cumhuriyeti",
        "common": "Somali"
      },
      "urd": {
        "official": "وفاقی جمہوریہ صومالیہ",
        "common": "صومالیہ"
      },
      "zho": {
        "official": "索马里共和国",
        "common": "索马里"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Somali",
        "m": "Somali"
      },
      "fra": {
        "f": "Somalienne",
        "m": "Somalien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nf.png",
      "svg": "https://flagcdn.com/nf.svg"
    },
    "cca2": "NF",
    "capital": [
      "Kingston"
    ],
    "altSpellings": [
      "NF",
      "Territory of Norfolk Island",
      "Teratri of Norf'k Ailen"
    ],
    "translations": {
      "ara": {
        "official": "إقليم جزيرة نورفولك",
        "common": "جزيرة نورفولك"
      },
      "bre": {
        "official": "Tiriad Enez Norfolk",
        "common": "Enez Norfolk"
      },
      "ces": {
        "official": "Teritorium ostrova Norfolk",
        "common": "Norfolk"
      },
      "cym": {
        "official": "Territory of Norfolk Island",
        "common": "Norfolk Island"
      },
      "deu": {
        "official": "Gebiet der Norfolkinsel",
        "common": "Norfolkinsel"
      },
      "est": {
        "official": "Norfolki saare ala",
        "common": "Norfolk"
      },
      "fin": {
        "official": "Norfolkinsaaren territorio",
        "common": "Norfolkinsaari"
      },
      "fra": {
        "official": "Territoire de l'île Norfolk",
        "common": "Île Norfolk"
      },
      "hrv": {
        "official": "Teritorij Norfolk Island",
        "common": "Otok Norfolk"
      },
      "hun": {
        "official": "Norfolk-sziget",
        "common": "Norfolk-sziget"
      },
      "ita": {
        "official": "Territorio di Norfolk Island",
        "common": "Isola Norfolk"
      },
      "jpn": {
        "official": "ノーフォーク島の領土",
        "common": "ノーフォーク島"
      },
      "kor": {
        "official": "노퍽 섬",
        "common": "노퍽 섬"
      },
      "nld": {
        "official": "Grondgebied van Norfolk Island",
        "common": "Norfolkeiland"
      },
      "per": {
        "official": "قلمرو جزایر نورفک",
        "common": "جزیره نورفک"
      },
      "pol": {
        "official": "Terytorium Wyspy Norfolk",
        "common": "Wyspa Norfolk"
      },
      "por": {
        "official": "Território da Ilha Norfolk",
        "common": "Ilha Norfolk"
      },
      "rus": {
        "official": "Территория острова Норфолк",
        "common": "Норфолк"
      },
      "slk": {
        "official": "Teritórium ostrova Norfolk",
        "common": "Norfolk"
      },
      "spa": {
        "official": "Territorio de la Isla Norfolk",
        "common": "Isla de Norfolk"
      },
      "swe": {
        "official": "Norfolkön",
        "common": "Norfolkön"
      },
      "tur": {
        "official": "Norfolk Adası",
        "common": "Norfolk Adası"
      },
      "urd": {
        "official": "جزیرہ نورفک خطہ",
        "common": "جزیرہ نورفک"
      },
      "zho": {
        "official": "诺福克岛",
        "common": "诺福克岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Norfolk Islander",
        "m": "Norfolk Islander"
      },
      "fra": {
        "f": "Norfolkaise",
        "m": "Norfolkais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/km.png",
      "svg": "https://flagcdn.com/km.svg"
    },
    "cca2": "KM",
    "capital": [
      "Moroni"
    ],
    "altSpellings": [
      "KM",
      "Union of the Comoros",
      "Union des Comores",
      "Udzima wa Komori",
      "al-Ittiḥād al-Qumurī"
    ],
    "translations": {
      "ara": {
        "official": "الإتحاد القمري",
        "common": "جزر القمر"
      },
      "bre": {
        "official": "Unaniezh Komorez",
        "common": "Komorez"
      },
      "ces": {
        "official": "Komorský svaz",
        "common": "Komory"
      },
      "cym": {
        "official": "Undeb y Comoros",
        "common": "Y Comoros"
      },
      "deu": {
        "official": "Union der Komoren",
        "common": "Komoren"
      },
      "est": {
        "official": "Komoori Liit",
        "common": "Komoorid"
      },
      "fin": {
        "official": "Komorien liitto",
        "common": "Komorit"
      },
      "fra": {
        "official": "Union des Comores",
        "common": "Comores"
      },
      "hrv": {
        "official": "Savez Komori",
        "common": "Komori"
      },
      "hun": {
        "official": "Comore-szigeteki Unió",
        "common": "Comore-szigetek"
      },
      "ita": {
        "official": "Unione delle Comore",
        "common": "Comore"
      },
      "jpn": {
        "official": "コモロ連合",
        "common": "コモロ"
      },
      "kor": {
        "official": "코모로 연방",
        "common": "코모로"
      },
      "nld": {
        "official": "Unie van de Comoren",
        "common": "Comoren"
      },
      "per": {
        "official": "مجمع‌الجزایر قمر",
        "common": "اتحاد قُمُر"
      },
      "pol": {
        "official": "Związek Komorów",
        "common": "Komory"
      },
      "por": {
        "official": "União das Comores",
        "common": "Comores"
      },
      "rus": {
        "official": "Союз Коморских Островов",
        "common": "Коморы"
      },
      "slk": {
        "official": "Komorská únia",
        "common": "Komory"
      },
      "spa": {
        "official": "Unión de las Comoras",
        "common": "Comoras"
      },
      "swe": {
        "official": "Unionen Komorerna",
        "common": "Komorerna"
      },
      "tur": {
        "official": "Komorlar Birliği",
        "common": "Komorlar"
      },
      "urd": {
        "official": "اتحاد القمری",
        "common": "القمری"
      },
      "zho": {
        "official": "科摩罗联盟",
        "common": "科摩罗"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Comoran",
        "m": "Comoran"
      },
      "fra": {
        "f": "Comorienne",
        "m": "Comorien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ni.png",
      "svg": "https://flagcdn.com/ni.svg"
    },
    "cca2": "NI",
    "capital": [
      "Managua"
    ],
    "altSpellings": [
      "NI",
      "Republic of Nicaragua",
      "República de Nicaragua"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية نيكاراغوا",
        "common": "نيكاراغوا"
      },
      "bre": {
        "official": "Republik Nicaragua",
        "common": "Nicaragua"
      },
      "ces": {
        "official": "Republika Nikaragua",
        "common": "Nikaragua"
      },
      "cym": {
        "official": "Republic of Nicaragua",
        "common": "Nicaragua"
      },
      "deu": {
        "official": "Republik Nicaragua",
        "common": "Nicaragua"
      },
      "est": {
        "official": "Nicaragua Vabariik",
        "common": "Nicaragua"
      },
      "fin": {
        "official": "Nicaraguan tasavalta",
        "common": "Nicaragua"
      },
      "fra": {
        "official": "République du Nicaragua",
        "common": "Nicaragua"
      },
      "hrv": {
        "official": "Republika Nikaragva",
        "common": "Nikaragva"
      },
      "hun": {
        "official": "Nicaraguai Köztársaság",
        "common": "Nicaragua"
      },
      "ita": {
        "official": "Repubblica del Nicaragua",
        "common": "Nicaragua"
      },
      "jpn": {
        "official": "ニカラグア共和国",
        "common": "ニカラグア"
      },
      "kor": {
        "official": "니카라과 공화국",
        "common": "니카라과"
      },
      "nld": {
        "official": "Republiek Nicaragua",
        "common": "Nicaragua"
      },
      "per": {
        "official": "جمهوری نیکاراگوئه",
        "common": "نیکاراگوئه"
      },
      "pol": {
        "official": "Republika Nikaragui",
        "common": "Nikaragua"
      },
      "por": {
        "official": "República da Nicarágua",
        "common": "Nicarágua"
      },
      "rus": {
        "official": "Республика Никарагуа",
        "common": "Никарагуа"
      },
      "slk": {
        "official": "Nikaragujská republika",
        "common": "Nikaragua"
      },
      "spa": {
        "official": "República de Nicaragua",
        "common": "Nicaragua"
      },
      "swe": {
        "official": "Republiken Nicaragua",
        "common": "Nicaragua"
      },
      "tur": {
        "official": "Nikaragua Cumhuriyeti",
        "common": "Nikaragua"
      },
      "urd": {
        "official": "جمہوریہ نکاراگوا",
        "common": "نکاراگوا"
      },
      "zho": {
        "official": "尼加拉瓜共和国",
        "common": "尼加拉瓜"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Nicaraguan",
        "m": "Nicaraguan"
      },
      "fra": {
        "f": "Nicaraguayenne",
        "m": "Nicaraguayen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ru.png",
      "svg": "https://flagcdn.com/ru.svg"
    },
    "cca2": "RU",
    "capital": [
      "Moscow"
    ],
    "altSpellings": [
      "RU",
      "Russian Federation",
      "Российская Федерация"
    ],
    "translations": {
      "ara": {
        "official": "روسيا الاتحادية",
        "common": "روسيا"
      },
      "bre": {
        "official": "Kevread Rusia",
        "common": "Rusia"
      },
      "ces": {
        "official": "Ruská federace",
        "common": "Rusko"
      },
      "cym": {
        "official": "Russian Federation",
        "common": "Russia"
      },
      "deu": {
        "official": "Russische Föderation",
        "common": "Russland"
      },
      "est": {
        "official": "Venemaa Föderatsioon",
        "common": "Venemaa"
      },
      "fin": {
        "official": "Venäjän federaatio",
        "common": "Venäjä"
      },
      "fra": {
        "official": "Fédération de Russie",
        "common": "Russie"
      },
      "hrv": {
        "official": "Ruska Federacija",
        "common": "Rusija"
      },
      "hun": {
        "official": "Oroszországi Föderáció",
        "common": "Oroszország"
      },
      "ita": {
        "official": "Federazione russa",
        "common": "Russia"
      },
      "jpn": {
        "official": "ロシア連邦",
        "common": "ロシア連邦"
      },
      "kor": {
        "official": "러시아 연방",
        "common": "러시아"
      },
      "nld": {
        "official": "Russische Federatie",
        "common": "Rusland"
      },
      "per": {
        "official": "فدراسیون روسیه",
        "common": "روسیه"
      },
      "pol": {
        "official": "Federacja Rosyjska",
        "common": "Rosja"
      },
      "por": {
        "official": "Federação Russa",
        "common": "Rússia"
      },
      "rus": {
        "official": "Российская Федерация",
        "common": "Россия"
      },
      "slk": {
        "official": "Ruská federácia",
        "common": "Rusko"
      },
      "spa": {
        "official": "Federación de Rusia",
        "common": "Rusia"
      },
      "swe": {
        "official": "Ryska federationen",
        "common": "Ryssland"
      },
      "tur": {
        "official": "Rusya Federasyonu",
        "common": "Rusya"
      },
      "urd": {
        "official": "روسی وفاق",
        "common": "روس"
      },
      "zho": {
        "official": "俄罗斯联邦",
        "common": "俄罗斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Russian",
        "m": "Russian"
      },
      "fra": {
        "f": "Russe",
        "m": "Russe"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ug.png",
      "svg": "https://flagcdn.com/ug.svg"
    },
    "cca2": "UG",
    "capital": [
      "Kampala"
    ],
    "altSpellings": [
      "UG",
      "Republic of Uganda",
      "Jamhuri ya Uganda"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أوغندا",
        "common": "أوغندا"
      },
      "bre": {
        "official": "Republik Ouganda",
        "common": "Ouganda"
      },
      "ces": {
        "official": "Ugandská republika",
        "common": "Uganda"
      },
      "cym": {
        "official": "Republic of Uganda",
        "common": "Uganda"
      },
      "deu": {
        "official": "Republik Uganda",
        "common": "Uganda"
      },
      "est": {
        "official": "Uganda Vabariik",
        "common": "Uganda"
      },
      "fin": {
        "official": "Ugandan tasavalta",
        "common": "Uganda"
      },
      "fra": {
        "official": "République de l'Ouganda",
        "common": "Ouganda"
      },
      "hrv": {
        "official": "Republika Uganda",
        "common": "Uganda"
      },
      "hun": {
        "official": "Ugandai Köztársaság",
        "common": "Uganda"
      },
      "ita": {
        "official": "Repubblica di Uganda",
        "common": "Uganda"
      },
      "jpn": {
        "official": "ウガンダ共和国",
        "common": "ウガンダ"
      },
      "kor": {
        "official": "우간다 공화국",
        "common": "우간다"
      },
      "nld": {
        "official": "Republiek Uganda",
        "common": "Oeganda"
      },
      "per": {
        "official": "جمهوری اوگاندا",
        "common": "اوگاندا"
      },
      "pol": {
        "official": "Republika Ugandy",
        "common": "Uganda"
      },
      "por": {
        "official": "República do Uganda",
        "common": "Uganda"
      },
      "rus": {
        "official": "Республика Уганда",
        "common": "Уганда"
      },
      "slk": {
        "official": "Ugandská republika",
        "common": "Uganda"
      },
      "spa": {
        "official": "República de Uganda",
        "common": "Uganda"
      },
      "swe": {
        "official": "Republiken Uganda",
        "common": "Uganda"
      },
      "tur": {
        "official": "Uganda Cumhuriyeti",
        "common": "Uganda"
      },
      "urd": {
        "official": "جمہوریہ یوگنڈا",
        "common": "یوگنڈا"
      },
      "zho": {
        "official": "乌干达共和国",
        "common": "乌干达"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ugandan",
        "m": "Ugandan"
      },
      "fra": {
        "f": "Ougandaise",
        "m": "Ougandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nr.png",
      "svg": "https://flagcdn.com/nr.svg"
    },
    "cca2": "NR",
    "capital": [
      "Yaren"
    ],
    "altSpellings": [
      "NR",
      "Naoero",
      "Pleasant Island",
      "Republic of Nauru",
      "Ripublik Naoero"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ناورو",
        "common": "ناورو"
      },
      "bre": {
        "official": "Republik Nauru",
        "common": "Nauru"
      },
      "ces": {
        "official": "Republika Nauru",
        "common": "Nauru"
      },
      "cym": {
        "official": "Republic of Nauru",
        "common": "Nauru"
      },
      "deu": {
        "official": "Republik Nauru",
        "common": "Nauru"
      },
      "est": {
        "official": "Nauru Vabariik",
        "common": "Nauru"
      },
      "fin": {
        "official": "Naurun tasavalta",
        "common": "Nauru"
      },
      "fra": {
        "official": "République de Nauru",
        "common": "Nauru"
      },
      "hrv": {
        "official": "Republika Nauru",
        "common": "Nauru"
      },
      "hun": {
        "official": "Naurui Köztársaság",
        "common": "Nauru"
      },
      "ita": {
        "official": "Repubblica di Nauru",
        "common": "Nauru"
      },
      "jpn": {
        "official": "ナウル共和国",
        "common": "ナウル"
      },
      "kor": {
        "official": "나우루 공화국",
        "common": "나우루"
      },
      "nld": {
        "official": "Republiek Nauru",
        "common": "Nauru"
      },
      "per": {
        "official": "جمهوری نائورو",
        "common": "نائورو"
      },
      "pol": {
        "official": "Republika Nauru",
        "common": "Nauru"
      },
      "por": {
        "official": "República de Nauru",
        "common": "Nauru"
      },
      "rus": {
        "official": "Республика Науру",
        "common": "Науру"
      },
      "slk": {
        "official": "Naurská republika",
        "common": "Nauru"
      },
      "spa": {
        "official": "República de Nauru",
        "common": "Nauru"
      },
      "swe": {
        "official": "Republiken Nauru",
        "common": "Nauru"
      },
      "tur": {
        "official": "Nauru Cumhuriyeti",
        "common": "Nauru"
      },
      "urd": {
        "official": "جمہوریہ ناورو",
        "common": "ناورو"
      },
      "zho": {
        "official": "瑙鲁共和国",
        "common": "瑙鲁"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Nauruan",
        "m": "Nauruan"
      },
      "fra": {
        "f": "Nauruane",
        "m": "Nauruan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ch.png",
      "svg": "https://flagcdn.com/ch.svg"
    },
    "cca2": "CH",
    "capital": [
      "Bern"
    ],
    "altSpellings": [
      "CH",
      "Swiss Confederation",
      "Schweiz",
      "Suisse",
      "Svizzera",
      "Svizra"
    ],
    "translations": {
      "ara": {
        "official": "الاتحاد السويسري",
        "common": "سويسرا"
      },
      "bre": {
        "official": "Kengevredad Suis",
        "common": "Suis"
      },
      "ces": {
        "official": "Švýcarská konfederace",
        "common": "Švýcarsko"
      },
      "cym": {
        "official": "Swiss Confederation",
        "common": "Switzerland"
      },
      "deu": {
        "official": "Schweizerische Eidgenossenschaft",
        "common": "Schweiz"
      },
      "est": {
        "official": "Šveitsi Konföderatsioon",
        "common": "Šveits"
      },
      "fin": {
        "official": "Sveitsin valaliitto",
        "common": "Sveitsi"
      },
      "fra": {
        "official": "Confédération suisse",
        "common": "Suisse"
      },
      "hrv": {
        "official": "švicarska Konfederacija",
        "common": "Švicarska"
      },
      "hun": {
        "official": "Svájc",
        "common": "Svájc"
      },
      "ita": {
        "official": "Confederazione svizzera",
        "common": "Svizzera"
      },
      "jpn": {
        "official": "スイス連邦",
        "common": "スイス"
      },
      "kor": {
        "official": "스위스 연방",
        "common": "스위스"
      },
      "nld": {
        "official": "Zwitserse Confederatie",
        "common": "Zwitserland"
      },
      "per": {
        "official": "کنفدراسیون سوئیس",
        "common": "سوئیس"
      },
      "pol": {
        "official": "Konfederacja Szwajcarska",
        "common": "Szwajcaria"
      },
      "por": {
        "official": "Confederação Suíça",
        "common": "Suíça"
      },
      "rus": {
        "official": "Швейцарская Конфедерация",
        "common": "Швейцария"
      },
      "slk": {
        "official": "Švajčiarska konfederácia",
        "common": "Švajčiarsko"
      },
      "spa": {
        "official": "Confederación Suiza",
        "common": "Suiza"
      },
      "swe": {
        "official": "Schweiziska edsförbundet",
        "common": "Schweiz"
      },
      "tur": {
        "official": "İsviçre Konfederasyonu",
        "common": "İsviçre"
      },
      "urd": {
        "official": "سوئیس  متحدہ",
        "common": "سویٹذرلینڈ"
      },
      "zho": {
        "official": "瑞士联邦",
        "common": "瑞士"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Swiss",
        "m": "Swiss"
      },
      "fra": {
        "f": "Suisse",
        "m": "Suisse"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pt.png",
      "svg": "https://flagcdn.com/pt.svg"
    },
    "cca2": "PT",
    "capital": [
      "Lisbon"
    ],
    "altSpellings": [
      "PT",
      "Portuguesa",
      "Portuguese Republic",
      "República Portuguesa"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية البرتغالية",
        "common": "البرتغال"
      },
      "bre": {
        "official": "Republik Portugalat",
        "common": "Portugal"
      },
      "ces": {
        "official": "Portugalská republika",
        "common": "Portugalsko"
      },
      "cym": {
        "official": "Portuguese Republic",
        "common": "Portugal"
      },
      "deu": {
        "official": "Portugiesische Republik",
        "common": "Portugal"
      },
      "est": {
        "official": "Portugali Vabariik",
        "common": "Portugal"
      },
      "fin": {
        "official": "Portugalin tasavalta",
        "common": "Portugali"
      },
      "fra": {
        "official": "République portugaise",
        "common": "Portugal"
      },
      "hrv": {
        "official": "Portugalska Republika",
        "common": "Portugal"
      },
      "hun": {
        "official": "Portugál Köztársaság",
        "common": "Portugália"
      },
      "ita": {
        "official": "Repubblica portoghese",
        "common": "Portogallo"
      },
      "jpn": {
        "official": "ポルトガル共和国",
        "common": "ポルトガル"
      },
      "kor": {
        "official": "포르투갈 공화국",
        "common": "포르투갈"
      },
      "nld": {
        "official": "Portugese Republiek",
        "common": "Portugal"
      },
      "per": {
        "official": "جمهوری پرتغال",
        "common": "پرتغال"
      },
      "pol": {
        "official": "Republika Portugalska",
        "common": "Portugalia"
      },
      "por": {
        "official": "República português",
        "common": "Portugal"
      },
      "rus": {
        "official": "Португальская Республика",
        "common": "Португалия"
      },
      "slk": {
        "official": "Portugalská republika",
        "common": "Portugalsko"
      },
      "spa": {
        "official": "República Portuguesa",
        "common": "Portugal"
      },
      "swe": {
        "official": "Republiken Portugal",
        "common": "Portugal"
      },
      "tur": {
        "official": "Portekiz Cumhuriyeti",
        "common": "Portekiz"
      },
      "urd": {
        "official": "جمہوریہ پرتگال",
        "common": "پرتگال"
      },
      "zho": {
        "official": "葡萄牙共和国",
        "common": "葡萄牙"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Portuguese",
        "m": "Portuguese"
      },
      "fra": {
        "f": "Portugaise",
        "m": "Portugais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tj.png",
      "svg": "https://flagcdn.com/tj.svg"
    },
    "cca2": "TJ",
    "capital": [
      "Dushanbe"
    ],
    "altSpellings": [
      "TJ",
      "Toçikiston",
      "Republic of Tajikistan",
      "Ҷумҳурии Тоҷикистон",
      "Çumhuriyi Toçikiston"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية طاجيكستان",
        "common": "طاجيكستان"
      },
      "bre": {
        "official": "Republik Tadjikistan",
        "common": "Tadjikistan"
      },
      "ces": {
        "official": "Republika Tádžikistán",
        "common": "Tádžikistán"
      },
      "cym": {
        "official": "Republic of Tajikistan",
        "common": "Tajikistan"
      },
      "deu": {
        "official": "Republik Tadschikistan",
        "common": "Tadschikistan"
      },
      "est": {
        "official": "Tadžikistani Vabariik",
        "common": "Tadžikistan"
      },
      "fin": {
        "official": "Tadžikistanin tasavalta",
        "common": "Tadžikistan"
      },
      "fra": {
        "official": "République du Tadjikistan",
        "common": "Tadjikistan"
      },
      "hrv": {
        "official": "Republika Tadžikistan",
        "common": "Tađikistan"
      },
      "hun": {
        "official": "Tádzsik Köztársaság",
        "common": "Tádzsikisztán"
      },
      "ita": {
        "official": "Repubblica del Tajikistan",
        "common": "Tagikistan"
      },
      "jpn": {
        "official": "タジキスタン共和国",
        "common": "タジキスタン"
      },
      "kor": {
        "official": "타지키스탄 공화국",
        "common": "타지키스탄"
      },
      "nld": {
        "official": "Tadzjikistan",
        "common": "Tadzjikistan"
      },
      "per": {
        "official": "جمهوری تاجیکستان",
        "common": "تاجیکِستان"
      },
      "pol": {
        "official": "Republika Tadżykistanu",
        "common": "Tadżykistan"
      },
      "por": {
        "official": "República do Tajiquistão",
        "common": "Tajiquistão"
      },
      "rus": {
        "official": "Республика Таджикистан",
        "common": "Таджикистан"
      },
      "slk": {
        "official": "Taǆická republika",
        "common": "Taǆikistan"
      },
      "spa": {
        "official": "República de Tayikistán",
        "common": "Tayikistán"
      },
      "swe": {
        "official": "Republiken Tadzjikistan",
        "common": "Tadzjikistan"
      },
      "tur": {
        "official": "Tacikistan Cumhuriyeti",
        "common": "Tacikistan"
      },
      "urd": {
        "official": "جمہوریہ تاجکستان",
        "common": "تاجکستان"
      },
      "zho": {
        "official": "塔吉克斯坦共和国",
        "common": "塔吉克斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tadzhik",
        "m": "Tadzhik"
      },
      "fra": {
        "f": "Tadjike",
        "m": "Tadjike"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ss.png",
      "svg": "https://flagcdn.com/ss.svg"
    },
    "cca2": "SS",
    "capital": [
      "Juba"
    ],
    "altSpellings": [
      "SS"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية جنوب السودان",
        "common": "جنوب السودان"
      },
      "bre": {
        "official": "Republik Soudan ar Su",
        "common": "Soudan ar Su"
      },
      "ces": {
        "official": "Jihosúdánská republika",
        "common": "Jižní Súdán"
      },
      "cym": {
        "official": "Republic of South Sudan",
        "common": "South Sudan"
      },
      "deu": {
        "official": "Republik Südsudan",
        "common": "Südsudan"
      },
      "est": {
        "official": "Lõuna-Sudaani Vabariik",
        "common": "Lõuna-Sudaan"
      },
      "fin": {
        "official": "Etelä-Sudanin tasavalta",
        "common": "Etelä-Sudan"
      },
      "fra": {
        "official": "République du Soudan du Sud",
        "common": "Soudan du Sud"
      },
      "hrv": {
        "official": "Republika Južni Sudan",
        "common": "Južni Sudan"
      },
      "hun": {
        "official": "Dél-szudáni Köztársaság",
        "common": "Dél-Szudán"
      },
      "ita": {
        "official": "Repubblica del Sudan del Sud",
        "common": "Sudan del sud"
      },
      "jpn": {
        "official": "南スーダン共和国",
        "common": "南スーダン"
      },
      "kor": {
        "official": "남수단 공화국",
        "common": "남수단"
      },
      "nld": {
        "official": "Republiek Zuid-Soedan",
        "common": "Zuid-Soedan"
      },
      "per": {
        "official": "جمهوری سودان جنوبی",
        "common": "سودان جنوبی"
      },
      "pol": {
        "official": "Republika Sudanu",
        "common": "Sudan"
      },
      "por": {
        "official": "República do Sudão do Sul",
        "common": "Sudão do Sul"
      },
      "rus": {
        "official": "Республика Южный Судан",
        "common": "Южный Судан"
      },
      "slk": {
        "official": "Juhosudánska republika",
        "common": "Južný Sudán"
      },
      "spa": {
        "official": "República de Sudán del Sur",
        "common": "Sudán del Sur"
      },
      "swe": {
        "official": "Republiken Sydsudan",
        "common": "Sydsudan"
      },
      "tur": {
        "official": "Güney Sudan Cumhuriyeti",
        "common": "Güney Sudan"
      },
      "urd": {
        "official": "جمہوریہ جنوبی سوڈان",
        "common": "جنوبی سوڈان"
      },
      "zho": {
        "official": "南苏丹共和国",
        "common": "南苏丹"
      }
    },
    "demonyms": {
      "eng": {
        "f": "South Sudanese",
        "m": "South Sudanese"
      },
      "fra": {
        "f": "Sud-Soudanaise",
        "m": "Sud-Soudanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bv.png",
      "svg": "https://flagcdn.com/bv.svg"
    },
    "cca2": "BV",
    "capital": [],
    "altSpellings": [
      "BV",
      "Bouvetøya",
      "Bouvet-øya"
    ],
    "translations": {
      "ara": {
        "official": "جزر بوفيه",
        "common": "جزر بوفيه"
      },
      "bre": {
        "official": "Enez Bouvet",
        "common": "Enez Bouvet"
      },
      "ces": {
        "official": "Bouvetův ostrov",
        "common": "Bouvetův ostrov"
      },
      "cym": {
        "official": "Bouvet Island",
        "common": "Bouvet Island"
      },
      "deu": {
        "official": "Bouvetinsel",
        "common": "Bouvetinsel"
      },
      "est": {
        "official": "Bouvet’ saar",
        "common": "Bouvet’ saar"
      },
      "fin": {
        "official": "Bouvet'nsaari",
        "common": "Bouvet'nsaari"
      },
      "fra": {
        "official": "Île Bouvet",
        "common": "Île Bouvet"
      },
      "hrv": {
        "official": "Bouvet Island",
        "common": "Otok Bouvet"
      },
      "hun": {
        "official": "Bouvet-sziget",
        "common": "Bouvet-sziget"
      },
      "ita": {
        "official": "Isola Bouvet",
        "common": "Isola Bouvet"
      },
      "jpn": {
        "official": "ブーヴェ島",
        "common": "ブーベ島"
      },
      "kor": {
        "official": "부베 섬",
        "common": "부베 섬"
      },
      "nld": {
        "official": "Bouvet Island",
        "common": "Bouveteiland"
      },
      "per": {
        "official": "جزیرهٔ بووه",
        "common": "جزیرهٔ بووه"
      },
      "pol": {
        "official": "Wyspa Bouveta",
        "common": "Wyspa Bouveta"
      },
      "por": {
        "official": "Ilha Bouvet",
        "common": "Ilha Bouvet"
      },
      "rus": {
        "official": "Остров Буве",
        "common": "Остров Буве"
      },
      "slk": {
        "official": "Bouvetov ostrov",
        "common": "Bouvetov ostrov"
      },
      "spa": {
        "official": "Isla Bouvet",
        "common": "Isla Bouvet"
      },
      "swe": {
        "official": "Bouvetön",
        "common": "Bouvetön"
      },
      "tur": {
        "official": "Bouvet Adası",
        "common": "Bouvet Adası"
      },
      "urd": {
        "official": "جزیرہ بووہ",
        "common": "جزیرہ بووہ"
      },
      "zho": {
        "official": "布维岛",
        "common": "布维岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "",
        "m": ""
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fm.png",
      "svg": "https://flagcdn.com/fm.svg"
    },
    "cca2": "FM",
    "capital": [
      "Palikir"
    ],
    "altSpellings": [
      "FM",
      "Federated States of Micronesia",
      "Micronesia, Federated States of"
    ],
    "translations": {
      "ara": {
        "official": "ولايات ميكرونيسيا المتحدة",
        "common": "ميكرونيسيا"
      },
      "bre": {
        "official": "Stadoù Kevreet Mikronezia",
        "common": "Mikronezia"
      },
      "ces": {
        "official": "Federativní státy Mikronésie",
        "common": "Mikronésie"
      },
      "cym": {
        "official": "Federated States of Micronesia",
        "common": "Micronesia"
      },
      "deu": {
        "official": "Föderierte Staaten von Mikronesien",
        "common": "Mikronesien"
      },
      "est": {
        "official": "Mikroneesia Liiduriigid",
        "common": "Mikroneesia"
      },
      "fin": {
        "official": "Mikronesian liittovaltio",
        "common": "Mikronesia"
      },
      "fra": {
        "official": "États fédérés de Micronésie",
        "common": "Micronésie"
      },
      "hrv": {
        "official": "Savezne Države Mikronezije",
        "common": "Mikronezija"
      },
      "hun": {
        "official": "Mikronéziai Szövetségi Államok",
        "common": "Mikronéziai Szövetségi Államok"
      },
      "ita": {
        "official": "Stati federati di Micronesia",
        "common": "Micronesia"
      },
      "jpn": {
        "official": "ミクロネシア連邦",
        "common": "ミクロネシア連邦"
      },
      "kor": {
        "official": "미크로네시아 연방",
        "common": "미크로네시아"
      },
      "nld": {
        "official": "Federale Staten van Micronesia",
        "common": "Micronesië"
      },
      "per": {
        "official": "ایالات فدرال میکرونزی",
        "common": "میکرونزی"
      },
      "pol": {
        "official": "Sfederowane Stany Mikronezji",
        "common": "Mikronezja"
      },
      "por": {
        "official": "Estados Federados da Micronésia",
        "common": "Micronésia"
      },
      "rus": {
        "official": "Федеративные Штаты Микронезии",
        "common": "Федеративные Штаты Микронезии"
      },
      "slk": {
        "official": "Mikronézske federatívne štáty",
        "common": "Mikronézia"
      },
      "spa": {
        "official": "Estados Federados de Micronesia",
        "common": "Micronesia"
      },
      "swe": {
        "official": "Mikronesiska federationen",
        "common": "Mikronesiska federationen"
      },
      "tur": {
        "official": "Mikronezya Federal Devletleri",
        "common": "Mikronezya"
      },
      "urd": {
        "official": "ریاستہائے وفاقیہ مائکرونیشیا",
        "common": "مائکرونیشیا"
      },
      "zho": {
        "official": "密克罗尼西亚联邦",
        "common": "密克罗尼西亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Micronesian",
        "m": "Micronesian"
      },
      "fra": {
        "f": "Micronésienne",
        "m": "Micronésien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/as.png",
      "svg": "https://flagcdn.com/as.svg"
    },
    "cca2": "AS",
    "capital": [
      "Pago Pago"
    ],
    "altSpellings": [
      "AS",
      "Amerika Sāmoa",
      "Amelika Sāmoa",
      "Sāmoa Amelika"
    ],
    "translations": {
      "ara": {
        "official": "ساموا الأمريكية",
        "common": "ساموا الأمريكية"
      },
      "bre": {
        "official": "Samoa Amerikan",
        "common": "Samoa Amerikan"
      },
      "ces": {
        "official": "Americká Samoa",
        "common": "Americká Samoa"
      },
      "cym": {
        "official": "American Samoa",
        "common": "American Samoa"
      },
      "deu": {
        "official": "Amerikanisch-Samoa",
        "common": "Amerikanisch-Samoa"
      },
      "est": {
        "official": "Ameerika Samoa",
        "common": "Ameerika Samoa"
      },
      "fin": {
        "official": "Amerikan Samoa",
        "common": "Amerikan Samoa"
      },
      "fra": {
        "official": "Samoa américaines",
        "common": "Samoa américaines"
      },
      "hrv": {
        "official": "američka Samoa",
        "common": "Američka Samoa"
      },
      "hun": {
        "official": "Szamoa",
        "common": "Szamoa"
      },
      "ita": {
        "official": "Samoa americane",
        "common": "Samoa Americane"
      },
      "jpn": {
        "official": "米サモア",
        "common": "アメリカ領サモア"
      },
      "kor": {
        "official": "아메리칸사모아",
        "common": "아메리칸사모아"
      },
      "nld": {
        "official": "Amerikaans Samoa",
        "common": "Amerikaans Samoa"
      },
      "per": {
        "official": "ساموآی آمریکا",
        "common": "ساموآی آمریکا"
      },
      "pol": {
        "official": "Samoa Amerykańskie",
        "common": "Samoa Amerykańskie"
      },
      "por": {
        "official": "Samoa americana",
        "common": "Samoa Americana"
      },
      "rus": {
        "official": "американское Самоа",
        "common": "Американское Самоа"
      },
      "slk": {
        "official": "Americká Samoa",
        "common": "Americká Samoa"
      },
      "spa": {
        "official": "Samoa Americana",
        "common": "Samoa Americana"
      },
      "swe": {
        "official": "Amerikanska Samoa",
        "common": "Amerikanska Samoa"
      },
      "tur": {
        "official": "Amerikan Samoası",
        "common": "Amerikan Samoası"
      },
      "urd": {
        "official": "امریکی سمووا",
        "common": "امریکی سمووا"
      },
      "zho": {
        "official": "美属萨摩亚",
        "common": "美属萨摩亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "American Samoan",
        "m": "American Samoan"
      },
      "fra": {
        "f": "Samoane",
        "m": "Samoan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cz.png",
      "svg": "https://flagcdn.com/cz.svg"
    },
    "cca2": "CZ",
    "capital": [
      "Prague"
    ],
    "altSpellings": [
      "CZ",
      "Česká republika",
      "Česko"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية التشيك",
        "common": "التشيك"
      },
      "bre": {
        "official": "Republik Tchek",
        "common": "Tchekia"
      },
      "ces": {
        "official": "Česká republika",
        "common": "Česko"
      },
      "cym": {
        "official": "Y Weriniaeth Tsiec",
        "common": "Y Weriniaeth Tsiec"
      },
      "deu": {
        "official": "Tschechische Republik",
        "common": "Tschechien"
      },
      "est": {
        "official": "Tšehhi Vabariik",
        "common": "Tšehhi"
      },
      "fin": {
        "official": "Tšekin tasavalta",
        "common": "Tšekki"
      },
      "fra": {
        "official": "République tchèque",
        "common": "Tchéquie"
      },
      "hrv": {
        "official": "Češka",
        "common": "Češka"
      },
      "hun": {
        "official": "Cseh Köztársaság",
        "common": "Csehország"
      },
      "ita": {
        "official": "Repubblica Ceca",
        "common": "Cechia"
      },
      "jpn": {
        "official": "チェコ共和国",
        "common": "チェコ"
      },
      "kor": {
        "official": "체코",
        "common": "체코"
      },
      "nld": {
        "official": "Tsjechische Republiek",
        "common": "Tsjechië"
      },
      "per": {
        "official": "جمهوری چک",
        "common": "جمهوری چک"
      },
      "pol": {
        "official": "Republika Czeska",
        "common": "Czechy"
      },
      "por": {
        "official": "República Checa",
        "common": "Chéquia"
      },
      "rus": {
        "official": "Чешская Республика",
        "common": "Чехия"
      },
      "slk": {
        "official": "Česká republika",
        "common": "Česko"
      },
      "spa": {
        "official": "República Checa",
        "common": "Chequia"
      },
      "swe": {
        "official": "Republiken Tjeckien",
        "common": "Tjeckien"
      },
      "tur": {
        "official": "Çek Cumhuriyeti",
        "common": "Çekya"
      },
      "urd": {
        "official": "چيک جمہوريہ",
        "common": "چيک"
      },
      "zho": {
        "official": "捷克共和国",
        "common": "捷克"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Czech",
        "m": "Czech"
      },
      "fra": {
        "f": "Tchèque",
        "m": "Tchèque"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bw.png",
      "svg": "https://flagcdn.com/bw.svg"
    },
    "cca2": "BW",
    "capital": [
      "Gaborone"
    ],
    "altSpellings": [
      "BW",
      "Republic of Botswana",
      "Lefatshe la Botswana"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بوتسوانا",
        "common": "بوتسوانا"
      },
      "bre": {
        "official": "Republik Botswana",
        "common": "Botswana"
      },
      "ces": {
        "official": "Botswanská republika",
        "common": "Botswana"
      },
      "cym": {
        "official": "Republic of Botswana",
        "common": "Botswana"
      },
      "deu": {
        "official": "Republik Botsuana",
        "common": "Botswana"
      },
      "est": {
        "official": "Botswana Vabariik",
        "common": "Botswana"
      },
      "fin": {
        "official": "Botswanan tasavalta",
        "common": "Botswana"
      },
      "fra": {
        "official": "République du Botswana",
        "common": "Botswana"
      },
      "hrv": {
        "official": "Republika Bocvana",
        "common": "Bocvana"
      },
      "hun": {
        "official": "Botswanai Köztársaság",
        "common": "Botswana"
      },
      "ita": {
        "official": "Repubblica del Botswana",
        "common": "Botswana"
      },
      "jpn": {
        "official": "ボツワナ共和国",
        "common": "ボツワナ"
      },
      "kor": {
        "official": "보츠와나 공화국",
        "common": "보츠와나"
      },
      "nld": {
        "official": "Republiek Botswana",
        "common": "Botswana"
      },
      "per": {
        "official": "جمهوری بوتسوانا",
        "common": "بوتسوانا"
      },
      "pol": {
        "official": "Republika Botswany",
        "common": "Botswana"
      },
      "por": {
        "official": "República do Botswana",
        "common": "Botswana"
      },
      "rus": {
        "official": "Республика Ботсвана",
        "common": "Ботсвана"
      },
      "slk": {
        "official": "Botswanská republika",
        "common": "Botswana"
      },
      "spa": {
        "official": "República de Botswana",
        "common": "Botswana"
      },
      "swe": {
        "official": "Republiken Botswana",
        "common": "Botswana"
      },
      "tur": {
        "official": "Botsvana Cumhuriyeti",
        "common": "Botsvana"
      },
      "urd": {
        "official": "جمہوریہ بوٹسوانا",
        "common": "بوٹسوانا"
      },
      "zho": {
        "official": "博茨瓦纳共和国",
        "common": "博茨瓦纳"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Motswana",
        "m": "Motswana"
      },
      "fra": {
        "f": "Botswanaise",
        "m": "Botswanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tn.png",
      "svg": "https://flagcdn.com/tn.svg"
    },
    "cca2": "TN",
    "capital": [
      "Tunis"
    ],
    "altSpellings": [
      "TN",
      "Republic of Tunisia",
      "al-Jumhūriyyah at-Tūnisiyyah"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية التونسية",
        "common": "تونس"
      },
      "bre": {
        "official": "Republik Tunizian",
        "common": "Tunizia"
      },
      "ces": {
        "official": "Tuniská republika",
        "common": "Tunisko"
      },
      "cym": {
        "official": "Tunisian Republic",
        "common": "Tunisia"
      },
      "deu": {
        "official": "Tunesische Republik",
        "common": "Tunesien"
      },
      "est": {
        "official": "Tuneesia Vabariik",
        "common": "Tuneesia"
      },
      "fin": {
        "official": "Tunisian tasavalta",
        "common": "Tunisia"
      },
      "fra": {
        "official": "République tunisienne",
        "common": "Tunisie"
      },
      "hrv": {
        "official": "Tuniski Republika",
        "common": "Tunis"
      },
      "hun": {
        "official": "Tunéziai Köztársaság",
        "common": "Tunézia"
      },
      "ita": {
        "official": "Repubblica tunisina",
        "common": "Tunisia"
      },
      "jpn": {
        "official": "チュニジア共和国",
        "common": "チュニジア"
      },
      "kor": {
        "official": "튀니지 공화국",
        "common": "튀니지"
      },
      "nld": {
        "official": "Republiek Tunesië",
        "common": "Tunesië"
      },
      "per": {
        "official": "جمهوری تونس",
        "common": "تونس"
      },
      "pol": {
        "official": "Republika Tunezyjska",
        "common": "Tunezja"
      },
      "por": {
        "official": "República da Tunísia",
        "common": "Tunísia"
      },
      "rus": {
        "official": "Тунисской Республики",
        "common": "Тунис"
      },
      "slk": {
        "official": "Tuniská republika",
        "common": "Tunisko"
      },
      "spa": {
        "official": "República de Túnez",
        "common": "Túnez"
      },
      "swe": {
        "official": "Republiken Tunisien",
        "common": "Tunisien"
      },
      "tur": {
        "official": "Tunus Cumhuriyeti",
        "common": "Tunus"
      },
      "urd": {
        "official": "جمہوریہ تونس",
        "common": "تونس"
      },
      "zho": {
        "official": "突尼斯共和国",
        "common": "突尼斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tunisian",
        "m": "Tunisian"
      },
      "fra": {
        "f": "Tunisienne",
        "m": "Tunisien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ai.png",
      "svg": "https://flagcdn.com/ai.svg"
    },
    "cca2": "AI",
    "capital": [
      "The Valley"
    ],
    "altSpellings": [
      "AI"
    ],
    "translations": {
      "ara": {
        "official": "أنغويلا",
        "common": "أنغويلا"
      },
      "bre": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "ces": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "cym": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "deu": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "est": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "fin": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "fra": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "hrv": {
        "official": "Anguilla",
        "common": "Angvila"
      },
      "hun": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "ita": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "jpn": {
        "official": "アングィラ",
        "common": "アンギラ"
      },
      "kor": {
        "official": "앵귈라",
        "common": "앵귈라"
      },
      "nld": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "per": {
        "official": "آنگویلا",
        "common": "آنگویلا"
      },
      "pol": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "por": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "rus": {
        "official": "Ангилья",
        "common": "Ангилья"
      },
      "slk": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "spa": {
        "official": "Anguila",
        "common": "Anguilla"
      },
      "swe": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "tur": {
        "official": "Anguilla",
        "common": "Anguilla"
      },
      "urd": {
        "official": "اینگویلا",
        "common": "اینگویلا"
      },
      "zho": {
        "official": "安圭拉",
        "common": "安圭拉"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Anguillian",
        "m": "Anguillian"
      },
      "fra": {
        "f": "Anguillane",
        "m": "Anguillan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gw.png",
      "svg": "https://flagcdn.com/gw.svg"
    },
    "cca2": "GW",
    "capital": [
      "Bissau"
    ],
    "altSpellings": [
      "GW",
      "Republic of Guinea-Bissau",
      "República da Guiné-Bissau"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غينيا بيساو",
        "common": "غينيا بيساو"
      },
      "bre": {
        "official": "Republik Ginea-Bissau",
        "common": "Ginea-Bissau"
      },
      "ces": {
        "official": "Republika Guinea-Bissau",
        "common": "Guinea-Bissau"
      },
      "cym": {
        "official": "Republic of Guinea-Bissau",
        "common": "Guinea-Bissau"
      },
      "deu": {
        "official": "Republik Guinea-Bissau",
        "common": "Guinea-Bissau"
      },
      "est": {
        "official": "Guinea-Bissau Vabariik",
        "common": "Guinea-Bissau"
      },
      "fin": {
        "official": "Guinea-Bissaun tasavalta",
        "common": "Guinea-Bissau"
      },
      "fra": {
        "official": "République de Guinée-Bissau",
        "common": "Guinée-Bissau"
      },
      "hrv": {
        "official": "Republika Gvineja Bisau",
        "common": "Gvineja Bisau"
      },
      "hun": {
        "official": "Bissau-Guineai Köztársaság",
        "common": "Bissau-Guinea"
      },
      "ita": {
        "official": "Repubblica di Guinea-Bissau",
        "common": "Guinea-Bissau"
      },
      "jpn": {
        "official": "ギニアビサウ共和国",
        "common": "ギニアビサウ"
      },
      "kor": {
        "official": "기니비사우 공화국",
        "common": "기니비사우"
      },
      "nld": {
        "official": "Republiek Guinee-Bissau",
        "common": "Guinee-Bissau"
      },
      "per": {
        "official": "جمهوری گینه بیسائو",
        "common": "گینه بیسائو"
      },
      "pol": {
        "official": "Republika Gwinei Bissau",
        "common": "Gwinea Bissau"
      },
      "por": {
        "official": "República da Guiné-Bissau",
        "common": "Guiné-Bissau"
      },
      "rus": {
        "official": "Республика Гвинея -Бисау",
        "common": "Гвинея-Бисау"
      },
      "slk": {
        "official": "Guinejsko-bissauská republika",
        "common": "Guinea-Bissau"
      },
      "spa": {
        "official": "República de Guinea-Bissau",
        "common": "Guinea-Bisáu"
      },
      "swe": {
        "official": "Republiken Guinea-Bissau",
        "common": "Guinea-Bissau"
      },
      "tur": {
        "official": "Gine-Bissau Cumhuriyeti",
        "common": "Gine-Bissau"
      },
      "urd": {
        "official": "جمہوریہ گنی بساؤ",
        "common": "گنی بساؤ"
      },
      "zho": {
        "official": "几内亚比绍共和国",
        "common": "几内亚比绍"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guinea-Bissauan",
        "m": "Guinea-Bissauan"
      },
      "fra": {
        "f": "Bissau-Guinéenne",
        "m": "Bissau-Guinéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sk.png",
      "svg": "https://flagcdn.com/sk.svg"
    },
    "cca2": "SK",
    "capital": [
      "Bratislava"
    ],
    "altSpellings": [
      "SK",
      "Slovak Republic",
      "Slovenská republika"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سلوفاكيا",
        "common": "سلوفاكيا"
      },
      "bre": {
        "official": "Republik Slovak",
        "common": "Slovakia"
      },
      "ces": {
        "official": "Slovenská republika",
        "common": "Slovensko"
      },
      "cym": {
        "official": "Slovak Republic",
        "common": "Slovakia"
      },
      "deu": {
        "official": "Slowakische Republik",
        "common": "Slowakei"
      },
      "est": {
        "official": "Slovaki Vabariik",
        "common": "Slovakkia"
      },
      "fin": {
        "official": "Slovakian tasavalta",
        "common": "Slovakia"
      },
      "fra": {
        "official": "République slovaque",
        "common": "Slovaquie"
      },
      "hrv": {
        "official": "slovačka",
        "common": "Slovačka"
      },
      "hun": {
        "official": "Szlovák Köztársaság",
        "common": "Szlovákia"
      },
      "ita": {
        "official": "Repubblica slovacca",
        "common": "Slovacchia"
      },
      "jpn": {
        "official": "スロバキア共和国",
        "common": "スロバキア"
      },
      "kor": {
        "official": "슬로바키아 공화국",
        "common": "슬로바키아"
      },
      "nld": {
        "official": "Slowaakse Republiek",
        "common": "Slowakije"
      },
      "per": {
        "official": "جمهوری اسلواکی",
        "common": "اِسلُواکی"
      },
      "pol": {
        "official": "Republika Słowacka",
        "common": "Słowacja"
      },
      "por": {
        "official": "República Eslovaca",
        "common": "Eslováquia"
      },
      "rus": {
        "official": "Словацкая Республика",
        "common": "Словакия"
      },
      "slk": {
        "official": "Slovenská republika",
        "common": "Slovensko"
      },
      "spa": {
        "official": "República Eslovaca",
        "common": "República Eslovaca"
      },
      "swe": {
        "official": "Republiken Slovakien",
        "common": "Slovakien"
      },
      "tur": {
        "official": "Slovak Cumhuriyeti",
        "common": "Slovakya"
      },
      "urd": {
        "official": "جمہوریہ سلوواکیہ",
        "common": "سلوواکیہ"
      },
      "zho": {
        "official": "斯洛伐克共和国",
        "common": "斯洛伐克"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Slovak",
        "m": "Slovak"
      },
      "fra": {
        "f": "Slovaque",
        "m": "Slovaque"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mh.png",
      "svg": "https://flagcdn.com/mh.svg"
    },
    "cca2": "MH",
    "capital": [
      "Majuro"
    ],
    "altSpellings": [
      "MH",
      "Republic of the Marshall Islands",
      "Aolepān Aorōkin M̧ajeļ"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية جزر مارشال",
        "common": "جزر مارشال"
      },
      "bre": {
        "official": "Republik Inizi Marshall",
        "common": "Inizi Marshall"
      },
      "ces": {
        "official": "Republika Marshallovy ostrovy",
        "common": "Marshallovy ostrovy"
      },
      "cym": {
        "official": "Republic of the Marshall Islands",
        "common": "Marshall Islands"
      },
      "deu": {
        "official": "Republik Marshallinseln",
        "common": "Marshallinseln"
      },
      "est": {
        "official": "Marshalli Saarte Vabariik",
        "common": "Marshalli Saared"
      },
      "fin": {
        "official": "Marshallinsaarten tasavalta",
        "common": "Marshallinsaaret"
      },
      "fra": {
        "official": "République des Îles Marshall",
        "common": "Îles Marshall"
      },
      "hrv": {
        "official": "Republika Maršalovi Otoci",
        "common": "Maršalovi Otoci"
      },
      "hun": {
        "official": "Marshall-szigetek",
        "common": "Marshall-szigetek"
      },
      "ita": {
        "official": "Repubblica delle Isole Marshall",
        "common": "Isole Marshall"
      },
      "jpn": {
        "official": "マーシャル諸島共和国",
        "common": "マーシャル諸島"
      },
      "kor": {
        "official": "마셜 제도 공화국",
        "common": "마셜 제도"
      },
      "nld": {
        "official": "Republiek van de Marshall-eilanden",
        "common": "Marshalleilanden"
      },
      "per": {
        "official": "جمهوری جزایر مارشال",
        "common": "جزایر مارشال"
      },
      "pol": {
        "official": "Republika Wysp Marshalla",
        "common": "Wyspy Marshalla"
      },
      "por": {
        "official": "República das Ilhas Marshall",
        "common": "Ilhas Marshall"
      },
      "rus": {
        "official": "Республика Маршалловы острова",
        "common": "Маршалловы Острова"
      },
      "slk": {
        "official": "Republika Marshallových ostrovov",
        "common": "Marshallove ostrovy"
      },
      "spa": {
        "official": "República de las Islas Marshall",
        "common": "Islas Marshall"
      },
      "swe": {
        "official": "Republiken Marshallöarna",
        "common": "Marshallöarna"
      },
      "tur": {
        "official": "Marshall Adaları Cumhuriyeti",
        "common": "Marshall Adaları"
      },
      "urd": {
        "official": "جمہوریہ جزائر مارشل",
        "common": "جزائر مارشل"
      },
      "zho": {
        "official": "马绍尔群岛共和国",
        "common": "马绍尔群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Marshallese",
        "m": "Marshallese"
      },
      "fra": {
        "f": "Marshallaise",
        "m": "Marshallais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/dz.png",
      "svg": "https://flagcdn.com/dz.svg"
    },
    "cca2": "DZ",
    "capital": [
      "Algiers"
    ],
    "altSpellings": [
      "DZ",
      "Dzayer",
      "Algérie"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية الديمقراطية الشعبية الجزائرية",
        "common": "الجزائر"
      },
      "bre": {
        "official": "Republik Aljerian Demokratel ha Poblel",
        "common": "Aljeria"
      },
      "ces": {
        "official": "Alžírská demokratická a lidová republika",
        "common": "Alžírsko"
      },
      "cym": {
        "official": "Gweriniaeth Ddemocrataidd Pobl Algeria",
        "common": "Algeria"
      },
      "deu": {
        "official": "Demokratische Volksrepublik Algerien",
        "common": "Algerien"
      },
      "est": {
        "official": "Alžeeria Demokraatlik Rahvavabariik",
        "common": "Alžeeria"
      },
      "fin": {
        "official": "Algerian demokraattinen kansantasavalta",
        "common": "Algeria"
      },
      "fra": {
        "official": "République démocratique et populaire d'Algérie",
        "common": "Algérie"
      },
      "hrv": {
        "official": "Narodna Demokratska Republika Alžir",
        "common": "Alžir"
      },
      "hun": {
        "official": "Algériai Népi Demokratikus Köztársaság",
        "common": "Algéria"
      },
      "ita": {
        "official": "Repubblica popolare democratica di Algeria",
        "common": "Algeria"
      },
      "jpn": {
        "official": "アルジェリア人民民主共和国",
        "common": "アルジェリア"
      },
      "kor": {
        "official": "알제리 인민 민주 공화국",
        "common": "알제리"
      },
      "nld": {
        "official": "Democratische Volksrepubliek Algerije",
        "common": "Algerije"
      },
      "per": {
        "official": "جمهوری دموکراتیک خلق الجزایر",
        "common": "الجزایر"
      },
      "pol": {
        "official": "Algierska Republika Ludowo-Demokratyczna",
        "common": "Algieria"
      },
      "por": {
        "official": "República Argelina Democrática e Popular",
        "common": "Argélia"
      },
      "rus": {
        "official": "Народно-Демократическая Республика Алжир",
        "common": "Алжир"
      },
      "slk": {
        "official": "Alžírska demokratická ľudová republika",
        "common": "Alžírsko"
      },
      "spa": {
        "official": "República Argelina Democrática y Popular",
        "common": "Argelia"
      },
      "swe": {
        "official": "Demokratiska folkrepubliken Algeriet",
        "common": "Algeriet"
      },
      "tur": {
        "official": "Cezayir Demokratik Halk Cumhuriyeti",
        "common": "Cezayir"
      },
      "urd": {
        "official": "عوامی جمہوری جمہوریہ الجزائر",
        "common": "الجزائر"
      },
      "zho": {
        "official": "阿尔及利亚人民民主共和国",
        "common": "阿尔及利亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Algerian",
        "m": "Algerian"
      },
      "fra": {
        "f": "Algérienne",
        "m": "Algérien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/jm.png",
      "svg": "https://flagcdn.com/jm.svg"
    },
    "cca2": "JM",
    "capital": [
      "Kingston"
    ],
    "altSpellings": [
      "JM"
    ],
    "translations": {
      "ara": {
        "official": "جامايكا",
        "common": "جامايكا"
      },
      "bre": {
        "official": "Jamaika",
        "common": "Jamaika"
      },
      "ces": {
        "official": "Jamajka",
        "common": "Jamajka"
      },
      "cym": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "deu": {
        "official": "Jamaika",
        "common": "Jamaika"
      },
      "est": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "fin": {
        "official": "Jamaika",
        "common": "Jamaika"
      },
      "fra": {
        "official": "Jamaïque",
        "common": "Jamaïque"
      },
      "hrv": {
        "official": "Jamajka",
        "common": "Jamajka"
      },
      "hun": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "ita": {
        "official": "Giamaica",
        "common": "Giamaica"
      },
      "jpn": {
        "official": "ジャマイカ",
        "common": "ジャマイカ"
      },
      "kor": {
        "official": "자메이카",
        "common": "자메이카"
      },
      "nld": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "per": {
        "official": "جامائیکا",
        "common": "جامائیکا"
      },
      "pol": {
        "official": "Jamajka",
        "common": "Jamajka"
      },
      "por": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "rus": {
        "official": "Ямайка",
        "common": "Ямайка"
      },
      "slk": {
        "official": "Jamajka",
        "common": "Jamajka"
      },
      "spa": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "swe": {
        "official": "Jamaica",
        "common": "Jamaica"
      },
      "tur": {
        "official": "Jamaika",
        "common": "Jamaika"
      },
      "urd": {
        "official": "جمیکا",
        "common": "جمیکا"
      },
      "zho": {
        "official": "牙买加",
        "common": "牙买加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Jamaican",
        "m": "Jamaican"
      },
      "fra": {
        "f": "Jamaïcaine",
        "m": "Jamaïcain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mz.png",
      "svg": "https://flagcdn.com/mz.svg"
    },
    "cca2": "MZ",
    "capital": [
      "Maputo"
    ],
    "altSpellings": [
      "MZ",
      "Republic of Mozambique",
      "República de Moçambique"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية موزمبيق",
        "common": "موزمبيق"
      },
      "bre": {
        "official": "Republik Mozambik",
        "common": "Mozambik"
      },
      "ces": {
        "official": "Mosambická republika",
        "common": "Mosambik"
      },
      "cym": {
        "official": "Republic of Mozambique",
        "common": "Mozambique"
      },
      "deu": {
        "official": "Republik Mosambik",
        "common": "Mosambik"
      },
      "est": {
        "official": "Mosambiigi Vabariik",
        "common": "Mosambiik"
      },
      "fin": {
        "official": "Mosambikin tasavalta",
        "common": "Mosambik"
      },
      "fra": {
        "official": "République du Mozambique",
        "common": "Mozambique"
      },
      "hrv": {
        "official": "Republika Mozambiku",
        "common": "Mozambik"
      },
      "hun": {
        "official": "Mozambiki Köztársaság",
        "common": "Mozambik"
      },
      "ita": {
        "official": "Repubblica del Mozambico",
        "common": "Mozambico"
      },
      "jpn": {
        "official": "モザンビーク共和国",
        "common": "モザンビーク"
      },
      "kor": {
        "official": "모잠비크 공화국",
        "common": "모잠비크"
      },
      "nld": {
        "official": "Republiek Mozambique",
        "common": "Mozambique"
      },
      "per": {
        "official": "جمهوری موزامبیک",
        "common": "موزامبیک"
      },
      "pol": {
        "official": "Republika Mozambiku",
        "common": "Mozambik"
      },
      "por": {
        "official": "República de Moçambique",
        "common": "Moçambique"
      },
      "rus": {
        "official": "Республика Мозамбик",
        "common": "Мозамбик"
      },
      "slk": {
        "official": "Mozambická republika",
        "common": "Mozambik"
      },
      "spa": {
        "official": "República de Mozambique",
        "common": "Mozambique"
      },
      "swe": {
        "official": "Republiken Moçambique",
        "common": "Moçambique"
      },
      "tur": {
        "official": "Mozambik Cumhuriyeti",
        "common": "Mozambik"
      },
      "urd": {
        "official": "جمہوریہ موزمبیق",
        "common": "موزمبیق"
      },
      "zho": {
        "official": "莫桑比克共和国",
        "common": "莫桑比克"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mozambican",
        "m": "Mozambican"
      },
      "fra": {
        "f": "Mozambicaine",
        "m": "Mozambicain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/es.png",
      "svg": "https://flagcdn.com/es.svg"
    },
    "cca2": "ES",
    "capital": [
      "Madrid"
    ],
    "altSpellings": [
      "ES",
      "Kingdom of Spain",
      "Reino de España"
    ],
    "translations": {
      "ara": {
        "official": "مملكة إسبانيا",
        "common": "إسبانيا"
      },
      "bre": {
        "official": "Rouantelezh Spagn",
        "common": "Spagn"
      },
      "ces": {
        "official": "Španělské království",
        "common": "Španělsko"
      },
      "cym": {
        "official": "Kingdom of Spain",
        "common": "Spain"
      },
      "deu": {
        "official": "Königreich Spanien",
        "common": "Spanien"
      },
      "est": {
        "official": "Hispaania Kuningriik",
        "common": "Hispaania"
      },
      "fin": {
        "official": "Espanjan kuningaskunta",
        "common": "Espanja"
      },
      "fra": {
        "official": "Royaume d'Espagne",
        "common": "Espagne"
      },
      "hrv": {
        "official": "Kraljevina Španjolska",
        "common": "Španjolska"
      },
      "hun": {
        "official": "Spanyol Királyság",
        "common": "Spanyolország"
      },
      "ita": {
        "official": "Regno di Spagna",
        "common": "Spagna"
      },
      "jpn": {
        "official": "スペイン王国",
        "common": "スペイン"
      },
      "kor": {
        "official": "에스파냐 왕국",
        "common": "스페인"
      },
      "nld": {
        "official": "Koninkrijk Spanje",
        "common": "Spanje"
      },
      "per": {
        "official": "پادشاهی اسپانیا",
        "common": "اسپانیا"
      },
      "pol": {
        "official": "Królestwo Hiszpanii ",
        "common": "Hiszpania"
      },
      "por": {
        "official": "Reino de Espanha",
        "common": "Espanha"
      },
      "rus": {
        "official": "Королевство Испания",
        "common": "Испания"
      },
      "slk": {
        "official": "Španielske kráľovstvo",
        "common": "Španielsko"
      },
      "spa": {
        "official": "Reino de España",
        "common": "España"
      },
      "swe": {
        "official": "Konungariket Spanien",
        "common": "Spanien"
      },
      "tur": {
        "official": "İspanya Krallığı",
        "common": "İspanya"
      },
      "urd": {
        "official": "مملکتِ ہسپانیہ",
        "common": "ہسپانیہ"
      },
      "zho": {
        "official": "西班牙王国",
        "common": "西班牙"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Spanish",
        "m": "Spanish"
      },
      "fra": {
        "f": "Espagnole",
        "m": "Espagnol"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/hn.png",
      "svg": "https://flagcdn.com/hn.svg"
    },
    "cca2": "HN",
    "capital": [
      "Tegucigalpa"
    ],
    "altSpellings": [
      "HN",
      "Republic of Honduras",
      "República de Honduras"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية هندوراس",
        "common": "هندوراس"
      },
      "bre": {
        "official": "Republik Honduras",
        "common": "Honduras"
      },
      "ces": {
        "official": "Honduraská republika",
        "common": "Honduras"
      },
      "cym": {
        "official": "Republic of Honduras",
        "common": "Honduras"
      },
      "deu": {
        "official": "Republik Honduras",
        "common": "Honduras"
      },
      "est": {
        "official": "Hondurase Vabariik",
        "common": "Honduras"
      },
      "fin": {
        "official": "Hondurasin tasavalta",
        "common": "Honduras"
      },
      "fra": {
        "official": "République du Honduras",
        "common": "Honduras"
      },
      "hrv": {
        "official": "Republika Honduras",
        "common": "Honduras"
      },
      "hun": {
        "official": "Hondurasi Köztársaság",
        "common": "Honduras"
      },
      "ita": {
        "official": "Repubblica di Honduras",
        "common": "Honduras"
      },
      "jpn": {
        "official": "ホンジュラス共和国",
        "common": "ホンジュラス"
      },
      "kor": {
        "official": "온두라스 공화국",
        "common": "온두라스"
      },
      "nld": {
        "official": "Republiek Honduras",
        "common": "Honduras"
      },
      "per": {
        "official": "جمهوری هندوراس",
        "common": "هُندوراس"
      },
      "pol": {
        "official": "Republika Hondurasu",
        "common": "Honduras"
      },
      "por": {
        "official": "República de Honduras",
        "common": "Honduras"
      },
      "rus": {
        "official": "Республика Гондурас",
        "common": "Гондурас"
      },
      "slk": {
        "official": "Honduraská republika",
        "common": "Honduras"
      },
      "spa": {
        "official": "República de Honduras",
        "common": "Honduras"
      },
      "swe": {
        "official": "Republiken Honduras",
        "common": "Honduras"
      },
      "tur": {
        "official": "Honduras Cumhuriyeti",
        "common": "Honduras"
      },
      "urd": {
        "official": "جمہوریہ ہونڈوراس",
        "common": "ہونڈوراس"
      },
      "zho": {
        "official": "洪都拉斯共和国",
        "common": "洪都拉斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Honduran",
        "m": "Honduran"
      },
      "fra": {
        "f": "Hondurienne",
        "m": "Hondurien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/nl.png",
      "svg": "https://flagcdn.com/nl.svg"
    },
    "cca2": "NL",
    "capital": [
      "Amsterdam"
    ],
    "altSpellings": [
      "NL",
      "Holland",
      "Nederland",
      "The Netherlands"
    ],
    "translations": {
      "ara": {
        "official": "مملكة هولندا",
        "common": "هولندا"
      },
      "bre": {
        "official": "Rouantelezh an Izelvroioù",
        "common": "Izelvroioù"
      },
      "ces": {
        "official": "Nizozemské království",
        "common": "Nizozemsko"
      },
      "cym": {
        "official": "Kingdom of the Netherlands",
        "common": "Netherlands"
      },
      "deu": {
        "official": "Niederlande",
        "common": "Niederlande"
      },
      "est": {
        "official": "Madalmaade Kuningriik",
        "common": "Holland"
      },
      "fin": {
        "official": "Alankomaat",
        "common": "Alankomaat"
      },
      "fra": {
        "official": "Pays-Bas",
        "common": "Pays-Bas"
      },
      "hrv": {
        "official": "Holandija",
        "common": "Nizozemska"
      },
      "hun": {
        "official": "Holland Királyság",
        "common": "Hollandia"
      },
      "ita": {
        "official": "Paesi Bassi",
        "common": "Paesi Bassi"
      },
      "jpn": {
        "official": "オランダ",
        "common": "オランダ"
      },
      "kor": {
        "official": "네덜란드 왕국",
        "common": "네덜란드"
      },
      "nld": {
        "official": "Nederland",
        "common": "Nederland"
      },
      "per": {
        "official": "هلند",
        "common": "هلند"
      },
      "pol": {
        "official": "Królestwo Niderlandów",
        "common": "Holandia"
      },
      "por": {
        "official": "Holanda",
        "common": "Holanda"
      },
      "rus": {
        "official": "Нидерланды",
        "common": "Нидерланды"
      },
      "slk": {
        "official": "Holandské kráľovstvo",
        "common": "Holansko"
      },
      "spa": {
        "official": "Países Bajos",
        "common": "Países Bajos"
      },
      "swe": {
        "official": "Nederländerna",
        "common": "Nederländerna"
      },
      "tur": {
        "official": "Hollanda",
        "common": "Hollanda"
      },
      "urd": {
        "official": "مملکتِ نیدرلینڈز",
        "common": "نیدرلینڈز"
      },
      "zho": {
        "official": "荷兰",
        "common": "荷兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Dutch",
        "m": "Dutch"
      },
      "fra": {
        "f": "Néerlandaise",
        "m": "Néerlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/za.png",
      "svg": "https://flagcdn.com/za.svg"
    },
    "cca2": "ZA",
    "capital": [
      "Pretoria",
      "Bloemfontein",
      "Cape Town"
    ],
    "altSpellings": [
      "ZA",
      "RSA",
      "Suid-Afrika",
      "Republic of South Africa"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية جنوب أفريقيا",
        "common": "جنوب أفريقيا"
      },
      "bre": {
        "official": "Republik Suafrika",
        "common": "Suafrika"
      },
      "ces": {
        "official": "Jihoafrická republika",
        "common": "Jihoafrická republika"
      },
      "cym": {
        "official": "Republic of South Africa",
        "common": "South Africa"
      },
      "deu": {
        "official": "Republik Südafrika",
        "common": "Südafrika"
      },
      "est": {
        "official": "Lõuna-Aafrika Vabariik",
        "common": "Lõuna-Aafrika Vabariik"
      },
      "fin": {
        "official": "Etelä-Afrikan tasavalta",
        "common": "Etelä-Afrikka"
      },
      "fra": {
        "official": "République d'Afrique du Sud",
        "common": "Afrique du Sud"
      },
      "hrv": {
        "official": "Južnoafrička Republika",
        "common": "Južna Afrika"
      },
      "hun": {
        "official": "Dél-afrikai Köztársaság",
        "common": "Dél-afrikai Köztársaság"
      },
      "ita": {
        "official": "Repubblica del Sud Africa",
        "common": "Sud Africa"
      },
      "jpn": {
        "official": "南アフリカ共和国",
        "common": "南アフリカ"
      },
      "kor": {
        "official": "남아프리카 공화국",
        "common": "남아프리카"
      },
      "nld": {
        "official": "Republiek Zuid-Afrika",
        "common": "Zuid-Afrika"
      },
      "per": {
        "official": "جمهوری آفریقای جنوبی",
        "common": "آفریقای جنوبی"
      },
      "pol": {
        "official": "Republika Południowej Afryki",
        "common": "Południowa Afryka"
      },
      "por": {
        "official": "República da África do Sul",
        "common": "África do Sul"
      },
      "rus": {
        "official": "Южно-Африканская Республика",
        "common": "Южная Африка"
      },
      "slk": {
        "official": "Juhoafrická republika",
        "common": "Južná Afrika"
      },
      "spa": {
        "official": "República de Sudáfrica",
        "common": "Sudáfrica"
      },
      "swe": {
        "official": "Republiken Sydafrika",
        "common": "Sydafrika"
      },
      "tur": {
        "official": "Güney Afrika Cumhuriyeti",
        "common": "Güney Afrika"
      },
      "urd": {
        "official": "جمہوریہ جنوبی افریقا",
        "common": "جنوبی افریقا"
      },
      "zho": {
        "official": "南非共和国",
        "common": "南非"
      }
    },
    "demonyms": {
      "eng": {
        "f": "South African",
        "m": "South African"
      },
      "fra": {
        "f": "Sud-africaine",
        "m": "Sud-africain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/az.png",
      "svg": "https://flagcdn.com/az.svg"
    },
    "cca2": "AZ",
    "capital": [
      "Baku"
    ],
    "altSpellings": [
      "AZ",
      "Republic of Azerbaijan",
      "Azərbaycan Respublikası"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أذربيجان",
        "common": "أذربيجان"
      },
      "bre": {
        "official": "Republik Azerbaidjan",
        "common": "Azerbaidjan"
      },
      "ces": {
        "official": "Ázerbájdžánská republika",
        "common": "Ázerbájdžán"
      },
      "cym": {
        "official": "Gweriniaeth Aserbaijan",
        "common": "Aserbaijan"
      },
      "deu": {
        "official": "Republik Aserbaidschan",
        "common": "Aserbaidschan"
      },
      "est": {
        "official": "Aserbaidžaani Vabariik",
        "common": "Aserbaidžaan"
      },
      "fin": {
        "official": "Azerbaidzanin tasavalta",
        "common": "Azerbaidzan"
      },
      "fra": {
        "official": "République d'Azerbaïdjan",
        "common": "Azerbaïdjan"
      },
      "hrv": {
        "official": "Republika Azerbajdžan",
        "common": "Azerbajdžan"
      },
      "hun": {
        "official": "Azerbajdzsán",
        "common": "Azerbajdzsán"
      },
      "ita": {
        "official": "Repubblica dell'Azerbaigian",
        "common": "Azerbaijan"
      },
      "jpn": {
        "official": "アゼルバイジャン共和国",
        "common": "アゼルバイジャン"
      },
      "kor": {
        "official": "아제르바이잔 공화국",
        "common": "아제르바이잔"
      },
      "nld": {
        "official": "Republiek Azerbeidzjan",
        "common": "Azerbeidzjan"
      },
      "per": {
        "official": "جمهوری آذربایجان",
        "common": "جمهوری آذربایجان"
      },
      "pol": {
        "official": "Republika Azerbejdżanu",
        "common": "Azerbejdżan"
      },
      "por": {
        "official": "República do Azerbaijão",
        "common": "Azerbeijão"
      },
      "rus": {
        "official": "Азербайджанская Республика",
        "common": "Азербайджан"
      },
      "slk": {
        "official": "Azerbajǆanská republika",
        "common": "AzerbajǇan"
      },
      "spa": {
        "official": "República de Azerbaiyán",
        "common": "Azerbaiyán"
      },
      "swe": {
        "official": "Republiken Azerbajdzjan",
        "common": "Azerbajdzjan"
      },
      "tur": {
        "official": "Azerbaycan Cumhuriyeti",
        "common": "Azerbaycan"
      },
      "urd": {
        "official": "جمہوریہ آذربائیجان",
        "common": "آذربائیجان"
      },
      "zho": {
        "official": "阿塞拜疆共和国",
        "common": "阿塞拜疆"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Azerbaijani",
        "m": "Azerbaijani"
      },
      "fra": {
        "f": "Azerbaïdjanaise",
        "m": "Azerbaïdjanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mk.png",
      "svg": "https://flagcdn.com/mk.svg"
    },
    "cca2": "MK",
    "capital": [
      "Skopje"
    ],
    "altSpellings": [
      "MK",
      "The former Yugoslav Republic of Macedonia",
      "Republic of North Macedonia",
      "Macedonia, The Former Yugoslav Republic of",
      "Република Северна Македонија"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية شمال مقدونيا",
        "common": "شمال مقدونيا"
      },
      "bre": {
        "official": "Republik Makedonia an Norzh",
        "common": "Makedonia an Norzh"
      },
      "ces": {
        "official": "Republika Severní Makedonie",
        "common": "Severní Makedonie"
      },
      "cym": {
        "official": "Republic of North Macedonia",
        "common": "North Macedonia"
      },
      "deu": {
        "official": "Republik Nordmazedonien",
        "common": "Nordmazedonien"
      },
      "est": {
        "official": "Põhja-Makedoonia Vabariik",
        "common": "Põhja-Makedoonia"
      },
      "fin": {
        "official": "Pohjois-Makedonian tasavalta",
        "common": "Pohjois-Makedonia"
      },
      "fra": {
        "official": "République de Macédoine du Nord",
        "common": "Macédoine du Nord"
      },
      "hrv": {
        "official": "Republika Sjeverna Makedonija",
        "common": "Sjeverna Makedonija"
      },
      "hun": {
        "official": "Észak-macedón Köztársaság",
        "common": "Észak-Macedónia"
      },
      "ita": {
        "official": "Repubblica di Macedonia del Nord",
        "common": "Macedonia del Nord"
      },
      "jpn": {
        "official": "北マケドニア共和国",
        "common": "北マケドニア "
      },
      "kor": {
        "official": "북마케도니아 공화국",
        "common": "북마케도니아"
      },
      "nld": {
        "official": "Republiek Noord-Macedonië",
        "common": "Noord-Macedonië"
      },
      "per": {
        "official": "جمهوری مقدونیه شمالی",
        "common": "مقدونیه شمالی"
      },
      "pol": {
        "official": "Republika Macedonii Północnej",
        "common": "Macedonia Północna"
      },
      "por": {
        "official": "República da Macedônia do Norte",
        "common": "Macedónia do Norte"
      },
      "rus": {
        "official": "Республика Северная Македония",
        "common": "Северная Македония"
      },
      "slk": {
        "official": "Severomacedónska republika",
        "common": "Severné Macedónsko"
      },
      "spa": {
        "official": "República de Macedonia del Norte",
        "common": "Macedonia del Norte"
      },
      "swe": {
        "official": "Republiken Nordmakedonien",
        "common": "Nordmakedonien"
      },
      "tur": {
        "official": "Kuzey Makedonya Cumhuriyeti",
        "common": "Kuzey Makedonya"
      },
      "urd": {
        "official": "جمہوریہ مقدونیہ",
        "common": "شمالی مقدونیہ"
      },
      "zho": {
        "official": "北馬其頓共和國",
        "common": "北馬其頓"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Macedonian",
        "m": "Macedonian"
      },
      "fra": {
        "f": "Macédonienne",
        "m": "Macédonien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gm.png",
      "svg": "https://flagcdn.com/gm.svg"
    },
    "cca2": "GM",
    "capital": [
      "Banjul"
    ],
    "altSpellings": [
      "GM",
      "Republic of the Gambia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غامبيا",
        "common": "غامبيا"
      },
      "bre": {
        "official": "Republik islamek ar Gambia",
        "common": "Gambia"
      },
      "ces": {
        "official": "Gambijská republika",
        "common": "Gambie"
      },
      "cym": {
        "official": "Republic of the Gambia",
        "common": "Gambia"
      },
      "deu": {
        "official": "Republik Gambia",
        "common": "Gambia"
      },
      "est": {
        "official": "Gambia Vabariik",
        "common": "Gambia"
      },
      "fin": {
        "official": "Gambian tasavalta",
        "common": "Gambia"
      },
      "fra": {
        "official": "République de Gambie",
        "common": "Gambie"
      },
      "hrv": {
        "official": "Republika Gambija",
        "common": "Gambija"
      },
      "hun": {
        "official": "Gambiai Köztársaság",
        "common": "Gambia"
      },
      "ita": {
        "official": "Repubblica del Gambia",
        "common": "Gambia"
      },
      "jpn": {
        "official": "ガンビア共和国",
        "common": "ガンビア"
      },
      "kor": {
        "official": "감비아 공화국",
        "common": "감비아"
      },
      "nld": {
        "official": "Republiek Gambia",
        "common": "Gambia"
      },
      "per": {
        "official": "جمهوری گامبیا",
        "common": "گامبیا"
      },
      "pol": {
        "official": "Republika Gambii",
        "common": "Gambia"
      },
      "por": {
        "official": "República da Gâmbia",
        "common": "Gâmbia"
      },
      "rus": {
        "official": "Республика Гамбия",
        "common": "Гамбия"
      },
      "slk": {
        "official": "Gambijská republika",
        "common": "Gambia"
      },
      "spa": {
        "official": "República de Gambia",
        "common": "Gambia"
      },
      "swe": {
        "official": "Republiken Gambia",
        "common": "Gambia"
      },
      "tur": {
        "official": "Gambiya Cumhuriyeti",
        "common": "Gambiya"
      },
      "urd": {
        "official": "جمہوریہ گیمبیا",
        "common": "گیمبیا"
      },
      "zho": {
        "official": "冈比亚共和国",
        "common": "冈比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Gambian",
        "m": "Gambian"
      },
      "fra": {
        "f": "Gambienne",
        "m": "Gambien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lr.png",
      "svg": "https://flagcdn.com/lr.svg"
    },
    "cca2": "LR",
    "capital": [
      "Monrovia"
    ],
    "altSpellings": [
      "LR",
      "Republic of Liberia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ليبيريا",
        "common": "ليبيريا"
      },
      "bre": {
        "official": "Republik Liberia",
        "common": "Liberia"
      },
      "ces": {
        "official": "Liberijská republika",
        "common": "Libérie"
      },
      "cym": {
        "official": "Republic of Liberia",
        "common": "Liberia"
      },
      "deu": {
        "official": "Republik Liberia",
        "common": "Liberia"
      },
      "est": {
        "official": "Libeeria Vabariik",
        "common": "Libeeria"
      },
      "fin": {
        "official": "Liberian tasavalta",
        "common": "Liberia"
      },
      "fra": {
        "official": "République du Libéria",
        "common": "Liberia"
      },
      "hrv": {
        "official": "Republika Liberija",
        "common": "Liberija"
      },
      "hun": {
        "official": "Libériai Köztársaság",
        "common": "Libéria"
      },
      "ita": {
        "official": "Repubblica di Liberia",
        "common": "Liberia"
      },
      "jpn": {
        "official": "リベリア共和国",
        "common": "リベリア"
      },
      "kor": {
        "official": "라이베리아 공화국",
        "common": "라이베리아"
      },
      "nld": {
        "official": "Republiek Liberia",
        "common": "Liberia"
      },
      "per": {
        "official": "جمهوری لیبریا",
        "common": "لیبـِریا"
      },
      "pol": {
        "official": "Republika Liberii",
        "common": "Liberia"
      },
      "por": {
        "official": "República da Libéria",
        "common": "Libéria"
      },
      "rus": {
        "official": "Республика Либерия",
        "common": "Либерия"
      },
      "slk": {
        "official": "Libérijská republika",
        "common": "Libéria"
      },
      "spa": {
        "official": "República de Liberia",
        "common": "Liberia"
      },
      "swe": {
        "official": "Republiken Liberia",
        "common": "Liberia"
      },
      "tur": {
        "official": "Liberya Cumhuriyeti",
        "common": "Liberya"
      },
      "urd": {
        "official": "جمہوریہ لائبیریا",
        "common": "لائبیریا"
      },
      "zho": {
        "official": "利比里亚共和国",
        "common": "利比里亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Liberian",
        "m": "Liberian"
      },
      "fra": {
        "f": "Libérienne",
        "m": "Libérien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lu.png",
      "svg": "https://flagcdn.com/lu.svg"
    },
    "cca2": "LU",
    "capital": [
      "Luxembourg"
    ],
    "altSpellings": [
      "LU",
      "Grand Duchy of Luxembourg",
      "Grand-Duché de Luxembourg",
      "Großherzogtum Luxemburg",
      "Groussherzogtum Lëtzebuerg"
    ],
    "translations": {
      "ara": {
        "official": "دوقية لوكسمبورغ",
        "common": "لوكسمبورغ"
      },
      "bre": {
        "official": "Dugelezh Veur Luksembourg",
        "common": "Luksembourg"
      },
      "ces": {
        "official": "Lucemburské velkovévodství",
        "common": "Lucembursko"
      },
      "cym": {
        "official": "Grand Duchy of Luxembourg",
        "common": "Luxembourg"
      },
      "deu": {
        "official": "Großherzogtum Luxemburg,",
        "common": "Luxemburg"
      },
      "est": {
        "official": "Luksemburgi Suurhertsogiriik",
        "common": "Luksemburg"
      },
      "fin": {
        "official": "Luxemburgin suurherttuakunta",
        "common": "Luxemburg"
      },
      "fra": {
        "official": "Grand-Duché de Luxembourg",
        "common": "Luxembourg"
      },
      "hrv": {
        "official": "Veliko Vojvodstvo Luksemburg",
        "common": "Luksemburg"
      },
      "hun": {
        "official": "Luxemburgi Nagyhercegség",
        "common": "Luxemburg"
      },
      "ita": {
        "official": "Granducato di Lussemburgo",
        "common": "Lussemburgo"
      },
      "jpn": {
        "official": "ルクセンブルク大公国",
        "common": "ルクセンブルク"
      },
      "kor": {
        "official": "룩셈부르크 대공국",
        "common": "룩셈부르크"
      },
      "nld": {
        "official": "Groothertogdom Luxemburg",
        "common": "Luxemburg"
      },
      "per": {
        "official": "دوک‌نشین لوکزامبورگ",
        "common": "لوکزامبورگ"
      },
      "pol": {
        "official": "Wielkie Księstwo Luksemburga",
        "common": "Luksemburg"
      },
      "por": {
        "official": "Grão-Ducado do Luxemburgo",
        "common": "Luxemburgo"
      },
      "rus": {
        "official": "Великое Герцогство Люксембург",
        "common": "Люксембург"
      },
      "slk": {
        "official": "Luxemburské veľkovojvodstvo",
        "common": "Luxembursko"
      },
      "spa": {
        "official": "Gran Ducado de Luxemburgo",
        "common": "Luxemburgo"
      },
      "swe": {
        "official": "Storhertigdömet Luxemburg",
        "common": "Luxemburg"
      },
      "tur": {
        "official": "Lüksemburg Büyük Dükalığı",
        "common": "Lüksemburg"
      },
      "urd": {
        "official": "دوقیہ کبیرلکسمبرگ",
        "common": "لکسمبرگ"
      },
      "zho": {
        "official": "卢森堡大公国",
        "common": "卢森堡"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Luxembourger",
        "m": "Luxembourger"
      },
      "fra": {
        "f": "Luxembourgeoise",
        "m": "Luxembourgeois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cv.png",
      "svg": "https://flagcdn.com/cv.svg"
    },
    "cca2": "CV",
    "capital": [
      "Praia"
    ],
    "altSpellings": [
      "CV",
      "Republic of Cabo Verde",
      "República de Cabo Verde"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كابو فيردي",
        "common": "كابو فيردي"
      },
      "bre": {
        "official": "Republik Kab Glas",
        "common": "Kab Glas"
      },
      "ces": {
        "official": "Kapverdská republika",
        "common": "Kapverdy"
      },
      "cym": {
        "official": "Gweriniaeth Cabo Verde",
        "common": "Penrhyn Verde"
      },
      "deu": {
        "official": "Republik Cabo Verde",
        "common": "Kap Verde"
      },
      "est": {
        "official": "Cabo Verde Vabariik",
        "common": "Roheneemesaared"
      },
      "fin": {
        "official": "Kap Verden tasavalta",
        "common": "Kap Verde"
      },
      "fra": {
        "official": "République du Cap-Vert",
        "common": "Îles du Cap-Vert"
      },
      "hrv": {
        "official": "Republika Cabo Verde",
        "common": "Zelenortska Republika"
      },
      "hun": {
        "official": "Zöld-foki Köztársaság",
        "common": "Zöld-foki Köztársaság"
      },
      "ita": {
        "official": "Repubblica di Capo Verde",
        "common": "Capo Verde"
      },
      "jpn": {
        "official": "カーボベルデ共和国",
        "common": "カーボベルデ"
      },
      "kor": {
        "official": "카보베르데 공화국",
        "common": "카보베르데"
      },
      "nld": {
        "official": "Republiek van Cabo Verde",
        "common": "Kaapverdië"
      },
      "per": {
        "official": "جمهوری کبو ورد",
        "common": "دماغهٔ سبز"
      },
      "pol": {
        "official": "Republika Zielonego Przylądka",
        "common": "Republika Zielonego Przylądka"
      },
      "por": {
        "official": "República de Cabo Verde",
        "common": "Cabo Verde"
      },
      "rus": {
        "official": "Республика Кабо -Верде",
        "common": "Кабо-Верде"
      },
      "slk": {
        "official": "Kapverdská republika",
        "common": "Kapverdy"
      },
      "spa": {
        "official": "República de Cabo Verde",
        "common": "Cabo Verde"
      },
      "swe": {
        "official": "Republiken Kap Verde",
        "common": "Kap Verde"
      },
      "tur": {
        "official": "Yeşil Burun Cumhuriyeti",
        "common": "Yeşil Burun"
      },
      "urd": {
        "official": "جمہوریہ کیپ ورڈی",
        "common": "کیپ ورڈی"
      },
      "zho": {
        "official": "佛得角共和国",
        "common": "佛得角"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cape Verdian",
        "m": "Cape Verdian"
      },
      "fra": {
        "f": "Cap-verdienne",
        "m": "Cap-verdien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bs.png",
      "svg": "https://flagcdn.com/bs.svg"
    },
    "cca2": "BS",
    "capital": [
      "Nassau"
    ],
    "altSpellings": [
      "BS",
      "Commonwealth of the Bahamas"
    ],
    "translations": {
      "ara": {
        "official": "كومنولث جزر البهاما",
        "common": "باهاماس"
      },
      "bre": {
        "official": "Kenglad ar Bahamas",
        "common": "Bahamas"
      },
      "ces": {
        "official": "Bahamské společenství",
        "common": "Bahamy"
      },
      "cym": {
        "official": "Cymanwlad y Bahamas",
        "common": "Bahamas"
      },
      "deu": {
        "official": "Commonwealth der Bahamas",
        "common": "Bahamas"
      },
      "est": {
        "official": "Bahama Ühendus",
        "common": "Bahama"
      },
      "fin": {
        "official": "Bahaman liittovaltio",
        "common": "Bahamasaaret"
      },
      "fra": {
        "official": "Commonwealth des Bahamas",
        "common": "Bahamas"
      },
      "hrv": {
        "official": "Zajednica Bahama",
        "common": "Bahami"
      },
      "hun": {
        "official": "Bahamai Közösség",
        "common": "Bahama-szigetek"
      },
      "ita": {
        "official": "Commonwealth delle Bahamas",
        "common": "Bahamas"
      },
      "jpn": {
        "official": "バハマ",
        "common": "バハマ"
      },
      "kor": {
        "official": "바하마 연방",
        "common": "바하마"
      },
      "nld": {
        "official": "Gemenebest van de Bahama's",
        "common": "Bahama’s"
      },
      "per": {
        "official": "قلمرو همسود باهاما",
        "common": "باهاما"
      },
      "pol": {
        "official": "Bahamy",
        "common": "Bahamy"
      },
      "por": {
        "official": "Comunidade das Bahamas",
        "common": "Bahamas"
      },
      "rus": {
        "official": "Содружество Багамских Островов",
        "common": "Багамские Острова"
      },
      "slk": {
        "official": "Bahamské spoločenstvo",
        "common": "Bahamy"
      },
      "spa": {
        "official": "Commonwealth de las Bahamas",
        "common": "Bahamas"
      },
      "swe": {
        "official": "Samväldet Bahamas",
        "common": "Bahamas"
      },
      "tur": {
        "official": "Bahama Milletler Topluluğu",
        "common": "Bahamalar"
      },
      "urd": {
        "official": "دولتِ مشترکہ بہاماس",
        "common": "بہاماس"
      },
      "zho": {
        "official": "巴哈马联邦",
        "common": "巴哈马"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bahamian",
        "m": "Bahamian"
      },
      "fra": {
        "f": "Bahamienne",
        "m": "Bahamien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ge.png",
      "svg": "https://flagcdn.com/ge.svg"
    },
    "cca2": "GE",
    "capital": [
      "Tbilisi"
    ],
    "altSpellings": [
      "GE",
      "Sakartvelo"
    ],
    "translations": {
      "ara": {
        "official": "جورجيا",
        "common": "جورجيا"
      },
      "bre": {
        "official": "Republik Jorjia",
        "common": "Jorjia"
      },
      "ces": {
        "official": "Gruzie",
        "common": "Gruzie"
      },
      "cym": {
        "official": "Georgia",
        "common": "Georgia"
      },
      "deu": {
        "official": "Georgien",
        "common": "Georgien"
      },
      "est": {
        "official": "Gruusia",
        "common": "Gruusia"
      },
      "fin": {
        "official": "Georgia",
        "common": "Georgia"
      },
      "fra": {
        "official": "République de Géorgie",
        "common": "Géorgie"
      },
      "hrv": {
        "official": "Gruzija",
        "common": "Gruzija"
      },
      "hun": {
        "official": "Grúzia",
        "common": "Grúzia"
      },
      "ita": {
        "official": "Georgia",
        "common": "Georgia"
      },
      "jpn": {
        "official": "グルジア",
        "common": "グルジア"
      },
      "kor": {
        "official": "조지아",
        "common": "조지아"
      },
      "nld": {
        "official": "Georgia",
        "common": "Georgië"
      },
      "per": {
        "official": "گرجستان",
        "common": "گرجستان"
      },
      "pol": {
        "official": "Gruzja",
        "common": "Gruzja"
      },
      "por": {
        "official": "Georgia",
        "common": "Geórgia"
      },
      "rus": {
        "official": "Грузия",
        "common": "Грузия"
      },
      "slk": {
        "official": "Gruzínsko",
        "common": "Gruzínsko"
      },
      "spa": {
        "official": "Georgia",
        "common": "Georgia"
      },
      "swe": {
        "official": "Georgien",
        "common": "Georgien"
      },
      "tur": {
        "official": "Gürcistan",
        "common": "Gürcistan"
      },
      "urd": {
        "official": "جارجیا",
        "common": "جارجیا"
      },
      "zho": {
        "official": "格鲁吉亚",
        "common": "格鲁吉亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Georgian",
        "m": "Georgian"
      },
      "fra": {
        "f": "Géorgienne",
        "m": "Géorgien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ki.png",
      "svg": "https://flagcdn.com/ki.svg"
    },
    "cca2": "KI",
    "capital": [
      "South Tarawa"
    ],
    "altSpellings": [
      "KI",
      "Republic of Kiribati",
      "Ribaberiki Kiribati"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كيريباتي",
        "common": "كيريباتي"
      },
      "bre": {
        "official": "Republik Kiribati",
        "common": "Kiribati"
      },
      "ces": {
        "official": "Republika Kiribati",
        "common": "Kiribati"
      },
      "cym": {
        "official": "Independent and Sovereign Republic of Kiribati",
        "common": "Kiribati"
      },
      "deu": {
        "official": "Republik Kiribati",
        "common": "Kiribati"
      },
      "est": {
        "official": "Kiribati Vabariik",
        "common": "Kiribati"
      },
      "fin": {
        "official": "Kiribatin tasavalta",
        "common": "Kiribati"
      },
      "fra": {
        "official": "République de Kiribati",
        "common": "Kiribati"
      },
      "hrv": {
        "official": "Samostalne i suverene Republike Kiribati",
        "common": "Kiribati"
      },
      "hun": {
        "official": "Kiribati Köztársaság",
        "common": "Kiribati"
      },
      "ita": {
        "official": "Repubblica indipendente e sovrano di Kiribati",
        "common": "Kiribati"
      },
      "jpn": {
        "official": "キリバスの独立と主権共和国",
        "common": "キリバス"
      },
      "kor": {
        "official": "키리바시 공화국",
        "common": "키리바시"
      },
      "nld": {
        "official": "Onafhankelijke en soevereine republiek Kiribati",
        "common": "Kiribati"
      },
      "per": {
        "official": "جمهوری کیریباتی",
        "common": "کیریباتی"
      },
      "pol": {
        "official": "Republika Kiribati",
        "common": "Kiribati"
      },
      "por": {
        "official": "Independente e soberano República de Kiribati",
        "common": "Kiribati"
      },
      "rus": {
        "official": "Независимой и суверенной Республики Кирибати",
        "common": "Кирибати"
      },
      "slk": {
        "official": "Kiribatská republika",
        "common": "Kiribati"
      },
      "spa": {
        "official": "República Independiente y Soberano de Kiribati",
        "common": "Kiribati"
      },
      "swe": {
        "official": "Republiken Kiribati",
        "common": "Kiribati"
      },
      "tur": {
        "official": "Kiribati Cumhuriyeti",
        "common": "Kiribati"
      },
      "urd": {
        "official": "سلطنت آزاد جمہوریہ کیریباتی",
        "common": "کیریباتی"
      },
      "zho": {
        "official": "基里巴斯共和国",
        "common": "基里巴斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "I-Kiribati",
        "m": "I-Kiribati"
      },
      "fra": {
        "f": "Kiribatienne",
        "m": "Kiribatien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tz.png",
      "svg": "https://flagcdn.com/tz.svg"
    },
    "cca2": "TZ",
    "capital": [
      "Dodoma"
    ],
    "altSpellings": [
      "TZ",
      "Tanzania, United Republic of",
      "United Republic of Tanzania",
      "Jamhuri ya Muungano wa Tanzania"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية تنزانيا الاتحادية",
        "common": "تنزانيا"
      },
      "bre": {
        "official": "Republik Unanet Tanzania",
        "common": "Tanzania"
      },
      "ces": {
        "official": "Sjednocená tanzanská republika",
        "common": "Tanzanie"
      },
      "cym": {
        "official": "United Republic of Tanzania",
        "common": "Tanzania"
      },
      "deu": {
        "official": "Vereinigte Republik Tansania",
        "common": "Tansania"
      },
      "est": {
        "official": "Tansaania Ühendvabariik",
        "common": "Tansaania"
      },
      "fin": {
        "official": "Tansanian yhdistynyt tasavalta",
        "common": "Tansania"
      },
      "fra": {
        "official": "République -Unie de Tanzanie",
        "common": "Tanzanie"
      },
      "hrv": {
        "official": "Ujedinjena Republika Tanzanija",
        "common": "Tanzanija"
      },
      "hun": {
        "official": "Tádzsik Köztársaság",
        "common": "Tádzsikisztán"
      },
      "ita": {
        "official": "Repubblica Unita di Tanzania",
        "common": "Tanzania"
      },
      "jpn": {
        "official": "タンザニア連合共和国",
        "common": "タンザニア"
      },
      "kor": {
        "official": "탄자니아 연합 공화국",
        "common": "탄자니아"
      },
      "nld": {
        "official": "Verenigde Republiek Tanzania",
        "common": "Tanzania"
      },
      "per": {
        "official": "جمهوری متحد تانزانیا",
        "common": "تانزانیا"
      },
      "pol": {
        "official": "Zjednoczona Republika Tanzanii",
        "common": "Tanzania"
      },
      "por": {
        "official": "República Unida da Tanzânia",
        "common": "Tanzânia"
      },
      "rus": {
        "official": "Объединенная Республика Танзания",
        "common": "Танзания"
      },
      "slk": {
        "official": "Tanzánijská zjednotená republika",
        "common": "Tanzánia"
      },
      "spa": {
        "official": "República Unida de Tanzania",
        "common": "Tanzania"
      },
      "swe": {
        "official": "Förenade republiken Tanzania",
        "common": "Tanzania"
      },
      "tur": {
        "official": "Tanzanya Birleşik Cumhuriyeti",
        "common": "Tanzanya"
      },
      "urd": {
        "official": "متحدہ جمہوریہ تنزانیہ",
        "common": "تنزانیہ"
      },
      "zho": {
        "official": "坦桑尼亚联合共和国",
        "common": "坦桑尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tanzanian",
        "m": "Tanzanian"
      },
      "fra": {
        "f": "Tanzanienne",
        "m": "Tanzanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cn.png",
      "svg": "https://flagcdn.com/cn.svg"
    },
    "cca2": "CN",
    "capital": [
      "Beijing"
    ],
    "altSpellings": [
      "CN",
      "Zhōngguó",
      "Zhongguo",
      "Zhonghua",
      "People's Republic of China",
      "中华人民共和国",
      "Zhōnghuá Rénmín Gònghéguó"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الصين الشعبية",
        "common": "الصين"
      },
      "bre": {
        "official": "Republik Pobl Sina",
        "common": "Sina"
      },
      "ces": {
        "official": "Čínská lidová republika",
        "common": "Čína"
      },
      "cym": {
        "official": "Gweriniaeth Pobl Tsieina",
        "common": "Tsieina"
      },
      "deu": {
        "official": "Volksrepublik China",
        "common": "China"
      },
      "est": {
        "official": "Hiina Rahvavabariik",
        "common": "Hiina"
      },
      "fin": {
        "official": "Kiinan kansantasavalta",
        "common": "Kiina"
      },
      "fra": {
        "official": "République populaire de Chine",
        "common": "Chine"
      },
      "hrv": {
        "official": "Narodna Republika Kina",
        "common": "Kina"
      },
      "hun": {
        "official": "Kínai Népköztársaság",
        "common": "Kína"
      },
      "ita": {
        "official": "Repubblica popolare cinese",
        "common": "Cina"
      },
      "jpn": {
        "official": "中華人民共和国",
        "common": "中国"
      },
      "kor": {
        "official": "중화인민공화국",
        "common": "중국"
      },
      "nld": {
        "official": "Volksrepubliek China",
        "common": "China"
      },
      "per": {
        "official": "جمهوری خلق چین",
        "common": "چین"
      },
      "pol": {
        "official": "Chińska Republika Ludowa",
        "common": "Chiny"
      },
      "por": {
        "official": "República Popular da China",
        "common": "China"
      },
      "rus": {
        "official": "Народная Республика Китай",
        "common": "Китай"
      },
      "slk": {
        "official": "Čínska ľudová republika",
        "common": "Čína"
      },
      "spa": {
        "official": "República Popular de China",
        "common": "China"
      },
      "swe": {
        "official": "Folkrepubliken Kina",
        "common": "Kina"
      },
      "tur": {
        "official": "Çin Halk Cumhuriyeti",
        "common": "Çin"
      },
      "urd": {
        "official": "عوامی جمہوریہ چین",
        "common": "چین"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Chinese",
        "m": "Chinese"
      },
      "fra": {
        "f": "Chinoise",
        "m": "Chinois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gt.png",
      "svg": "https://flagcdn.com/gt.svg"
    },
    "cca2": "GT",
    "capital": [
      "Guatemala City"
    ],
    "altSpellings": [
      "GT"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غواتيمالا",
        "common": "غواتيمالا"
      },
      "bre": {
        "official": "Republik Guatemala",
        "common": "Guatemala"
      },
      "ces": {
        "official": "Republika Guatemala",
        "common": "Guatemala"
      },
      "cym": {
        "official": "Republic of Guatemala",
        "common": "Guatemala"
      },
      "deu": {
        "official": "Republik Guatemala",
        "common": "Guatemala"
      },
      "est": {
        "official": "Guatemala Vabariik",
        "common": "Guatemala"
      },
      "fin": {
        "official": "Guatemalan tasavalta",
        "common": "Guatemala"
      },
      "fra": {
        "official": "République du Guatemala",
        "common": "Guatemala"
      },
      "hrv": {
        "official": "Republika Gvatemala",
        "common": "Gvatemala"
      },
      "hun": {
        "official": "Guatemalai Köztársaság",
        "common": "Guatemala"
      },
      "ita": {
        "official": "Repubblica del Guatemala",
        "common": "Guatemala"
      },
      "jpn": {
        "official": "グアテマラ共和国",
        "common": "グアテマラ"
      },
      "kor": {
        "official": "과테말라 공화국",
        "common": "과테말라"
      },
      "nld": {
        "official": "Republiek Guatemala",
        "common": "Guatemala"
      },
      "per": {
        "official": "جمهوری گواتِمالا",
        "common": "گواتِمالا"
      },
      "pol": {
        "official": "Republika Gwatemali",
        "common": "Gwatemala"
      },
      "por": {
        "official": "República da Guatemala",
        "common": "Guatemala"
      },
      "rus": {
        "official": "Республика Гватемала",
        "common": "Гватемала"
      },
      "slk": {
        "official": "Guatemalská republika",
        "common": "Guatemala"
      },
      "spa": {
        "official": "República de Guatemala",
        "common": "Guatemala"
      },
      "swe": {
        "official": "Republiken Guatemala",
        "common": "Guatemala"
      },
      "tur": {
        "official": "Guatemala Cumhuriyeti",
        "common": "Guatemala"
      },
      "urd": {
        "official": "جمہوریہ گواتیمالا",
        "common": "گواتیمالا"
      },
      "zho": {
        "official": "危地马拉共和国",
        "common": "危地马拉"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guatemalan",
        "m": "Guatemalan"
      },
      "fra": {
        "f": "Guatémaltèque",
        "m": "Guatémaltèque"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/st.png",
      "svg": "https://flagcdn.com/st.svg"
    },
    "cca2": "ST",
    "capital": [
      "São Tomé"
    ],
    "altSpellings": [
      "ST",
      "Democratic Republic of São Tomé and Príncipe",
      "Sao Tome and Principe",
      "República Democrática de São Tomé e Príncipe"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ساو تومي وبرينسيب الديمقراطية",
        "common": "ساو تومي وبرينسيب"
      },
      "bre": {
        "official": "Republik Demokratel São Tomé ha Príncipe",
        "common": "São Tomé ha Príncipe"
      },
      "ces": {
        "official": "Demokratická republika Svatý Tomáš a Princův ostrov",
        "common": "Svatý Tomáš a Princův ostrov"
      },
      "cym": {
        "official": "Democratic Republic of São Tomé and Príncipe",
        "common": "São Tomé and Príncipe"
      },
      "deu": {
        "official": "Demokratische Republik São Tomé und Príncipe",
        "common": "São Tomé und Príncipe"
      },
      "est": {
        "official": "São Tomé ja Príncipe Demokraatlik Vabariik",
        "common": "São Tomé ja Príncipe"
      },
      "fin": {
        "official": "São Tomé ja Príncipen demokraattinen tasavalta",
        "common": "São Téme ja Príncipe"
      },
      "fra": {
        "official": "République démocratique de São Tomé et Príncipe",
        "common": "São Tomé et Príncipe"
      },
      "hrv": {
        "official": "Demokratska Republika São Tome i Principe",
        "common": "Sveti Toma i Princip"
      },
      "hun": {
        "official": "São Tomé és Príncipe Demokratikus Köztársaság",
        "common": "São Tomé és Príncipe"
      },
      "ita": {
        "official": "Repubblica democratica di São Tomé e Príncipe",
        "common": "São Tomé e Príncipe"
      },
      "jpn": {
        "official": "サントメ·プリンシペ民主共和国",
        "common": "サントメ・プリンシペ"
      },
      "kor": {
        "official": "상투메 프린시페 민주 공화국",
        "common": "상투메 프린시페"
      },
      "nld": {
        "official": "Democratische Republiek Sao Tomé en Principe",
        "common": "Sao Tomé en Principe"
      },
      "per": {
        "official": "جمهوری دموکراتیک سائوتومه و پرنسیپ",
        "common": "سائوتومه و پرنسیپ"
      },
      "pol": {
        "official": "Demokratyczna Republika Wysp Świętego Tomasza i Książęcej",
        "common": "Wyspy Świętego Tomasza i Książęca"
      },
      "por": {
        "official": "República Democrática de São Tomé e Príncipe",
        "common": "São Tomé e Príncipe"
      },
      "rus": {
        "official": "Демократическая Республика Сан-Томе и Принсипи",
        "common": "Сан-Томе и Принсипи"
      },
      "slk": {
        "official": "Demokratická republika Svätého Tomáša A princovho ostrova",
        "common": "Svätý Tomáš a Princov ostrov"
      },
      "spa": {
        "official": "República Democrática de Santo Tomé y Príncipe",
        "common": "Santo Tomé y Príncipe"
      },
      "swe": {
        "official": "Demokratiska republiken São Tomé och Príncipe",
        "common": "São Tomé och Príncipe"
      },
      "tur": {
        "official": "São Tomé ve Príncipe Demokratik Cumhuriyeti",
        "common": "São Tomé ve Príncipe"
      },
      "urd": {
        "official": "جمہوریہ ساؤ ٹومے و پرنسپے",
        "common": "ساؤ ٹومے و پرنسپے"
      },
      "zho": {
        "official": "圣多美和普林西比民主共和国",
        "common": "圣多美和普林西比"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sao Tomean",
        "m": "Sao Tomean"
      },
      "fra": {
        "f": "Santoméenne",
        "m": "Santoméen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/de.png",
      "svg": "https://flagcdn.com/de.svg"
    },
    "cca2": "DE",
    "capital": [
      "Berlin"
    ],
    "altSpellings": [
      "DE",
      "Federal Republic of Germany",
      "Bundesrepublik Deutschland"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ألمانيا الاتحادية",
        "common": "ألمانيا"
      },
      "bre": {
        "official": "Republik Kevreadel Alamagn",
        "common": "Alamagn"
      },
      "ces": {
        "official": "Spolková republika Německo",
        "common": "Německo"
      },
      "cym": {
        "official": "Federal Republic of Germany",
        "common": "Germany"
      },
      "deu": {
        "official": "Bundesrepublik Deutschland",
        "common": "Deutschland"
      },
      "est": {
        "official": "Saksamaa Liitvabariik",
        "common": "Saksamaa"
      },
      "fin": {
        "official": "Saksan liittotasavalta",
        "common": "Saksa"
      },
      "fra": {
        "official": "République fédérale d'Allemagne",
        "common": "Allemagne"
      },
      "hrv": {
        "official": "Njemačka Federativna Republika",
        "common": "Njemačka"
      },
      "hun": {
        "official": "Német Szövetségi Köztársaság",
        "common": "Németország"
      },
      "ita": {
        "official": "Repubblica federale di Germania",
        "common": "Germania"
      },
      "jpn": {
        "official": "ドイツ連邦共和国",
        "common": "ドイツ"
      },
      "kor": {
        "official": "독일 연방 공화국",
        "common": "독일"
      },
      "nld": {
        "official": "Bondsrepubliek Duitsland",
        "common": "Duitsland"
      },
      "per": {
        "official": "جمهوری فدرال آلمان",
        "common": "آلمان"
      },
      "pol": {
        "official": "Republika Federalna Niemiec",
        "common": "Niemcy"
      },
      "por": {
        "official": "República Federal da Alemanha",
        "common": "Alemanha"
      },
      "rus": {
        "official": "Федеративная Республика Германия",
        "common": "Германия"
      },
      "slk": {
        "official": "Nemecká spolková republika",
        "common": "Nemecko"
      },
      "spa": {
        "official": "República Federal de Alemania",
        "common": "Alemania"
      },
      "swe": {
        "official": "Förbundsrepubliken Tyskland",
        "common": "Tyskland"
      },
      "tur": {
        "official": "Almanya Federal Cumhuriyeti",
        "common": "Almanya"
      },
      "urd": {
        "official": "وفاقی جمہوریہ جرمنی",
        "common": "جرمنی"
      },
      "zho": {
        "official": "德意志联邦共和国",
        "common": "德国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "German",
        "m": "German"
      },
      "fra": {
        "f": "Allemande",
        "m": "Allemand"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sl.png",
      "svg": "https://flagcdn.com/sl.svg"
    },
    "cca2": "SL",
    "capital": [
      "Freetown"
    ],
    "altSpellings": [
      "SL",
      "Republic of Sierra Leone"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سيراليون",
        "common": "سيراليون"
      },
      "bre": {
        "official": "Republik Sierra Leone",
        "common": "Sierra Leone"
      },
      "ces": {
        "official": "Republika Sierra Leone",
        "common": "Sierra Leone"
      },
      "cym": {
        "official": "Republic of Sierra Leone",
        "common": "Sierra Leone"
      },
      "deu": {
        "official": "Republik Sierra Leone",
        "common": "Sierra Leone"
      },
      "est": {
        "official": "Sierra Leone Vabariik",
        "common": "Sierra Leone"
      },
      "fin": {
        "official": "Sierra Leonen tasavalta",
        "common": "Sierra Leone"
      },
      "fra": {
        "official": "République de Sierra Leone",
        "common": "Sierra Leone"
      },
      "hrv": {
        "official": "Republika Sijera Leone",
        "common": "Sijera Leone"
      },
      "hun": {
        "official": "Sierra Leone Köztársaság",
        "common": "Sierra Leone"
      },
      "ita": {
        "official": "Repubblica della Sierra Leone",
        "common": "Sierra Leone"
      },
      "jpn": {
        "official": "シエラレオネ共和国",
        "common": "シエラレオネ"
      },
      "kor": {
        "official": "시에라리온 공화국",
        "common": "시에라리온"
      },
      "nld": {
        "official": "Republiek Sierra Leone",
        "common": "Sierra Leone"
      },
      "per": {
        "official": "جمهوری سیرالئون",
        "common": "سیرالئون"
      },
      "pol": {
        "official": "Sierra Leone",
        "common": "Sierra Leone"
      },
      "por": {
        "official": "República da Serra Leoa",
        "common": "Serra Leoa"
      },
      "rus": {
        "official": "Республика Сьерра-Леоне",
        "common": "Сьерра-Леоне"
      },
      "slk": {
        "official": "Sierraleonská republika",
        "common": "Sierra Leone"
      },
      "spa": {
        "official": "República de Sierra Leona",
        "common": "Sierra Leone"
      },
      "swe": {
        "official": "Republiken Sierra Leone",
        "common": "Sierra Leone"
      },
      "tur": {
        "official": "Sierra Leone Cumhuriyeti",
        "common": "Sierra Leone"
      },
      "urd": {
        "official": "جمہوریہ سیرالیون",
        "common": "سیرالیون"
      },
      "zho": {
        "official": "塞拉利昂共和国",
        "common": "塞拉利昂"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Sierra Leonean",
        "m": "Sierra Leonean"
      },
      "fra": {
        "f": "Sierra-leonaise",
        "m": "Sierra-leonais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/td.png",
      "svg": "https://flagcdn.com/td.svg"
    },
    "cca2": "TD",
    "capital": [
      "N'Djamena"
    ],
    "altSpellings": [
      "TD",
      "Tchad",
      "Republic of Chad",
      "République du Tchad"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية تشاد",
        "common": "تشاد"
      },
      "bre": {
        "official": "Republik Tchad",
        "common": "Tchad"
      },
      "ces": {
        "official": "Čadská republika",
        "common": "Čad"
      },
      "cym": {
        "official": "Gweriniaeth Tsiad",
        "common": "Tsiad"
      },
      "deu": {
        "official": "Republik Tschad",
        "common": "Tschad"
      },
      "est": {
        "official": "Tšaadi Vabariik",
        "common": "Tšaad"
      },
      "fin": {
        "official": "Tšadin tasavalta",
        "common": "Tšad"
      },
      "fra": {
        "official": "République du Tchad",
        "common": "Tchad"
      },
      "hrv": {
        "official": "Čadu",
        "common": "Čad"
      },
      "hun": {
        "official": "Csád Köztársaság",
        "common": "Csád"
      },
      "ita": {
        "official": "Repubblica del Ciad",
        "common": "Ciad"
      },
      "jpn": {
        "official": "チャド共和国",
        "common": "チャド"
      },
      "kor": {
        "official": "차드 공화국",
        "common": "차드"
      },
      "nld": {
        "official": "Republiek Tsjaad",
        "common": "Tsjaad"
      },
      "per": {
        "official": "جمهوری چاد",
        "common": "چاد"
      },
      "pol": {
        "official": "Republika Czadu",
        "common": "Czad"
      },
      "por": {
        "official": "República do Chade",
        "common": "Chade"
      },
      "rus": {
        "official": "Республика Чад",
        "common": "Чад"
      },
      "slk": {
        "official": "Čadská republika",
        "common": "Čad"
      },
      "spa": {
        "official": "República de Chad",
        "common": "Chad"
      },
      "swe": {
        "official": "Republiken Tchad",
        "common": "Tchad"
      },
      "tur": {
        "official": "Çad Cumhuriyeti",
        "common": "Çad"
      },
      "urd": {
        "official": "جمہوریہ چاڈ",
        "common": "چاڈ"
      },
      "zho": {
        "official": "乍得共和国",
        "common": "乍得"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Chadian",
        "m": "Chadian"
      },
      "fra": {
        "f": "Tchadienne",
        "m": "Tchadien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gb.png",
      "svg": "https://flagcdn.com/gb.svg"
    },
    "cca2": "GB",
    "capital": [
      "London"
    ],
    "altSpellings": [
      "GB",
      "UK",
      "Great Britain"
    ],
    "translations": {
      "ara": {
        "official": "المملكة المتحدة لبريطانيا العظمى وايرلندا الشمالية",
        "common": "المملكة المتحدة"
      },
      "bre": {
        "official": "Rouantelezh-Unanet Breizh-Veur ha Norzhiwerzhon",
        "common": "Rouantelezh-Unanet"
      },
      "ces": {
        "official": "Spojené království Velké Británie a Severního Irska",
        "common": "Spojené království"
      },
      "cym": {
        "official": "United Kingdom of Great Britain and Northern Ireland",
        "common": "United Kingdom"
      },
      "deu": {
        "official": "Vereinigtes Königreich Großbritannien und Nordirland",
        "common": "Vereinigtes Königreich"
      },
      "est": {
        "official": "Suurbritannia ja Põhja-Iiri Ühendkuningriik",
        "common": "Suurbritannia"
      },
      "fin": {
        "official": "Ison-Britannian ja Pohjois-Irlannin yhdistynyt kuningaskunta",
        "common": "Yhdistynyt kuningaskunta"
      },
      "fra": {
        "official": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
        "common": "Royaume-Uni"
      },
      "hrv": {
        "official": "Ujedinjeno Kraljevstvo Velike Britanije i Sjeverne Irske",
        "common": "Ujedinjeno Kraljevstvo"
      },
      "hun": {
        "official": "Nagy-Britannia és Észak-Írország Egyesült Királysága",
        "common": "Egyesült Királyság"
      },
      "ita": {
        "official": "Regno Unito di Gran Bretagna e Irlanda del Nord",
        "common": "Regno Unito"
      },
      "jpn": {
        "official": "グレート·ブリテンおよび北アイルランド連合王国",
        "common": "イギリス"
      },
      "kor": {
        "official": "그레이트브리튼 북아일랜드 연합 왕국",
        "common": "영국"
      },
      "nld": {
        "official": "Verenigd Koninkrijk van Groot-Brittannië en Noord-Ierland",
        "common": "Verenigd Koninkrijk"
      },
      "per": {
        "official": "پادشاهی متحد بریتانیای کبیر و ایرلند شمالی",
        "common": "انگلیس"
      },
      "pol": {
        "official": "Zjednoczone Królestwo Wielkiej Brytanii i Irlandii Północnej",
        "common": "Zjednoczone Królestwo"
      },
      "por": {
        "official": "Reino Unido da Grã-Bretanha e Irlanda do Norte",
        "common": "Reino Unido"
      },
      "rus": {
        "official": "Соединенное Королевство Великобритании и Северной Ирландии",
        "common": "Великобритания"
      },
      "slk": {
        "official": "Spojené kráľovstvo Veľkej Británie a SevernéhoÌrska",
        "common": "Veľká Británia (Spojené kráľovstvo)"
      },
      "spa": {
        "official": "Reino Unido de Gran Bretaña e Irlanda del Norte",
        "common": "Reino Unido"
      },
      "swe": {
        "official": "Förenade konungariket Storbritannien och Nordirland",
        "common": "Storbritannien"
      },
      "tur": {
        "official": "Büyük Britanya ve Kuzey İrlanda Birleşik Krallığı",
        "common": "Birleşik Krallık"
      },
      "urd": {
        "official": "مملکتِ متحدہ برطانیہ عظمی و شمالی آئرلینڈ",
        "common": "مملکتِ متحدہ"
      },
      "zho": {
        "official": "大不列颠及北爱尔兰联合王国",
        "common": "英国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "British",
        "m": "British"
      },
      "fra": {
        "f": "Britannique",
        "m": "Britannique"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/si.png",
      "svg": "https://flagcdn.com/si.svg"
    },
    "cca2": "SI",
    "capital": [
      "Ljubljana"
    ],
    "altSpellings": [
      "SI",
      "Republic of Slovenia",
      "Republika Slovenija"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية سلوفينيا",
        "common": "سلوفينيا"
      },
      "bre": {
        "official": "Republik Slovenia",
        "common": "Slovenia"
      },
      "ces": {
        "official": "Slovinská republika",
        "common": "Slovinsko"
      },
      "cym": {
        "official": "Republic of Slovenia",
        "common": "Slovenia"
      },
      "deu": {
        "official": "Republik Slowenien",
        "common": "Slowenien"
      },
      "est": {
        "official": "Sloveenia Vabariik",
        "common": "Sloveenia"
      },
      "fin": {
        "official": "Slovenian tasavalta",
        "common": "Slovenia"
      },
      "fra": {
        "official": "République de Slovénie",
        "common": "Slovénie"
      },
      "hrv": {
        "official": "Republika Slovenija",
        "common": "Slovenija"
      },
      "hun": {
        "official": "Szlovén Köztársaság",
        "common": "Szlovénia"
      },
      "ita": {
        "official": "Repubblica di Slovenia",
        "common": "Slovenia"
      },
      "jpn": {
        "official": "スロベニア共和国",
        "common": "スロベニア"
      },
      "kor": {
        "official": "슬로베니아 공화국",
        "common": "슬로베니아"
      },
      "nld": {
        "official": "Republiek Slovenië",
        "common": "Slovenië"
      },
      "per": {
        "official": "جمهوری اسلوونی",
        "common": "اسلوونی"
      },
      "pol": {
        "official": "Republika Słowenii",
        "common": "Słowenia"
      },
      "por": {
        "official": "República da Eslovénia",
        "common": "Eslovénia"
      },
      "rus": {
        "official": "Республика Словения",
        "common": "Словения"
      },
      "slk": {
        "official": "Slovinská republika",
        "common": "Slovinsko"
      },
      "spa": {
        "official": "República de Eslovenia",
        "common": "Eslovenia"
      },
      "swe": {
        "official": "Republiken Slovenien",
        "common": "Slovenien"
      },
      "tur": {
        "official": "Slovenya Cumhuriyeti",
        "common": "Slovenya"
      },
      "urd": {
        "official": "جمہوریہ سلووینیا",
        "common": "سلووینیا"
      },
      "zho": {
        "official": "斯洛文尼亚共和国",
        "common": "斯洛文尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Slovene",
        "m": "Slovene"
      },
      "fra": {
        "f": "Slovène",
        "m": "Slovène"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/th.png",
      "svg": "https://flagcdn.com/th.svg"
    },
    "cca2": "TH",
    "capital": [
      "Bangkok"
    ],
    "altSpellings": [
      "TH",
      "Prathet",
      "Thai",
      "Kingdom of Thailand",
      "ราชอาณาจักรไทย",
      "Ratcha Anachak Thai"
    ],
    "translations": {
      "ara": {
        "official": "مملكة تايلند",
        "common": "تايلند"
      },
      "bre": {
        "official": "Rouantelezh Thailand",
        "common": "Thailand"
      },
      "ces": {
        "official": "Thajské království",
        "common": "Thajsko"
      },
      "cym": {
        "official": "Kingdom of Thailand",
        "common": "Thailand"
      },
      "deu": {
        "official": "Königreich Thailand",
        "common": "Thailand"
      },
      "est": {
        "official": "Tai Kuningriik",
        "common": "Tai"
      },
      "fin": {
        "official": "Thaimaan kuningaskunta",
        "common": "Thaimaa"
      },
      "fra": {
        "official": "Royaume de Thaïlande",
        "common": "Thaïlande"
      },
      "hrv": {
        "official": "Kraljevina Tajland",
        "common": "Tajland"
      },
      "hun": {
        "official": "Thaiföldi Királyság",
        "common": "Thaiföld"
      },
      "ita": {
        "official": "Regno di Thailandia",
        "common": "Tailandia"
      },
      "jpn": {
        "official": "タイ王国",
        "common": "タイ"
      },
      "kor": {
        "official": "타이 왕국",
        "common": "태국"
      },
      "nld": {
        "official": "Koninkrijk Thailand",
        "common": "Thailand"
      },
      "per": {
        "official": "پادشاهی تایلند",
        "common": "تایلند"
      },
      "pol": {
        "official": "Królestwo Tajlandii",
        "common": "Tajlandia"
      },
      "por": {
        "official": "Reino da Tailândia",
        "common": "Tailândia"
      },
      "rus": {
        "official": "Королевство Таиланд",
        "common": "Таиланд"
      },
      "slk": {
        "official": "Thajské kráľovstvo",
        "common": "Thajsko"
      },
      "spa": {
        "official": "Reino de Tailandia",
        "common": "Tailandia"
      },
      "swe": {
        "official": "Konungariket Thailand",
        "common": "Thailand"
      },
      "tur": {
        "official": "Tayland Krallığı",
        "common": "Tayland"
      },
      "urd": {
        "official": "مملکتِ تھائی لینڈ",
        "common": "تھائی لینڈ"
      },
      "zho": {
        "official": "泰王国",
        "common": "泰国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Thai",
        "m": "Thai"
      },
      "fra": {
        "f": "Thaïlandaise",
        "m": "Thaïlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/id.png",
      "svg": "https://flagcdn.com/id.svg"
    },
    "cca2": "ID",
    "capital": [
      "Jakarta"
    ],
    "altSpellings": [
      "ID",
      "Republic of Indonesia",
      "Republik Indonesia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية إندونيسيا",
        "common": "إندونيسيا"
      },
      "bre": {
        "official": "Republik Indonezia",
        "common": "Indonezia"
      },
      "ces": {
        "official": "Indonéská republika",
        "common": "Indonésie"
      },
      "cym": {
        "official": "Republic of Indonesia",
        "common": "Indonesia"
      },
      "deu": {
        "official": "Republik Indonesien",
        "common": "Indonesien"
      },
      "est": {
        "official": "Indoneesia Vabariik",
        "common": "Indoneesia"
      },
      "fin": {
        "official": "Indonesian tasavalta",
        "common": "Indonesia"
      },
      "fra": {
        "official": "République d'Indonésie",
        "common": "Indonésie"
      },
      "hrv": {
        "official": "Republika Indonezija",
        "common": "Indonezija"
      },
      "hun": {
        "official": "Indonéz Köztársaság",
        "common": "Indonézia"
      },
      "ita": {
        "official": "Repubblica di Indonesia",
        "common": "Indonesia"
      },
      "jpn": {
        "official": "インドネシア共和国",
        "common": "インドネシア"
      },
      "kor": {
        "official": "인도네시아 공화국",
        "common": "인도네시아"
      },
      "nld": {
        "official": "Republiek Indonesië",
        "common": "Indonesië"
      },
      "per": {
        "official": "جمهوری اندونزی",
        "common": "اندونزی"
      },
      "pol": {
        "official": "Republika Indonezji",
        "common": "Indonezja"
      },
      "por": {
        "official": "República da Indonésia",
        "common": "Indonésia"
      },
      "rus": {
        "official": "Республика Индонезия",
        "common": "Индонезия"
      },
      "slk": {
        "official": "Indonézska republika",
        "common": "Indonézia"
      },
      "spa": {
        "official": "República de Indonesia",
        "common": "Indonesia"
      },
      "swe": {
        "official": "Republiken Indonesien",
        "common": "Indonesien"
      },
      "tur": {
        "official": "Endonezya Cumhuriyeti",
        "common": "Endonezya"
      },
      "urd": {
        "official": "جمہوریہ انڈونیشیا",
        "common": "انڈونیشیا"
      },
      "zho": {
        "official": "印度尼西亚共和国",
        "common": "印度尼西亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Indonesian",
        "m": "Indonesian"
      },
      "fra": {
        "f": "Indonésienne",
        "m": "Indonésien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mp.png",
      "svg": "https://flagcdn.com/mp.svg"
    },
    "cca2": "MP",
    "capital": [
      "Saipan"
    ],
    "altSpellings": [
      "MP",
      "Commonwealth of the Northern Mariana Islands",
      "Sankattan Siha Na Islas Mariånas"
    ],
    "translations": {
      "ara": {
        "official": "كومونولث جزر ماريانا الشمالية",
        "common": "جزر ماريانا الشمالية"
      },
      "bre": {
        "official": "Kenglad Inizi Mariana an Norzh",
        "common": "Inizi Mariana an Norzh"
      },
      "ces": {
        "official": "Společenství Severních Marian",
        "common": "Severní Mariany"
      },
      "cym": {
        "official": "Commonwealth of the Northern Mariana Islands",
        "common": "Northern Mariana Islands"
      },
      "deu": {
        "official": "Commonwealth der Nördlichen Marianen",
        "common": "Nördliche Marianen"
      },
      "est": {
        "official": "Põhja-Mariaani Ühendus",
        "common": "Põhja-Mariaanid"
      },
      "fin": {
        "official": "Pohjois-Mariaanit",
        "common": "Pohjois-Mariaanit"
      },
      "fra": {
        "official": "Commonwealth des îles Mariannes du Nord",
        "common": "Îles Mariannes du Nord"
      },
      "hrv": {
        "official": "Zajednica je Sjeverni Marijanski otoci",
        "common": "Sjevernomarijanski otoci"
      },
      "hun": {
        "official": "Északi-Mariana-szigetek",
        "common": "Északi-Mariana-szigetek"
      },
      "ita": {
        "official": "Commonwealth delle Isole Marianne Settentrionali",
        "common": "Isole Marianne Settentrionali"
      },
      "jpn": {
        "official": "北マリアナ諸島",
        "common": "北マリアナ諸島"
      },
      "kor": {
        "official": "북마리아나 제도",
        "common": "북마리아나 제도"
      },
      "nld": {
        "official": "Commonwealth van de Noordelijke Marianen",
        "common": "Noordelijke Marianeneilanden"
      },
      "per": {
        "official": "جزایر ماریانای شمالی",
        "common": "جزایر ماریانای شمالی"
      },
      "pol": {
        "official": "Wspólnota Marianów Północnych",
        "common": "Mariany Północne"
      },
      "por": {
        "official": "Comunidade das Ilhas Marianas do Norte",
        "common": "Marianas Setentrionais"
      },
      "rus": {
        "official": "Содружество Северных Марианских островов",
        "common": "Северные Марианские острова"
      },
      "slk": {
        "official": "Spoločenstvo ostrovov Severné Mariány",
        "common": "Severné Mariány"
      },
      "spa": {
        "official": "Mancomunidad de las Islas Marianas del Norte",
        "common": "Islas Marianas del Norte"
      },
      "swe": {
        "official": "Nordmarianerna",
        "common": "Nordmarianerna"
      },
      "tur": {
        "official": "Kuzey Mariana Adaları Milletler Topluluğu",
        "common": "Kuzey Mariana Adaları"
      },
      "urd": {
        "official": "دولتِ مشترکہ جزائر شمالی ماریانا",
        "common": "جزائر شمالی ماریانا"
      },
      "zho": {
        "official": "北马里亚纳群岛",
        "common": "北马里亚纳群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "American",
        "m": "American"
      },
      "fra": {
        "f": "Américaine",
        "m": "Américan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gu.png",
      "svg": "https://flagcdn.com/gu.svg"
    },
    "cca2": "GU",
    "capital": [
      "Hagåtña"
    ],
    "altSpellings": [
      "GU",
      "Guåhån"
    ],
    "translations": {
      "ara": {
        "official": "غوام",
        "common": "غوام"
      },
      "bre": {
        "official": "Guam",
        "common": "Guam"
      },
      "ces": {
        "official": "Guam",
        "common": "Guam"
      },
      "cym": {
        "official": "Guam",
        "common": "Guam"
      },
      "deu": {
        "official": "Guam",
        "common": "Guam"
      },
      "est": {
        "official": "Guami ala",
        "common": "Guam"
      },
      "fin": {
        "official": "Guam",
        "common": "Guam"
      },
      "fra": {
        "official": "Guam",
        "common": "Guam"
      },
      "hrv": {
        "official": "Guam",
        "common": "Guam"
      },
      "hun": {
        "official": "Guam",
        "common": "Guam"
      },
      "ita": {
        "official": "Guam",
        "common": "Guam"
      },
      "jpn": {
        "official": "グアム",
        "common": "グアム"
      },
      "kor": {
        "official": "괌",
        "common": "괌"
      },
      "nld": {
        "official": "Guam",
        "common": "Guam"
      },
      "per": {
        "official": "گوآم",
        "common": "گوآم"
      },
      "pol": {
        "official": "Terytorium Guamu",
        "common": "Guam"
      },
      "por": {
        "official": "Guam",
        "common": "Guam"
      },
      "rus": {
        "official": "Гуам",
        "common": "Гуам"
      },
      "slk": {
        "official": "Guam",
        "common": "Guam"
      },
      "spa": {
        "official": "Guam",
        "common": "Guam"
      },
      "swe": {
        "official": "Guam",
        "common": "Guam"
      },
      "tur": {
        "official": "Guam Toprağı",
        "common": "Guam"
      },
      "urd": {
        "official": "گوام",
        "common": "گوام"
      },
      "zho": {
        "official": "关岛",
        "common": "关岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guamanian",
        "m": "Guamanian"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fi.png",
      "svg": "https://flagcdn.com/fi.svg"
    },
    "cca2": "FI",
    "capital": [
      "Helsinki"
    ],
    "altSpellings": [
      "FI",
      "Suomi",
      "Republic of Finland",
      "Suomen tasavalta",
      "Republiken Finland"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية فنلندا",
        "common": "فنلندا"
      },
      "bre": {
        "official": "Republik Finland",
        "common": "Finland"
      },
      "ces": {
        "official": "Finská republika",
        "common": "Finsko"
      },
      "cym": {
        "official": "Republic of Finland",
        "common": "Finland"
      },
      "deu": {
        "official": "Republik Finnland",
        "common": "Finnland"
      },
      "est": {
        "official": "Soome Vabariik",
        "common": "Soome"
      },
      "fin": {
        "official": "Suomen tasavalta",
        "common": "Suomi"
      },
      "fra": {
        "official": "République de Finlande",
        "common": "Finlande"
      },
      "hrv": {
        "official": "Republika Finska",
        "common": "Finska"
      },
      "hun": {
        "official": "Finn Köztársaság",
        "common": "Finnország"
      },
      "ita": {
        "official": "Repubblica di Finlandia",
        "common": "Finlandia"
      },
      "jpn": {
        "official": "フィンランド共和国",
        "common": "フィンランド"
      },
      "kor": {
        "official": "핀란드 공화국",
        "common": "핀란드"
      },
      "nld": {
        "official": "Republiek Finland",
        "common": "Finland"
      },
      "per": {
        "official": "جمهوری فنلاند",
        "common": "فنلاند"
      },
      "pol": {
        "official": "Republika Finlandii",
        "common": "Finlandia"
      },
      "por": {
        "official": "República da Finlândia",
        "common": "Finlândia"
      },
      "rus": {
        "official": "Финляндская Республика",
        "common": "Финляндия"
      },
      "slk": {
        "official": "Fínska republika",
        "common": "Fínsko"
      },
      "spa": {
        "official": "República de Finlandia",
        "common": "Finlandia"
      },
      "swe": {
        "official": "Republiken Finland",
        "common": "Finland"
      },
      "tur": {
        "official": "Finlandiya Cumhuriyeti",
        "common": "Finlandiya"
      },
      "urd": {
        "official": "جمہوریہ فن لینڈ",
        "common": "فن لینڈ"
      },
      "zho": {
        "official": "芬兰共和国",
        "common": "芬兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Finnish",
        "m": "Finnish"
      },
      "fra": {
        "f": "Finlandaise",
        "m": "Finlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/yt.png",
      "svg": "https://flagcdn.com/yt.svg"
    },
    "cca2": "YT",
    "capital": [
      "Mamoudzou"
    ],
    "altSpellings": [
      "YT",
      "Department of Mayotte",
      "Département de Mayotte"
    ],
    "translations": {
      "ara": {
        "official": "مايوت",
        "common": "مايوت"
      },
      "bre": {
        "official": "Departamant Mayotte",
        "common": "Mayotte"
      },
      "ces": {
        "official": "Mayotte",
        "common": "Mayotte"
      },
      "cym": {
        "official": "Department of Mayotte",
        "common": "Mayotte"
      },
      "deu": {
        "official": "Übersee-Département Mayotte",
        "common": "Mayotte"
      },
      "est": {
        "official": "Mayotte",
        "common": "Mayotte"
      },
      "fin": {
        "official": "Mayotte",
        "common": "Mayotte"
      },
      "fra": {
        "official": "Département de Mayotte",
        "common": "Mayotte"
      },
      "hrv": {
        "official": "Odjel Mayotte",
        "common": "Mayotte"
      },
      "hun": {
        "official": "Mayotte",
        "common": "Mayotte"
      },
      "ita": {
        "official": "Dipartimento di Mayotte",
        "common": "Mayotte"
      },
      "jpn": {
        "official": "マヨット科",
        "common": "マヨット"
      },
      "kor": {
        "official": "마요트",
        "common": "마요트"
      },
      "nld": {
        "official": "Afdeling Mayotte",
        "common": "Mayotte"
      },
      "per": {
        "official": "مجموعه شهرستانی مایوت",
        "common": "مایوت"
      },
      "pol": {
        "official": "Majotta",
        "common": "Majotta"
      },
      "por": {
        "official": "Departamento de Mayotte",
        "common": "Mayotte"
      },
      "rus": {
        "official": "Департамент Майотта",
        "common": "Майотта"
      },
      "slk": {
        "official": "Department Mayotte",
        "common": "Mayotte"
      },
      "spa": {
        "official": "Departamento de Mayotte",
        "common": "Mayotte"
      },
      "swe": {
        "official": "Departementsområdet Mayotte",
        "common": "Mayotte"
      },
      "tur": {
        "official": "Mayotte",
        "common": "Mayotte"
      },
      "urd": {
        "official": "مایوٹ",
        "common": "مایوٹ"
      },
      "zho": {
        "official": "马约特",
        "common": "马约特"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mahoran",
        "m": "Mahoran"
      },
      "fra": {
        "f": "Mahoraise",
        "m": "Mahorais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gi.png",
      "svg": "https://flagcdn.com/gi.svg"
    },
    "cca2": "GI",
    "capital": [
      "Gibraltar"
    ],
    "altSpellings": [
      "GI"
    ],
    "translations": {
      "ara": {
        "official": "جبل طارق",
        "common": "جبل طارق"
      },
      "bre": {
        "official": "Jibraltar",
        "common": "Jibraltar"
      },
      "ces": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "cym": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "deu": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "est": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "fin": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "fra": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "hrv": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "hun": {
        "official": "Gibraltár",
        "common": "Gibraltár"
      },
      "ita": {
        "official": "Gibilterra",
        "common": "Gibilterra"
      },
      "jpn": {
        "official": "ジブラルタル",
        "common": "ジブラルタル"
      },
      "kor": {
        "official": "지브롤터",
        "common": "지브롤터"
      },
      "nld": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "per": {
        "official": "جبل طارق",
        "common": "جبل طارق"
      },
      "pol": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "por": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "rus": {
        "official": "Гибралтар",
        "common": "Гибралтар"
      },
      "slk": {
        "official": "Gibraltár",
        "common": "Gibraltár"
      },
      "spa": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "swe": {
        "official": "Gibraltar",
        "common": "Gibraltar"
      },
      "tur": {
        "official": "Cebelitarık",
        "common": "Cebelitarık"
      },
      "urd": {
        "official": "جبل الطارق",
        "common": "جبل الطارق"
      },
      "zho": {
        "official": "直布罗陀",
        "common": "直布罗陀"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Gibraltar",
        "m": "Gibraltar"
      },
      "fra": {
        "f": "Gibraltarienne",
        "m": "Gibraltarien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tm.png",
      "svg": "https://flagcdn.com/tm.svg"
    },
    "cca2": "TM",
    "capital": [
      "Ashgabat"
    ],
    "altSpellings": [
      "TM"
    ],
    "translations": {
      "ara": {
        "official": "تركمانستان",
        "common": "تركمانستان"
      },
      "bre": {
        "official": "Republik Turkmenistan",
        "common": "Turkmenistan"
      },
      "ces": {
        "official": "Turkmenistán",
        "common": "Turkmenistán"
      },
      "cym": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "deu": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "est": {
        "official": "Türkmenistan",
        "common": "Türkmenistan"
      },
      "fin": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "fra": {
        "official": "Turkménistan",
        "common": "Turkménistan"
      },
      "hrv": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "hun": {
        "official": "Türkmén Köztársaság",
        "common": "Türkmenisztán"
      },
      "ita": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "jpn": {
        "official": "トルクメニスタン",
        "common": "トルクメニスタン"
      },
      "kor": {
        "official": "투르크메니스탄",
        "common": "투르크메니스탄"
      },
      "nld": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "per": {
        "official": "جمهوری خلق ترکمنستان",
        "common": "ترکمنستان"
      },
      "pol": {
        "official": "Republika Turkmenistanu",
        "common": "Turkmenistan"
      },
      "por": {
        "official": "Turcomenistão",
        "common": "Turquemenistão"
      },
      "rus": {
        "official": "Туркменистан",
        "common": "Туркмения"
      },
      "slk": {
        "official": "Turkménsko",
        "common": "Turkménsko"
      },
      "spa": {
        "official": "Turkmenistán",
        "common": "Turkmenistán"
      },
      "swe": {
        "official": "Turkmenistan",
        "common": "Turkmenistan"
      },
      "tur": {
        "official": "Türkmenistan",
        "common": "Türkmenistan"
      },
      "urd": {
        "official": "ترکمانستان",
        "common": "ترکمانستان"
      },
      "zho": {
        "official": "土库曼斯坦",
        "common": "土库曼斯坦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Turkmen",
        "m": "Turkmen"
      },
      "fra": {
        "f": "Turkmène",
        "m": "Turkmène"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/xk.png",
      "svg": "https://flagcdn.com/xk.svg"
    },
    "cca2": "XK",
    "capital": [
      "Pristina"
    ],
    "altSpellings": [
      "XK",
      "Република Косово"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كوسوفو",
        "common": "كوسوفو"
      },
      "bre": {
        "official": "Republik Kosovo",
        "common": "Kosovo"
      },
      "ces": {
        "official": "Kosovská republika",
        "common": "Kosovo"
      },
      "cym": {
        "official": "Republic of Kosovo",
        "common": "Kosovo"
      },
      "deu": {
        "official": "Republik Kosovo",
        "common": "Kosovo"
      },
      "est": {
        "official": "Kosovo Vabariik",
        "common": "Kosovo"
      },
      "fin": {
        "official": "Kosovon tasavalta",
        "common": "Kosovo"
      },
      "fra": {
        "official": "République du Kosovo",
        "common": "Kosovo"
      },
      "hrv": {
        "official": "Republika Kosovo",
        "common": "Kosovo"
      },
      "hun": {
        "official": "Koszovó",
        "common": "Koszovó"
      },
      "ita": {
        "official": "Repubblica del Kosovo",
        "common": "Kosovo"
      },
      "kor": {
        "official": "코소보 공화국",
        "common": "코소보"
      },
      "nld": {
        "official": "Republiek Kosovo",
        "common": "Kosovo"
      },
      "per": {
        "official": "جمهوری کوزوو",
        "common": "کوزوو"
      },
      "pol": {
        "official": "Republika Kosowa",
        "common": "Kosowo"
      },
      "por": {
        "official": "República do Kosovo",
        "common": "Kosovo"
      },
      "rus": {
        "official": "Республика Косово",
        "common": "Республика Косово"
      },
      "slk": {
        "official": "Republika Kosovo",
        "common": "Kosovo"
      },
      "spa": {
        "official": "República de Kosovo",
        "common": "Kosovo"
      },
      "swe": {
        "official": "Republiken Kosovo",
        "common": "Kosovo"
      },
      "tur": {
        "official": "Kosova Cumhuriyeti",
        "common": "Kosova"
      },
      "urd": {
        "official": "جمہوریہ کوسووہ",
        "common": "کوسووہ"
      },
      "zho": {
        "official": "科索沃共和国",
        "common": "科索沃"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kosovar",
        "m": "Kosovar"
      },
      "fra": {
        "f": "Kosovare",
        "m": "Kosovar"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ci.png",
      "svg": "https://flagcdn.com/ci.svg"
    },
    "cca2": "CI",
    "capital": [
      "Yamoussoukro"
    ],
    "altSpellings": [
      "CI",
      "Côte d'Ivoire",
      "Ivory Coast",
      "Republic of Côte d'Ivoire",
      "République de Côte d'Ivoire"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ساحل العاج",
        "common": "ساحل العاج"
      },
      "bre": {
        "official": "Republik Aod an Olifant",
        "common": "Aod an Olifant"
      },
      "ces": {
        "official": "Republika Pobřeží slonoviny",
        "common": "Pobřeží slonoviny"
      },
      "cym": {
        "official": "Republic of Côte d'Ivoire",
        "common": "Ivory Coast"
      },
      "deu": {
        "official": "Republik Côte d'Ivoire",
        "common": "Elfenbeinküste"
      },
      "est": {
        "official": "Côte d’Ivoire’i Vabariik",
        "common": "Elevandiluurannik"
      },
      "fin": {
        "official": "Norsunluurannikon tasavalta",
        "common": "Norsunluurannikko"
      },
      "fra": {
        "official": "République de Côte d' Ivoire",
        "common": "Côte d'Ivoire"
      },
      "hrv": {
        "official": "Republika Côte d'Ivoire",
        "common": "Obala Bjelokosti"
      },
      "hun": {
        "official": "Elefántcsontparti Köztársaság",
        "common": "Elefántcsontpart"
      },
      "ita": {
        "official": "Repubblica della Costa d'Avorio",
        "common": "Costa d'Avorio"
      },
      "jpn": {
        "official": "コートジボワール共和国",
        "common": "コートジボワール"
      },
      "kor": {
        "official": "코트디부아르 공화국",
        "common": "코트디부아르"
      },
      "nld": {
        "official": "Republiek Ivoorkust",
        "common": "Ivoorkust"
      },
      "per": {
        "official": "جمهوری ساحل عاج",
        "common": "ساحل عاج"
      },
      "pol": {
        "official": "Republika WybrzeŻa Kości Słoniowej",
        "common": "WybrzeŻe Kości Słoniowej"
      },
      "por": {
        "official": "República da Côte d'Ivoire",
        "common": "Costa do Marfim"
      },
      "rus": {
        "official": "Республика Кот-д'Ивуаре",
        "common": "Кот-д’Ивуар"
      },
      "slk": {
        "official": "Republika Pobrežie Slonoviny",
        "common": "Pobržie Slonoviny"
      },
      "spa": {
        "official": "República de Côte d'Ivoire",
        "common": "Costa de Marfil"
      },
      "swe": {
        "official": "Republiken Elfenbenskusten",
        "common": "Elfenbenskusten"
      },
      "tur": {
        "official": "Fildişi Sahili",
        "common": "Fildişi Sahili"
      },
      "urd": {
        "official": "جمہوریہ کوت دیواغ",
        "common": "آئیوری کوسٹ"
      },
      "zho": {
        "official": "科特迪瓦共和国",
        "common": "科特迪瓦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ivorian",
        "m": "Ivorian"
      },
      "fra": {
        "f": "Ivoirienne",
        "m": "Ivoirien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ma.png",
      "svg": "https://flagcdn.com/ma.svg"
    },
    "cca2": "MA",
    "capital": [
      "Rabat"
    ],
    "altSpellings": [
      "MA",
      "Kingdom of Morocco",
      "Al-Mamlakah al-Maġribiyah"
    ],
    "translations": {
      "ara": {
        "official": "المملكة المغربية",
        "common": "المغرب"
      },
      "bre": {
        "official": "Rouantelezh Maroko",
        "common": "Maroko"
      },
      "ces": {
        "official": "Marocké království",
        "common": "Maroko"
      },
      "cym": {
        "official": "Kingdom of Morocco",
        "common": "Morocco"
      },
      "deu": {
        "official": "Königreich Marokko",
        "common": "Marokko"
      },
      "est": {
        "official": "Maroko Kuningriik",
        "common": "Maroko"
      },
      "fin": {
        "official": "Marokon kuningaskunta",
        "common": "Marokko"
      },
      "fra": {
        "official": "Royaume du Maroc",
        "common": "Maroc"
      },
      "hrv": {
        "official": "Kraljevina Maroko",
        "common": "Maroko"
      },
      "hun": {
        "official": "Marokkói Királyság",
        "common": "Marokkó"
      },
      "ita": {
        "official": "Regno del Marocco",
        "common": "Marocco"
      },
      "jpn": {
        "official": "モロッコ王国",
        "common": "モロッコ"
      },
      "kor": {
        "official": "모로코 왕국",
        "common": "모로코"
      },
      "nld": {
        "official": "Koninkrijk Marokko",
        "common": "Marokko"
      },
      "per": {
        "official": "پادشاهی مراکش",
        "common": "مراکش"
      },
      "pol": {
        "official": "Królestwo Marokańskie",
        "common": "Maroko"
      },
      "por": {
        "official": "Reino de Marrocos",
        "common": "Marrocos"
      },
      "rus": {
        "official": "Королевство Марокко",
        "common": "Марокко"
      },
      "slk": {
        "official": "Marocké kniežatstvo",
        "common": "Maroko"
      },
      "spa": {
        "official": "Reino de Marruecos",
        "common": "Marruecos"
      },
      "swe": {
        "official": "Konungariket Marocko",
        "common": "Marocko"
      },
      "tur": {
        "official": "Fas Krallığı",
        "common": "Fas"
      },
      "urd": {
        "official": "مملکتِ مراکش",
        "common": "مراکش"
      },
      "zho": {
        "official": "摩洛哥王国",
        "common": "摩洛哥"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Moroccan",
        "m": "Moroccan"
      },
      "fra": {
        "f": "Marocaine",
        "m": "Marocain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/do.png",
      "svg": "https://flagcdn.com/do.svg"
    },
    "cca2": "DO",
    "capital": [
      "Santo Domingo"
    ],
    "altSpellings": [
      "DO"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الدومينيكان",
        "common": "جمهورية الدومينيكان"
      },
      "bre": {
        "official": "Republik Dominikan",
        "common": "Republik Dominikan"
      },
      "ces": {
        "official": "Dominikánská republika",
        "common": "Dominikánská republika"
      },
      "cym": {
        "official": "Gweriniaeth Dominica",
        "common": "Gweriniaeth Dominica"
      },
      "deu": {
        "official": "Dominikanische Republik",
        "common": "Dominikanische Republik"
      },
      "est": {
        "official": "Dominikaani Vabariik",
        "common": "Dominikaani Vabariik"
      },
      "fin": {
        "official": "Dominikaaninen tasavalta",
        "common": "Dominikaaninen tasavalta"
      },
      "fra": {
        "official": "République Dominicaine",
        "common": "République dominicaine"
      },
      "hrv": {
        "official": "Dominikanska Republika",
        "common": "Dominikanska Republika"
      },
      "hun": {
        "official": "Dominikai Köztársaság",
        "common": "Dominikai Köztársaság"
      },
      "ita": {
        "official": "Repubblica Dominicana",
        "common": "Repubblica Dominicana"
      },
      "jpn": {
        "official": "ドミニカ共和国",
        "common": "ドミニカ共和国"
      },
      "kor": {
        "official": "도미니카 공화국",
        "common": "도미니카 공화국"
      },
      "nld": {
        "official": "Dominicaanse Republiek",
        "common": "Dominicaanse Republiek"
      },
      "per": {
        "official": "جمهوری دومینیکن",
        "common": "جمهوری دومینیکن"
      },
      "pol": {
        "official": "Republika Dominikańska",
        "common": "Dominikana"
      },
      "por": {
        "official": "República Dominicana",
        "common": "República Dominicana"
      },
      "rus": {
        "official": "Доминиканская Республика",
        "common": "Доминиканская Республика"
      },
      "slk": {
        "official": "Dominikánska republika",
        "common": "Dominikánska republika"
      },
      "spa": {
        "official": "República Dominicana",
        "common": "República Dominicana"
      },
      "swe": {
        "official": "Dominikanska republiken",
        "common": "Dominikanska republiken"
      },
      "tur": {
        "official": "Dominik Cumhuriyeti",
        "common": "Dominik Cumhuriyeti"
      },
      "urd": {
        "official": "جمہوریہ ڈومینیکن",
        "common": "ڈومینیکن"
      },
      "zho": {
        "official": "多明尼加共和国",
        "common": "多明尼加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Dominican",
        "m": "Dominican"
      },
      "fra": {
        "f": "Dominicaine",
        "m": "Dominicain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gh.png",
      "svg": "https://flagcdn.com/gh.svg"
    },
    "cca2": "GH",
    "capital": [
      "Accra"
    ],
    "altSpellings": [
      "GH"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غانا",
        "common": "غانا"
      },
      "bre": {
        "official": "Republik Ghana",
        "common": "Ghana"
      },
      "ces": {
        "official": "Ghanská republika",
        "common": "Ghana"
      },
      "cym": {
        "official": "Republic of Ghana",
        "common": "Ghana"
      },
      "deu": {
        "official": "Republik Ghana",
        "common": "Ghana"
      },
      "est": {
        "official": "Ghana Vabariik",
        "common": "Ghana"
      },
      "fin": {
        "official": "Ghanan tasavalta",
        "common": "Ghana"
      },
      "fra": {
        "official": "République du Ghana",
        "common": "Ghana"
      },
      "hrv": {
        "official": "Republika Gana",
        "common": "Gana"
      },
      "hun": {
        "official": "Ghánai Köztársaság",
        "common": "Ghána"
      },
      "ita": {
        "official": "Repubblica del Ghana",
        "common": "Ghana"
      },
      "jpn": {
        "official": "ガーナ共和国",
        "common": "ガーナ"
      },
      "kor": {
        "official": "가나 공화국",
        "common": "가나"
      },
      "nld": {
        "official": "Republiek Ghana",
        "common": "Ghana"
      },
      "per": {
        "official": "جمهوری غنا",
        "common": "غنا"
      },
      "pol": {
        "official": "Republika Ghany",
        "common": "Ghana"
      },
      "por": {
        "official": "República do Gana",
        "common": "Gana"
      },
      "rus": {
        "official": "Республика Гана",
        "common": "Гана"
      },
      "slk": {
        "official": "Ghanská republika",
        "common": "Ghana"
      },
      "spa": {
        "official": "República de Ghana",
        "common": "Ghana"
      },
      "swe": {
        "official": "Republiken Ghana",
        "common": "Ghana"
      },
      "tur": {
        "official": "Gana Cumhuriyeti",
        "common": "Gana"
      },
      "urd": {
        "official": "جمہوریہ گھانا",
        "common": "گھانا"
      },
      "zho": {
        "official": "加纳共和国",
        "common": "加纳"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ghanaian",
        "m": "Ghanaian"
      },
      "fra": {
        "f": "Ghanéenne",
        "m": "Ghanéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/va.png",
      "svg": "https://flagcdn.com/va.svg"
    },
    "cca2": "VA",
    "capital": [
      "Vatican City"
    ],
    "altSpellings": [
      "VA",
      "Holy See (Vatican City State)",
      "Vatican City State",
      "Stato della Città del Vaticano"
    ],
    "translations": {
      "ara": {
        "official": "دولة مدينة الفاتيكان",
        "common": "مدينة الفاتيكان"
      },
      "bre": {
        "official": "Riez Keoded ar Vatikan",
        "common": "Vatikan"
      },
      "ces": {
        "official": "Městský stát Vatikán",
        "common": "Vatikán"
      },
      "cym": {
        "official": "Vatican City State",
        "common": "Vatican City"
      },
      "deu": {
        "official": "Staat Vatikanstadt",
        "common": "Vatikanstadt"
      },
      "est": {
        "official": "Vatikani Linnriik",
        "common": "Vatikan"
      },
      "fin": {
        "official": "Vatikaanin kaupunkivaltio",
        "common": "Vatikaani"
      },
      "fra": {
        "official": "Cité du Vatican",
        "common": "Cité du Vatican"
      },
      "hrv": {
        "official": "Vatikan",
        "common": "Vatikan"
      },
      "hun": {
        "official": "Vatikán Állam",
        "common": "Vatikán"
      },
      "ita": {
        "official": "Città del Vaticano",
        "common": "Città del Vaticano"
      },
      "jpn": {
        "official": "バチカン市国の状態",
        "common": "バチカン市国"
      },
      "kor": {
        "official": "바티칸 시국",
        "common": "바티칸"
      },
      "nld": {
        "official": "Vaticaanstad",
        "common": "Vaticaanstad"
      },
      "per": {
        "official": "دولت‌شهر واتیکان",
        "common": "واتیکان"
      },
      "pol": {
        "official": "Państwo Watykańskie",
        "common": "Watykan"
      },
      "por": {
        "official": "Cidade do Vaticano",
        "common": "Cidade do Vaticano"
      },
      "rus": {
        "official": "Город-государство Ватикан",
        "common": "Ватикан"
      },
      "slk": {
        "official": "Svätá stolica (Vatikánsky mestský štát",
        "common": "Vatikán"
      },
      "spa": {
        "official": "Ciudad del Vaticano",
        "common": "Ciudad del Vaticano"
      },
      "swe": {
        "official": "Vatikanstaten",
        "common": "Vatikanstaten"
      },
      "tur": {
        "official": "Vatikan Şehir Devleti",
        "common": "Vatikan"
      },
      "urd": {
        "official": "ویٹیکن سٹی",
        "common": "ویٹیکن سٹی"
      },
      "zho": {
        "official": "梵蒂冈城国",
        "common": "梵蒂冈"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Vatican",
        "m": "Vatican"
      },
      "fra": {
        "f": "Vaticane",
        "m": "Vatican"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/je.png",
      "svg": "https://flagcdn.com/je.svg"
    },
    "cca2": "JE",
    "capital": [
      "Saint Helier"
    ],
    "altSpellings": [
      "JE",
      "Bailiwick of Jersey",
      "Bailliage de Jersey",
      "Bailliage dé Jèrri"
    ],
    "translations": {
      "ara": {
        "official": "جيرزي",
        "common": "جيرزي"
      },
      "bre": {
        "official": "Jerzenez",
        "common": "Jerzenez"
      },
      "ces": {
        "official": "Rychtářství Jersey",
        "common": "Jersey"
      },
      "cym": {
        "official": "Bailiwick of Jersey",
        "common": "Jersey"
      },
      "deu": {
        "official": "Vogtei Jersey",
        "common": "Jersey"
      },
      "est": {
        "official": "Jersey foogtkond",
        "common": "Jersey"
      },
      "fin": {
        "official": "Jersey",
        "common": "Jersey"
      },
      "fra": {
        "official": "Bailliage de Jersey",
        "common": "Jersey"
      },
      "hrv": {
        "official": "Struka od Jersey",
        "common": "Jersey"
      },
      "hun": {
        "official": "Jersey",
        "common": "Jersey"
      },
      "ita": {
        "official": "Baliato di Jersey",
        "common": "Isola di Jersey"
      },
      "jpn": {
        "official": "ジャージの得意分野",
        "common": "ジャージー"
      },
      "kor": {
        "official": "저지 섬",
        "common": "저지 섬"
      },
      "nld": {
        "official": "Baljuwschap Jersey",
        "common": "Jersey"
      },
      "per": {
        "official": "جرزی",
        "common": "جرزی"
      },
      "pol": {
        "official": "Jersey",
        "common": "Jersey"
      },
      "por": {
        "official": "Bailiado de Jersey",
        "common": "Jersey"
      },
      "rus": {
        "official": "Коронное владение Джерси",
        "common": "Джерси"
      },
      "slk": {
        "official": "Bailiwick Jersey",
        "common": "Jersey"
      },
      "spa": {
        "official": "Bailía de Jersey",
        "common": "Jersey"
      },
      "swe": {
        "official": "Jersey",
        "common": "Jersey"
      },
      "tur": {
        "official": "Jersey",
        "common": "Jersey"
      },
      "urd": {
        "official": "جرزی",
        "common": "جرزی"
      },
      "zho": {
        "official": "泽西岛",
        "common": "泽西岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Channel Islander",
        "m": "Channel Islander"
      },
      "fra": {
        "f": "Jersiaise",
        "m": "Jersiais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tr.png",
      "svg": "https://flagcdn.com/tr.svg"
    },
    "cca2": "TR",
    "capital": [
      "Ankara"
    ],
    "altSpellings": [
      "TR",
      "Turkiye",
      "Republic of Turkey",
      "Türkiye Cumhuriyeti"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية التركية",
        "common": "تركيا"
      },
      "bre": {
        "official": "Republik Turkia",
        "common": "Turkia"
      },
      "ces": {
        "official": "Turecká republika",
        "common": "Turecko"
      },
      "cym": {
        "official": "Republic of Turkey",
        "common": "Turkey"
      },
      "deu": {
        "official": "Republik Türkei",
        "common": "Türkei"
      },
      "est": {
        "official": "Türgi Vabariik",
        "common": "Türgi"
      },
      "fin": {
        "official": "Turkin tasavalta",
        "common": "Turkki"
      },
      "fra": {
        "official": "République de Turquie",
        "common": "Turquie"
      },
      "hrv": {
        "official": "Republika Turska",
        "common": "Turska"
      },
      "hun": {
        "official": "Török Köztársaság",
        "common": "Törökország"
      },
      "ita": {
        "official": "Repubblica di Turchia",
        "common": "Turchia"
      },
      "jpn": {
        "official": "トルコ共和国",
        "common": "トルコ"
      },
      "kor": {
        "official": "터키 공화국",
        "common": "터키"
      },
      "nld": {
        "official": "Republiek Turkije",
        "common": "Turkije"
      },
      "per": {
        "official": "جمهوری ترکیه",
        "common": "ترکیه"
      },
      "pol": {
        "official": "Republika Turcji",
        "common": "Turcja"
      },
      "por": {
        "official": "República da Turquia",
        "common": "Turquia"
      },
      "rus": {
        "official": "Республика Турции",
        "common": "Турция"
      },
      "slk": {
        "official": "Turecká republika",
        "common": "Turecko"
      },
      "spa": {
        "official": "República de Turquía",
        "common": "Turquía"
      },
      "swe": {
        "official": "Republiken Turkiet",
        "common": "Turkiet"
      },
      "tur": {
        "official": "Türkiye Cumhuriyeti",
        "common": "Türkiye"
      },
      "urd": {
        "official": "جمہوریہ ترکی",
        "common": "ترکی"
      },
      "zho": {
        "official": "土耳其共和国",
        "common": "土耳其"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Turkish",
        "m": "Turkish"
      },
      "fra": {
        "f": "Turque",
        "m": "Turc"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tv.png",
      "svg": "https://flagcdn.com/tv.svg"
    },
    "cca2": "TV",
    "capital": [
      "Funafuti"
    ],
    "altSpellings": [
      "TV"
    ],
    "translations": {
      "ara": {
        "official": "توفالو",
        "common": "توفالو"
      },
      "bre": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "ces": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "cym": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "deu": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "est": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "fin": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "fra": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "hrv": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "hun": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "ita": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "jpn": {
        "official": "ツバル",
        "common": "ツバル"
      },
      "kor": {
        "official": "투발루",
        "common": "투발루"
      },
      "nld": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "per": {
        "official": "تووالو",
        "common": "تووالو"
      },
      "pol": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "por": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "rus": {
        "official": "Тувалу",
        "common": "Тувалу"
      },
      "slk": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "spa": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "swe": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "tur": {
        "official": "Tuvalu",
        "common": "Tuvalu"
      },
      "urd": {
        "official": "تووالو",
        "common": "تووالو"
      },
      "zho": {
        "official": "图瓦卢",
        "common": "图瓦卢"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tuvaluan",
        "m": "Tuvaluan"
      },
      "fra": {
        "f": "Tuvaluane",
        "m": "Tuvaluan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ir.png",
      "svg": "https://flagcdn.com/ir.svg"
    },
    "cca2": "IR",
    "capital": [
      "Tehran"
    ],
    "altSpellings": [
      "IR",
      "Islamic Republic of Iran",
      "Iran, Islamic Republic of",
      "Jomhuri-ye Eslāmi-ye Irān"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية إيران الإسلامية",
        "common": "إيران"
      },
      "bre": {
        "official": "Republik Islamek Iran",
        "common": "Iran"
      },
      "ces": {
        "official": "Islámská republika Írán",
        "common": "Írán"
      },
      "cym": {
        "official": "Islamic Republic of Iran",
        "common": "Iran"
      },
      "deu": {
        "official": "Islamische Republik Iran",
        "common": "Iran"
      },
      "est": {
        "official": "Iraani Islamivabariik",
        "common": "Iraan"
      },
      "fin": {
        "official": "Iranin islamilainen tasavalta",
        "common": "Iran"
      },
      "fra": {
        "official": "République islamique d'Iran",
        "common": "Iran"
      },
      "hrv": {
        "official": "Islamska Republika Iran",
        "common": "Iran"
      },
      "hun": {
        "official": "Iráni Iszlám Köztársaság",
        "common": "Irán"
      },
      "ita": {
        "official": "Repubblica islamica dell'Iran",
        "common": "Iran"
      },
      "jpn": {
        "official": "イラン·イスラム共和国",
        "common": "イラン・イスラム共和国"
      },
      "kor": {
        "official": "이란 이슬람 공화국",
        "common": "이란"
      },
      "nld": {
        "official": "Islamitische Republiek Iran",
        "common": "Iran"
      },
      "pol": {
        "official": "Islamska Republika Iranu",
        "common": "Iran"
      },
      "por": {
        "official": "República Islâmica do Irã",
        "common": "Irão"
      },
      "rus": {
        "official": "Исламская Республика Иран",
        "common": "Иран"
      },
      "slk": {
        "official": "Iránska islamská republika",
        "common": "Irán"
      },
      "spa": {
        "official": "República Islámica de Irán",
        "common": "Iran"
      },
      "swe": {
        "official": "Islamiska republiken Iran",
        "common": "Iran"
      },
      "tur": {
        "official": "İran İslam Cumhuriyeti",
        "common": "İran"
      },
      "urd": {
        "official": "جمہوریہ ایران",
        "common": "ایران"
      },
      "zho": {
        "official": "伊朗伊斯兰共和国",
        "common": "伊朗"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Iranian",
        "m": "Iranian"
      },
      "fra": {
        "f": "Iranienne",
        "m": "Iranien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/eg.png",
      "svg": "https://flagcdn.com/eg.svg"
    },
    "cca2": "EG",
    "capital": [
      "Cairo"
    ],
    "altSpellings": [
      "EG",
      "Arab Republic of Egypt"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مصر العربية",
        "common": "مصر"
      },
      "bre": {
        "official": "Republik arab Egipt",
        "common": "Egipt"
      },
      "ces": {
        "official": "Egyptská arabská republika",
        "common": "Egypt"
      },
      "cym": {
        "official": "Gweriniaeth Arabaidd yr Aifft",
        "common": "Yr Aifft"
      },
      "deu": {
        "official": "Arabische Republik Ägypten",
        "common": "Ägypten"
      },
      "est": {
        "official": "Egiptuse Araabia Vabariik",
        "common": "Egiptus"
      },
      "fin": {
        "official": "Egyptin arabitasavalta",
        "common": "Egypti"
      },
      "fra": {
        "official": "République arabe d'Égypte",
        "common": "Égypte"
      },
      "hrv": {
        "official": "Arapska Republika Egipat",
        "common": "Egipat"
      },
      "hun": {
        "official": "Egyiptomi Arab Köztársaság",
        "common": "Egyiptom"
      },
      "ita": {
        "official": "Repubblica araba d'Egitto",
        "common": "Egitto"
      },
      "jpn": {
        "official": "エジプト·アラブ共和国",
        "common": "エジプト"
      },
      "kor": {
        "official": "이집트 아랍 공화국",
        "common": "이집트"
      },
      "nld": {
        "official": "Arabische Republiek Egypte",
        "common": "Egypte"
      },
      "per": {
        "official": "جمهوری عربی مصر",
        "common": "مصر"
      },
      "pol": {
        "official": "Arabska Republika Egiptu",
        "common": "Egipt"
      },
      "por": {
        "official": "República Árabe do Egipto",
        "common": "Egito"
      },
      "rus": {
        "official": "Арабская Республика Египет",
        "common": "Египет"
      },
      "slk": {
        "official": "Egyptská arabská republika",
        "common": "Egypt"
      },
      "spa": {
        "official": "República Árabe de Egipto",
        "common": "Egipto"
      },
      "swe": {
        "official": "Arabrepubliken Egypten",
        "common": "Egypten"
      },
      "tur": {
        "official": "Mısır Arap Cumhuriyeti",
        "common": "Mısır"
      },
      "urd": {
        "official": "مصری عرب جمہوریہ",
        "common": "مصر"
      },
      "zho": {
        "official": "阿拉伯埃及共和国",
        "common": "埃及"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Egyptian",
        "m": "Egyptian"
      },
      "fra": {
        "f": "Égyptienne",
        "m": "Égyptien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/dk.png",
      "svg": "https://flagcdn.com/dk.svg"
    },
    "cca2": "DK",
    "capital": [
      "Copenhagen"
    ],
    "altSpellings": [
      "DK",
      "Danmark",
      "Kingdom of Denmark",
      "Kongeriget Danmark"
    ],
    "translations": {
      "ara": {
        "official": "مملكة الدنمارك",
        "common": "الدنمارك"
      },
      "bre": {
        "official": "Rouantelezh Danmark",
        "common": "Danmark"
      },
      "ces": {
        "official": "Dánské království",
        "common": "Dánsko"
      },
      "cym": {
        "official": "Teyrnas Denmarc",
        "common": "Denmarc"
      },
      "deu": {
        "official": "Königreich Dänemark",
        "common": "Dänemark"
      },
      "est": {
        "official": "Taani Kuningriik",
        "common": "Taani"
      },
      "fin": {
        "official": "Tanskan kuningaskunta",
        "common": "Tanska"
      },
      "fra": {
        "official": "Royaume du Danemark",
        "common": "Danemark"
      },
      "hrv": {
        "official": "Kraljevina Danska",
        "common": "Danska"
      },
      "hun": {
        "official": "Dán Királyság",
        "common": "Dánia"
      },
      "ita": {
        "official": "Regno di Danimarca",
        "common": "Danimarca"
      },
      "jpn": {
        "official": "デンマーク王国",
        "common": "デンマーク"
      },
      "kor": {
        "official": "덴마크 왕국",
        "common": "덴마크"
      },
      "nld": {
        "official": "Koninkrijk Denemarken",
        "common": "Denemarken"
      },
      "per": {
        "official": "پادشاهی دانمارک",
        "common": "دانمارک"
      },
      "pol": {
        "official": "Królestwo Danii",
        "common": "Dania"
      },
      "por": {
        "official": "Reino da Dinamarca",
        "common": "Dinamarca"
      },
      "rus": {
        "official": "Королевство Дания",
        "common": "Дания"
      },
      "slk": {
        "official": "Dánske kráľovstvo",
        "common": "Dánsko"
      },
      "spa": {
        "official": "Reino de Dinamarca",
        "common": "Dinamarca"
      },
      "swe": {
        "official": "Konungariket Danmark",
        "common": "Danmark"
      },
      "tur": {
        "official": "Danimarka Krallığı",
        "common": "Danimarka"
      },
      "urd": {
        "official": "مملکتِ ڈنمارک",
        "common": "ڈنمارک"
      },
      "zho": {
        "official": "丹麦王国",
        "common": "丹麦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Danish",
        "m": "Danish"
      },
      "fra": {
        "f": "Danoise",
        "m": "Danois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ke.png",
      "svg": "https://flagcdn.com/ke.svg"
    },
    "cca2": "KE",
    "capital": [
      "Nairobi"
    ],
    "altSpellings": [
      "KE",
      "Republic of Kenya",
      "Jamhuri ya Kenya"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كينيا",
        "common": "كينيا"
      },
      "bre": {
        "official": "Republik Kenya",
        "common": "Kenya"
      },
      "ces": {
        "official": "Keňská republika",
        "common": "Keňa"
      },
      "cym": {
        "official": "Republic of Kenya",
        "common": "Kenya"
      },
      "deu": {
        "official": "Republik Kenia",
        "common": "Kenia"
      },
      "est": {
        "official": "Keenia Vabariik",
        "common": "Keenia"
      },
      "fin": {
        "official": "Kenian tasavalta",
        "common": "Kenia"
      },
      "fra": {
        "official": "République du Kenya",
        "common": "Kenya"
      },
      "hrv": {
        "official": "Republika Kenija",
        "common": "Kenija"
      },
      "hun": {
        "official": "Kenyai Köztársaság",
        "common": "Kenya"
      },
      "ita": {
        "official": "Repubblica del Kenya",
        "common": "Kenya"
      },
      "jpn": {
        "official": "ケニア共和国",
        "common": "ケニア"
      },
      "kor": {
        "official": "케냐 공화국",
        "common": "케냐"
      },
      "nld": {
        "official": "Republiek Kenia",
        "common": "Kenia"
      },
      "per": {
        "official": "جمهوری کنیا",
        "common": "کنیا"
      },
      "pol": {
        "official": "Republika Kenii",
        "common": "Kenia"
      },
      "por": {
        "official": "República do Quénia",
        "common": "Quénia"
      },
      "rus": {
        "official": "Республика Кения",
        "common": "Кения"
      },
      "slk": {
        "official": "Kenská republika",
        "common": "Keňa"
      },
      "spa": {
        "official": "República de Kenya",
        "common": "Kenia"
      },
      "swe": {
        "official": "Republiken Kenya",
        "common": "Kenya"
      },
      "tur": {
        "official": "Kenya Cumhuriyeti",
        "common": "Kenya"
      },
      "urd": {
        "official": "جمہوریہ کینیا",
        "common": "کینیا"
      },
      "zho": {
        "official": "肯尼亚共和国",
        "common": "肯尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kenyan",
        "m": "Kenyan"
      },
      "fra": {
        "f": "Kényane",
        "m": "Kényan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bg.png",
      "svg": "https://flagcdn.com/bg.svg"
    },
    "cca2": "BG",
    "capital": [
      "Sofia"
    ],
    "altSpellings": [
      "BG",
      "Republic of Bulgaria",
      "Република България"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بلغاريا",
        "common": "بلغاريا"
      },
      "bre": {
        "official": "Republik Bulgaria",
        "common": "Bulgaria"
      },
      "ces": {
        "official": "Bulharská republika",
        "common": "Bulharsko"
      },
      "cym": {
        "official": "Gweriniaeth Bwlgaria",
        "common": "Bwlgaria"
      },
      "deu": {
        "official": "Republik Bulgarien",
        "common": "Bulgarien"
      },
      "est": {
        "official": "Bulgaaria Vabariik",
        "common": "Bulgaaria"
      },
      "fin": {
        "official": "Bulgarian tasavalta",
        "common": "Bulgaria"
      },
      "fra": {
        "official": "République de Bulgarie",
        "common": "Bulgarie"
      },
      "hrv": {
        "official": "Republika Bugarska",
        "common": "Bugarska"
      },
      "hun": {
        "official": "Bolgár Köztársaság",
        "common": "Bulgária"
      },
      "ita": {
        "official": "Repubblica di Bulgaria",
        "common": "Bulgaria"
      },
      "jpn": {
        "official": "ブルガリア共和国",
        "common": "ブルガリア"
      },
      "kor": {
        "official": "불가리아 공화국",
        "common": "불가리아"
      },
      "nld": {
        "official": "Republiek Bulgarije",
        "common": "Bulgarije"
      },
      "per": {
        "official": "جمهوری بلغارستان",
        "common": "بلغارستان"
      },
      "pol": {
        "official": "Republika Bułgarii",
        "common": "Bułgaria"
      },
      "por": {
        "official": "República da Bulgária",
        "common": "Bulgária"
      },
      "rus": {
        "official": "Республика Болгария",
        "common": "Болгария"
      },
      "slk": {
        "official": "Bulharská republika",
        "common": "Bulharsko"
      },
      "spa": {
        "official": "República de Bulgaria",
        "common": "Bulgaria"
      },
      "swe": {
        "official": "Republiken Bulgarien",
        "common": "Bulgarien"
      },
      "tur": {
        "official": "Bulgaristan Cumhuriyeti",
        "common": "Bulgaristan"
      },
      "urd": {
        "official": "جمہوریہ بلغاریہ",
        "common": "بلغاریہ"
      },
      "zho": {
        "official": "保加利亚共和国",
        "common": "保加利亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bulgarian",
        "m": "Bulgarian"
      },
      "fra": {
        "f": "Bulgare",
        "m": "Bulgare"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/zw.png",
      "svg": "https://flagcdn.com/zw.svg"
    },
    "cca2": "ZW",
    "capital": [
      "Harare"
    ],
    "altSpellings": [
      "ZW",
      "Republic of Zimbabwe"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية زيمبابوي",
        "common": "زيمبابوي"
      },
      "bre": {
        "official": "Republik Zimbabwe",
        "common": "Zimbabwe"
      },
      "ces": {
        "official": "Zimbabwská republika",
        "common": "Zimbabwe"
      },
      "cym": {
        "official": "Republic of Zimbabwe",
        "common": "Zimbabwe"
      },
      "deu": {
        "official": "Republik Simbabwe",
        "common": "Simbabwe"
      },
      "est": {
        "official": "Zimbabwe Vabariik",
        "common": "Zimbabwe"
      },
      "fin": {
        "official": "Zimbabwen tasavalta",
        "common": "Zimbabwe"
      },
      "fra": {
        "official": "République du Zimbabwe",
        "common": "Zimbabwe"
      },
      "hrv": {
        "official": "Republika Zimbabve",
        "common": "Zimbabve"
      },
      "hun": {
        "official": "Zimbabwei Köztársaság",
        "common": "Zimbabwe"
      },
      "ita": {
        "official": "Repubblica dello Zimbabwe",
        "common": "Zimbabwe"
      },
      "jpn": {
        "official": "ジンバブエ共和国",
        "common": "ジンバブエ"
      },
      "kor": {
        "official": "짐바브웨 공화국",
        "common": "짐바브웨"
      },
      "nld": {
        "official": "Republiek Zimbabwe",
        "common": "Zimbabwe"
      },
      "per": {
        "official": "جمهوری زیمبابوه",
        "common": "زیمبابوه"
      },
      "pol": {
        "official": "Republika Zimbabwe",
        "common": "Zimbabwe"
      },
      "por": {
        "official": "República do Zimbabwe",
        "common": "Zimbabwe"
      },
      "rus": {
        "official": "Республика Зимбабве",
        "common": "Зимбабве"
      },
      "slk": {
        "official": "Zimbabwianska republika",
        "common": "Zimbabwe"
      },
      "spa": {
        "official": "República de Zimbabue",
        "common": "Zimbabue"
      },
      "swe": {
        "official": "Republiken Zimbabwe",
        "common": "Zimbabwe"
      },
      "tur": {
        "official": "Zimbabve Cumhuriyeti",
        "common": "Zimbabve"
      },
      "urd": {
        "official": "جمہوریہ زمبابوے",
        "common": "زمبابوے"
      },
      "zho": {
        "official": "津巴布韦共和国",
        "common": "津巴布韦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Zimbabwean",
        "m": "Zimbabwean"
      },
      "fra": {
        "f": "Zimbabwéenne",
        "m": "Zimbabwéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ao.png",
      "svg": "https://flagcdn.com/ao.svg"
    },
    "cca2": "AO",
    "capital": [
      "Luanda"
    ],
    "altSpellings": [
      "AO",
      "República de Angola",
      "ʁɛpublika de an'ɡɔla"
    ],
    "translations": {
      "ara": {
        "official": "أنغولا",
        "common": "جمهورية أنغولا"
      },
      "bre": {
        "official": "Republik Angola",
        "common": "Angola"
      },
      "ces": {
        "official": "Angolská republika",
        "common": "Angola"
      },
      "cym": {
        "official": "Gweriniaeth Angola",
        "common": "Angola"
      },
      "deu": {
        "official": "Republik Angola",
        "common": "Angola"
      },
      "est": {
        "official": "Angola Vabariik",
        "common": "Angola"
      },
      "fin": {
        "official": "Angolan tasavalta",
        "common": "Angola"
      },
      "fra": {
        "official": "République d'Angola",
        "common": "Angola"
      },
      "hrv": {
        "official": "Republika Angola",
        "common": "Angola"
      },
      "hun": {
        "official": "Angola",
        "common": "Angola"
      },
      "ita": {
        "official": "Repubblica dell'Angola",
        "common": "Angola"
      },
      "jpn": {
        "official": "アンゴラ共和国",
        "common": "アンゴラ"
      },
      "kor": {
        "official": "앙골라 공화국",
        "common": "앙골라"
      },
      "nld": {
        "official": "Republiek Angola",
        "common": "Angola"
      },
      "per": {
        "official": "جمهوری آنگولا",
        "common": "آنگولا"
      },
      "pol": {
        "official": "Republika Angoli",
        "common": "Angola"
      },
      "por": {
        "official": "República de Angola",
        "common": "Angola"
      },
      "rus": {
        "official": "Республика Ангола",
        "common": "Ангола"
      },
      "slk": {
        "official": "Angolská republika",
        "common": "Angola"
      },
      "spa": {
        "official": "República de Angola",
        "common": "Angola"
      },
      "swe": {
        "official": "Republiken Angola",
        "common": "Angola"
      },
      "tur": {
        "official": "Angola Cumhuriyeti",
        "common": "Angola"
      },
      "urd": {
        "official": "جمہوریہ انگولہ",
        "common": "انگولہ"
      },
      "zho": {
        "official": "安哥拉共和国",
        "common": "安哥拉"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Angolan",
        "m": "Angolan"
      },
      "fra": {
        "f": "Angolaise",
        "m": "Angolais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ng.png",
      "svg": "https://flagcdn.com/ng.svg"
    },
    "cca2": "NG",
    "capital": [
      "Abuja"
    ],
    "altSpellings": [
      "NG",
      "Nijeriya",
      "Naíjíríà",
      "Federal Republic of Nigeria"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية نيجيريا الاتحادية",
        "common": "نيجيريا"
      },
      "bre": {
        "official": "Republik Kevreadel Nigeria",
        "common": "Nigeria"
      },
      "ces": {
        "official": "Nigerijská federativní republika",
        "common": "Nigérie"
      },
      "cym": {
        "official": "Federal Republic of Nigeria",
        "common": "Nigeria"
      },
      "deu": {
        "official": "Bundesrepublik Nigeria",
        "common": "Nigeria"
      },
      "est": {
        "official": "Nigeeria Liitvabariik",
        "common": "Nigeeria"
      },
      "fin": {
        "official": "Nigerian liittotasavalta",
        "common": "Nigeria"
      },
      "fra": {
        "official": "République fédérale du Nigeria",
        "common": "Nigéria"
      },
      "hrv": {
        "official": "Savezna Republika Nigerija",
        "common": "Nigerija"
      },
      "hun": {
        "official": "Nigéria",
        "common": "Nigéria"
      },
      "ita": {
        "official": "Repubblica federale di Nigeria",
        "common": "Nigeria"
      },
      "jpn": {
        "official": "ナイジェリア連邦共和国",
        "common": "ナイジェリア"
      },
      "kor": {
        "official": "나이지리아 연방 공화국",
        "common": "나이지리아"
      },
      "nld": {
        "official": "Federale Republiek Nigeria",
        "common": "Nigeria"
      },
      "per": {
        "official": "جمهوری فدرال نیجریه",
        "common": "نیجریه"
      },
      "pol": {
        "official": "Federalna Republika Nigerii",
        "common": "Nigeria"
      },
      "por": {
        "official": "República Federal da Nigéria",
        "common": "Nigéria"
      },
      "rus": {
        "official": "Федеративная Республика Нигерия",
        "common": "Нигерия"
      },
      "slk": {
        "official": "Nigérijská federatívna republika",
        "common": "Nigéria"
      },
      "spa": {
        "official": "República Federal de Nigeria",
        "common": "Nigeria"
      },
      "swe": {
        "official": "Förbundsrepubliken Nigeria",
        "common": "Nigeria"
      },
      "tur": {
        "official": "Nijerya Federal Cumhuriyeti",
        "common": "Nijerya"
      },
      "urd": {
        "official": "وفاقی جمہوریہ نائجیریا",
        "common": "نائجیریا"
      },
      "zho": {
        "official": "尼日利亚联邦共和国",
        "common": "尼日利亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Nigerian",
        "m": "Nigerian"
      },
      "fra": {
        "f": "Nigériane",
        "m": "Nigérian"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pl.png",
      "svg": "https://flagcdn.com/pl.svg"
    },
    "cca2": "PL",
    "capital": [
      "Warsaw"
    ],
    "altSpellings": [
      "PL",
      "Republic of Poland",
      "Rzeczpospolita Polska"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية البولندية",
        "common": "بولندا"
      },
      "bre": {
        "official": "Republik Polonia",
        "common": "Polonia"
      },
      "ces": {
        "official": "Polská republika",
        "common": "Polsko"
      },
      "cym": {
        "official": "Republic of Poland",
        "common": "Poland"
      },
      "deu": {
        "official": "Republik Polen",
        "common": "Polen"
      },
      "est": {
        "official": "Poola Vabariik",
        "common": "Poola"
      },
      "fin": {
        "official": "Puolan tasavalta",
        "common": "Puola"
      },
      "fra": {
        "official": "République de Pologne",
        "common": "Pologne"
      },
      "hrv": {
        "official": "Republika Poljska",
        "common": "Poljska"
      },
      "hun": {
        "official": "Lengyel Köztársaság",
        "common": "Lengyelország"
      },
      "ita": {
        "official": "Repubblica di Polonia",
        "common": "Polonia"
      },
      "jpn": {
        "official": "ポーランド共和国",
        "common": "ポーランド"
      },
      "kor": {
        "official": "폴란드 공화국",
        "common": "폴란드"
      },
      "nld": {
        "official": "Republiek Polen",
        "common": "Polen"
      },
      "per": {
        "official": "جمهوری لهستان",
        "common": "لهستان"
      },
      "pol": {
        "official": "Rzeczpospolita Polska",
        "common": "Polska"
      },
      "por": {
        "official": "República da Polónia",
        "common": "Polónia"
      },
      "rus": {
        "official": "Республика Польша",
        "common": "Польша"
      },
      "slk": {
        "official": "Poľská republika",
        "common": "Poľsko"
      },
      "spa": {
        "official": "República de Polonia",
        "common": "Polonia"
      },
      "swe": {
        "official": "Republiken Polen",
        "common": "Polen"
      },
      "tur": {
        "official": "Polonya Cumhuriyeti",
        "common": "Polonya"
      },
      "urd": {
        "official": "جمہوریہ پولینڈ",
        "common": "پولینڈ"
      },
      "zho": {
        "official": "波兰共和国",
        "common": "波兰"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Polish",
        "m": "Polish"
      },
      "fra": {
        "f": "Polonaise",
        "m": "Polonais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sn.png",
      "svg": "https://flagcdn.com/sn.svg"
    },
    "cca2": "SN",
    "capital": [
      "Dakar"
    ],
    "altSpellings": [
      "SN",
      "Republic of Senegal",
      "République du Sénégal"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية السنغال",
        "common": "السنغال"
      },
      "bre": {
        "official": "Republik Senegal",
        "common": "Senegal"
      },
      "ces": {
        "official": "Senegalská republika",
        "common": "Senegal"
      },
      "cym": {
        "official": "Republic of Senegal",
        "common": "Senegal"
      },
      "deu": {
        "official": "Republik Senegal",
        "common": "Senegal"
      },
      "est": {
        "official": "Senegali Vabariik",
        "common": "Senegal"
      },
      "fin": {
        "official": "Senegalin tasavalta",
        "common": "Senegal"
      },
      "fra": {
        "official": "République du Sénégal",
        "common": "Sénégal"
      },
      "hrv": {
        "official": "Republika Senegal",
        "common": "Senegal"
      },
      "hun": {
        "official": "Szenegáli Köztársaság",
        "common": "Szenegál"
      },
      "ita": {
        "official": "Repubblica del Senegal",
        "common": "Senegal"
      },
      "jpn": {
        "official": "セネガル共和国",
        "common": "セネガル"
      },
      "kor": {
        "official": "세네갈 공화국",
        "common": "세네갈"
      },
      "nld": {
        "official": "Republiek Senegal",
        "common": "Senegal"
      },
      "per": {
        "official": "جمهوری سنگال",
        "common": "سنگال"
      },
      "pol": {
        "official": "Senegal",
        "common": "Senegal"
      },
      "por": {
        "official": "República do Senegal",
        "common": "Senegal"
      },
      "rus": {
        "official": "Республика Сенегал",
        "common": "Сенегал"
      },
      "slk": {
        "official": "Senegalská republika",
        "common": "Senegal"
      },
      "spa": {
        "official": "República de Senegal",
        "common": "Senegal"
      },
      "swe": {
        "official": "Republiken Senegal",
        "common": "Senegal"
      },
      "tur": {
        "official": "Senegal Cumhuriyeti",
        "common": "Senegal"
      },
      "urd": {
        "official": "جمہوریہ سینیگال",
        "common": "سینیگال"
      },
      "zho": {
        "official": "塞内加尔共和国",
        "common": "塞内加尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Senegalese",
        "m": "Senegalese"
      },
      "fra": {
        "f": "Sénégalaise",
        "m": "Sénégalais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sh.png",
      "svg": "https://flagcdn.com/sh.svg"
    },
    "cca2": "SH",
    "capital": [
      "Jamestown"
    ],
    "altSpellings": [
      "Saint Helena",
      "St. Helena, Ascension and Tristan da Cunha"
    ],
    "translations": {
      "ara": {
        "official": "سانت هيلينا وأسينشين وتريستان دا كونا",
        "common": "سانت هيلينا وأسينشين وتريستان دا كونا"
      },
      "bre": {
        "official": "Saint Helena, Ascension ha Tristan da Cunha",
        "common": "Saint Helena, Ascension ha Tristan da Cunha"
      },
      "ces": {
        "official": "Svatá Helena, Ascension a Tristan da Cunha",
        "common": "Svatá Helena, Ascension a Tristan da Cunha"
      },
      "cym": {
        "official": "Saint Helena, Ascension and Tristan da Cunha",
        "common": "Saint Helena, Ascension and Tristan da Cunha"
      },
      "deu": {
        "official": "Sankt Helena, Ascension und Tristan da Cunha",
        "common": "St. Helena, Ascension und Tristan da Cunha"
      },
      "est": {
        "official": "Saint Helena, Ascension ja Tristan da Cunha",
        "common": "Saint Helena, Ascension ja Tristan da Cunha"
      },
      "fin": {
        "official": "Saint Helena, Ascension ja Tristan da Cunha",
        "common": "Saint Helena, Ascension ja Tristan da Cunha"
      },
      "fra": {
        "official": "Sainte-Hélène, Ascension et Tristan da Cunha",
        "common": "Sainte-Hélène, Ascension et Tristan da Cunha"
      },
      "hrv": {
        "official": "Sveta Helena",
        "common": "Sveta Helena"
      },
      "hun": {
        "official": "Szent Ilona",
        "common": "Szent Ilona-sziget"
      },
      "ita": {
        "official": "Sant'Elena, Ascensione e Tristan da Cunha",
        "common": "Sant'Elena, Ascensione e Tristan da Cunha"
      },
      "jpn": {
        "official": "セントヘレナ・アセンションおよびトリスタンダクーニャ",
        "common": "セントヘレナ・アセンションおよびトリスタンダクーニャ"
      },
      "kor": {
        "official": "세인트헬레나",
        "common": "세인트헬레나"
      },
      "nld": {
        "official": "Sint-Helena, Ascension en Tristan da Cunha",
        "common": "Sint-Helena, Ascension en Tristan da Cunha"
      },
      "per": {
        "official": "سنت هلن",
        "common": "سنت هلن"
      },
      "pol": {
        "official": "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
        "common": "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha"
      },
      "por": {
        "official": "Santa Helena, Ascensão e Tristão da Cunha",
        "common": "Santa Helena, Ascensão e Tristão da Cunha"
      },
      "rus": {
        "official": "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
        "common": "Острова Святой Елены, Вознесения и Тристан-да-Кунья"
      },
      "slk": {
        "official": "Svätá Helena (zámorské územie)",
        "common": "Svätá Helena (zámorské územie)"
      },
      "spa": {
        "official": "Santa Elena, Ascensión y Tristán de Acuña",
        "common": "Santa Elena, Ascensión y Tristán de Acuña"
      },
      "swe": {
        "official": "Sankta Helena",
        "common": "Sankta Helena"
      },
      "tur": {
        "official": "Saint Helena",
        "common": "Saint Helena"
      },
      "urd": {
        "official": "سینٹ ہلینا، اسینشن و ترسٹان دا کونیا",
        "common": "سینٹ ہلینا، اسینشن و ترسٹان دا کونیا"
      },
      "zho": {
        "official": "圣赫勒拿、阿森松和特里斯坦-达库尼亚",
        "common": "圣赫勒拿、阿森松和特里斯坦-达库尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint Helenian",
        "m": "Saint Helenian"
      },
      "fra": {
        "f": "Sainte-Hélénoise",
        "m": "Sainte-Hélènois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/lb.png",
      "svg": "https://flagcdn.com/lb.svg"
    },
    "cca2": "LB",
    "capital": [
      "Beirut"
    ],
    "altSpellings": [
      "LB",
      "Lebanese Republic",
      "Al-Jumhūrīyah Al-Libnānīyah"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية اللبنانية",
        "common": "لبنان"
      },
      "bre": {
        "official": "Republik Liban",
        "common": "Liban"
      },
      "ces": {
        "official": "Libanonská republika",
        "common": "Libanon"
      },
      "cym": {
        "official": "Lebanese Republic",
        "common": "Lebanon"
      },
      "deu": {
        "official": "Libanesische Republik",
        "common": "Libanon"
      },
      "est": {
        "official": "Liibanoni Vabariik",
        "common": "Liibanon"
      },
      "fin": {
        "official": "Libanonin tasavalta",
        "common": "Libanon"
      },
      "fra": {
        "official": "République libanaise",
        "common": "Liban"
      },
      "hrv": {
        "official": "Libanonska Republika",
        "common": "Libanon"
      },
      "hun": {
        "official": "Libanoni Köztársaság",
        "common": "Libanon"
      },
      "ita": {
        "official": "Repubblica libanese",
        "common": "Libano"
      },
      "jpn": {
        "official": "レバノン共和国",
        "common": "レバノン"
      },
      "kor": {
        "official": "레바논 공화국",
        "common": "레바논"
      },
      "nld": {
        "official": "Libanese Republiek",
        "common": "Libanon"
      },
      "per": {
        "official": "جمهوری لبنان",
        "common": "لبنان"
      },
      "pol": {
        "official": "Republika Libańska",
        "common": "Liban"
      },
      "por": {
        "official": "República Libanesa",
        "common": "Líbano"
      },
      "rus": {
        "official": "Ливанская Республика",
        "common": "Ливан"
      },
      "slk": {
        "official": "Libanonská republika",
        "common": "Libanon"
      },
      "spa": {
        "official": "República Libanesa",
        "common": "Líbano"
      },
      "swe": {
        "official": "Republiken Libanon",
        "common": "Libanon"
      },
      "tur": {
        "official": "Lübnan Cumhuriyeti",
        "common": "Lübnan"
      },
      "urd": {
        "official": "جمہوریہ لبنان",
        "common": "لبنان"
      },
      "zho": {
        "official": "黎巴嫩共和国",
        "common": "黎巴嫩"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Lebanese",
        "m": "Lebanese"
      },
      "fra": {
        "f": "Libanaise",
        "m": "Libanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ws.png",
      "svg": "https://flagcdn.com/ws.svg"
    },
    "cca2": "WS",
    "capital": [
      "Apia"
    ],
    "altSpellings": [
      "WS",
      "Independent State of Samoa",
      "Malo Saʻoloto Tutoʻatasi o Sāmoa"
    ],
    "translations": {
      "ara": {
        "official": "دولة ساموا المستقلة",
        "common": "ساموا"
      },
      "bre": {
        "official": "Stad Dizalc'h Samoa",
        "common": "Samoa"
      },
      "ces": {
        "official": "Nezávislý stát Samoa",
        "common": "Samoa"
      },
      "cym": {
        "official": "Independent State of Samoa",
        "common": "Samoa"
      },
      "deu": {
        "official": "Unabhängige Staat Samoa",
        "common": "Samoa"
      },
      "est": {
        "official": "Samoa Iseseisvusriik",
        "common": "Samoa"
      },
      "fin": {
        "official": "Samoan itsenäinen valtio",
        "common": "Samoa"
      },
      "fra": {
        "official": "Samoa",
        "common": "Samoa"
      },
      "hrv": {
        "official": "Nezavisna Država Samoa",
        "common": "Samoa"
      },
      "hun": {
        "official": "Szamoai Független Állam",
        "common": "Szamoa"
      },
      "ita": {
        "official": "Stato indipendente di Samoa",
        "common": "Samoa"
      },
      "jpn": {
        "official": "サモア独立国",
        "common": "サモア"
      },
      "kor": {
        "official": "사모아 독립국",
        "common": "사모아"
      },
      "nld": {
        "official": "Onafhankelijke Staat Samoa",
        "common": "Samoa"
      },
      "per": {
        "official": "ایالت مستقل ساموآ",
        "common": "ساموآ"
      },
      "pol": {
        "official": "Niezależne Państwo Samoa",
        "common": "Samoa"
      },
      "por": {
        "official": "Estado Independente de Samoa",
        "common": "Samoa"
      },
      "rus": {
        "official": "Независимое Государство Самоа",
        "common": "Самоа"
      },
      "slk": {
        "official": "Nezávislý štátSamoa",
        "common": "Samoa"
      },
      "spa": {
        "official": "Estado Independiente de Samoa",
        "common": "Samoa"
      },
      "swe": {
        "official": "Självständiga staten Samoa",
        "common": "Samoa"
      },
      "tur": {
        "official": "Bağımsız Samoa Devleti",
        "common": "Bağımsız Samoa Devleti"
      },
      "urd": {
        "official": "آزاد سلطنتِ ساموا",
        "common": "سامووا"
      },
      "zho": {
        "official": "萨摩亚独立国",
        "common": "萨摩亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Samoan",
        "m": "Samoan"
      },
      "fra": {
        "f": "Samoane",
        "m": "Samoan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ph.png",
      "svg": "https://flagcdn.com/ph.svg"
    },
    "cca2": "PH",
    "capital": [
      "Manila"
    ],
    "altSpellings": [
      "PH",
      "Republic of the Philippines",
      "Repúblika ng Pilipinas"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الفلبين",
        "common": "الفلبين"
      },
      "bre": {
        "official": "Republik Filipinez",
        "common": "Filipinez"
      },
      "ces": {
        "official": "Filipínská republika",
        "common": "Filipíny"
      },
      "cym": {
        "official": "Republic of the Philippines",
        "common": "Philippines"
      },
      "deu": {
        "official": "Republik der Philippinen",
        "common": "Philippinen"
      },
      "est": {
        "official": "Filipiini Vabariik",
        "common": "Filipiinid"
      },
      "fin": {
        "official": "Filippiinien tasavalta",
        "common": "Filippiinit"
      },
      "fra": {
        "official": "République des Philippines",
        "common": "Philippines"
      },
      "hrv": {
        "official": "Republika Filipini",
        "common": "Filipini"
      },
      "hun": {
        "official": "Fülöp-szigeteki Köztársaság",
        "common": "Fülöp-szigetek"
      },
      "ita": {
        "official": "Repubblica delle Filippine",
        "common": "Filippine"
      },
      "jpn": {
        "official": "フィリピン共和国",
        "common": "フィリピン"
      },
      "kor": {
        "official": "필리핀 공화국",
        "common": "필리핀"
      },
      "nld": {
        "official": "Republiek der Filipijnen",
        "common": "Filipijnen"
      },
      "per": {
        "official": "جمهوری فیلیپین",
        "common": "فیلیپین"
      },
      "pol": {
        "official": "Republika Filipin",
        "common": "Filipiny"
      },
      "por": {
        "official": "República das Filipinas",
        "common": "Filipinas"
      },
      "rus": {
        "official": "Республика Филиппины",
        "common": "Филиппины"
      },
      "slk": {
        "official": "Filipínska republika",
        "common": "Filipíny"
      },
      "spa": {
        "official": "República de las Filipinas",
        "common": "Filipinas"
      },
      "swe": {
        "official": "Republiken Filippinerna",
        "common": "Filippinerna"
      },
      "tur": {
        "official": "Filipinler Cumhuriyeti",
        "common": "Filipinler"
      },
      "urd": {
        "official": "جمہوریہ فلپائن",
        "common": "فلپائن"
      },
      "zho": {
        "official": "菲律宾共和国",
        "common": "菲律宾"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Filipino",
        "m": "Filipino"
      },
      "fra": {
        "f": "Philippine",
        "m": "Philippin"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gg.png",
      "svg": "https://flagcdn.com/gg.svg"
    },
    "cca2": "GG",
    "capital": [
      "St. Peter Port"
    ],
    "altSpellings": [
      "GG",
      "Bailiwick of Guernsey",
      "Bailliage de Guernesey"
    ],
    "translations": {
      "ara": {
        "official": "غيرنزي",
        "common": "غيرنزي"
      },
      "bre": {
        "official": "Gwernenez",
        "common": "Gwernenez"
      },
      "ces": {
        "official": "Rychtářství Guernsey",
        "common": "Guernsey"
      },
      "cym": {
        "official": "Bailiwick of Guernsey",
        "common": "Guernsey"
      },
      "deu": {
        "official": "Vogtei Guernsey",
        "common": "Guernsey"
      },
      "est": {
        "official": "Guernsey foogtkond",
        "common": "Guernsey"
      },
      "fin": {
        "official": "Guernsey",
        "common": "Guernsey"
      },
      "fra": {
        "official": "Bailliage de Guernesey",
        "common": "Guernesey"
      },
      "hrv": {
        "official": "Struka Guernsey",
        "common": "Guernsey"
      },
      "hun": {
        "official": "Guernsey",
        "common": "Guernsey"
      },
      "ita": {
        "official": "Baliato di Guernsey",
        "common": "Guernsey"
      },
      "jpn": {
        "official": "ガーンジーの得意分野",
        "common": "ガーンジー"
      },
      "kor": {
        "official": "건지 섬",
        "common": "건지 섬"
      },
      "nld": {
        "official": "Baljuwschap Guernsey",
        "common": "Guernsey"
      },
      "per": {
        "official": "گرنزی",
        "common": "گرنزی"
      },
      "pol": {
        "official": "Baliwat Guernsey",
        "common": "Guernsey"
      },
      "por": {
        "official": "Bailiado de Guernsey",
        "common": "Guernsey"
      },
      "rus": {
        "official": "Коронное владение Гернси",
        "common": "Гернси"
      },
      "slk": {
        "official": "Guernsey",
        "common": "Guernsey"
      },
      "spa": {
        "official": "Bailía de Guernsey",
        "common": "Guernsey"
      },
      "swe": {
        "official": "Guernsey",
        "common": "Guernsey"
      },
      "tur": {
        "official": "Guernsey Muhafızlığı",
        "common": "Guernsey"
      },
      "urd": {
        "official": "گرنزی رودبار",
        "common": "گرنزی"
      },
      "zho": {
        "official": "根西岛",
        "common": "根西岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Channel Islander",
        "m": "Channel Islander"
      },
      "fra": {
        "f": "Guernesiaise",
        "m": "Guernesiais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sv.png",
      "svg": "https://flagcdn.com/sv.svg"
    },
    "cca2": "SV",
    "capital": [
      "San Salvador"
    ],
    "altSpellings": [
      "SV",
      "Republic of El Salvador",
      "República de El Salvador"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية السلفادور",
        "common": "السلفادور"
      },
      "bre": {
        "official": "Republik El Salvador",
        "common": "El Salvador"
      },
      "ces": {
        "official": "Salvadorská republika",
        "common": "Salvador"
      },
      "cym": {
        "official": "Gweriniaeth El Salfador",
        "common": "El Salfador"
      },
      "deu": {
        "official": "Republik El Salvador",
        "common": "El Salvador"
      },
      "est": {
        "official": "El Salvadori Vabariik",
        "common": "El Salvador"
      },
      "fin": {
        "official": "El Salvadorin tasavalta",
        "common": "El Salvador"
      },
      "fra": {
        "official": "République du Salvador",
        "common": "Salvador"
      },
      "hrv": {
        "official": "Republika El Salvador",
        "common": "Salvador"
      },
      "hun": {
        "official": "Salvadori Köztársaság",
        "common": "Salvador"
      },
      "ita": {
        "official": "Repubblica di El Salvador",
        "common": "El Salvador"
      },
      "jpn": {
        "official": "エルサルバドル共和国",
        "common": "エルサルバドル"
      },
      "kor": {
        "official": "엘살바도르 공화국",
        "common": "엘살바도르"
      },
      "nld": {
        "official": "Republiek El Salvador",
        "common": "El Salvador"
      },
      "per": {
        "official": "جمهوری السالوادور",
        "common": "السالوادور"
      },
      "pol": {
        "official": "Republika Salwadoru",
        "common": "Salwador"
      },
      "por": {
        "official": "República de El Salvador",
        "common": "El Salvador"
      },
      "rus": {
        "official": "Республика Эль-Сальвадор",
        "common": "Сальвадор"
      },
      "slk": {
        "official": "Salvádorská republika",
        "common": "Salvádor"
      },
      "spa": {
        "official": "República de El Salvador",
        "common": "El Salvador"
      },
      "swe": {
        "official": "Republiken El Salvador",
        "common": "El Salvador"
      },
      "tur": {
        "official": "El Salvador Cumhuriyeti",
        "common": "El Salvador"
      },
      "urd": {
        "official": "جمہوریہ ایل سیلواڈور",
        "common": "ایل سیلواڈور"
      },
      "zho": {
        "official": "萨尔瓦多共和国",
        "common": "萨尔瓦多"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Salvadoran",
        "m": "Salvadoran"
      },
      "fra": {
        "f": "Salvadorienne",
        "m": "Salvadorien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kw.png",
      "svg": "https://flagcdn.com/kw.svg"
    },
    "cca2": "KW",
    "capital": [
      "Kuwait City"
    ],
    "altSpellings": [
      "KW",
      "State of Kuwait",
      "Dawlat al-Kuwait"
    ],
    "translations": {
      "ara": {
        "official": "دولة الكويت",
        "common": "الكويت"
      },
      "bre": {
        "official": "Stad Koweit",
        "common": "Koweit"
      },
      "ces": {
        "official": "Stát Kuvajt",
        "common": "Kuvajt"
      },
      "cym": {
        "official": "State of Kuwait",
        "common": "Kuwait"
      },
      "deu": {
        "official": "Staat Kuwait",
        "common": "Kuwait"
      },
      "est": {
        "official": "Kuveidi Riik",
        "common": "Kuveit"
      },
      "fin": {
        "official": "Kuwaitin valtio",
        "common": "Kuwait"
      },
      "fra": {
        "official": "État du Koweït",
        "common": "Koweït"
      },
      "hrv": {
        "official": "Država Kuvajt",
        "common": "Kuvajt"
      },
      "hun": {
        "official": "Kuvaiti Állam",
        "common": "Kuvait"
      },
      "ita": {
        "official": "Stato del Kuwait",
        "common": "Kuwait"
      },
      "jpn": {
        "official": "クウェート国",
        "common": "クウェート"
      },
      "kor": {
        "official": "쿠웨이트국",
        "common": "쿠웨이트"
      },
      "nld": {
        "official": "Staat Koeweit",
        "common": "Koeweit"
      },
      "per": {
        "official": "دولت کویت",
        "common": "کُویت"
      },
      "pol": {
        "official": "Państwo Kuwejt",
        "common": "Kuwejt"
      },
      "por": {
        "official": "Estado do Kuwait",
        "common": "Kuwait"
      },
      "rus": {
        "official": "Государство Кувейт",
        "common": "Кувейт"
      },
      "slk": {
        "official": "Kuvajtský štát",
        "common": "Kuvajt"
      },
      "spa": {
        "official": "Estado de Kuwait",
        "common": "Kuwait"
      },
      "swe": {
        "official": "Staten Kuwait",
        "common": "Kuwait"
      },
      "tur": {
        "official": "Kuveyt Devleti",
        "common": "Kuveyt"
      },
      "urd": {
        "official": "دولتِ کویت",
        "common": "کویت"
      },
      "zho": {
        "official": "科威特国",
        "common": "科威特"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kuwaiti",
        "m": "Kuwaiti"
      },
      "fra": {
        "f": "Koweïtienne",
        "m": "Koweïtien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/to.png",
      "svg": "https://flagcdn.com/to.svg"
    },
    "cca2": "TO",
    "capital": [
      "Nuku'alofa"
    ],
    "altSpellings": [
      "TO"
    ],
    "translations": {
      "ara": {
        "official": "مملكة تونغا",
        "common": "تونغا"
      },
      "bre": {
        "official": "Rouantelezh Tonga",
        "common": "Tonga"
      },
      "ces": {
        "official": "Království Tonga",
        "common": "Tonga"
      },
      "cym": {
        "official": "Kingdom of Tonga",
        "common": "Tonga"
      },
      "deu": {
        "official": "Königreich Tonga",
        "common": "Tonga"
      },
      "est": {
        "official": "Tonga Kuningriik",
        "common": "Tonga"
      },
      "fin": {
        "official": "Tongan kuningaskunta",
        "common": "Tonga"
      },
      "fra": {
        "official": "Royaume des Tonga",
        "common": "Tonga"
      },
      "hrv": {
        "official": "Kraljevina Tonga",
        "common": "Tonga"
      },
      "hun": {
        "official": "Tongai Királyság",
        "common": "Tonga"
      },
      "ita": {
        "official": "Regno di Tonga",
        "common": "Tonga"
      },
      "jpn": {
        "official": "トンガ王国",
        "common": "トンガ"
      },
      "kor": {
        "official": "통가 왕국",
        "common": "통가"
      },
      "nld": {
        "official": "Koninkrijk Tonga",
        "common": "Tonga"
      },
      "per": {
        "official": "پادشاهی تونگا",
        "common": "تونگا"
      },
      "pol": {
        "official": "Królestwo Tonga",
        "common": "Tonga"
      },
      "por": {
        "official": "Reino de Tonga",
        "common": "Tonga"
      },
      "rus": {
        "official": "Королевство Тонга",
        "common": "Тонга"
      },
      "slk": {
        "official": "Tongské kráľovstvo",
        "common": "Tonga"
      },
      "spa": {
        "official": "Reino de Tonga",
        "common": "Tonga"
      },
      "swe": {
        "official": "Konungariket Tonga",
        "common": "Tonga"
      },
      "tur": {
        "official": "Tonga Krallığı",
        "common": "Tonga"
      },
      "urd": {
        "official": "مملکتِ ٹونگا",
        "common": "ٹونگا"
      },
      "zho": {
        "official": "汤加王国",
        "common": "汤加"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tongan",
        "m": "Tongan"
      },
      "fra": {
        "f": "Tonguienne",
        "m": "Tonguien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/om.png",
      "svg": "https://flagcdn.com/om.svg"
    },
    "cca2": "OM",
    "capital": [
      "Muscat"
    ],
    "altSpellings": [
      "OM",
      "Sultanate of Oman",
      "Salṭanat ʻUmān"
    ],
    "translations": {
      "ara": {
        "official": "سلطنة عمان",
        "common": "عمان"
      },
      "bre": {
        "official": "Sultanelezh Oman",
        "common": "Oman"
      },
      "ces": {
        "official": "Sultanát Omán",
        "common": "Omán"
      },
      "cym": {
        "official": "Sultanate of Oman",
        "common": "Oman"
      },
      "deu": {
        "official": "Sultanat Oman",
        "common": "Oman"
      },
      "est": {
        "official": "Omaani Sultaniriik",
        "common": "Omaan"
      },
      "fin": {
        "official": "Omanin sulttaanikunta",
        "common": "Oman"
      },
      "fra": {
        "official": "Sultanat d'Oman",
        "common": "Oman"
      },
      "hrv": {
        "official": "Sultanat Oman",
        "common": "Oman"
      },
      "hun": {
        "official": "Ománi Szultanátus",
        "common": "Omán"
      },
      "ita": {
        "official": "Sultanato dell'Oman",
        "common": "oman"
      },
      "jpn": {
        "official": "オマーン·スルタン国",
        "common": "オマーン"
      },
      "kor": {
        "official": "오만 술탄국",
        "common": "오만"
      },
      "nld": {
        "official": "Sultanaat van Oman",
        "common": "Oman"
      },
      "per": {
        "official": "سلطان‌نشین عُمان",
        "common": "عمان"
      },
      "pol": {
        "official": "Sułtanat Omanu",
        "common": "Oman"
      },
      "por": {
        "official": "Sultanato de Omã",
        "common": "Omã"
      },
      "rus": {
        "official": "Султанат Оман",
        "common": "Оман"
      },
      "slk": {
        "official": "Ománsky sultanát",
        "common": "Omán"
      },
      "spa": {
        "official": "Sultanato de Omán",
        "common": "Omán"
      },
      "swe": {
        "official": "Sultanatet Oman",
        "common": "Oman"
      },
      "tur": {
        "official": "Umman Sultanlığı",
        "common": "Umman"
      },
      "urd": {
        "official": "سلطنت عمان",
        "common": "عمان"
      },
      "zho": {
        "official": "阿曼苏丹国",
        "common": "阿曼"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Omani",
        "m": "Omani"
      },
      "fra": {
        "f": "Omanaise",
        "m": "Omanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png",
      "svg": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
    },
    "cca2": "AF",
    "capital": [
      "Kabul"
    ],
    "altSpellings": [
      "AF",
      "Afġānistān"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أففانستان الإسلامية",
        "common": "أفغانستان"
      },
      "bre": {
        "official": "Republik Islamek Afghanistan",
        "common": "Afghanistan"
      },
      "ces": {
        "official": "Afghánská islámská republika",
        "common": "Afghánistán"
      },
      "cym": {
        "official": "Gweriniaeth Islamaidd Affganistan",
        "common": "Affganistan"
      },
      "deu": {
        "official": "Islamische Republik Afghanistan",
        "common": "Afghanistan"
      },
      "est": {
        "official": "Afganistani Islamivabariik",
        "common": "Afganistan"
      },
      "fin": {
        "official": "Afganistanin islamilainen tasavalta",
        "common": "Afganistan"
      },
      "fra": {
        "official": "République islamique d'Afghanistan",
        "common": "Afghanistan"
      },
      "hrv": {
        "official": "Islamska Republika Afganistan",
        "common": "Afganistan"
      },
      "hun": {
        "official": "Afganisztáni Iszlám Köztársaság",
        "common": "Afganisztán"
      },
      "ita": {
        "official": "Repubblica islamica dell'Afghanistan",
        "common": "Afghanistan"
      },
      "jpn": {
        "official": "アフガニスタン·イスラム共和国",
        "common": "アフガニスタン"
      },
      "kor": {
        "official": "아프가니스탄 이슬람 공화국",
        "common": "아프가니스탄"
      },
      "nld": {
        "official": "Islamitische Republiek Afghanistan",
        "common": "Afghanistan"
      },
      "per": {
        "official": "جمهوری اسلامی افغانستان",
        "common": "افغانستان"
      },
      "pol": {
        "official": "Islamska Republika Afganistanu",
        "common": "Afganistan"
      },
      "por": {
        "official": "República Islâmica do Afeganistão",
        "common": "Afeganistão"
      },
      "rus": {
        "official": "Исламская Республика Афганистан",
        "common": "Афганистан"
      },
      "slk": {
        "official": "Afgánsky islamský štát",
        "common": "Afganistan"
      },
      "spa": {
        "official": "República Islámica de Afganistán",
        "common": "Afganistán"
      },
      "swe": {
        "official": "Islamiska republiken Afghanistan",
        "common": "Afghanistan"
      },
      "tur": {
        "official": "Afganistan İslam Cumhuriyeti",
        "common": "Afganistan"
      },
      "urd": {
        "official": "اسلامی جمہوریہ افغانستان",
        "common": "افغانستان"
      },
      "zho": {
        "official": "阿富汗伊斯兰共和国",
        "common": "阿富汗"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Afghan",
        "m": "Afghan"
      },
      "fra": {
        "f": "Afghane",
        "m": "Afghan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kn.png",
      "svg": "https://flagcdn.com/kn.svg"
    },
    "cca2": "KN",
    "capital": [
      "Basseterre"
    ],
    "altSpellings": [
      "KN",
      "Federation of Saint Christopher and Nevis"
    ],
    "translations": {
      "ara": {
        "official": "اتحاد القديس كريستوفر ونيفيس",
        "common": "سانت كيتس ونيفيس"
      },
      "bre": {
        "official": "Kevread Saint Kitts ha Nevis",
        "common": "Saint Kitts ha Nevis"
      },
      "ces": {
        "official": "Federace Sv. Kryštof a Nevis",
        "common": "Svatý Kryštof a Nevis"
      },
      "cym": {
        "official": "Federation of Saint Christopher and Nevis",
        "common": "Saint Kitts and Nevis"
      },
      "deu": {
        "official": "Föderation von St. Kitts und Nevis",
        "common": "St. Kitts und Nevis"
      },
      "est": {
        "official": "Saint Kittsi ja Nevise Föderatsioon",
        "common": "Saint Kitts ja Nevis"
      },
      "fin": {
        "official": "Saint Christopherin ja Nevisin federaatio",
        "common": "Saint Kitts ja Nevis"
      },
      "fra": {
        "official": "Fédération de Saint-Christophe-et-Niévès",
        "common": "Saint-Christophe-et-Niévès"
      },
      "hrv": {
        "official": "Federacija Sv.Kristofora i Nevisa",
        "common": "Sveti Kristof i Nevis"
      },
      "hun": {
        "official": "Saint Christopher és Nevis Államszövetség",
        "common": "Saint Kitts és Nevis"
      },
      "ita": {
        "official": "Federazione di Saint Christopher e Nevis",
        "common": "Saint Kitts e Nevis"
      },
      "jpn": {
        "official": "セントクリストファーNevis連盟",
        "common": "セントクリストファー・ネイビス"
      },
      "kor": {
        "official": "세인트키츠 네비스 연방",
        "common": "세인트키츠 네비스"
      },
      "nld": {
        "official": "Federatie van Saint Kitts en Nevis",
        "common": "Saint Kitts en Nevis"
      },
      "per": {
        "official": "فدراسیون سنت کیتس و نویس",
        "common": "سنت کیتس و نویس"
      },
      "pol": {
        "official": "Federacja Saint Kitts i Nevis",
        "common": "Saint Kitts i Nevis"
      },
      "por": {
        "official": "Federação de São Cristóvão e Nevis",
        "common": "São Cristóvão e Nevis"
      },
      "rus": {
        "official": "Федерация Сент-Кристофер и Н е в и с",
        "common": "Сент-Китс и Невис"
      },
      "slk": {
        "official": "Feder໡cia Svätého Krištofa a Nevisu",
        "common": "Svätý Krištof a Nevis"
      },
      "spa": {
        "official": "Federación de San Cristóbal y Nevis",
        "common": "San Cristóbal y Nieves"
      },
      "swe": {
        "official": "Federationen Saint Kitts och Nevis",
        "common": "Saint Kitts och Nevis"
      },
      "tur": {
        "official": "Saint Kitts ve Nevis Federasyonu",
        "common": "Saint Kitts ve Nevis"
      },
      "urd": {
        "official": "وفاقِ سینٹ کیٹز و ناویس",
        "common": "سینٹ کیٹز و ناویس"
      },
      "zho": {
        "official": "圣克里斯托弗和尼维斯联邦",
        "common": "圣基茨和尼维斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Kittitian or Nevisian",
        "m": "Kittitian or Nevisian"
      },
      "fra": {
        "f": "Kittitienne-et-nevicienne",
        "m": "Kittitien-et-nevicien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cf.png",
      "svg": "https://flagcdn.com/cf.svg"
    },
    "cca2": "CF",
    "capital": [
      "Bangui"
    ],
    "altSpellings": [
      "CF",
      "Central African Republic",
      "République centrafricaine"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أفريقيا الوسطى",
        "common": "جمهورية أفريقيا الوسطى"
      },
      "bre": {
        "official": "Republik Kreizafrikan",
        "common": "Republik Kreizafrikan"
      },
      "ces": {
        "official": "Středoafrická republika",
        "common": "Středoafrická republika"
      },
      "cym": {
        "official": "Gweriniaeth Canolbarth Affrica",
        "common": "Gweriniaeth Canolbarth Affrica"
      },
      "deu": {
        "official": "Zentralafrikanische Republik",
        "common": "Zentralafrikanische Republik"
      },
      "est": {
        "official": "Kesk-Aafrika Vabariik",
        "common": "Kesk-Aafrika Vabariik"
      },
      "fin": {
        "official": "Keski-Afrikan tasavalta",
        "common": "Keski-Afrikan tasavalta"
      },
      "fra": {
        "official": "République centrafricaine",
        "common": "République centrafricaine"
      },
      "hrv": {
        "official": "Centralna Afrička Republika",
        "common": "Srednjoafrička Republika"
      },
      "hun": {
        "official": "Közép-afrikai Köztársaság",
        "common": "Közép-afrikai Köztársaság"
      },
      "ita": {
        "official": "Repubblica Centrafricana",
        "common": "Repubblica Centrafricana"
      },
      "jpn": {
        "official": "中央アフリカ共和国",
        "common": "中央アフリカ共和国"
      },
      "kor": {
        "official": "중앙아프리카 공화국",
        "common": "중앙아프리카 공화국"
      },
      "nld": {
        "official": "Centraal-Afrikaanse Republiek",
        "common": "Centraal-Afrikaanse Republiek"
      },
      "per": {
        "official": "جمهوری آفریقای مرکزی",
        "common": "جمهوری آفریقای مرکزی"
      },
      "pol": {
        "official": "Republika Środkowoafrykańska",
        "common": "Republika Środkowoafrykańska"
      },
      "por": {
        "official": "República Centro-Africano",
        "common": "República Centro-Africana"
      },
      "rus": {
        "official": "Центрально-Африканская Республика",
        "common": "Центральноафриканская Республика"
      },
      "slk": {
        "official": "Stredoafrická republika",
        "common": "Stredoafrická republika"
      },
      "spa": {
        "official": "República Centroafricana",
        "common": "República Centroafricana"
      },
      "swe": {
        "official": "Centralafrikanska republiken",
        "common": "Centralafrikanska republiken"
      },
      "tur": {
        "official": "Orta Afrika Cumhuriyeti",
        "common": "Orta Afrika Cumhuriyeti"
      },
      "urd": {
        "official": "وسطی افریقی جمہوریہ",
        "common": "وسطی افریقی جمہوریہ"
      },
      "zho": {
        "official": "中非共和国",
        "common": "中非共和国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Central African",
        "m": "Central African"
      },
      "fra": {
        "f": "Centrafricaine",
        "m": "Centrafricain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/rs.png",
      "svg": "https://flagcdn.com/rs.svg"
    },
    "cca2": "RS",
    "capital": [
      "Belgrade"
    ],
    "altSpellings": [
      "RS",
      "Srbija",
      "Republic of Serbia",
      "Република Србија",
      "Republika Srbija"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية صيربيا",
        "common": "صيربيا"
      },
      "bre": {
        "official": "Republik Serbia",
        "common": "Serbia"
      },
      "ces": {
        "official": "Srbská republika",
        "common": "Srbsko"
      },
      "cym": {
        "official": "Republic of Serbia",
        "common": "Serbia"
      },
      "deu": {
        "official": "Republik Serbien",
        "common": "Serbien"
      },
      "est": {
        "official": "Serbia Vabariik",
        "common": "Serbia"
      },
      "fin": {
        "official": "Serbian tasavalta",
        "common": "Serbia"
      },
      "fra": {
        "official": "République de Serbie",
        "common": "Serbie"
      },
      "hrv": {
        "official": "Republika Srbija",
        "common": "Srbija"
      },
      "hun": {
        "official": "Szerb Köztársaság",
        "common": "Szerbia"
      },
      "ita": {
        "official": "Repubblica di Serbia",
        "common": "Serbia"
      },
      "jpn": {
        "official": "セルビア共和国",
        "common": "セルビア"
      },
      "kor": {
        "official": "세르비아 공화국",
        "common": "세르비아"
      },
      "nld": {
        "official": "Republiek Servië",
        "common": "Servië"
      },
      "per": {
        "official": "جمهوری صربستان",
        "common": "صربستان"
      },
      "pol": {
        "official": "Republika Serbii",
        "common": "Serbia"
      },
      "por": {
        "official": "República da Sérvia",
        "common": "Sérvia"
      },
      "rus": {
        "official": "Республика Сербия",
        "common": "Сербия"
      },
      "slk": {
        "official": "Srbská republika",
        "common": "Srbsko"
      },
      "spa": {
        "official": "República de Serbia",
        "common": "Serbia"
      },
      "swe": {
        "official": "Republiken Serbien",
        "common": "Serbien"
      },
      "tur": {
        "official": "Sırbistan Cumhuriyeti",
        "common": "Sırbistan"
      },
      "urd": {
        "official": "جمہوریہ سربیا",
        "common": "سربیا"
      },
      "zho": {
        "official": "塞尔维亚共和国",
        "common": "塞尔维亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Serbian",
        "m": "Serbian"
      },
      "fra": {
        "f": "Serbe",
        "m": "Serbe"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/am.png",
      "svg": "https://flagcdn.com/am.svg"
    },
    "cca2": "AM",
    "capital": [
      "Yerevan"
    ],
    "altSpellings": [
      "AM",
      "Hayastan",
      "Republic of Armenia",
      "Հայաստանի Հանրապետություն"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية أرمينيا",
        "common": "أرمينيا"
      },
      "bre": {
        "official": "Republik Armenia",
        "common": "Armenia"
      },
      "ces": {
        "official": "Arménská republika",
        "common": "Arménie"
      },
      "cym": {
        "official": "Gweriniaeth Armenia",
        "common": "Armenia"
      },
      "deu": {
        "official": "Republik Armenien",
        "common": "Armenien"
      },
      "est": {
        "official": "Armeenia Vabariik",
        "common": "Armeenia"
      },
      "fin": {
        "official": "Armenian tasavalta",
        "common": "Armenia"
      },
      "fra": {
        "official": "République d'Arménie",
        "common": "Arménie"
      },
      "hrv": {
        "official": "Republika Armenija",
        "common": "Armenija"
      },
      "hun": {
        "official": "Örményország",
        "common": "Örményország"
      },
      "ita": {
        "official": "Repubblica di Armenia",
        "common": "Armenia"
      },
      "jpn": {
        "official": "アルメニア共和国",
        "common": "アルメニア"
      },
      "kor": {
        "official": "아르메니아 공화국",
        "common": "아르메니아"
      },
      "nld": {
        "official": "Republiek Armenië",
        "common": "Armenië"
      },
      "per": {
        "official": "جمهوری ارمنستان",
        "common": "ارمنستان"
      },
      "pol": {
        "official": "Republika Armenii",
        "common": "Armenia"
      },
      "por": {
        "official": "República da Arménia",
        "common": "Arménia"
      },
      "rus": {
        "official": "Республика Армения",
        "common": "Армения"
      },
      "slk": {
        "official": "Arménska republika",
        "common": "Arménsko"
      },
      "spa": {
        "official": "República de Armenia",
        "common": "Armenia"
      },
      "swe": {
        "official": "Republiken Armenien",
        "common": "Armenien"
      },
      "tur": {
        "official": "Ermenistan Cumhuriyeti",
        "common": "Ermenistan"
      },
      "urd": {
        "official": "جمہوریہ آرمینیا",
        "common": "آرمینیا"
      },
      "zho": {
        "official": "亚美尼亚共和国",
        "common": "亚美尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Armenian",
        "m": "Armenian"
      },
      "fra": {
        "f": "Arménienne",
        "m": "Arménien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bd.png",
      "svg": "https://flagcdn.com/bd.svg"
    },
    "cca2": "BD",
    "capital": [
      "Dhaka"
    ],
    "altSpellings": [
      "BD",
      "People's Republic of Bangladesh",
      "Gônôprôjatôntri Bangladesh"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بنغلاديش الشعبية",
        "common": "بنغلاديش"
      },
      "bre": {
        "official": "Republik pobl Bangladesh",
        "common": "Bangladesh"
      },
      "ces": {
        "official": "Bangladéšská lidová republika",
        "common": "Bangladéš"
      },
      "cym": {
        "official": "Gweriniaeth Pobl Bangladesh",
        "common": "Bangladesh"
      },
      "deu": {
        "official": "Volksrepublik Bangladesch",
        "common": "Bangladesch"
      },
      "est": {
        "official": "Bangladeshi Rahvavabariik",
        "common": "Bangladesh"
      },
      "fin": {
        "official": "Bangladeshin kansantasavalta",
        "common": "Bangladesh"
      },
      "fra": {
        "official": "La République populaire du Bangladesh",
        "common": "Bangladesh"
      },
      "hrv": {
        "official": "Narodna Republika Bangladeš",
        "common": "Bangladeš"
      },
      "hun": {
        "official": "Banglades",
        "common": "Banglades"
      },
      "ita": {
        "official": "Repubblica popolare del Bangladesh",
        "common": "Bangladesh"
      },
      "jpn": {
        "official": "バングラデシュ人民共和国",
        "common": "バングラデシュ"
      },
      "kor": {
        "official": "방글라데시 인민 공화국",
        "common": "방글라데시"
      },
      "nld": {
        "official": "Volksrepubliek Bangladesh",
        "common": "Bangladesh"
      },
      "per": {
        "official": "جمهوری خلق بنگلادش",
        "common": "بنگلادش"
      },
      "pol": {
        "official": "Ludowa Republika Bangladeszu",
        "common": "Bangladesz"
      },
      "por": {
        "official": "República Popular do Bangladesh",
        "common": "Bangladesh"
      },
      "rus": {
        "official": "Народная Республика Бангладеш",
        "common": "Бангладеш"
      },
      "slk": {
        "official": "Bangladéšska ľudová republika",
        "common": "Bangladéš"
      },
      "spa": {
        "official": "República Popular de Bangladesh",
        "common": "Bangladesh"
      },
      "swe": {
        "official": "Folkrepubliken Bangladesh",
        "common": "Bangladesh"
      },
      "tur": {
        "official": "Bangladeş Halk Cumhuriyeti",
        "common": "Bangladeş"
      },
      "urd": {
        "official": "عوامی جمہوریہ بنگلہ دیش",
        "common": "بنگلہ دیش"
      },
      "zho": {
        "official": "孟加拉人民共和国",
        "common": "孟加拉国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bangladeshi",
        "m": "Bangladeshi"
      },
      "fra": {
        "f": "Bangladaise",
        "m": "Bangladais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/la.png",
      "svg": "https://flagcdn.com/la.svg"
    },
    "cca2": "LA",
    "capital": [
      "Vientiane"
    ],
    "altSpellings": [
      "LA",
      "Lao",
      "Lao People's Democratic Republic",
      "Sathalanalat Paxathipatai Paxaxon Lao"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية لاوس الديمقراطية الشعبية",
        "common": "لاوس"
      },
      "bre": {
        "official": "Republik Demokratel ar Bobl Lao",
        "common": "Laos"
      },
      "ces": {
        "official": "Laoská lidově demokratická republika",
        "common": "Laos"
      },
      "cym": {
        "official": "Lao People's Democratic Republic",
        "common": "Laos"
      },
      "deu": {
        "official": "Demokratische Volksrepublik Laos",
        "common": "Laos"
      },
      "est": {
        "official": "Laose Demokraatlik Rahvavabariik",
        "common": "Laos"
      },
      "fin": {
        "official": "Laosin demokraattinen kansantasavalta",
        "common": "Laos"
      },
      "fra": {
        "official": "République démocratique populaire lao",
        "common": "Laos"
      },
      "hrv": {
        "official": "Narodna Demokratska Republika",
        "common": "Laos"
      },
      "hun": {
        "official": "Laoszi Népi Demokratikus Köztársaság",
        "common": "Laosz"
      },
      "ita": {
        "official": "Repubblica democratica popolare del Laos",
        "common": "Laos"
      },
      "jpn": {
        "official": "ラオス人民民主共和国",
        "common": "ラオス人民民主共和国"
      },
      "kor": {
        "official": "라오 인민 민주 공화국",
        "common": "라오스"
      },
      "nld": {
        "official": "Lao Democratische Volksrepubliek",
        "common": "Laos"
      },
      "per": {
        "official": "جمهوری دموکراتیک خلق لائوس",
        "common": "لائوس"
      },
      "pol": {
        "official": "Laotańska Republika Ludowo-Demokratyczna",
        "common": "Laos"
      },
      "por": {
        "official": "Laos, República Democrática",
        "common": "Laos"
      },
      "rus": {
        "official": "Лаосская Народно-Демократическая Республика",
        "common": "Лаос"
      },
      "slk": {
        "official": "Laoská ľudovodemokratická republika",
        "common": "Laos"
      },
      "spa": {
        "official": "República Democrática Popular Lao",
        "common": "Laos"
      },
      "swe": {
        "official": "Demokratiska folkrepubliken Laos",
        "common": "Laos"
      },
      "tur": {
        "official": "Laos Demokratik Halk Cumhuriyeti",
        "common": "Laos"
      },
      "urd": {
        "official": "عوامی جمہوری جمہوریہ لاؤ",
        "common": "لاؤس"
      },
      "zho": {
        "official": "老挝人民民主共和国",
        "common": "老挝"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Laotian",
        "m": "Laotian"
      },
      "fra": {
        "f": "Laotienne",
        "m": "Laotien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cl.png",
      "svg": "https://flagcdn.com/cl.svg"
    },
    "cca2": "CL",
    "capital": [
      "Santiago"
    ],
    "altSpellings": [
      "CL",
      "Republic of Chile",
      "República de Chile"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية تشيلي",
        "common": "تشيلي"
      },
      "bre": {
        "official": "Republik Chile",
        "common": "Chile"
      },
      "ces": {
        "official": "Chilská republika",
        "common": "Chile"
      },
      "cym": {
        "official": "Gweriniaeth Chile",
        "common": "Chile"
      },
      "deu": {
        "official": "Republik Chile",
        "common": "Chile"
      },
      "est": {
        "official": "Tšiili Vabariik",
        "common": "Tšiili"
      },
      "fin": {
        "official": "Chilen tasavalta",
        "common": "Chile"
      },
      "fra": {
        "official": "République du Chili",
        "common": "Chili"
      },
      "hrv": {
        "official": "Republika Čile",
        "common": "Čile"
      },
      "hun": {
        "official": "Chilei Köztársaság",
        "common": "Chile"
      },
      "ita": {
        "official": "Repubblica del Cile",
        "common": "Cile"
      },
      "jpn": {
        "official": "チリ共和国",
        "common": "チリ"
      },
      "kor": {
        "official": "칠레 공화국",
        "common": "칠레"
      },
      "nld": {
        "official": "Republiek Chili",
        "common": "Chili"
      },
      "per": {
        "official": "جمهوری شیلی",
        "common": "شیلی"
      },
      "pol": {
        "official": "Republika Chile",
        "common": "Chile"
      },
      "por": {
        "official": "República do Chile",
        "common": "Chile"
      },
      "rus": {
        "official": "Республика Чили",
        "common": "Чили"
      },
      "slk": {
        "official": "Čílska republika",
        "common": "Čile"
      },
      "spa": {
        "official": "República de Chile",
        "common": "Chile"
      },
      "swe": {
        "official": "Republiken Chile",
        "common": "Chile"
      },
      "tur": {
        "official": "Şili Cumhuriyeti",
        "common": "Şili"
      },
      "urd": {
        "official": "جمہوریہ چلی",
        "common": "چلی"
      },
      "zho": {
        "official": "智利共和国",
        "common": "智利"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Chilean",
        "m": "Chilean"
      },
      "fra": {
        "f": "Chilienne",
        "m": "Chilien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pf.png",
      "svg": "https://flagcdn.com/pf.svg"
    },
    "cca2": "PF",
    "capital": [
      "Papeetē"
    ],
    "altSpellings": [
      "PF",
      "Polynésie française",
      "French Polynesia",
      "Pōrīnetia Farāni"
    ],
    "translations": {
      "ara": {
        "official": "بولينزيا الفرنسية",
        "common": "بولينزيا الفرنسية"
      },
      "bre": {
        "official": "Polinezia C'hall",
        "common": "Polinezia C'hall"
      },
      "ces": {
        "official": "Francouzská Polynésie",
        "common": "Francouzská Polynésie"
      },
      "cym": {
        "official": "French Polynesia",
        "common": "French Polynesia"
      },
      "deu": {
        "official": "Französisch-Polynesien",
        "common": "Französisch-Polynesien"
      },
      "est": {
        "official": "Prantsuse Polüneesia",
        "common": "Prantsuse Polüneesia"
      },
      "fin": {
        "official": "Ranskan Polynesia",
        "common": "Ranskan Polynesia"
      },
      "fra": {
        "official": "Polynésie française",
        "common": "Polynésie française"
      },
      "hrv": {
        "official": "Francuska Polinezija",
        "common": "Francuska Polinezija"
      },
      "hun": {
        "official": "Francia Polinézia",
        "common": "Francia Polinézia"
      },
      "ita": {
        "official": "Polinesia Francese",
        "common": "Polinesia Francese"
      },
      "jpn": {
        "official": "フランス領ポリネシア",
        "common": "フランス領ポリネシア"
      },
      "kor": {
        "official": "프랑스령 폴리네시아",
        "common": "프랑스령 폴리네시아"
      },
      "nld": {
        "official": "Frans-Polynesië",
        "common": "Frans-Polynesië"
      },
      "per": {
        "official": "پُلی‌نِزی فرانسه",
        "common": "پُلی‌نِزی فرانسه"
      },
      "pol": {
        "official": "Polinezja Francuska",
        "common": "Polinezja Francuska"
      },
      "por": {
        "official": "Polinésia Francesa",
        "common": "Polinésia Francesa"
      },
      "rus": {
        "official": "Французская Полинезия",
        "common": "Французская Полинезия"
      },
      "slk": {
        "official": "Francúzska Polynézia",
        "common": "Francúzska Polynézia"
      },
      "spa": {
        "official": "Polinesia francés",
        "common": "Polinesia Francesa"
      },
      "swe": {
        "official": "Franska Polynesien",
        "common": "Franska Polynesien"
      },
      "tur": {
        "official": "Fransız Polinezyası",
        "common": "Fransız Polinezyası"
      },
      "urd": {
        "official": "فرانسیسی پولینیشیا",
        "common": "فرانسیسی پولینیشیا"
      },
      "zho": {
        "official": "法属波利尼西亚",
        "common": "法属波利尼西亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "French Polynesian",
        "m": "French Polynesian"
      },
      "fra": {
        "f": "Polynésienne",
        "m": "Polynésien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pa.png",
      "svg": "https://flagcdn.com/pa.svg"
    },
    "cca2": "PA",
    "capital": [
      "Panama City"
    ],
    "altSpellings": [
      "PA",
      "Republic of Panama",
      "República de Panamá"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بنما",
        "common": "بنما"
      },
      "bre": {
        "official": "Republik Panama",
        "common": "Panama"
      },
      "ces": {
        "official": "Panamská republika",
        "common": "Panama"
      },
      "cym": {
        "official": "Republic of Panama",
        "common": "Panama"
      },
      "deu": {
        "official": "Republik Panama",
        "common": "Panama"
      },
      "est": {
        "official": "Panama Vabariik",
        "common": "Panama"
      },
      "fin": {
        "official": "Panaman tasavalta",
        "common": "Panama"
      },
      "fra": {
        "official": "République du Panama",
        "common": "Panama"
      },
      "hrv": {
        "official": "Republika Panama",
        "common": "Panama"
      },
      "hun": {
        "official": "Panamai Köztársaság",
        "common": "Panama"
      },
      "ita": {
        "official": "Repubblica di Panama",
        "common": "Panama"
      },
      "jpn": {
        "official": "パナマ共和国",
        "common": "パナマ"
      },
      "kor": {
        "official": "파나마 공화국",
        "common": "파나마"
      },
      "nld": {
        "official": "Republiek Panama",
        "common": "Panama"
      },
      "per": {
        "official": "جمهوری پاناما",
        "common": "پاناما"
      },
      "pol": {
        "official": "Republika Panamy",
        "common": "Panama"
      },
      "por": {
        "official": "República do Panamá",
        "common": "Panamá"
      },
      "rus": {
        "official": "Республика Панама",
        "common": "Панама"
      },
      "slk": {
        "official": "Panamská republika",
        "common": "Panama"
      },
      "spa": {
        "official": "República de Panamá",
        "common": "Panamá"
      },
      "swe": {
        "official": "Republiken Panama",
        "common": "Panama"
      },
      "tur": {
        "official": "Panama Cumhuriyeti",
        "common": "Panama"
      },
      "urd": {
        "official": "جمہوریہ پاناما",
        "common": "پاناما"
      },
      "zho": {
        "official": "巴拿马共和国",
        "common": "巴拿马"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Panamanian",
        "m": "Panamanian"
      },
      "fra": {
        "f": "Panaméenne",
        "m": "Panaméen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/us.png",
      "svg": "https://flagcdn.com/us.svg"
    },
    "cca2": "US",
    "capital": [
      "Washington, D.C."
    ],
    "altSpellings": [
      "US",
      "USA",
      "United States of America"
    ],
    "translations": {
      "ara": {
        "official": "الولايات المتحدة الامريكية",
        "common": "الولايات المتحدة"
      },
      "bre": {
        "official": "Stadoù-Unanet Amerika",
        "common": "Stadoù-Unanet"
      },
      "ces": {
        "official": "Spojené státy americké",
        "common": "Spojené státy"
      },
      "cym": {
        "official": "United States of America",
        "common": "United States"
      },
      "deu": {
        "official": "Vereinigte Staaten von Amerika",
        "common": "Vereinigte Staaten"
      },
      "est": {
        "official": "Ameerika Ühendriigid",
        "common": "Ameerika Ühendriigid"
      },
      "fin": {
        "official": "Amerikan yhdysvallat",
        "common": "Yhdysvallat"
      },
      "fra": {
        "official": "Les états-unis d'Amérique",
        "common": "États-Unis"
      },
      "hrv": {
        "official": "Sjedinjene Države Amerike",
        "common": "Sjedinjene Američke Države"
      },
      "hun": {
        "official": "Amerikai Egyesült Államok",
        "common": "Amerikai Egyesült Államok"
      },
      "ita": {
        "official": "Stati Uniti d'America",
        "common": "Stati Uniti d'America"
      },
      "jpn": {
        "official": "アメリカ合衆国",
        "common": "アメリカ合衆国"
      },
      "kor": {
        "official": "아메리카 합중국",
        "common": "미국"
      },
      "nld": {
        "official": "Verenigde Staten van Amerika",
        "common": "Verenigde Staten"
      },
      "per": {
        "official": "ایالات متحده آمریکا",
        "common": "ایالات متحده آمریکا"
      },
      "pol": {
        "official": "Stany Zjednoczone Ameryki",
        "common": "Stany Zjednoczone"
      },
      "por": {
        "official": "Estados Unidos da América",
        "common": "Estados Unidos"
      },
      "rus": {
        "official": "Соединенные Штаты Америки",
        "common": "Соединённые Штаты Америки"
      },
      "slk": {
        "official": "Spojené štáty Americké",
        "common": "Spojené štáty americké"
      },
      "spa": {
        "official": "Estados Unidos de América",
        "common": "Estados Unidos"
      },
      "swe": {
        "official": "Amerikas förenta stater",
        "common": "USA"
      },
      "tur": {
        "official": "Amerika Birleşik Devletleri",
        "common": "Amerika Birleşik Devletleri"
      },
      "urd": {
        "official": "ریاستہائے متحدہ امریکا",
        "common": "ریاستہائے متحدہ"
      },
      "zho": {
        "official": "美利坚合众国",
        "common": "美国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "American",
        "m": "American"
      },
      "fra": {
        "f": "Américaine",
        "m": "Américain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/np.png",
      "svg": "https://flagcdn.com/np.svg"
    },
    "cca2": "NP",
    "capital": [
      "Kathmandu"
    ],
    "altSpellings": [
      "NP",
      "Federal Democratic Republic of Nepal",
      "Loktāntrik Ganatantra Nepāl"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية نيبال الديمقراطية الاتحادية",
        "common": "نيبال"
      },
      "bre": {
        "official": "Republik Demokratel Kevreadel Nepal",
        "common": "Nepal"
      },
      "ces": {
        "official": "Federativní demokratická republika Nepál",
        "common": "Nepál"
      },
      "cym": {
        "official": "Federal Democratic Republic of Nepal",
        "common": "Nepal"
      },
      "deu": {
        "official": "Demokratische Bundesrepublik Nepal",
        "common": "Nepal"
      },
      "est": {
        "official": "Nepali Demokraatlik Liitvabariik",
        "common": "Nepal"
      },
      "fin": {
        "official": "Nepalin demokraattinen liittotasavalta",
        "common": "Nepal"
      },
      "fra": {
        "official": "République du Népal",
        "common": "Népal"
      },
      "hrv": {
        "official": "Savezna Demokratska Republika Nepal",
        "common": "Nepal"
      },
      "hun": {
        "official": "Nepáli Szövetségi Demokratikus Köztársaság",
        "common": "Nepál"
      },
      "ita": {
        "official": "Repubblica federale democratica del Nepal",
        "common": "Nepal"
      },
      "jpn": {
        "official": "ネパール連邦民主共和国",
        "common": "ネパール"
      },
      "kor": {
        "official": "네팔 연방 민주 공화국",
        "common": "네팔"
      },
      "nld": {
        "official": "Federale Democratische Republiek Nepal",
        "common": "Nepal"
      },
      "per": {
        "official": "جمهوری فدرال دموکراتیک نپال",
        "common": "نپال"
      },
      "pol": {
        "official": "Federalna Demokratyczna Republika Nepalu",
        "common": "Nepal"
      },
      "por": {
        "official": "República Democrática Federal do Nepal",
        "common": "Nepal"
      },
      "rus": {
        "official": "Федеративная Демократическая Республика Непал",
        "common": "Непал"
      },
      "slk": {
        "official": "Nepálska federatívna demokratická republika",
        "common": "Nepál"
      },
      "spa": {
        "official": "República Democrática Federal de Nepal",
        "common": "Nepal"
      },
      "swe": {
        "official": "Demokratiska förbundsrepubliken Nepal",
        "common": "Nepal"
      },
      "tur": {
        "official": "Nepal Federal Demokratik Cumhuriyeti",
        "common": "Nepal"
      },
      "urd": {
        "official": "وفاقی جمہوری جمہوریہ نیپال",
        "common": "نیپال"
      },
      "zho": {
        "official": "尼泊尔联邦民主共和国",
        "common": "尼泊尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Nepalese",
        "m": "Nepalese"
      },
      "fra": {
        "f": "Népalaise",
        "m": "Népalais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mm.png",
      "svg": "https://flagcdn.com/mm.svg"
    },
    "cca2": "MM",
    "capital": [
      "Naypyidaw"
    ],
    "altSpellings": [
      "MM",
      "Burma",
      "Republic of the Union of Myanmar",
      "Pyidaunzu Thanmăda Myăma Nainngandaw"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية اتحاد ميانمار",
        "common": "ميانمار"
      },
      "bre": {
        "official": "Republik Unaniezh Myanmar",
        "common": "Myanmar"
      },
      "ces": {
        "official": "Republika Myanmarský svaz",
        "common": "Myanmar"
      },
      "cym": {
        "official": "Republic of the Union of Myanmar",
        "common": "Myanmar"
      },
      "deu": {
        "official": "Republik der Union Myanmar",
        "common": "Myanmar"
      },
      "est": {
        "official": "Myanmari Liidu Vabariik",
        "common": "Myanmar"
      },
      "fin": {
        "official": "Myanmarin liiton tasavalta",
        "common": "Myanmar"
      },
      "fra": {
        "official": "République de l'Union du Myanmar",
        "common": "Birmanie"
      },
      "hrv": {
        "official": "Republika Unije Mijanmar",
        "common": "Mijanmar"
      },
      "hun": {
        "official": "Mianmari Államszövetség Köztársasága",
        "common": "Mianmar"
      },
      "ita": {
        "official": "Repubblica dell'Unione di Myanmar",
        "common": "Birmania"
      },
      "jpn": {
        "official": "ミャンマー連邦共和国",
        "common": "ミャンマー"
      },
      "kor": {
        "official": "미얀마 연방 공화국",
        "common": "미얀마"
      },
      "nld": {
        "official": "Republiek van de Unie van Myanmar",
        "common": "Myanmar"
      },
      "per": {
        "official": "اتحادیه جمهوری میانمار",
        "common": "میانمار"
      },
      "pol": {
        "official": "Republika Związku Mjanmy",
        "common": "Mjanma"
      },
      "por": {
        "official": "República da União de Myanmar",
        "common": "Myanmar"
      },
      "rus": {
        "official": "Республика Союза Мьянма",
        "common": "Мьянма"
      },
      "slk": {
        "official": "Mjanmarská zväzová republika",
        "common": "Mjanmarsko"
      },
      "spa": {
        "official": "República de la Unión de Myanmar",
        "common": "Myanmar"
      },
      "swe": {
        "official": "Republiken Unionen Myanmar",
        "common": "Myanmar"
      },
      "tur": {
        "official": "Myanmar Birliği Cumhuriyeti",
        "common": "Myanmar"
      },
      "urd": {
        "official": "متحدہ جمہوریہ میانمار",
        "common": "میانمار"
      },
      "zho": {
        "official": "缅甸联邦共和国",
        "common": "缅甸"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Burmese",
        "m": "Burmese"
      },
      "fra": {
        "f": "Birmane",
        "m": "Birman"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fk.png",
      "svg": "https://flagcdn.com/fk.svg"
    },
    "cca2": "FK",
    "capital": [
      "Stanley"
    ],
    "altSpellings": [
      "FK",
      "Islas Malvinas",
      "Falkland Islands (Malvinas)"
    ],
    "translations": {
      "ara": {
        "official": "جزر فوكلاند",
        "common": "جزر فوكلاند"
      },
      "bre": {
        "official": "Inizi Maloù",
        "common": "Inizi Maloù"
      },
      "ces": {
        "official": "Falklandské ostrovy",
        "common": "Falklandy"
      },
      "cym": {
        "official": "Falkland Islands",
        "common": "Falkland Islands"
      },
      "deu": {
        "official": "Falklandinseln",
        "common": "Falklandinseln"
      },
      "est": {
        "official": "Falklandi saared",
        "common": "Falklandi saared"
      },
      "fin": {
        "official": "Falkandinsaaret",
        "common": "Falkandinsaaret"
      },
      "fra": {
        "official": "Îles Malouines",
        "common": "Îles Malouines"
      },
      "hrv": {
        "official": "Falklandski otoci",
        "common": "Falklandski Otoci"
      },
      "hun": {
        "official": "Falkland-szigetek",
        "common": "Falkland-szigetek"
      },
      "ita": {
        "official": "Isole Falkland",
        "common": "Isole Falkland o Isole Malvine"
      },
      "jpn": {
        "official": "フォークランド",
        "common": "フォークランド（マルビナス）諸島"
      },
      "kor": {
        "official": "포클랜드 제도",
        "common": "포클랜드 제도"
      },
      "nld": {
        "official": "Falkland eilanden",
        "common": "Falklandeilanden"
      },
      "per": {
        "official": "جزایر فالکلند",
        "common": "جزایر فالکلند"
      },
      "pol": {
        "official": "Falklandy",
        "common": "Falklandy"
      },
      "por": {
        "official": "Ilhas Malvinas",
        "common": "Ilhas Malvinas"
      },
      "rus": {
        "official": "Фолклендские острова",
        "common": "Фолклендские острова"
      },
      "slk": {
        "official": "Falklandské ostrovy",
        "common": "Falklandy"
      },
      "spa": {
        "official": "islas Malvinas",
        "common": "Islas Malvinas"
      },
      "swe": {
        "official": "Falklandsöarna",
        "common": "Falklandsöarna"
      },
      "tur": {
        "official": "Falkland (Malvina) Adaları",
        "common": "Falkland (Malvina) Adaları"
      },
      "urd": {
        "official": "جزائر فاکلینڈ",
        "common": "جزائر فاکلینڈ"
      },
      "zho": {
        "official": "福克兰群岛",
        "common": "福克兰群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Falkland Islander",
        "m": "Falkland Islander"
      },
      "fra": {
        "f": "Malouinne",
        "m": "Malouin"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/hu.png",
      "svg": "https://flagcdn.com/hu.svg"
    },
    "cca2": "HU",
    "capital": [
      "Budapest"
    ],
    "altSpellings": [
      "HU"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية المجرية",
        "common": "المجر"
      },
      "bre": {
        "official": "Hungaria",
        "common": "Hungaria"
      },
      "ces": {
        "official": "Maďarsko",
        "common": "Maďarsko"
      },
      "cym": {
        "official": "Hungary",
        "common": "Hungary"
      },
      "deu": {
        "official": "Ungarn",
        "common": "Ungarn"
      },
      "est": {
        "official": "Ungari",
        "common": "Ungari"
      },
      "fin": {
        "official": "Unkari",
        "common": "Unkari"
      },
      "fra": {
        "official": "Hongrie",
        "common": "Hongrie"
      },
      "hrv": {
        "official": "Madžarska",
        "common": "Mađarska"
      },
      "hun": {
        "official": "Magyarország",
        "common": "Magyarország"
      },
      "ita": {
        "official": "Ungheria",
        "common": "Ungheria"
      },
      "jpn": {
        "official": "ハンガリー",
        "common": "ハンガリー"
      },
      "kor": {
        "official": "헝가리",
        "common": "헝가리"
      },
      "nld": {
        "official": "Hongarije",
        "common": "Hongarije"
      },
      "per": {
        "official": "مجارستان",
        "common": "مجارستان"
      },
      "pol": {
        "official": "Węgry",
        "common": "Węgry"
      },
      "por": {
        "official": "Hungria",
        "common": "Hungria"
      },
      "rus": {
        "official": "Венгрия",
        "common": "Венгрия"
      },
      "slk": {
        "official": "Maďarsko",
        "common": "Maďarsko"
      },
      "spa": {
        "official": "Hungría",
        "common": "Hungría"
      },
      "swe": {
        "official": "Ungern",
        "common": "Ungern"
      },
      "tur": {
        "official": "Macaristan",
        "common": "Macaristan"
      },
      "urd": {
        "official": "مجارستان",
        "common": "مجارستان"
      },
      "zho": {
        "official": "匈牙利",
        "common": "匈牙利"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Hungarian",
        "m": "Hungarian"
      },
      "fra": {
        "f": "Hongroise",
        "m": "Hongrois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pw.png",
      "svg": "https://flagcdn.com/pw.svg"
    },
    "cca2": "PW",
    "capital": [
      "Ngerulmud"
    ],
    "altSpellings": [
      "PW",
      "Republic of Palau",
      "Beluu er a Belau"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بالاو",
        "common": "بالاو"
      },
      "bre": {
        "official": "Republik Palau",
        "common": "Palau"
      },
      "ces": {
        "official": "Republika Palau",
        "common": "Palau"
      },
      "cym": {
        "official": "Republic of Palau",
        "common": "Palau"
      },
      "deu": {
        "official": "Republik Palau",
        "common": "Palau"
      },
      "est": {
        "official": "Belau Vabariik",
        "common": "Belau"
      },
      "fin": {
        "official": "Palaun tasavalta",
        "common": "Palau"
      },
      "fra": {
        "official": "République des Palaos (Palau)",
        "common": "Palaos (Palau)"
      },
      "hrv": {
        "official": "Republika Palau",
        "common": "Palau"
      },
      "hun": {
        "official": "Palaui Köztársaság",
        "common": "Palau"
      },
      "ita": {
        "official": "Repubblica di Palau",
        "common": "Palau"
      },
      "jpn": {
        "official": "パラオ共和国",
        "common": "パラオ"
      },
      "kor": {
        "official": "팔라우 공화국",
        "common": "팔라우"
      },
      "nld": {
        "official": "Republiek van Palau",
        "common": "Palau"
      },
      "per": {
        "official": "جمهوری پالائو",
        "common": "پالائو"
      },
      "pol": {
        "official": "Republika Palau",
        "common": "Palau"
      },
      "por": {
        "official": "República de Palau",
        "common": "Palau"
      },
      "rus": {
        "official": "Республика Палау",
        "common": "Палау"
      },
      "slk": {
        "official": "Palauská republika",
        "common": "Palau"
      },
      "spa": {
        "official": "República de Palau",
        "common": "Palau"
      },
      "swe": {
        "official": "Republiken Palau",
        "common": "Palau"
      },
      "tur": {
        "official": "Palau Cumhuriyeti",
        "common": "Palau"
      },
      "urd": {
        "official": "جمہوریہ پلاؤ",
        "common": "پلاؤ"
      },
      "zho": {
        "official": "帕劳共和国",
        "common": "帕劳"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Palauan",
        "m": "Palauan"
      },
      "fra": {
        "f": "Paluane",
        "m": "Paluan"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gq.png",
      "svg": "https://flagcdn.com/gq.svg"
    },
    "cca2": "GQ",
    "capital": [
      "Malabo"
    ],
    "altSpellings": [
      "GQ",
      "Republic of Equatorial Guinea",
      "República de Guinea Ecuatorial",
      "République de Guinée équatoriale",
      "República da Guiné Equatorial"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غينيا الاستوائية",
        "common": "غينيا الاستوائية"
      },
      "bre": {
        "official": "Republik Ginea ar C'heheder",
        "common": "Ginea ar C'heheder"
      },
      "ces": {
        "official": "Republika Rovníková Guinea",
        "common": "Rovníková Guinea"
      },
      "cym": {
        "official": "Gweriniaeth Gini Gyhydeddol",
        "common": "Gini Gyhydeddol"
      },
      "deu": {
        "official": "Republik Äquatorialguinea",
        "common": "Äquatorialguinea"
      },
      "est": {
        "official": "Ekvatoriaal-Guinea Vabariik",
        "common": "Ekvatoriaal-Guinea"
      },
      "fin": {
        "official": "Päiväntasaajan Guinean tasavalta",
        "common": "Päiväntasaajan Guinea"
      },
      "fra": {
        "official": "République de Guinée équatoriale",
        "common": "Guinée équatoriale"
      },
      "hrv": {
        "official": "Republika Ekvatorska Gvineja",
        "common": "Ekvatorijalna Gvineja"
      },
      "hun": {
        "official": "Egyenlítői-Guinea-i Köztársaság",
        "common": "Egyenlítői-Guinea"
      },
      "ita": {
        "official": "Repubblica della Guinea Equatoriale",
        "common": "Guinea Equatoriale"
      },
      "jpn": {
        "official": "赤道ギニア共和国",
        "common": "赤道ギニア"
      },
      "kor": {
        "official": "적도 기니 공화국",
        "common": "적도 기니"
      },
      "nld": {
        "official": "Republiek Equatoriaal-Guinea",
        "common": "Equatoriaal-Guinea"
      },
      "per": {
        "official": "جمهوری گینه استوایی",
        "common": "گینه استوایی"
      },
      "pol": {
        "official": "Republika Gwinei Równikowej",
        "common": "Gwinea Równikowa"
      },
      "por": {
        "official": "República da Guiné Equatorial",
        "common": "Guiné Equatorial"
      },
      "rus": {
        "official": "Республика Экваториальная Гвинея",
        "common": "Экваториальная Гвинея"
      },
      "slk": {
        "official": "Republika rovníkovej Guiney",
        "common": "Rovníková Guinea"
      },
      "spa": {
        "official": "República de Guinea Ecuatorial",
        "common": "Guinea Ecuatorial"
      },
      "swe": {
        "official": "Republiken Ekvatorialguinea",
        "common": "Ekvatorialguinea"
      },
      "tur": {
        "official": "Ekvator Ginesi Cumhuriyeti",
        "common": "Ekvator Ginesi"
      },
      "urd": {
        "official": "جمہوریہ استوائی گنی",
        "common": "استوائی گنی"
      },
      "zho": {
        "official": "赤道几内亚共和国",
        "common": "赤道几内亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Equatorial Guinean",
        "m": "Equatorial Guinean"
      },
      "fra": {
        "f": "Équato-guinéenne",
        "m": "Équato-guinéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gn.png",
      "svg": "https://flagcdn.com/gn.svg"
    },
    "cca2": "GN",
    "capital": [
      "Conakry"
    ],
    "altSpellings": [
      "GN",
      "Republic of Guinea",
      "République de Guinée"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية غينيا",
        "common": "غينيا"
      },
      "bre": {
        "official": "Republik Ginea",
        "common": "Ginea"
      },
      "ces": {
        "official": "Guinejská republika",
        "common": "Guinea"
      },
      "cym": {
        "official": "Republic of Guinea",
        "common": "Guinea"
      },
      "deu": {
        "official": "Republik Guinea",
        "common": "Guinea"
      },
      "est": {
        "official": "Guinea Vabariik",
        "common": "Guinea"
      },
      "fin": {
        "official": "Guinean tasavalta",
        "common": "Guinea"
      },
      "fra": {
        "official": "République de Guinée",
        "common": "Guinée"
      },
      "hrv": {
        "official": "Republika Gvineja",
        "common": "Gvineja"
      },
      "hun": {
        "official": "Guineai Köztársaság",
        "common": "Guinea"
      },
      "ita": {
        "official": "Repubblica di Guinea",
        "common": "Guinea"
      },
      "jpn": {
        "official": "ギニア共和国",
        "common": "ギニア"
      },
      "kor": {
        "official": "기니 공화국",
        "common": "기니"
      },
      "nld": {
        "official": "Republiek Guinee",
        "common": "Guinee"
      },
      "per": {
        "official": "مملکت مستقل پاپوآ گینه نو",
        "common": "پاپوآ گینه نو"
      },
      "pol": {
        "official": "Republika Gwinei",
        "common": "Gwinea"
      },
      "por": {
        "official": "República da Guiné",
        "common": "Guiné"
      },
      "rus": {
        "official": "Республика Гвинея",
        "common": "Гвинея"
      },
      "slk": {
        "official": "Guinejská republika",
        "common": "Guinea"
      },
      "spa": {
        "official": "República de Guinea",
        "common": "Guinea"
      },
      "swe": {
        "official": "Republiken Guinea",
        "common": "Guinea"
      },
      "tur": {
        "official": "Gine Cumhuriyeti",
        "common": "Gine"
      },
      "urd": {
        "official": "جمہوریہ گنی",
        "common": "گنی"
      },
      "zho": {
        "official": "几内亚共和国",
        "common": "几内亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guinean",
        "m": "Guinean"
      },
      "fra": {
        "f": "Guinéenne",
        "m": "Guinéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ae.png",
      "svg": "https://flagcdn.com/ae.svg"
    },
    "cca2": "AE",
    "capital": [
      "Abu Dhabi"
    ],
    "altSpellings": [
      "AE",
      "UAE",
      "Emirates"
    ],
    "translations": {
      "ara": {
        "official": "الإمارات العربية المتحدة",
        "common": "دولة الإمارات العربية المتحدة"
      },
      "bre": {
        "official": "Emirelezhioù Arab Unanet",
        "common": "Emirelezhioù Arab Unanet"
      },
      "ces": {
        "official": "Spojené arabské emiráty",
        "common": "Arabské emiráty"
      },
      "cym": {
        "official": "United Arab Emirates",
        "common": "United Arab Emirates"
      },
      "deu": {
        "official": "Vereinigte Arabische Emirate",
        "common": "Vereinigte Arabische Emirate"
      },
      "est": {
        "official": "Araabia Ühendemiraadid",
        "common": "Araabia Ühendemiraadid"
      },
      "fin": {
        "official": "Yhdistyneet arabiemiirikunnat",
        "common": "Arabiemiraatit"
      },
      "fra": {
        "official": "Émirats arabes unis",
        "common": "Émirats arabes unis"
      },
      "hrv": {
        "official": "Ujedinjeni Arapski Emirati",
        "common": "Arapski Emirati"
      },
      "hun": {
        "official": "Egyesült Arab Emírségek",
        "common": "Egyesült Arab Emírségek"
      },
      "ita": {
        "official": "Emirati Arabi Uniti",
        "common": "Emirati Arabi Uniti"
      },
      "jpn": {
        "official": "アラブ首長国連邦",
        "common": "アラブ首長国連邦"
      },
      "kor": {
        "official": "아랍 토후국 연방",
        "common": "아랍에미리트"
      },
      "nld": {
        "official": "Verenigde Arabische Emiraten",
        "common": "Verenigde Arabische Emiraten"
      },
      "per": {
        "official": "امارات متحده عربی",
        "common": "امارات"
      },
      "pol": {
        "official": "Zjednoczone Emiraty Arabskie",
        "common": "Emiraty Arabskie"
      },
      "por": {
        "official": "Emirados Árabes Unidos",
        "common": "Emirados Árabes Unidos"
      },
      "rus": {
        "official": "Объединенные Арабские Эмираты",
        "common": "Объединённые Арабские Эмираты"
      },
      "slk": {
        "official": "Spojené arabské emiráty",
        "common": "Arabské emiráty"
      },
      "spa": {
        "official": "Emiratos Árabes Unidos",
        "common": "Emiratos Árabes Unidos"
      },
      "swe": {
        "official": "Förenade Arabemiraten",
        "common": "Förenade Arabemiraten"
      },
      "tur": {
        "official": "Birleşik Arap Emirlikleri",
        "common": "Birleşik Arap Emirlikleri"
      },
      "urd": {
        "official": "متحدہ عرب امارات",
        "common": "متحدہ عرب امارات"
      },
      "zho": {
        "official": "阿拉伯联合酋长国",
        "common": "阿拉伯联合酋长国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Emirati",
        "m": "Emirati"
      },
      "fra": {
        "f": "Emirienne",
        "m": "Emirien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tc.png",
      "svg": "https://flagcdn.com/tc.svg"
    },
    "cca2": "TC",
    "capital": [
      "Cockburn Town"
    ],
    "altSpellings": [
      "TC"
    ],
    "translations": {
      "ara": {
        "official": "جزر توركس وكايكوس",
        "common": "جزر توركس وكايكوس"
      },
      "bre": {
        "official": "Inizi Turks ha Caicos",
        "common": "Inizi Turks ha Caicos"
      },
      "ces": {
        "official": "Turks a Caicos",
        "common": "Turks a Caicos"
      },
      "cym": {
        "official": "Turks and Caicos Islands",
        "common": "Turks and Caicos Islands"
      },
      "deu": {
        "official": "Turks und Caicos Inseln",
        "common": "Turks-und Caicosinseln"
      },
      "est": {
        "official": "Turksi ja Caicose saared",
        "common": "Turks ja Caicos"
      },
      "fin": {
        "official": "Turks-ja Caicossaaret",
        "common": "Turks-ja Caicossaaret"
      },
      "fra": {
        "official": "Îles Turques et Caïques",
        "common": "Îles Turques-et-Caïques"
      },
      "hrv": {
        "official": "Otoci Turks i Caicos",
        "common": "Otoci Turks i Caicos"
      },
      "hun": {
        "official": "Turks- és Caicos-szigetek",
        "common": "Turks- és Caicos-szigetek"
      },
      "ita": {
        "official": "Turks e Caicos",
        "common": "Isole Turks e Caicos"
      },
      "jpn": {
        "official": "タークス·カイコス諸島",
        "common": "タークス・カイコス諸島"
      },
      "kor": {
        "official": "터크스 케이커스 제도",
        "common": "터크스 케이커스 제도"
      },
      "nld": {
        "official": "Turks-en Caicoseilanden",
        "common": "Turks-en Caicoseilanden"
      },
      "per": {
        "official": "جزایر تورکس و کایکوس",
        "common": "جزایر تورکس و کایکوس"
      },
      "pol": {
        "official": "Turks i Caicos",
        "common": "Turks i Caicos"
      },
      "por": {
        "official": "Ilhas Turks e Caicos",
        "common": "Ilhas Turks e Caicos"
      },
      "rus": {
        "official": "Теркс и Кайкос острова",
        "common": "Теркс и Кайкос"
      },
      "slk": {
        "official": "Ostrovy Turks a Caicos",
        "common": "Turks a Caicos"
      },
      "spa": {
        "official": "Islas Turcas y Caicos",
        "common": "Islas Turks y Caicos"
      },
      "swe": {
        "official": "Turks- och Caicosöarna",
        "common": "Turks- och Caicosöarna"
      },
      "tur": {
        "official": "Turks ve Caicos Adaları",
        "common": "Turks ve Caicos Adaları"
      },
      "urd": {
        "official": "جزائر کیکس و ترکیہ",
        "common": "جزائر کیکس و ترکیہ"
      },
      "zho": {
        "official": "特克斯和凯科斯群岛",
        "common": "特克斯和凯科斯群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Turks and Caicos Islander",
        "m": "Turks and Caicos Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/vg.png",
      "svg": "https://flagcdn.com/vg.svg"
    },
    "cca2": "VG",
    "capital": [
      "Road Town"
    ],
    "altSpellings": [
      "VG",
      "Virgin Islands, British"
    ],
    "translations": {
      "ara": {
        "official": "جزر العذراء البريطانية",
        "common": "جزر العذراء"
      },
      "bre": {
        "official": "Inizi Gwerc'h Breizhveurat",
        "common": "Inizi Gwerc'h Breizhveurat"
      },
      "ces": {
        "official": "Britské Panenské ostrovy",
        "common": "Britské Panenské ostrovy"
      },
      "cym": {
        "official": "Virgin Islands",
        "common": "British Virgin Islands"
      },
      "deu": {
        "official": "Jungferninseln",
        "common": "Britische Jungferninseln"
      },
      "est": {
        "official": "Neitsisaared",
        "common": "Briti Neitsisaared"
      },
      "fin": {
        "official": "Brittiläiset Neitsytsaaret",
        "common": "Neitsytsaaret"
      },
      "fra": {
        "official": "îles Vierges",
        "common": "Îles Vierges britanniques"
      },
      "hrv": {
        "official": "Djevičanski Otoci",
        "common": "Britanski Djevičanski Otoci"
      },
      "hun": {
        "official": "Brit Virgin-szigetek",
        "common": "Brit Virgin-szigetek"
      },
      "ita": {
        "official": "Isole Vergini",
        "common": "Isole Vergini Britanniche"
      },
      "jpn": {
        "official": "バージン諸島",
        "common": "イギリス領ヴァージン諸島"
      },
      "kor": {
        "official": "영국령 버진아일랜드",
        "common": "영국령 버진아일랜드"
      },
      "nld": {
        "official": "Maagdeneilanden",
        "common": "Britse Maagdeneilanden"
      },
      "per": {
        "official": "جزایر ویرجین بریتانیا",
        "common": "جزایر ویرجین بریتانیا"
      },
      "pol": {
        "official": "Brytyjskie Wyspy Dziewicze",
        "common": "Brytyjskie Wyspy Dziewicze"
      },
      "por": {
        "official": "Ilhas Virgens",
        "common": "Ilhas Virgens"
      },
      "rus": {
        "official": "Виргинские острова",
        "common": "Британские Виргинские острова"
      },
      "slk": {
        "official": "Panenské ostrovy",
        "common": "Panenské ostrovy"
      },
      "spa": {
        "official": "Islas Vírgenes",
        "common": "Islas Vírgenes del Reino Unido"
      },
      "swe": {
        "official": "Brittiska Jungfruöarna",
        "common": "Brittiska Jungfruöarna"
      },
      "tur": {
        "official": "Virjin Adaları",
        "common": "Virjin Adaları"
      },
      "urd": {
        "official": "برطانوی جزائر ورجن",
        "common": "برطانوی جزائر ورجن"
      },
      "zho": {
        "official": "英属维尔京群岛",
        "common": "英属维尔京群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Virgin Islander",
        "m": "Virgin Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/li.png",
      "svg": "https://flagcdn.com/li.svg"
    },
    "cca2": "LI",
    "capital": [
      "Vaduz"
    ],
    "altSpellings": [
      "LI",
      "Principality of Liechtenstein",
      "Fürstentum Liechtenstein"
    ],
    "translations": {
      "ara": {
        "official": "إمارة ليختنشتاين",
        "common": "ليختنشتاين"
      },
      "bre": {
        "official": "Priñselezh Liechtenstein",
        "common": "Liechtenstein"
      },
      "ces": {
        "official": "Knížectví Lichtenštejnské",
        "common": "Lichtenštejnsko"
      },
      "cym": {
        "official": "Principality of Liechtenstein",
        "common": "Liechtenstein"
      },
      "deu": {
        "official": "Fürstentum Liechtenstein",
        "common": "Liechtenstein"
      },
      "est": {
        "official": "Liechtensteini Vürstiriik",
        "common": "Liechtenstein"
      },
      "fin": {
        "official": "Liechensteinin ruhtinaskunta",
        "common": "Liechenstein"
      },
      "fra": {
        "official": "Principauté du Liechtenstein",
        "common": "Liechtenstein"
      },
      "hrv": {
        "official": "Kneževina Lihtenštajn",
        "common": "Lihtenštajn"
      },
      "hun": {
        "official": "Liechtensteini Hercegség",
        "common": "Liechtenstein"
      },
      "ita": {
        "official": "Principato del Liechtenstein",
        "common": "Liechtenstein"
      },
      "jpn": {
        "official": "リヒテンシュタイン公国",
        "common": "リヒテンシュタイン"
      },
      "kor": {
        "official": "리히텐슈타인 공국",
        "common": "리히텐슈타인"
      },
      "nld": {
        "official": "Vorstendom Liechtenstein",
        "common": "Liechtenstein"
      },
      "per": {
        "official": "شاهزاده‌نشین لیختن‌اشتاین",
        "common": "لیختن‌اشتاین"
      },
      "pol": {
        "official": "Księstwo Liechtensteinu",
        "common": "Liechtenstein"
      },
      "por": {
        "official": "Principado de Liechtenstein",
        "common": "Liechtenstein"
      },
      "rus": {
        "official": "Княжество Лихтенштейн",
        "common": "Лихтенштейн"
      },
      "slk": {
        "official": "Lichtenštajnské kniežatstvo",
        "common": "Lichtenštajnsko"
      },
      "spa": {
        "official": "Principado de Liechtenstein",
        "common": "Liechtenstein"
      },
      "swe": {
        "official": "Furstendömet Liechtenstein",
        "common": "Liechtenstein"
      },
      "tur": {
        "official": "Lihtenştayn Prensliği",
        "common": "Lihtenştayn"
      },
      "urd": {
        "official": "امارات لیختینستائن",
        "common": "لیختینستائن"
      },
      "zho": {
        "official": "列支敦士登公国",
        "common": "列支敦士登"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Liechtensteiner",
        "m": "Liechtensteiner"
      },
      "fra": {
        "f": "Liechtensteinoise",
        "m": "Liechtensteinois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ba.png",
      "svg": "https://flagcdn.com/ba.svg"
    },
    "cca2": "BA",
    "capital": [
      "Sarajevo"
    ],
    "altSpellings": [
      "BA",
      "Bosnia-Herzegovina",
      "Босна и Херцеговина"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية البوسنة والهرسك",
        "common": "البوسنة والهرسك"
      },
      "bre": {
        "official": "Bosnia-ha-Herzegovina",
        "common": "Bosnia-ha-Herzegovina"
      },
      "ces": {
        "official": "Bosna a Hercegovina",
        "common": "Bosna a Hercegovina"
      },
      "cym": {
        "official": "Bosnia a Hercegovina",
        "common": "Bosnia a Hercegovina"
      },
      "deu": {
        "official": "Bosnien und Herzegowina",
        "common": "Bosnien und Herzegowina"
      },
      "est": {
        "official": "Bosnia ja Hertsegoviina",
        "common": "Bosnia ja Hertsegoviina"
      },
      "fin": {
        "official": "Bosnia ja Hertsegovina",
        "common": "Bosnia ja Hertsegovina"
      },
      "fra": {
        "official": "Bosnie-et-Herzégovine",
        "common": "Bosnie-Herzégovine"
      },
      "hrv": {
        "official": "Bosna i Hercegovina",
        "common": "Bosna i Hercegovina"
      },
      "hun": {
        "official": "Bosznia-Hercegovina",
        "common": "Bosznia-Hercegovina"
      },
      "ita": {
        "official": "Bosnia-Erzegovina",
        "common": "Bosnia ed Erzegovina"
      },
      "jpn": {
        "official": "ボスニア·ヘルツェゴビナ",
        "common": "ボスニア・ヘルツェゴビナ"
      },
      "kor": {
        "official": "보스니아 헤르체고비나",
        "common": "보스니아 헤르체고비나"
      },
      "nld": {
        "official": "Bosnië-Herzegovina",
        "common": "Bosnië en Herzegovina"
      },
      "per": {
        "official": "بوسنی و هرزگوین",
        "common": "بوسنی و هرزگوین"
      },
      "pol": {
        "official": "Bośnia i Hercegowina",
        "common": "Bośnia i Hercegowina"
      },
      "por": {
        "official": "Bósnia e Herzegovina",
        "common": "Bósnia e Herzegovina"
      },
      "rus": {
        "official": "Босния и Герцеговина",
        "common": "Босния и Герцеговина"
      },
      "slk": {
        "official": "Republika Bosny a Hercegoviny",
        "common": "Bosna a Hercegovina"
      },
      "spa": {
        "official": "Bosnia y Herzegovina",
        "common": "Bosnia y Herzegovina"
      },
      "swe": {
        "official": "Bosnien och Hercegovina",
        "common": "Bosnien och Hercegovina"
      },
      "tur": {
        "official": "Bosna ve Hersek",
        "common": "Bosna-Hersek"
      },
      "urd": {
        "official": "بوسنیا و ہرزیگووینا",
        "common": "بوسنیا و ہرزیگووینا"
      },
      "zho": {
        "official": "波斯尼亚和黑塞哥维那",
        "common": "波斯尼亚和黑塞哥维那"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bosnian, Herzegovinian",
        "m": "Bosnian, Herzegovinian"
      },
      "fra": {
        "f": "Bosnienne",
        "m": "Bosnien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mw.png",
      "svg": "https://flagcdn.com/mw.svg"
    },
    "cca2": "MW",
    "capital": [
      "Lilongwe"
    ],
    "altSpellings": [
      "MW",
      "Republic of Malawi"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية مالاوي",
        "common": "مالاوي"
      },
      "bre": {
        "official": "Republik Malawi",
        "common": "Malawi"
      },
      "ces": {
        "official": "Malawiská republika",
        "common": "Malawi"
      },
      "cym": {
        "official": "Republic of Malawi",
        "common": "Malawi"
      },
      "deu": {
        "official": "Republik Malawi",
        "common": "Malawi"
      },
      "est": {
        "official": "Malawi Vabariik",
        "common": "Malawi"
      },
      "fin": {
        "official": "Malawin tasavalta",
        "common": "Malawi"
      },
      "fra": {
        "official": "République du Malawi",
        "common": "Malawi"
      },
      "hrv": {
        "official": "Republika Malavi",
        "common": "Malavi"
      },
      "hun": {
        "official": "Malawi Köztársaság",
        "common": "Malawi"
      },
      "ita": {
        "official": "Repubblica del Malawi",
        "common": "Malawi"
      },
      "jpn": {
        "official": "マラウイ共和国",
        "common": "マラウイ"
      },
      "kor": {
        "official": "말라위 공화국",
        "common": "말라위"
      },
      "nld": {
        "official": "Republiek Malawi",
        "common": "Malawi"
      },
      "per": {
        "official": "جمهوری مالاوی",
        "common": "مالاوی"
      },
      "pol": {
        "official": "Republika Malawi",
        "common": "Malawi"
      },
      "por": {
        "official": "República do Malawi",
        "common": "Malawi"
      },
      "rus": {
        "official": "Республика Малави",
        "common": "Малави"
      },
      "slk": {
        "official": "Malawijská republika",
        "common": "Malawi"
      },
      "spa": {
        "official": "República de Malawi",
        "common": "Malawi"
      },
      "swe": {
        "official": "Republiken Malawi",
        "common": "Malawi"
      },
      "tur": {
        "official": "Malavi Cumhuriyeti",
        "common": "Malavi"
      },
      "urd": {
        "official": "جمہوریہ ملاوی",
        "common": "ملاوی"
      },
      "zho": {
        "official": "马拉维共和国",
        "common": "马拉维"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Malawian",
        "m": "Malawian"
      },
      "fra": {
        "f": "Malawienne",
        "m": "Malawien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bt.png",
      "svg": "https://flagcdn.com/bt.svg"
    },
    "cca2": "BT",
    "capital": [
      "Thimphu"
    ],
    "altSpellings": [
      "BT",
      "Kingdom of Bhutan"
    ],
    "translations": {
      "ara": {
        "official": "مملكة بوتان",
        "common": "بوتان"
      },
      "bre": {
        "official": "Rouantelezh Bhoutan",
        "common": "Bhoutan"
      },
      "ces": {
        "official": "Bhútánské království",
        "common": "Bhútán"
      },
      "cym": {
        "official": "Teyrnas Bhwtan",
        "common": "Bhwtan"
      },
      "deu": {
        "official": "Königreich Bhutan",
        "common": "Bhutan"
      },
      "est": {
        "official": "Bhutani Kuningriik",
        "common": "Bhutan"
      },
      "fin": {
        "official": "Bhutanin kuningaskunta",
        "common": "Bhutan"
      },
      "fra": {
        "official": "Royaume du Bhoutan",
        "common": "Bhoutan"
      },
      "hrv": {
        "official": "Kraljevina Butan",
        "common": "Butan"
      },
      "hun": {
        "official": "Bhutáni Királyság",
        "common": "Bhután"
      },
      "ita": {
        "official": "Regno del Bhutan",
        "common": "Bhutan"
      },
      "jpn": {
        "official": "ブータン王国",
        "common": "ブータン"
      },
      "kor": {
        "official": "부탄 왕국",
        "common": "부탄"
      },
      "nld": {
        "official": "Koninkrijk Bhutan",
        "common": "Bhutan"
      },
      "per": {
        "official": "پادشاهی بوتان",
        "common": "بوتان"
      },
      "pol": {
        "official": "Bhutan",
        "common": "Bhutan"
      },
      "por": {
        "official": "Reino do Butão",
        "common": "Butão"
      },
      "rus": {
        "official": "Королевство Бутан",
        "common": "Бутан"
      },
      "slk": {
        "official": "Bhutánske krâľovstvo",
        "common": "Bhután"
      },
      "spa": {
        "official": "Reino de Bután",
        "common": "Bután"
      },
      "swe": {
        "official": "Konungariket Bhutan",
        "common": "Bhutan"
      },
      "tur": {
        "official": "Butan Krallığı",
        "common": "Butan"
      },
      "urd": {
        "official": "سلطنت بھوٹان",
        "common": "بھوٹان"
      },
      "zho": {
        "official": "不丹王国",
        "common": "不丹"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bhutanese",
        "m": "Bhutanese"
      },
      "fra": {
        "f": "Bhoutanaise",
        "m": "Bhoutanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cm.png",
      "svg": "https://flagcdn.com/cm.svg"
    },
    "cca2": "CM",
    "capital": [
      "Yaoundé"
    ],
    "altSpellings": [
      "CM",
      "Republic of Cameroon",
      "République du Cameroun"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الكاميرون",
        "common": "الكاميرون"
      },
      "bre": {
        "official": "Republik Kameroun",
        "common": "Kameroun"
      },
      "ces": {
        "official": "Kamerunská republika",
        "common": "Kamerun"
      },
      "cym": {
        "official": "Gweriniaeth Camerŵn",
        "common": "Camerŵn"
      },
      "deu": {
        "official": "Republik Kamerun",
        "common": "Kamerun"
      },
      "est": {
        "official": "Kameruni Vabariik",
        "common": "Kamerun"
      },
      "fin": {
        "official": "Kamerunin tasavalta",
        "common": "Kamerun"
      },
      "fra": {
        "official": "République du Cameroun",
        "common": "Cameroun"
      },
      "hrv": {
        "official": "Republika Kamerun",
        "common": "Kamerun"
      },
      "hun": {
        "official": "Kameruni Köztársaság",
        "common": "Kamerun"
      },
      "ita": {
        "official": "Repubblica del Camerun",
        "common": "Camerun"
      },
      "jpn": {
        "official": "カメルーン共和国",
        "common": "カメルーン"
      },
      "kor": {
        "official": "카메룬 공화국",
        "common": "카메룬"
      },
      "nld": {
        "official": "Republiek Kameroen",
        "common": "Kameroen"
      },
      "per": {
        "official": "جمهوری کامِرون",
        "common": "کامِرون"
      },
      "pol": {
        "official": "Republika Kamerunu",
        "common": "Kamerun"
      },
      "por": {
        "official": "República dos Camarões",
        "common": "Camarões"
      },
      "rus": {
        "official": "Республика Камерун",
        "common": "Камерун"
      },
      "slk": {
        "official": "Kamerunská republika",
        "common": "Kamerun"
      },
      "spa": {
        "official": "República de Camerún",
        "common": "Camerún"
      },
      "swe": {
        "official": "Republiken Kamerun",
        "common": "Kamerun"
      },
      "tur": {
        "official": "Kamerun Cumhuriyeti",
        "common": "Kamerun"
      },
      "urd": {
        "official": "جمہوریہ کیمرون",
        "common": "کیمرون"
      },
      "zho": {
        "official": "喀麦隆共和国",
        "common": "喀麦隆"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Cameroonian",
        "m": "Cameroonian"
      },
      "fra": {
        "f": "Camerounaise",
        "m": "Camerounais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ad.png",
      "svg": "https://flagcdn.com/ad.svg"
    },
    "cca2": "AD",
    "capital": [
      "Andorra la Vella"
    ],
    "altSpellings": [
      "AD",
      "Principality of Andorra",
      "Principat d'Andorra"
    ],
    "translations": {
      "ara": {
        "official": "إمارة أندورا",
        "common": "أندورا"
      },
      "bre": {
        "official": "Priñselezh Andorra",
        "common": "Andorra"
      },
      "ces": {
        "official": "Andorrské knížectví",
        "common": "Andorra"
      },
      "cym": {
        "official": "Tywysogaeth Andorra",
        "common": "Andorra"
      },
      "deu": {
        "official": "Fürstentum Andorra",
        "common": "Andorra"
      },
      "est": {
        "official": "Andorra Vürstiriik",
        "common": "Andorra"
      },
      "fin": {
        "official": "Andorran ruhtinaskunta",
        "common": "Andorra"
      },
      "fra": {
        "official": "Principauté d'Andorre",
        "common": "Andorre"
      },
      "hrv": {
        "official": "Kneževina Andora",
        "common": "Andora"
      },
      "hun": {
        "official": "Andorra",
        "common": "Andorra"
      },
      "ita": {
        "official": "Principato di Andorra",
        "common": "Andorra"
      },
      "jpn": {
        "official": "アンドラ公国",
        "common": "アンドラ"
      },
      "kor": {
        "official": "안도라 공국",
        "common": "안도라"
      },
      "nld": {
        "official": "Prinsdom Andorra",
        "common": "Andorra"
      },
      "per": {
        "official": "شاهزاده‌نشین آندورا",
        "common": "آندورا"
      },
      "pol": {
        "official": "Księstwo Andory",
        "common": "Andora"
      },
      "por": {
        "official": "Principado de Andorra",
        "common": "Andorra"
      },
      "rus": {
        "official": "Княжество Андорра",
        "common": "Андорра"
      },
      "slk": {
        "official": "Andorrské kniežatstvo",
        "common": "Andorra"
      },
      "spa": {
        "official": "Principado de Andorra",
        "common": "Andorra"
      },
      "swe": {
        "official": "Furstendömet Andorra",
        "common": "Andorra"
      },
      "tur": {
        "official": "Andorra Prensliği",
        "common": "Andorra"
      },
      "urd": {
        "official": "اماراتِ انڈورا",
        "common": "انڈورا"
      },
      "zho": {
        "official": "安道尔公国",
        "common": "安道尔"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Andorran",
        "m": "Andorran"
      },
      "fra": {
        "f": "Andorrane",
        "m": "Andorran"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gp.png",
      "svg": "https://flagcdn.com/gp.svg"
    },
    "cca2": "GP",
    "capital": [
      "Basse-Terre"
    ],
    "altSpellings": [
      "GP",
      "Gwadloup"
    ],
    "translations": {
      "ara": {
        "official": "غوادلوب",
        "common": "غوادلوب"
      },
      "bre": {
        "official": "Gwadeloup",
        "common": "Gwadeloup"
      },
      "ces": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "cym": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "deu": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "est": {
        "official": "Guadeloupe’i ja sõltkondade departemang",
        "common": "Guadeloupe"
      },
      "fin": {
        "official": "Guadeloupen departmentti",
        "common": "Guadeloupe"
      },
      "fra": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "hrv": {
        "official": "Gvadalupa",
        "common": "Gvadalupa"
      },
      "hun": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "ita": {
        "official": "Guadeloupe",
        "common": "Guadeloupa"
      },
      "jpn": {
        "official": "グアドループ島",
        "common": "グアドループ"
      },
      "kor": {
        "official": "과들루프",
        "common": "과들루프"
      },
      "nld": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "per": {
        "official": "گوادلوپ",
        "common": "گوادلوپ"
      },
      "pol": {
        "official": "Gwadelupa",
        "common": "Gwadelupa"
      },
      "por": {
        "official": "Guadalupe",
        "common": "Guadalupe"
      },
      "rus": {
        "official": "Гваделупа",
        "common": "Гваделупа"
      },
      "slk": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "spa": {
        "official": "Guadalupe",
        "common": "Guadalupe"
      },
      "swe": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "tur": {
        "official": "Guadeloupe",
        "common": "Guadeloupe"
      },
      "urd": {
        "official": "گواڈیلوپ",
        "common": "گواڈیلوپ"
      },
      "zho": {
        "official": "瓜德罗普岛",
        "common": "瓜德罗普岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guadeloupian",
        "m": "Guadeloupian"
      },
      "fra": {
        "f": "Guadeloupéenne",
        "m": "Guadeloupéen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cg.png",
      "svg": "https://flagcdn.com/cg.svg"
    },
    "cca2": "CG",
    "capital": [
      "Brazzaville"
    ],
    "altSpellings": [
      "CG",
      "Congo",
      "Congo-Brazzaville"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الكونغو",
        "common": "جمهورية الكونفو"
      },
      "bre": {
        "official": "Republik Kongo",
        "common": "Kongo-Brazzaville"
      },
      "ces": {
        "official": "Konžská republika",
        "common": "Kongo"
      },
      "cym": {
        "official": "Gweriniaeth y Congo",
        "common": "Gweriniaeth y Congo"
      },
      "deu": {
        "official": "Republik Kongo",
        "common": "Kongo"
      },
      "est": {
        "official": "Kongo Vabariik",
        "common": "Kongo Vabariik"
      },
      "fin": {
        "official": "Kongon tasavalta",
        "common": "Kongo-Brazzaville"
      },
      "fra": {
        "official": "République du Congo",
        "common": "Congo"
      },
      "hrv": {
        "official": "Republika Kongo",
        "common": "Kongo"
      },
      "hun": {
        "official": "Kongói Köztársaság",
        "common": "Kongói Köztársaság"
      },
      "ita": {
        "official": "Repubblica del Congo",
        "common": "Congo"
      },
      "jpn": {
        "official": "コンゴ共和国",
        "common": "コンゴ共和国"
      },
      "kor": {
        "official": "콩고",
        "common": "콩고"
      },
      "nld": {
        "official": "Republiek Congo",
        "common": "Congo"
      },
      "per": {
        "official": "جمهوری برازاویل کُنگو",
        "common": "جمهوری کُنگو"
      },
      "pol": {
        "official": "Republika Konga",
        "common": "Kongo"
      },
      "por": {
        "official": "República do Congo",
        "common": "Congo"
      },
      "rus": {
        "official": "Республика Конго",
        "common": "Республика Конго"
      },
      "slk": {
        "official": "Konžská republika",
        "common": "Kongo"
      },
      "spa": {
        "official": "República del Congo",
        "common": "Congo"
      },
      "swe": {
        "official": "Republiken Kongo",
        "common": "Kongo-Brazzaville"
      },
      "tur": {
        "official": "Kongo Cumhuriyeti",
        "common": "Kongo Cumhuriyeti"
      },
      "urd": {
        "official": "جمہوریہ کانگو",
        "common": "جمہوریہ کانگو"
      },
      "zho": {
        "official": "刚果共和国",
        "common": "刚果"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Congolese",
        "m": "Congolese"
      },
      "fra": {
        "f": "Congolaise",
        "m": "Congolais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/re.png",
      "svg": "https://flagcdn.com/re.svg"
    },
    "cca2": "RE",
    "capital": [
      "Saint-Denis"
    ],
    "altSpellings": [
      "RE",
      "Reunion"
    ],
    "translations": {
      "ara": {
        "official": "جزيرة لا ريونيون",
        "common": "لا ريونيون"
      },
      "bre": {
        "official": "Enez ar Reünion",
        "common": "Reünion"
      },
      "ces": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "cym": {
        "official": "Réunion Island",
        "common": "Réunion"
      },
      "deu": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "est": {
        "official": "Réunioni departemang",
        "common": "Réunion"
      },
      "fin": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "fra": {
        "official": "Ile de la Réunion",
        "common": "Réunion"
      },
      "hrv": {
        "official": "Réunion Island",
        "common": "Réunion"
      },
      "hun": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "ita": {
        "official": "Réunion",
        "common": "Riunione"
      },
      "jpn": {
        "official": "レユニオン島",
        "common": "レユニオン"
      },
      "kor": {
        "official": "레위니옹",
        "common": "레위니옹"
      },
      "nld": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "per": {
        "official": "رئونیون",
        "common": "رئونیون"
      },
      "pol": {
        "official": "Reunion",
        "common": "Reunion"
      },
      "por": {
        "official": "Ilha da Reunião",
        "common": "Reunião"
      },
      "rus": {
        "official": "Реюньон",
        "common": "Реюньон"
      },
      "slk": {
        "official": "Réunionský zámorský departmán",
        "common": "Réunion"
      },
      "spa": {
        "official": "Isla de la Reunión",
        "common": "Reunión"
      },
      "swe": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "tur": {
        "official": "Réunion",
        "common": "Réunion"
      },
      "urd": {
        "official": "رے یونیوں جزیرہ",
        "common": "رے یونیوں"
      },
      "zho": {
        "official": "留尼旺岛",
        "common": "留尼旺岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Réunionese",
        "m": "Réunionese"
      },
      "fra": {
        "f": "Réunionnaise",
        "m": "Réunionnais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bf.png",
      "svg": "https://flagcdn.com/bf.svg"
    },
    "cca2": "BF",
    "capital": [
      "Ouagadougou"
    ],
    "altSpellings": [
      "BF"
    ],
    "translations": {
      "ara": {
        "official": "بوركينا فاسو",
        "common": "بوركينا فاسو"
      },
      "bre": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "ces": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "cym": {
        "official": "Bwrcina Ffaso",
        "common": "Bwrcina Ffaso"
      },
      "deu": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "est": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "fin": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "fra": {
        "official": "République du Burkina",
        "common": "Burkina Faso"
      },
      "hrv": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "hun": {
        "official": "Burkina Faso",
        "common": "Burkina"
      },
      "ita": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "jpn": {
        "official": "ブルキナファソ",
        "common": "ブルキナファソ"
      },
      "kor": {
        "official": "부르키나파소",
        "common": "부르키나파소"
      },
      "nld": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "per": {
        "official": "بورکینافاسو",
        "common": "بورکینافاسو"
      },
      "pol": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "por": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "rus": {
        "official": "Буркина -Фасо",
        "common": "Буркина-Фасо"
      },
      "slk": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "spa": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "swe": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "tur": {
        "official": "Burkina Faso",
        "common": "Burkina Faso"
      },
      "urd": {
        "official": "برکینا فاسو",
        "common": "برکینا فاسو"
      },
      "zho": {
        "official": "布基纳法索",
        "common": "布基纳法索"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Burkinabe",
        "m": "Burkinabe"
      },
      "fra": {
        "f": "Burkinabée",
        "m": "Burkinabé"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/jo.png",
      "svg": "https://flagcdn.com/jo.svg"
    },
    "cca2": "JO",
    "capital": [
      "Amman"
    ],
    "altSpellings": [
      "JO",
      "Hashemite Kingdom of Jordan",
      "al-Mamlakah al-Urdunīyah al-Hāshimīyah"
    ],
    "translations": {
      "ara": {
        "official": "المملكة الأردنية الهاشمية",
        "common": "الأردن"
      },
      "bre": {
        "official": "Rouantelezh hachemit Jordania",
        "common": "Jordania"
      },
      "ces": {
        "official": "Jordánské hášimovské království",
        "common": "Jordánsko"
      },
      "cym": {
        "official": "Hashemite Kingdom of Jordan",
        "common": "Jordan"
      },
      "deu": {
        "official": "Haschemitisches Königreich Jordanien",
        "common": "Jordanien"
      },
      "est": {
        "official": "Jordaania Hašimiidi Kuningriik",
        "common": "Jordaania"
      },
      "fin": {
        "official": "Jordanian hašemiittinen kunigaskunta",
        "common": "Jordania"
      },
      "fra": {
        "official": "Royaume hachémite de Jordanie",
        "common": "Jordanie"
      },
      "hrv": {
        "official": "Hašemitske Kraljevine Jordan",
        "common": "Jordan"
      },
      "hun": {
        "official": "Jordánia",
        "common": "Jordánia"
      },
      "ita": {
        "official": "Regno hascemita di Giordania",
        "common": "Giordania"
      },
      "jpn": {
        "official": "ヨルダン·ハシミテ王国",
        "common": "ヨルダン"
      },
      "kor": {
        "official": "요르단 하심 왕국",
        "common": "요르단"
      },
      "nld": {
        "official": "Hasjemitisch Koninkrijk Jordanië",
        "common": "Jordanië"
      },
      "per": {
        "official": "پادشاهی اُردُن هاشمی",
        "common": "اردن"
      },
      "pol": {
        "official": "Jordańskie Królestwo Haszymidzkie",
        "common": "Jordania"
      },
      "por": {
        "official": "Reino Hachemita da Jordânia",
        "common": "Jordânia"
      },
      "rus": {
        "official": "Иорданского Хашимитского Королевства",
        "common": "Иордания"
      },
      "slk": {
        "official": "Jordánske hášimovské kráľovstvo",
        "common": "Jordánsko"
      },
      "spa": {
        "official": "Reino Hachemita de Jordania",
        "common": "Jordania"
      },
      "swe": {
        "official": "Hashimitiska kungadömet Jordanien",
        "common": "Jordanien"
      },
      "tur": {
        "official": "Ürdün Hâşimi Krallığı",
        "common": "Ürdün"
      },
      "urd": {
        "official": "ھاشمی مملکتِ اردن",
        "common": "اردن"
      },
      "zho": {
        "official": "约旦哈希姆王国",
        "common": "约旦"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Jordanian",
        "m": "Jordanian"
      },
      "fra": {
        "f": "Jordanienne",
        "m": "Jordanien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/cd.png",
      "svg": "https://flagcdn.com/cd.svg"
    },
    "cca2": "CD",
    "capital": [
      "Kinshasa"
    ],
    "altSpellings": [
      "CD",
      "DR Congo",
      "Congo-Kinshasa",
      "Congo, the Democratic Republic of the",
      "DRC"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الكونغو الديمقراطية",
        "common": "الكونغو"
      },
      "bre": {
        "official": "Republik Demokratel Kongo",
        "common": "Kongo-Kinshasa"
      },
      "ces": {
        "official": "Demokratická republika Kongo",
        "common": "DR Kongo"
      },
      "cym": {
        "official": "Gweriniaeth Ddemocrataidd Congo",
        "common": "Gweriniaeth Ddemocrataidd Congo"
      },
      "deu": {
        "official": "Demokratische Republik Kongo",
        "common": "Kongo (Dem. Rep.)"
      },
      "est": {
        "official": "Kongo Demokraatlik Vabariik",
        "common": "Kongo DV"
      },
      "fin": {
        "official": "Kongon demokraattinen tasavalta",
        "common": "Kongon demokraattinen tasavalta"
      },
      "fra": {
        "official": "République démocratique du Congo",
        "common": "Congo (Rép. dém.)"
      },
      "hrv": {
        "official": "Demokratska Republika Kongo",
        "common": "Kongo, Demokratska Republika"
      },
      "hun": {
        "official": "Kongói Demokratikus Köztársaság",
        "common": "Kongói Demokratikus Köztársaság"
      },
      "ita": {
        "official": "Repubblica Democratica del Congo",
        "common": "Congo (Rep. Dem.)"
      },
      "jpn": {
        "official": "コンゴ民主共和国",
        "common": "コンゴ民主共和国"
      },
      "kor": {
        "official": "콩고 민주 공화국",
        "common": "콩고 민주 공화국"
      },
      "nld": {
        "official": "Democratische Republiek Congo",
        "common": "Congo (DRC)"
      },
      "per": {
        "official": "جمهوری دموکراتیک کنگو",
        "common": "کنگو دموکراتیک"
      },
      "pol": {
        "official": "Demokratyczna Republika Konga",
        "common": "Demokratyczna Republika Konga"
      },
      "por": {
        "official": "República Democrática do Congo",
        "common": "República Democrática do Congo"
      },
      "rus": {
        "official": "Демократическая Республика Конго",
        "common": "Демократическая Республика Конго"
      },
      "slk": {
        "official": "Konžská demokratická republika",
        "common": "Kongo"
      },
      "spa": {
        "official": "República Democrática del Congo",
        "common": "Congo (Rep. Dem.)"
      },
      "swe": {
        "official": "Demokratiska republiken Kongo",
        "common": "Kongo-Kinshasa"
      },
      "tur": {
        "official": "Kongo Demokratik Cumhuriyeti",
        "common": "Kongo Demokratik Cumhuriyeti"
      },
      "urd": {
        "official": "جمہوری جمہوریہ کانگو",
        "common": "کانگو"
      },
      "zho": {
        "official": "刚果民主共和国",
        "common": "民主刚果"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Congolese",
        "m": "Congolese"
      },
      "fra": {
        "f": "Congolaise",
        "m": "Congolais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/tk.png",
      "svg": "https://flagcdn.com/tk.svg"
    },
    "cca2": "TK",
    "capital": [
      "Fakaofo"
    ],
    "altSpellings": [
      "TK"
    ],
    "translations": {
      "ara": {
        "official": "توكيلاو",
        "common": "توكيلاو"
      },
      "bre": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "ces": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "cym": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "deu": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "est": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "fin": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "fra": {
        "official": "Îles Tokelau",
        "common": "Tokelau"
      },
      "hrv": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "hun": {
        "official": "Tokelau-szigetek",
        "common": "Tokelau-szigetek"
      },
      "ita": {
        "official": "Tokelau",
        "common": "Isole Tokelau"
      },
      "jpn": {
        "official": "トケラウ諸島",
        "common": "トケラウ"
      },
      "kor": {
        "official": "토켈라우",
        "common": "토켈라우"
      },
      "nld": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "per": {
        "official": "توکلائو",
        "common": "توکلائو"
      },
      "pol": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "por": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "rus": {
        "official": "Токелау",
        "common": "Токелау"
      },
      "slk": {
        "official": "Tokelauské ostrovy",
        "common": "Tokelau"
      },
      "spa": {
        "official": "Tokelau",
        "common": "Islas Tokelau"
      },
      "swe": {
        "official": "Tokelauöarna",
        "common": "Tokelauöarna"
      },
      "tur": {
        "official": "Tokelau",
        "common": "Tokelau"
      },
      "urd": {
        "official": "ٹوکیلاؤ",
        "common": "ٹوکیلاؤ"
      },
      "zho": {
        "official": "托克劳",
        "common": "托克劳"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Tokelauan",
        "m": "Tokelauan"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/vn.png",
      "svg": "https://flagcdn.com/vn.svg"
    },
    "cca2": "VN",
    "capital": [
      "Hanoi"
    ],
    "altSpellings": [
      "VN",
      "Socialist Republic of Vietnam",
      "Cộng hòa Xã hội chủ nghĩa Việt Nam",
      "Viet Nam"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية فيتنام الاشتراكية",
        "common": "فيتنام"
      },
      "bre": {
        "official": "Republik Sokialour Viêt Nam",
        "common": "Viêt Nam"
      },
      "ces": {
        "official": "Vietnamská socialistická republika",
        "common": "Vietnam"
      },
      "cym": {
        "official": "Socialist Republic of Vietnam",
        "common": "Vietnam"
      },
      "deu": {
        "official": "Sozialistische Republik Vietnam",
        "common": "Vietnam"
      },
      "est": {
        "official": "Vietnami Sotsialistlik Vabariik",
        "common": "Vietnam"
      },
      "fin": {
        "official": "Vietnamin sosialistinen tasavalta",
        "common": "Vietnam"
      },
      "fra": {
        "official": "République socialiste du Viêt Nam",
        "common": "Viêt Nam"
      },
      "hrv": {
        "official": "Socijalistička Republika Vijetnam",
        "common": "Vijetnam"
      },
      "hun": {
        "official": "Vietnámi Szocialista Köztársaság",
        "common": "Vietnám"
      },
      "ita": {
        "official": "Repubblica socialista del Vietnam",
        "common": "Vietnam"
      },
      "jpn": {
        "official": "ベトナム社会主義共和国",
        "common": "ベトナム"
      },
      "kor": {
        "official": "베트남 사회주의 공화국",
        "common": "베트남"
      },
      "nld": {
        "official": "Socialistische Republiek Vietnam",
        "common": "Vietnam"
      },
      "per": {
        "official": "جمهوری سوسیالیستی ویتنام",
        "common": "ویتنام"
      },
      "pol": {
        "official": "Socjalistyczna Republika Wietnamu",
        "common": "Wietnam"
      },
      "por": {
        "official": "República Socialista do Vietname",
        "common": "Vietname"
      },
      "rus": {
        "official": "Социалистическая Республика Вьетнам",
        "common": "Вьетнам"
      },
      "slk": {
        "official": "Vietnamská socialistická republika",
        "common": "Vietnam"
      },
      "spa": {
        "official": "República Socialista de Vietnam",
        "common": "Vietnam"
      },
      "swe": {
        "official": "Socialistiska republiken Vietnam",
        "common": "Vietnam"
      },
      "tur": {
        "official": "Vietnam Sosyalist Cumhuriyeti",
        "common": "Vietnam"
      },
      "urd": {
        "official": "اشتراکی جمہوریہ ویتنام",
        "common": "ویتنام"
      },
      "zho": {
        "official": "越南社会主义共和国",
        "common": "越南"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Vietnamese",
        "m": "Vietnamese"
      },
      "fra": {
        "f": "Vietnamienne",
        "m": "Vietnamien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sx.png",
      "svg": "https://flagcdn.com/sx.svg"
    },
    "cca2": "SX",
    "capital": [
      "Philipsburg"
    ],
    "altSpellings": [
      "SX",
      "Sint Maarten (Dutch part)"
    ],
    "translations": {
      "ara": {
        "official": "سينت مارتن",
        "common": "سينت مارتن"
      },
      "bre": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "ces": {
        "official": "Svatý Martin",
        "common": "Svatý Martin (Nizozemsko)"
      },
      "cym": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "deu": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "est": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "fin": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "fra": {
        "official": "Sint Maarten",
        "common": "Saint-Martin"
      },
      "hrv": {
        "official": "Sveti Martin",
        "common": "Sveti Martin"
      },
      "hun": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "ita": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "jpn": {
        "official": "シントマールテン島",
        "common": "シント・マールテン"
      },
      "kor": {
        "official": "신트마르턴",
        "common": "신트마르턴"
      },
      "nld": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "per": {
        "official": "سن مارتن",
        "common": "سن مارتن"
      },
      "pol": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "por": {
        "official": "Sint Maarten",
        "common": "São Martinho"
      },
      "rus": {
        "official": "Синт-Маартен",
        "common": "Синт-Мартен"
      },
      "slk": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "spa": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "swe": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "tur": {
        "official": "Sint Maarten",
        "common": "Sint Maarten"
      },
      "urd": {
        "official": "سنٹ مارٹن",
        "common": "سنٹ مارٹن"
      },
      "zho": {
        "official": "圣马丁岛",
        "common": "圣马丁岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "St. Maartener",
        "m": "St. Maartener"
      },
      "fra": {
        "f": "Saint-Martinoise",
        "m": "Saint-Martinois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/be.png",
      "svg": "https://flagcdn.com/be.svg"
    },
    "cca2": "BE",
    "capital": [
      "Brussels"
    ],
    "altSpellings": [
      "BE",
      "België",
      "Belgie",
      "Belgien",
      "Belgique",
      "Kingdom of Belgium",
      "Koninkrijk België",
      "Royaume de Belgique",
      "Königreich Belgien"
    ],
    "translations": {
      "ara": {
        "official": "مملكة بلجيكا",
        "common": "بلجيكا"
      },
      "bre": {
        "official": "Rouantelezh Belgia",
        "common": "Belgia"
      },
      "ces": {
        "official": "Belgické království",
        "common": "Belgie"
      },
      "cym": {
        "official": "Teyrnas Gwlad Belg",
        "common": "Gwlad Belg"
      },
      "deu": {
        "official": "Königreich Belgien",
        "common": "Belgien"
      },
      "est": {
        "official": "Belgia Kuningriik",
        "common": "Belgia"
      },
      "fin": {
        "official": "Belgian kuningaskunta",
        "common": "Belgia"
      },
      "fra": {
        "official": "Royaume de Belgique",
        "common": "Belgique"
      },
      "hrv": {
        "official": "Kraljevina Belgija",
        "common": "Belgija"
      },
      "hun": {
        "official": "Belga Királyság",
        "common": "Belgium"
      },
      "ita": {
        "official": "Regno del Belgio",
        "common": "Belgio"
      },
      "jpn": {
        "official": "ベルギー王国",
        "common": "ベルギー"
      },
      "kor": {
        "official": "벨기에 왕국",
        "common": "벨기에"
      },
      "nld": {
        "official": "Koninkrijk België",
        "common": "België"
      },
      "per": {
        "official": "پادشاهی بلژیک",
        "common": "بلژیک"
      },
      "pol": {
        "official": "Królestwo Belgii",
        "common": "Belgia"
      },
      "por": {
        "official": "Reino da Bélgica",
        "common": "Bélgica"
      },
      "rus": {
        "official": "Королевство Бельгия",
        "common": "Бельгия"
      },
      "slk": {
        "official": "Belgické kráľovstvo",
        "common": "Belgicko"
      },
      "spa": {
        "official": "Reino de Bélgica",
        "common": "Bélgica"
      },
      "swe": {
        "official": "Konungariket Belgien",
        "common": "Belgien"
      },
      "tur": {
        "official": "Belçika Krallığı",
        "common": "Belçika"
      },
      "urd": {
        "official": "مملکتِ بلجئیم",
        "common": "بلجئیم"
      },
      "zho": {
        "official": "比利时王国",
        "common": "比利时"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Belgian",
        "m": "Belgian"
      },
      "fra": {
        "f": "Belge",
        "m": "Belge"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/um.png",
      "svg": "https://flagcdn.com/um.svg"
    },
    "cca2": "UM",
    "capital": [
      "Washington DC"
    ],
    "altSpellings": [
      "UM"
    ],
    "translations": {
      "ara": {
        "official": "جزر الولايات المتحدة الصغيرة النائية",
        "common": "جزر الولايات المتحدة الصغيرة النائية"
      },
      "bre": {
        "official": "Inizi Minor A-bell Stadoù-Unanet",
        "common": "Inizi Minor A-bell Stadoù-Unanet"
      },
      "ces": {
        "official": "Menší odlehlé ostrovy Spojených států amerických",
        "common": "Menší odlehlé ostrovy USA"
      },
      "cym": {
        "official": "United States Minor Outlying Islands",
        "common": "United States Minor Outlying Islands"
      },
      "deu": {
        "official": "USA, kleinere ausgelagerte Inseln",
        "common": "Kleinere Inselbesitzungen der Vereinigten Staaten"
      },
      "est": {
        "official": "Ühendriikide väikesed hajasaared",
        "common": "Ühendriikide hajasaared"
      },
      "fin": {
        "official": "Yhdysvaltain asumattomat saaret",
        "common": "Yhdysvaltain asumattomat saaret"
      },
      "fra": {
        "official": "Îles mineures éloignées des États-Unis",
        "common": "Îles mineures éloignées des États-Unis"
      },
      "hrv": {
        "official": "Mali udaljeni otoci SAD-a",
        "common": "Mali udaljeni otoci SAD-a"
      },
      "hun": {
        "official": "Az Amerikai Egyesült Államok lakatlan külbirtokai",
        "common": "Az Amerikai Egyesült Államok lakatlan külbirtokai"
      },
      "ita": {
        "official": "Stati Uniti Isole Minori",
        "common": "Isole minori esterne degli Stati Uniti d'America"
      },
      "jpn": {
        "official": "アメリカ合衆国外諸島",
        "common": "合衆国領有小離島"
      },
      "kor": {
        "official": "미국령 군소 제도",
        "common": "미국령 군소 제도"
      },
      "nld": {
        "official": "Kleine afgelegen eilanden van de Verenigde Staten",
        "common": "Kleine afgelegen eilanden van de Verenigde Staten"
      },
      "per": {
        "official": "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
        "common": "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا"
      },
      "pol": {
        "official": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
        "common": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
      },
      "por": {
        "official": "Estados Unidos Ilhas Menores Distantes",
        "common": "Ilhas Menores Distantes dos Estados Unidos"
      },
      "rus": {
        "official": "Внешние малые острова США",
        "common": "Внешние малые острова США"
      },
      "slk": {
        "official": "Menšie odľahlé ostrovy Spjoených štátov",
        "common": "Menšie odľahlé ostrovy USA"
      },
      "spa": {
        "official": "Estados Unidos Islas menores alejadas de",
        "common": "Islas Ultramarinas Menores de Estados Unidos"
      },
      "swe": {
        "official": "Förenta staternas mindre öar i Oceanien och Västindien",
        "common": "Förenta staternas mindre öar i Oceanien och Västindien"
      },
      "tur": {
        "official": "Amerika Birleşik Devletleri Küçük Dış Adaları",
        "common": "Amerika Birleşik Devletleri Küçük Dış Adaları"
      },
      "urd": {
        "official": "امریکی چھوٹے بیرونی جزائر",
        "common": "امریکی چھوٹے بیرونی جزائر"
      },
      "zho": {
        "official": "美国本土外小岛屿",
        "common": "美国本土外小岛屿"
      }
    },
    "demonyms": {
      "eng": {
        "f": "American Islander",
        "m": "American Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/br.png",
      "svg": "https://flagcdn.com/br.svg"
    },
    "cca2": "BR",
    "capital": [
      "Brasília"
    ],
    "altSpellings": [
      "BR",
      "Brasil",
      "Federative Republic of Brazil",
      "República Federativa do Brasil"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية البرازيل الاتحادية",
        "common": "البرازيل"
      },
      "bre": {
        "official": "Republik Kevreel Brazil",
        "common": "Brazil"
      },
      "ces": {
        "official": "Brazilská federativní republika",
        "common": "Brazílie"
      },
      "cym": {
        "official": "Gweriniaeth Ffederal Brasil",
        "common": "Brasil"
      },
      "deu": {
        "official": "Föderative Republik Brasilien",
        "common": "Brasilien"
      },
      "est": {
        "official": "Brasiilia Liitvabariik",
        "common": "Brasiilia"
      },
      "fin": {
        "official": "Brasilian liittotasavalta",
        "common": "Brasilia"
      },
      "fra": {
        "official": "République fédérative du Brésil",
        "common": "Brésil"
      },
      "hrv": {
        "official": "Savezne Republike Brazil",
        "common": "Brazil"
      },
      "hun": {
        "official": "Brazil Szövetségi Köztársaság",
        "common": "Brazília"
      },
      "ita": {
        "official": "Repubblica federativa del Brasile",
        "common": "Brasile"
      },
      "jpn": {
        "official": "ブラジル連邦共和国",
        "common": "ブラジル"
      },
      "kor": {
        "official": "브라질 연방 공화국",
        "common": "브라질"
      },
      "nld": {
        "official": "Federale Republiek Brazilië",
        "common": "Brazilië"
      },
      "per": {
        "official": "جمهوری فدراتیو برزیل",
        "common": "برزیل"
      },
      "pol": {
        "official": "Federacyjna Republika Brazylii",
        "common": "Brazylia"
      },
      "por": {
        "official": "República Federativa do Brasil",
        "common": "Brasil"
      },
      "rus": {
        "official": "Федеративная Республика Бразилия",
        "common": "Бразилия"
      },
      "slk": {
        "official": "Brazílska federatívna republika",
        "common": "Brazília"
      },
      "spa": {
        "official": "República Federativa del Brasil",
        "common": "Brasil"
      },
      "swe": {
        "official": "Förbundsrepubliken Brasilien",
        "common": "Brasilien"
      },
      "tur": {
        "official": "Brezilya Federal Cumhuriyeti",
        "common": "Brezilya"
      },
      "urd": {
        "official": "وفاقی جمہوریہ برازیل",
        "common": "برازیل"
      },
      "zho": {
        "official": "巴西联邦共和国",
        "common": "巴西"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Brazilian",
        "m": "Brazilian"
      },
      "fra": {
        "f": "Brésilienne",
        "m": "Brésilien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bz.png",
      "svg": "https://flagcdn.com/bz.svg"
    },
    "cca2": "BZ",
    "capital": [
      "Belmopan"
    ],
    "altSpellings": [
      "BZ"
    ],
    "translations": {
      "ara": {
        "official": "بليز",
        "common": "بليز"
      },
      "bre": {
        "official": "Belize",
        "common": "Belize"
      },
      "ces": {
        "official": "Belize",
        "common": "Belize"
      },
      "cym": {
        "official": "Belîs",
        "common": "Belîs"
      },
      "deu": {
        "official": "Belize",
        "common": "Belize"
      },
      "est": {
        "official": "Belize",
        "common": "Belize"
      },
      "fin": {
        "official": "Belize",
        "common": "Belize"
      },
      "fra": {
        "official": "Belize",
        "common": "Belize"
      },
      "hrv": {
        "official": "Belize",
        "common": "Belize"
      },
      "hun": {
        "official": "Belize",
        "common": "Belize"
      },
      "ita": {
        "official": "Belize",
        "common": "Belize"
      },
      "jpn": {
        "official": "ベリーズ",
        "common": "ベリーズ"
      },
      "kor": {
        "official": "벨리즈",
        "common": "벨리즈"
      },
      "nld": {
        "official": "Belize",
        "common": "Belize"
      },
      "per": {
        "official": "بلیز",
        "common": "بلیز"
      },
      "pol": {
        "official": "Belize",
        "common": "Belize"
      },
      "por": {
        "official": "Belize",
        "common": "Belize"
      },
      "rus": {
        "official": "Белиз",
        "common": "Белиз"
      },
      "slk": {
        "official": "Belize",
        "common": "Belize"
      },
      "spa": {
        "official": "Belice",
        "common": "Belice"
      },
      "swe": {
        "official": "Belize",
        "common": "Belize"
      },
      "tur": {
        "official": "Belize",
        "common": "Belize"
      },
      "urd": {
        "official": "بیلیز",
        "common": "بیلیز"
      },
      "zho": {
        "official": "伯利兹",
        "common": "伯利兹"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Belizean",
        "m": "Belizean"
      },
      "fra": {
        "f": "Bélizienne",
        "m": "Bélizien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ag.png",
      "svg": "https://flagcdn.com/ag.svg"
    },
    "cca2": "AG",
    "capital": [
      "Saint John's"
    ],
    "altSpellings": [
      "AG"
    ],
    "translations": {
      "ara": {
        "official": "أنتيغوا وباربودا",
        "common": "أنتيغوا وباربودا"
      },
      "bre": {
        "official": "Antigua ha Barbuda",
        "common": "Antigua ha Barbuda"
      },
      "ces": {
        "official": "Antigua a Barbuda",
        "common": "Antigua a Barbuda"
      },
      "cym": {
        "official": "Antigwa a Barbiwda",
        "common": "Antigwa a Barbiwda"
      },
      "deu": {
        "official": "Antigua und Barbuda",
        "common": "Antigua und Barbuda"
      },
      "est": {
        "official": "Antigua ja Barbuda",
        "common": "Antigua ja Barbuda"
      },
      "fin": {
        "official": "Antigua ja Barbuda",
        "common": "Antigua ja Barbuda"
      },
      "fra": {
        "official": "Antigua -et-Barbuda",
        "common": "Antigua-et-Barbuda"
      },
      "hrv": {
        "official": "Antigva i Barbuda",
        "common": "Antigva i Barbuda"
      },
      "hun": {
        "official": "Antigua és Barbuda",
        "common": "Antigua és Barbuda"
      },
      "ita": {
        "official": "Antigua e Barbuda",
        "common": "Antigua e Barbuda"
      },
      "jpn": {
        "official": "アンチグアバーブーダ",
        "common": "アンティグア・バーブーダ"
      },
      "kor": {
        "official": "앤티가 바부다",
        "common": "앤티가 바부다"
      },
      "nld": {
        "official": "Antigua en Barbuda",
        "common": "Antigua en Barbuda"
      },
      "per": {
        "official": "آنتیگوا و باربودا",
        "common": "آنتیگوا و باربودا"
      },
      "pol": {
        "official": "Antigua i Barbuda",
        "common": "Antigua i Barbuda"
      },
      "por": {
        "official": "Antigua e Barbuda",
        "common": "Antígua e Barbuda"
      },
      "rus": {
        "official": "Антигуа и Барбуда",
        "common": "Антигуа и Барбуда"
      },
      "slk": {
        "official": "Antigua a Barbuda",
        "common": "Antigua a Barbuda"
      },
      "spa": {
        "official": "Antigua y Barbuda",
        "common": "Antigua y Barbuda"
      },
      "swe": {
        "official": "Antigua och Barbuda",
        "common": "Antigua och Barbuda"
      },
      "tur": {
        "official": "Antigua ve Barbuda",
        "common": "Antigua ve Barbuda"
      },
      "urd": {
        "official": "اینٹیگوا و باربوڈا",
        "common": "اینٹیگوا و باربوڈا"
      },
      "zho": {
        "official": "安提瓜和巴布达",
        "common": "安提瓜和巴布达"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Antiguan, Barbudan",
        "m": "Antiguan, Barbudan"
      },
      "fra": {
        "f": "Antiguaise et barbudienne",
        "m": "Antiguaise et barbudien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/py.png",
      "svg": "https://flagcdn.com/py.svg"
    },
    "cca2": "PY",
    "capital": [
      "Asunción"
    ],
    "altSpellings": [
      "PY",
      "Republic of Paraguay",
      "República del Paraguay",
      "Tetã Paraguái"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية باراغواي",
        "common": "باراغواي"
      },
      "bre": {
        "official": "Republik Paraguay",
        "common": "Paraguay"
      },
      "ces": {
        "official": "Paraguayská republika",
        "common": "Paraguay"
      },
      "cym": {
        "official": "Republic of Paraguay",
        "common": "Paraguay"
      },
      "deu": {
        "official": "Republik Paraguay",
        "common": "Paraguay"
      },
      "est": {
        "official": "Paraguay Vabariik",
        "common": "Paraguay"
      },
      "fin": {
        "official": "Paraguayn tasavalta",
        "common": "Paraguay"
      },
      "fra": {
        "official": "République du Paraguay",
        "common": "Paraguay"
      },
      "hrv": {
        "official": "Republika Paragvaj",
        "common": "Paragvaj"
      },
      "hun": {
        "official": "Paraguayi Köztársaság",
        "common": "Paraguay"
      },
      "ita": {
        "official": "Repubblica del Paraguay",
        "common": "Paraguay"
      },
      "jpn": {
        "official": "パラグアイ共和国",
        "common": "パラグアイ"
      },
      "kor": {
        "official": "파라과이 공화국",
        "common": "파라과이"
      },
      "nld": {
        "official": "Republiek Paraguay",
        "common": "Paraguay"
      },
      "per": {
        "official": "جمهوری پاراگوئه",
        "common": "پاراگوئه"
      },
      "pol": {
        "official": "Republika Paragwaju",
        "common": "Paragwaj"
      },
      "por": {
        "official": "República do Paraguai",
        "common": "Paraguai"
      },
      "rus": {
        "official": "Республика Парагвай",
        "common": "Парагвай"
      },
      "slk": {
        "official": "Paraguajská republika",
        "common": "Paraguaj"
      },
      "spa": {
        "official": "República de Paraguay",
        "common": "Paraguay"
      },
      "swe": {
        "official": "Republiken Paraguay",
        "common": "Paraguay"
      },
      "tur": {
        "official": "Paraguay Cumhuriyeti",
        "common": "Paraguay"
      },
      "urd": {
        "official": "جمہوریہ پیراگوئے",
        "common": "پیراگوئے"
      },
      "zho": {
        "official": "巴拉圭共和国",
        "common": "巴拉圭"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Paraguayan",
        "m": "Paraguayan"
      },
      "fra": {
        "f": "Paraguayenne",
        "m": "Paraguayen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bj.png",
      "svg": "https://flagcdn.com/bj.svg"
    },
    "cca2": "BJ",
    "capital": [
      "Porto-Novo"
    ],
    "altSpellings": [
      "BJ",
      "Republic of Benin",
      "République du Bénin"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بنين",
        "common": "بنين"
      },
      "bre": {
        "official": "Republik Benin",
        "common": "Benin"
      },
      "ces": {
        "official": "Beninská republika",
        "common": "Benin"
      },
      "cym": {
        "official": "Gweriniaeth Benin",
        "common": "Benin"
      },
      "deu": {
        "official": "Republik Benin",
        "common": "Benin"
      },
      "est": {
        "official": "Benini Vabariik",
        "common": "Benin"
      },
      "fin": {
        "official": "Beninin tasavalta",
        "common": "Benin"
      },
      "fra": {
        "official": "République du Bénin",
        "common": "Bénin"
      },
      "hrv": {
        "official": "Republika Benin",
        "common": "Benin"
      },
      "hun": {
        "official": "Benini Köztársaság",
        "common": "Benin"
      },
      "ita": {
        "official": "Repubblica del Benin",
        "common": "Benin"
      },
      "jpn": {
        "official": "ベナン共和国",
        "common": "ベナン"
      },
      "kor": {
        "official": "베냉 공화국",
        "common": "베냉"
      },
      "nld": {
        "official": "Republiek Benin",
        "common": "Benin"
      },
      "per": {
        "official": "جمهوری بنین",
        "common": "بنین"
      },
      "pol": {
        "official": "Benin",
        "common": "Benin"
      },
      "por": {
        "official": "República do Benin",
        "common": "Benin"
      },
      "rus": {
        "official": "Республика Бенин",
        "common": "Бенин"
      },
      "slk": {
        "official": "Beninská republika",
        "common": "Benin"
      },
      "spa": {
        "official": "República de Benin",
        "common": "Benín"
      },
      "swe": {
        "official": "Republiken Benin",
        "common": "Benin"
      },
      "tur": {
        "official": "Benin Cumhuriyeti",
        "common": "Benin"
      },
      "urd": {
        "official": "جمہوریہ بینن",
        "common": "بینن"
      },
      "zho": {
        "official": "贝宁共和国",
        "common": "贝宁"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Beninese",
        "m": "Beninese"
      },
      "fra": {
        "f": "Béninoise",
        "m": "Béninois"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bn.png",
      "svg": "https://flagcdn.com/bn.svg"
    },
    "cca2": "BN",
    "capital": [
      "Bandar Seri Begawan"
    ],
    "altSpellings": [
      "BN",
      "Brunei Darussalam",
      "Nation of Brunei",
      "the Abode of Peace"
    ],
    "translations": {
      "ara": {
        "official": "بروناي دار السلام",
        "common": "بروناي"
      },
      "bre": {
        "official": "Stad Brunei Darussalam",
        "common": "Brunei"
      },
      "ces": {
        "official": "Sultanát Brunej",
        "common": "Brunej"
      },
      "cym": {
        "official": "Teyrnas Brwnei",
        "common": "Brunei"
      },
      "deu": {
        "official": "Sultanat Brunei Darussalam",
        "common": "Brunei"
      },
      "est": {
        "official": "Brunei Darussalami Riik",
        "common": "Brunei"
      },
      "fin": {
        "official": "Brunei Darussalamin valtio",
        "common": "Brunei"
      },
      "fra": {
        "official": "État de Brunei Darussalam",
        "common": "Brunei"
      },
      "hrv": {
        "official": "Nacija od Bruneja, Kuću Mira",
        "common": "Brunej"
      },
      "hun": {
        "official": "Brunei Szultanátus",
        "common": "Brunei"
      },
      "ita": {
        "official": "Nazione di Brunei, Dimora della Pace",
        "common": "Brunei"
      },
      "jpn": {
        "official": "ブルネイ、平和の精舎の国家",
        "common": "ブルネイ・ダルサラーム"
      },
      "kor": {
        "official": "브루나이 다루살람국",
        "common": "브루나이"
      },
      "nld": {
        "official": "Natie van Brunei, de verblijfplaats van de Vrede",
        "common": "Brunei"
      },
      "per": {
        "official": "برونئی سرای صلح",
        "common": "برونئی"
      },
      "pol": {
        "official": "Państwo Brunei Darussalam",
        "common": "Brunei"
      },
      "por": {
        "official": "Nação do Brunei, Morada da Paz",
        "common": "Brunei"
      },
      "rus": {
        "official": "Нация Бруней, обитель мира",
        "common": "Бруней"
      },
      "slk": {
        "official": "Brunejský sultanât",
        "common": "Brunej"
      },
      "spa": {
        "official": "Nación de Brunei, Morada de la Paz",
        "common": "Brunei"
      },
      "swe": {
        "official": "Brunei Darussalam",
        "common": "Brunei"
      },
      "tur": {
        "official": "Brunei Barış Ülkesi Devleti",
        "common": "Brunei"
      },
      "urd": {
        "official": "ریاستِ برونائی دارالسلام",
        "common": "برونائی"
      },
      "zho": {
        "official": "文莱和平之国",
        "common": "文莱"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bruneian",
        "m": "Bruneian"
      },
      "fra": {
        "f": "Brunéienne",
        "m": "Brunéien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bo.png",
      "svg": "https://flagcdn.com/bo.svg"
    },
    "cca2": "BO",
    "capital": [
      "Sucre"
    ],
    "altSpellings": [
      "BO",
      "Buliwya",
      "Wuliwya",
      "Bolivia, Plurinational State of",
      "Plurinational State of Bolivia",
      "Estado Plurinacional de Bolivia",
      "Buliwya Mamallaqta",
      "Wuliwya Suyu",
      "Tetã Volívia"
    ],
    "translations": {
      "ara": {
        "official": "دولة بوليفيا المتعددة القوميات",
        "common": "بوليفيا"
      },
      "bre": {
        "official": "Stad Liesvroadel Bolivia",
        "common": "Bolivia"
      },
      "ces": {
        "official": "Mnohonárodnostní stát Bolívie",
        "common": "Bolívie"
      },
      "cym": {
        "official": "Gweriniaeth Bolifia",
        "common": "Bolifia"
      },
      "deu": {
        "official": "Plurinationaler Staat Bolivien",
        "common": "Bolivien"
      },
      "est": {
        "official": "Boliivia Paljurahvuseline Riik",
        "common": "Boliivia"
      },
      "fin": {
        "official": "Bolivian monikansainen valtio",
        "common": "Bolivia"
      },
      "fra": {
        "official": "État plurinational de Bolivie",
        "common": "Bolivie"
      },
      "hrv": {
        "official": "Plurinational State of Bolivia",
        "common": "Bolivija"
      },
      "hun": {
        "official": "Bolíviai Többnemzetiségű Állam",
        "common": "Bolívia"
      },
      "ita": {
        "official": "Stato Plurinazionale della Bolivia",
        "common": "Bolivia"
      },
      "jpn": {
        "official": "ボリビアの多民族国",
        "common": "ボリビア多民族国"
      },
      "kor": {
        "official": "볼리비아 다민족국",
        "common": "볼리비아"
      },
      "nld": {
        "official": "Plurinationale Staat van Bolivia",
        "common": "Bolivia"
      },
      "per": {
        "official": "جمهوری بولیوی",
        "common": "بولیوی"
      },
      "pol": {
        "official": "Wielonarodowe Państwo Boliwia",
        "common": "Boliwia"
      },
      "por": {
        "official": "Estado Plurinacional da Bolívia",
        "common": "Bolívia"
      },
      "rus": {
        "official": "Многонациональное Государство Боливия",
        "common": "Боливия"
      },
      "slk": {
        "official": "Bolívijská republika",
        "common": "Bolívia"
      },
      "spa": {
        "official": "Estado Plurinacional de Bolivia",
        "common": "Bolivia"
      },
      "swe": {
        "official": "Mångnationella staten Bolivia",
        "common": "Bolivia"
      },
      "tur": {
        "official": "Bolivya Çokuluslu Devleti",
        "common": "Bolivya"
      },
      "urd": {
        "official": "جمہوریہ بولیویا",
        "common": "بولیویا"
      },
      "zho": {
        "official": "多民族玻利维亚国",
        "common": "玻利维亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Bolivian",
        "m": "Bolivian"
      },
      "fra": {
        "f": "Bolivienne",
        "m": "Bolivien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/dj.png",
      "svg": "https://flagcdn.com/dj.svg"
    },
    "cca2": "DJ",
    "capital": [
      "Djibouti"
    ],
    "altSpellings": [
      "DJ",
      "Jabuuti",
      "Gabuuti",
      "Republic of Djibouti",
      "République de Djibouti",
      "Gabuutih Ummuuno",
      "Jamhuuriyadda Jabuuti"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية جيبوتي",
        "common": "جيبوتي"
      },
      "bre": {
        "official": "Republik Djibouti",
        "common": "Djibouti"
      },
      "ces": {
        "official": "Džibutská republika",
        "common": "Džibutsko"
      },
      "cym": {
        "official": "Gweriniaeth Jibwti",
        "common": "Jibwti"
      },
      "deu": {
        "official": "Republik Dschibuti",
        "common": "Dschibuti"
      },
      "est": {
        "official": "Djibouti Vabariik",
        "common": "Djibouti"
      },
      "fin": {
        "official": "Dijiboutin tasavalta",
        "common": "Dijibouti"
      },
      "fra": {
        "official": "République de Djibouti",
        "common": "Djibouti"
      },
      "hrv": {
        "official": "Republika Džibuti",
        "common": "Džibuti"
      },
      "hun": {
        "official": "Dzsibuti Köztársaság",
        "common": "Dzsibuti"
      },
      "ita": {
        "official": "Repubblica di Gibuti",
        "common": "Gibuti"
      },
      "jpn": {
        "official": "ジブチ共和国",
        "common": "ジブチ"
      },
      "kor": {
        "official": "지부티 공화국",
        "common": "지부티"
      },
      "nld": {
        "official": "Republiek Djibouti",
        "common": "Djibouti"
      },
      "per": {
        "official": "جمهوری جیبوتی",
        "common": "جیبوتی"
      },
      "pol": {
        "official": "Republika Dżibuti",
        "common": "Dżibuti"
      },
      "por": {
        "official": "República do Djibouti",
        "common": "Djibouti"
      },
      "rus": {
        "official": "Республика Джибути",
        "common": "Джибути"
      },
      "slk": {
        "official": "ǅibutská republika",
        "common": "ǅibutsko"
      },
      "spa": {
        "official": "República de Djibouti",
        "common": "Djibouti"
      },
      "swe": {
        "official": "Republiken Djibouti",
        "common": "Djibouti"
      },
      "tur": {
        "official": "Cibuti Cumhuriyeti",
        "common": "Cibuti"
      },
      "urd": {
        "official": "جمہوریہ جبوتی",
        "common": "جبوتی"
      },
      "zho": {
        "official": "吉布提共和国",
        "common": "吉布提"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Djibouti",
        "m": "Djibouti"
      },
      "fra": {
        "f": "Djiboutienne",
        "m": "Djiboutien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gf.png",
      "svg": "https://flagcdn.com/gf.svg"
    },
    "cca2": "GF",
    "capital": [
      "Cayenne"
    ],
    "altSpellings": [
      "GF",
      "Guiana",
      "Guyane"
    ],
    "translations": {
      "ara": {
        "official": "غويانا الفرنسية",
        "common": "غويانا"
      },
      "bre": {
        "official": "Gwiana C'hall",
        "common": "Gwiana C'hall"
      },
      "ces": {
        "official": "Francouzská Guyana",
        "common": "Francouzská Guyana"
      },
      "cym": {
        "official": "Guiana",
        "common": "French Guiana"
      },
      "deu": {
        "official": "Französisch-Guayana",
        "common": "Französisch-Guayana"
      },
      "est": {
        "official": "Guajaana departemang",
        "common": "Prantsuse Guajaana"
      },
      "fin": {
        "official": "Ranskan Guayana",
        "common": "Ranskan Guayana"
      },
      "fra": {
        "official": "Guyane",
        "common": "Guyane"
      },
      "hrv": {
        "official": "Gijana",
        "common": "Francuska Gvajana"
      },
      "hun": {
        "official": "Francia Guyana",
        "common": "Francia Guyana"
      },
      "ita": {
        "official": "Guiana",
        "common": "Guyana francese"
      },
      "jpn": {
        "official": "ギアナ",
        "common": "フランス領ギアナ"
      },
      "kor": {
        "official": "프랑스령 기아나",
        "common": "프랑스령 기아나"
      },
      "nld": {
        "official": "Guyana",
        "common": "Frans-Guyana"
      },
      "per": {
        "official": "گویان فرانسه",
        "common": "گویان فرانسه"
      },
      "pol": {
        "official": "Gujana Francuska",
        "common": "Gujana Francuska"
      },
      "por": {
        "official": "Guiana",
        "common": "Guiana Francesa"
      },
      "rus": {
        "official": "Гвиана",
        "common": "Французская Гвиана"
      },
      "slk": {
        "official": "Francúzska Guyana",
        "common": "Guyana"
      },
      "spa": {
        "official": "Guayana",
        "common": "Guayana Francesa"
      },
      "swe": {
        "official": "Franska Guyana",
        "common": "Franska Guyana"
      },
      "tur": {
        "official": "Fransız Guyanası",
        "common": "Fransız Guyanası"
      },
      "urd": {
        "official": "گیانا",
        "common": "فرانسیسی گیانا"
      },
      "zho": {
        "official": "法属圭亚那",
        "common": "法属圭亚那"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Guianan",
        "m": "Guianan"
      },
      "fra": {
        "f": "Guyanaise",
        "m": "Guyanais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ca.png",
      "svg": "https://flagcdn.com/ca.svg"
    },
    "cca2": "CA",
    "capital": [
      "Ottawa"
    ],
    "altSpellings": [
      "CA"
    ],
    "translations": {
      "ara": {
        "official": "كندا",
        "common": "كندا"
      },
      "bre": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "ces": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "cym": {
        "official": "Canada",
        "common": "Canada"
      },
      "deu": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "est": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "fin": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "fra": {
        "official": "Canada",
        "common": "Canada"
      },
      "hrv": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "hun": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "ita": {
        "official": "Canada",
        "common": "Canada"
      },
      "jpn": {
        "official": "カナダ",
        "common": "カナダ"
      },
      "kor": {
        "official": "캐나다",
        "common": "캐나다"
      },
      "nld": {
        "official": "Canada",
        "common": "Canada"
      },
      "per": {
        "official": "کانادا",
        "common": "کانادا"
      },
      "pol": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "por": {
        "official": "Canadá",
        "common": "Canadá"
      },
      "rus": {
        "official": "Канада",
        "common": "Канада"
      },
      "slk": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "spa": {
        "official": "Canadá",
        "common": "Canadá"
      },
      "swe": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "tur": {
        "official": "Kanada",
        "common": "Kanada"
      },
      "urd": {
        "official": "کینیڈا",
        "common": "کینیڈا"
      },
      "zho": {
        "official": "加拿大",
        "common": "加拿大"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Canadian",
        "m": "Canadian"
      },
      "fra": {
        "f": "Canadienne",
        "m": "Canadien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/pm.png",
      "svg": "https://flagcdn.com/pm.svg"
    },
    "cca2": "PM",
    "capital": [
      "Saint-Pierre"
    ],
    "altSpellings": [
      "PM",
      "Collectivité territoriale de Saint-Pierre-et-Miquelon"
    ],
    "translations": {
      "ara": {
        "official": "سان بيير وميكلون",
        "common": "سان بيير وميكلون"
      },
      "bre": {
        "official": "Sant-Pêr-ha-Mikelon",
        "common": "Sant-Pêr-ha-Mikelon"
      },
      "ces": {
        "official": "Saint-Pierre a Miquelon",
        "common": "Saint-Pierre a Miquelon"
      },
      "cym": {
        "official": "Saint Pierre and Miquelon",
        "common": "Saint Pierre and Miquelon"
      },
      "deu": {
        "official": "St. Pierre und Miquelon",
        "common": "St. Pierre und Miquelon"
      },
      "est": {
        "official": "Saint-Pierre’i ja Miqueloni territoriaalühendus",
        "common": "Saint-Pierre ja Miquelon"
      },
      "fin": {
        "official": "Saint-Pierre ja Miquelon",
        "common": "Saint-Pierre ja Miquelon"
      },
      "fra": {
        "official": "Saint-Pierre-et-Miquelon",
        "common": "Saint-Pierre-et-Miquelon"
      },
      "hrv": {
        "official": "Saint Pierre i Miquelon",
        "common": "Sveti Petar i Mikelon"
      },
      "hun": {
        "official": "Saint-Pierre és Miquelon",
        "common": "Saint-Pierre és Miquelon"
      },
      "ita": {
        "official": "Saint Pierre e Miquelon",
        "common": "Saint-Pierre e Miquelon"
      },
      "jpn": {
        "official": "サンピエール島·ミクロン島",
        "common": "サンピエール島・ミクロン島"
      },
      "kor": {
        "official": "생피에르 미클롱",
        "common": "생피에르 미클롱"
      },
      "nld": {
        "official": "Saint-Pierre en Miquelon",
        "common": "Saint Pierre en Miquelon"
      },
      "per": {
        "official": "سن-پیر-ا-میکلون",
        "common": "سن-پیِر و میکلُن"
      },
      "pol": {
        "official": "Saint-Pierre i Miquelon",
        "common": "Saint-Pierre i Miquelon"
      },
      "por": {
        "official": "Saint Pierre e Miquelon",
        "common": "Saint-Pierre e Miquelon"
      },
      "rus": {
        "official": "Сен-Пьер и Микелон",
        "common": "Сен-Пьер и Микелон"
      },
      "slk": {
        "official": "Ostrovy Saint Pierre a Miquelon",
        "common": "Saint Pierre a Miquelon"
      },
      "spa": {
        "official": "San Pedro y Miquelón",
        "common": "San Pedro y Miquelón"
      },
      "swe": {
        "official": "Saint-Pierre och Miquelon",
        "common": "Saint-Pierre och Miquelon"
      },
      "tur": {
        "official": "Saint Pierre ve Miquelon",
        "common": "Saint Pierre ve Miquelon"
      },
      "urd": {
        "official": "سینٹ پیئر و میکیلون",
        "common": "سینٹ پیئر و میکیلون"
      },
      "zho": {
        "official": "圣皮埃尔和密克隆",
        "common": "圣皮埃尔和密克隆"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint-Pierrais, Miquelonnais",
        "m": "Saint-Pierrais, Miquelonnais"
      },
      "fra": {
        "f": "Saint-Pierraise, Miquelonaise",
        "m": "Saint-Pierrais, Miquelonais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/jp.png",
      "svg": "https://flagcdn.com/jp.svg"
    },
    "cca2": "JP",
    "capital": [
      "Tokyo"
    ],
    "altSpellings": [
      "JP",
      "Nippon",
      "Nihon"
    ],
    "translations": {
      "ara": {
        "official": "اليابان",
        "common": "اليابان"
      },
      "bre": {
        "official": "Japan",
        "common": "Japan"
      },
      "ces": {
        "official": "Japonsko",
        "common": "Japonsko"
      },
      "cym": {
        "official": "Japan",
        "common": "Japan"
      },
      "deu": {
        "official": "Japan",
        "common": "Japan"
      },
      "est": {
        "official": "Jaapan",
        "common": "Jaapan"
      },
      "fin": {
        "official": "Japani",
        "common": "Japani"
      },
      "fra": {
        "official": "Japon",
        "common": "Japon"
      },
      "hrv": {
        "official": "Japan",
        "common": "Japan"
      },
      "hun": {
        "official": "Japán",
        "common": "Japán"
      },
      "ita": {
        "official": "Giappone",
        "common": "Giappone"
      },
      "jpn": {
        "official": "日本",
        "common": "日本"
      },
      "kor": {
        "official": "일본국",
        "common": "일본"
      },
      "nld": {
        "official": "Japan",
        "common": "Japan"
      },
      "per": {
        "official": "ژاپن",
        "common": "ژاپن"
      },
      "pol": {
        "official": "Japonia",
        "common": "Japonia"
      },
      "por": {
        "official": "Japão",
        "common": "Japão"
      },
      "rus": {
        "official": "Япония",
        "common": "Япония"
      },
      "slk": {
        "official": "Japonsko",
        "common": "Japonsko"
      },
      "spa": {
        "official": "Japón",
        "common": "Japón"
      },
      "swe": {
        "official": "Japan",
        "common": "Japan"
      },
      "tur": {
        "official": "Japonya",
        "common": "Japonya"
      },
      "urd": {
        "official": "جاپان",
        "common": "جاپان"
      },
      "zho": {
        "official": "日本国",
        "common": "日本"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Japanese",
        "m": "Japanese"
      },
      "fra": {
        "f": "Japonaise",
        "m": "Japonais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/rw.png",
      "svg": "https://flagcdn.com/rw.svg"
    },
    "cca2": "RW",
    "capital": [
      "Kigali"
    ],
    "altSpellings": [
      "RW",
      "Republic of Rwanda",
      "Repubulika y'u Rwanda",
      "République du Rwanda"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية رواندا",
        "common": "رواندا"
      },
      "bre": {
        "official": "Republik Rwanda",
        "common": "Rwanda"
      },
      "ces": {
        "official": "Rwandská republika",
        "common": "Rwanda"
      },
      "cym": {
        "official": "Republic of Rwanda",
        "common": "Rwanda"
      },
      "deu": {
        "official": "Republik Ruanda",
        "common": "Ruanda"
      },
      "est": {
        "official": "Rwanda Vabariik",
        "common": "Rwanda"
      },
      "fin": {
        "official": "Ruandan tasavalta",
        "common": "Ruanda"
      },
      "fra": {
        "official": "République rwandaise",
        "common": "Rwanda"
      },
      "hrv": {
        "official": "Republika Ruandi",
        "common": "Ruanda"
      },
      "hun": {
        "official": "Ruandai Köztársaság",
        "common": "Ruanda"
      },
      "ita": {
        "official": "Repubblica del Ruanda",
        "common": "Ruanda"
      },
      "jpn": {
        "official": "ルワンダ共和国",
        "common": "ルワンダ"
      },
      "kor": {
        "official": "르완다 공화국",
        "common": "르완다"
      },
      "nld": {
        "official": "Republiek Rwanda",
        "common": "Rwanda"
      },
      "per": {
        "official": "جمهوری رواندا",
        "common": "رواندا"
      },
      "pol": {
        "official": "Republika Rwandy",
        "common": "Rwanda"
      },
      "por": {
        "official": "República do Ruanda",
        "common": "Ruanda"
      },
      "rus": {
        "official": "Республика Руанда",
        "common": "Руанда"
      },
      "slk": {
        "official": "Rwandská republika",
        "common": "Rwanda"
      },
      "spa": {
        "official": "República de Rwanda",
        "common": "Ruanda"
      },
      "swe": {
        "official": "Republiken Rwanda",
        "common": "Rwanda"
      },
      "tur": {
        "official": "Ruanda Cumhuriyeti",
        "common": "Ruanda"
      },
      "urd": {
        "official": "جمہوریہ روانڈا",
        "common": "روانڈا"
      },
      "zho": {
        "official": "卢旺达共和国",
        "common": "卢旺达"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Rwandan",
        "m": "Rwandan"
      },
      "fra": {
        "f": "Rwandaise",
        "m": "Rwandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/vc.png",
      "svg": "https://flagcdn.com/vc.svg"
    },
    "cca2": "VC",
    "capital": [
      "Kingstown"
    ],
    "altSpellings": [
      "VC"
    ],
    "translations": {
      "ara": {
        "official": "سانت فينسنت والغرينادين",
        "common": "سانت فينسنت والغرينادين"
      },
      "bre": {
        "official": "Sant-Visant hag ar Grenadinez",
        "common": "Sant-Visant hag ar Grenadinez"
      },
      "ces": {
        "official": "Svatý Vincenc a Grenadiny",
        "common": "Svatý Vincenc a Grenadiny"
      },
      "cym": {
        "official": "Saint Vincent and the Grenadines",
        "common": "Saint Vincent and the Grenadines"
      },
      "deu": {
        "official": "St. Vincent und die Grenadinen",
        "common": "St. Vincent und die Grenadinen"
      },
      "est": {
        "official": "Saint Vincent ja Grenadiinid",
        "common": "Saint Vincent"
      },
      "fin": {
        "official": "Saint Vincent ja Grenadiinit",
        "common": "Saint Vincent ja Grenadiinit"
      },
      "fra": {
        "official": "Saint-Vincent-et-les Grenadines",
        "common": "Saint-Vincent-et-les-Grenadines"
      },
      "hrv": {
        "official": "Sveti Vincent i Grenadini",
        "common": "Sveti Vincent i Grenadini"
      },
      "hun": {
        "official": "Saint Vincent és a Grenadine-szigetek",
        "common": "Saint Vincent és a Grenadine-szigetek"
      },
      "ita": {
        "official": "Saint Vincent e Grenadine",
        "common": "Saint Vincent e Grenadine"
      },
      "jpn": {
        "official": "セントビンセントおよびグレナディーン諸島",
        "common": "セントビンセントおよびグレナディーン諸島"
      },
      "kor": {
        "official": "세인트빈센트 그레나딘",
        "common": "세인트빈센트 그레나딘"
      },
      "nld": {
        "official": "Saint Vincent en de Grenadines",
        "common": "Saint Vincent en de Grenadines"
      },
      "per": {
        "official": "سنت وینسنت و گرنادین‌ها",
        "common": "سنت وینسنت و گرنادین‌ها"
      },
      "pol": {
        "official": "Saint Vincent i Grenadyny",
        "common": "Saint Vincent i Grenadyny"
      },
      "por": {
        "official": "São Vicente e Granadinas",
        "common": "São Vincente e Granadinas"
      },
      "rus": {
        "official": "Сент-Винсент и Гренадины",
        "common": "Сент-Винсент и Гренадины"
      },
      "slk": {
        "official": "Svätý Vincent a Grenadíny",
        "common": "Svätý Vincent a Grenadíny"
      },
      "spa": {
        "official": "San Vicente y las Granadinas",
        "common": "San Vicente y Granadinas"
      },
      "swe": {
        "official": "Saint Vincent och Grenadinerna",
        "common": "Saint Vincent och Grenadinerna"
      },
      "tur": {
        "official": "Saint Vincent ve Grenadinler",
        "common": "Saint Vincent ve Grenadinler"
      },
      "urd": {
        "official": "سینٹ وینسینٹ و گریناڈائنز",
        "common": "سینٹ وینسینٹ و گریناڈائنز"
      },
      "zho": {
        "official": "圣文森特和格林纳丁斯",
        "common": "圣文森特和格林纳丁斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saint Vincentian",
        "m": "Saint Vincentian"
      },
      "fra": {
        "f": "Vincentaise",
        "m": "Vincentais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/is.png",
      "svg": "https://flagcdn.com/is.svg"
    },
    "cca2": "IS",
    "capital": [
      "Reykjavik"
    ],
    "altSpellings": [
      "IS",
      "Island",
      "Republic of Iceland",
      "Lýðveldið Ísland"
    ],
    "translations": {
      "ara": {
        "official": "آيسلندا",
        "common": "آيسلندا"
      },
      "bre": {
        "official": "Island",
        "common": "Island"
      },
      "ces": {
        "official": "Island",
        "common": "Island"
      },
      "cym": {
        "official": "Iceland",
        "common": "Iceland"
      },
      "deu": {
        "official": "Island",
        "common": "Island"
      },
      "est": {
        "official": "Islandi Vabariik",
        "common": "Island"
      },
      "fin": {
        "official": "Islanti",
        "common": "Islanti"
      },
      "fra": {
        "official": "République d'Islande",
        "common": "Islande"
      },
      "hrv": {
        "official": "Island",
        "common": "Island"
      },
      "hun": {
        "official": "Izland",
        "common": "Izland"
      },
      "ita": {
        "official": "Islanda",
        "common": "Islanda"
      },
      "jpn": {
        "official": "アイスランド",
        "common": "アイスランド"
      },
      "kor": {
        "official": "아이슬란드 공화국",
        "common": "아이슬란드"
      },
      "nld": {
        "official": "IJsland",
        "common": "IJsland"
      },
      "per": {
        "official": "جمهوری ایسلند",
        "common": "ایسلند"
      },
      "pol": {
        "official": "Republika Islandii",
        "common": "Islandia"
      },
      "por": {
        "official": "Islândia",
        "common": "Islândia"
      },
      "rus": {
        "official": "Исландия",
        "common": "Исландия"
      },
      "slk": {
        "official": "Islandská republika",
        "common": "Island"
      },
      "spa": {
        "official": "Islandia",
        "common": "Islandia"
      },
      "swe": {
        "official": "Island",
        "common": "Island"
      },
      "tur": {
        "official": "İzlanda",
        "common": "İzlanda"
      },
      "urd": {
        "official": "آئس لینڈ",
        "common": "آئس لینڈ"
      },
      "zho": {
        "official": "冰岛",
        "common": "冰岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Icelander",
        "m": "Icelander"
      },
      "fra": {
        "f": "Islandaise",
        "m": "Islandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/by.png",
      "svg": "https://flagcdn.com/by.svg"
    },
    "cca2": "BY",
    "capital": [
      "Minsk"
    ],
    "altSpellings": [
      "BY",
      "Bielaruś",
      "Republic of Belarus",
      "Белоруссия",
      "Республика Белоруссия"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية بيلاروسيا",
        "common": "بيلاروسيا"
      },
      "bre": {
        "official": "Republik Belarus",
        "common": "Belarus"
      },
      "ces": {
        "official": "Běloruská republika",
        "common": "Bělorusko"
      },
      "cym": {
        "official": "Gweriniaeth Belarws",
        "common": "Belarws"
      },
      "deu": {
        "official": "Republik Belarus",
        "common": "Weißrussland"
      },
      "est": {
        "official": "Valgevene Vabariik",
        "common": "Valgevene"
      },
      "fin": {
        "official": "Valko-Venäjän tasavalta",
        "common": "Valko-Venäjä"
      },
      "fra": {
        "official": "République de Biélorussie",
        "common": "Biélorussie"
      },
      "hrv": {
        "official": "Republika Bjelorusija",
        "common": "Bjelorusija"
      },
      "hun": {
        "official": "Fehérorosz Köztársaság",
        "common": "Fehéroroszország"
      },
      "ita": {
        "official": "Repubblica di Belarus",
        "common": "Bielorussia"
      },
      "jpn": {
        "official": "ベラルーシ共和国",
        "common": "ベラルーシ"
      },
      "kor": {
        "official": "벨라루스 공화국",
        "common": "벨라루스"
      },
      "nld": {
        "official": "Republiek Belarus",
        "common": "Wit-Rusland"
      },
      "per": {
        "official": "جمهوری بلاروس",
        "common": "بلاروس"
      },
      "pol": {
        "official": "Republika Białorusi",
        "common": "Białoruś"
      },
      "por": {
        "official": "República da Bielorrússia",
        "common": "Bielorússia"
      },
      "rus": {
        "official": "Республика Беларусь",
        "common": "Беларусь"
      },
      "slk": {
        "official": "Bieloruská republika",
        "common": "Bielorusko"
      },
      "spa": {
        "official": "República de Belarús",
        "common": "Bielorrusia"
      },
      "swe": {
        "official": "Republiken Vitryssland",
        "common": "Belarus"
      },
      "tur": {
        "official": "Belarus Cumhuriyeti",
        "common": "Belarus"
      },
      "urd": {
        "official": "جمہوریہ بیلاروس",
        "common": "بیلاروس"
      },
      "zho": {
        "official": "白俄罗斯共和国",
        "common": "白俄罗斯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Belarusian",
        "m": "Belarusian"
      },
      "fra": {
        "f": "Biélorusse",
        "m": "Biélorusse"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/hk.png",
      "svg": "https://flagcdn.com/hk.svg"
    },
    "cca2": "HK",
    "capital": [
      "City of Victoria"
    ],
    "altSpellings": [
      "HK"
    ],
    "translations": {
      "ara": {
        "official": "منطقة هونغ كونغ الادارية التابعة لجمهورية الصين الشعبية",
        "common": "هونغ كونغ"
      },
      "bre": {
        "official": "Rannvro velestradurel arbennik Hong Kong eus Republik pobl Sina",
        "common": "Hong Kong"
      },
      "ces": {
        "official": "Zvláštní administrativní oblast Čínské lidové republiky Hongkong",
        "common": "Hongkong"
      },
      "cym": {
        "official": "Hong Kong Special Administrative Region of the People's Republic of China",
        "common": "Hong Kong"
      },
      "deu": {
        "official": "Sonderverwaltungszone Hongkong der Volksrepublik China",
        "common": "Hongkong"
      },
      "est": {
        "official": "Hongkongi erihalduspiirkond",
        "common": "Hongkong"
      },
      "fin": {
        "official": "Hong Kongin erityishallintoalue",
        "common": "Hongkong"
      },
      "fra": {
        "official": "Région administrative spéciale de Hong Kong de la République populaire de Chine",
        "common": "Hong Kong"
      },
      "hrv": {
        "official": "Hong Kong Posebnog upravnog područjaNarodne Republike Kine",
        "common": "Hong Kong"
      },
      "hun": {
        "official": "Hongkong",
        "common": "Hongkong"
      },
      "ita": {
        "official": "Hong Kong Regione amministrativa speciale della Repubblica Popolare Cinese",
        "common": "Hong Kong"
      },
      "jpn": {
        "official": "中華人民共和国香港特別行政区",
        "common": "香港"
      },
      "kor": {
        "official": "중화인민공화국 홍콩 특별행정구",
        "common": "홍콩"
      },
      "nld": {
        "official": "Hong Kong Speciale Administratieve Regio van de Volksrepubliek China",
        "common": "Hongkong"
      },
      "per": {
        "official": "هُنگ کُنگ",
        "common": "هُنگ کُنگ"
      },
      "pol": {
        "official": "Specjalny Region Administracyjny Chińskiej Republiki Ludowej Hongkong",
        "common": "Hongkong"
      },
      "por": {
        "official": "Hong Kong Região Administrativa Especial da República Popular da China",
        "common": "Hong Kong"
      },
      "rus": {
        "official": "Hong Kong Специальный административный район Китайской Народной Республики Китая",
        "common": "Гонконг"
      },
      "slk": {
        "official": "Špeciálna administratívna oblasťČínskej ľudovej republiky Hongkong",
        "common": "Hongkong"
      },
      "spa": {
        "official": "Hong Kong Región Administrativa Especial de la República Popular China",
        "common": "Hong Kong"
      },
      "swe": {
        "official": "Hongkong",
        "common": "Hongkong"
      },
      "tur": {
        "official": "Çin Halk Cumhuriyeti Hong Kong Özel İdari Bölgesi",
        "common": "Hong Kong"
      },
      "urd": {
        "official": "ہانگ کانگ عوامی جمہوریہ چین کا خصوصی انتظامی علاقہ",
        "common": "ہانگ کانگ"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Hong Konger",
        "m": "Hong Konger"
      },
      "fra": {
        "f": "Hongkongaise",
        "m": "Hongkongais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ls.png",
      "svg": "https://flagcdn.com/ls.svg"
    },
    "cca2": "LS",
    "capital": [
      "Maseru"
    ],
    "altSpellings": [
      "LS",
      "Kingdom of Lesotho",
      "Muso oa Lesotho"
    ],
    "translations": {
      "ara": {
        "official": "مملكة ليسوتو",
        "common": "ليسوتو"
      },
      "bre": {
        "official": "Rouantelezh Lesotho",
        "common": "Lesotho"
      },
      "ces": {
        "official": "Lesothské království",
        "common": "Lesotho"
      },
      "cym": {
        "official": "Kingdom of Lesotho",
        "common": "Lesotho"
      },
      "deu": {
        "official": "Königreich Lesotho",
        "common": "Lesotho"
      },
      "est": {
        "official": "Lesotho Kuningriik",
        "common": "Lesotho"
      },
      "fin": {
        "official": "Lesothon kuningaskunta",
        "common": "Lesotho"
      },
      "fra": {
        "official": "Royaume du Lesotho",
        "common": "Lesotho"
      },
      "hrv": {
        "official": "Kraljevina Lesoto",
        "common": "Lesoto"
      },
      "hun": {
        "official": "Lesothói Királyság",
        "common": "Lesotho"
      },
      "ita": {
        "official": "Regno del Lesotho",
        "common": "Lesotho"
      },
      "jpn": {
        "official": "レソト王国",
        "common": "レソト"
      },
      "kor": {
        "official": "레소토 왕국",
        "common": "레소토"
      },
      "nld": {
        "official": "Koninkrijk Lesotho",
        "common": "Lesotho"
      },
      "per": {
        "official": "پادشاهی لسوتو",
        "common": "لسوتو"
      },
      "pol": {
        "official": "Królestwo Lesotho",
        "common": "Lesotho"
      },
      "por": {
        "official": "Reino do Lesoto",
        "common": "Lesoto"
      },
      "rus": {
        "official": "Королевство Лесото",
        "common": "Лесото"
      },
      "slk": {
        "official": "Lesothské kráľovstvo",
        "common": "Lesotho"
      },
      "spa": {
        "official": "Reino de Lesotho",
        "common": "Lesotho"
      },
      "swe": {
        "official": "Konungariket Lesotho",
        "common": "Lesotho"
      },
      "tur": {
        "official": "Lesotho Krallığı",
        "common": "Lesotho"
      },
      "urd": {
        "official": "مملکتِ لیسوتھو",
        "common": "لیسوتھو"
      },
      "zho": {
        "official": "莱索托王国",
        "common": "莱索托"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mosotho",
        "m": "Mosotho"
      },
      "fra": {
        "f": "Lésothienne",
        "m": "Lésothien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fj.png",
      "svg": "https://flagcdn.com/fj.svg"
    },
    "cca2": "FJ",
    "capital": [
      "Suva"
    ],
    "altSpellings": [
      "FJ",
      "Viti",
      "Republic of Fiji",
      "Matanitu ko Viti",
      "Fijī Gaṇarājya"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية جزر فيجي",
        "common": "فيجي"
      },
      "bre": {
        "official": "Republik Fidji",
        "common": "Fidji"
      },
      "ces": {
        "official": "Republika Fidžijských ostrovů",
        "common": "Fidži"
      },
      "cym": {
        "official": "Republic of Fiji",
        "common": "Fiji"
      },
      "deu": {
        "official": "Republik Fidschi",
        "common": "Fidschi"
      },
      "est": {
        "official": "Fidži Vabariik",
        "common": "Fidži"
      },
      "fin": {
        "official": "Fidžin tasavalta",
        "common": "Fidži"
      },
      "fra": {
        "official": "République des Fidji",
        "common": "Fidji"
      },
      "hrv": {
        "official": "Republika Fidži",
        "common": "Fiđi"
      },
      "hun": {
        "official": "Fidzsi-szigeteki Köztársaság",
        "common": "Fidzsi-szigetek"
      },
      "ita": {
        "official": "Repubblica di Figi",
        "common": "Figi"
      },
      "jpn": {
        "official": "フィジー共和国",
        "common": "フィジー"
      },
      "kor": {
        "official": "피지 공화국",
        "common": "피지"
      },
      "nld": {
        "official": "Republiek Fiji",
        "common": "Fiji"
      },
      "per": {
        "official": "جمهوری جزایر فیجی",
        "common": "فیجی"
      },
      "pol": {
        "official": "Republika Fidżi",
        "common": "Fidżi"
      },
      "por": {
        "official": "República de Fiji",
        "common": "Fiji"
      },
      "rus": {
        "official": "Республика Фиджи",
        "common": "Фиджи"
      },
      "slk": {
        "official": "Fiǆijská republika",
        "common": "Fiǆi"
      },
      "spa": {
        "official": "República de Fiji",
        "common": "Fiyi"
      },
      "swe": {
        "official": "Republiken Fiji",
        "common": "Fiji"
      },
      "tur": {
        "official": "Fiji Cumhuriyeti",
        "common": "Fiji"
      },
      "urd": {
        "official": "جمہوریہ فجی",
        "common": "فجی"
      },
      "zho": {
        "official": "斐济共和国",
        "common": "斐济"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Fijian",
        "m": "Fijian"
      },
      "fra": {
        "f": "Fidjienne",
        "m": "Fidjien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/at.png",
      "svg": "https://flagcdn.com/at.svg"
    },
    "cca2": "AT",
    "capital": [
      "Vienna"
    ],
    "altSpellings": [
      "AT",
      "Osterreich",
      "Oesterreich"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية النمسا",
        "common": "النمسا"
      },
      "bre": {
        "official": "Republik Aostria",
        "common": "Aostria"
      },
      "ces": {
        "official": "Rakouská republika",
        "common": "Rakousko"
      },
      "cym": {
        "official": "Gweriniaeth Awstria",
        "common": "Awstria"
      },
      "deu": {
        "official": "Republik Österreich",
        "common": "Österreich"
      },
      "est": {
        "official": "Austria Vabariik",
        "common": "Austria"
      },
      "fin": {
        "official": "Itävallan tasavalta",
        "common": "Itävalta"
      },
      "fra": {
        "official": "République d'Autriche",
        "common": "Autriche"
      },
      "hrv": {
        "official": "Republika Austrija",
        "common": "Austrija"
      },
      "hun": {
        "official": "Ausztria",
        "common": "Ausztria"
      },
      "ita": {
        "official": "Repubblica d'Austria",
        "common": "Austria"
      },
      "jpn": {
        "official": "オーストリア共和国",
        "common": "オーストリア"
      },
      "kor": {
        "official": "오스트리아 공화국",
        "common": "오스트리아"
      },
      "nld": {
        "official": "Republiek Oostenrijk",
        "common": "Oostenrijk"
      },
      "per": {
        "official": "جمهوری اتریش",
        "common": "اتریش"
      },
      "pol": {
        "official": "Republika Austrii",
        "common": "Austria"
      },
      "por": {
        "official": "República da Áustria",
        "common": "Áustria"
      },
      "rus": {
        "official": "Австрийская Республика",
        "common": "Австрия"
      },
      "slk": {
        "official": "Rakúska republika",
        "common": "Rakúsko"
      },
      "spa": {
        "official": "República de Austria",
        "common": "Austria"
      },
      "swe": {
        "official": "Republiken Österrike",
        "common": "Österrike"
      },
      "tur": {
        "official": "Avusturya Cumhuriyeti",
        "common": "Avusturya"
      },
      "urd": {
        "official": "جمہوریہ آسٹریا",
        "common": "آسٹریا"
      },
      "zho": {
        "official": "奥地利共和国",
        "common": "奥地利"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Austrian",
        "m": "Austrian"
      },
      "fra": {
        "f": "Autrichienne",
        "m": "Autrichien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/co.png",
      "svg": "https://flagcdn.com/co.svg"
    },
    "cca2": "CO",
    "capital": [
      "Bogotá"
    ],
    "altSpellings": [
      "CO",
      "Republic of Colombia",
      "República de Colombia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كولومبيا",
        "common": "كولومبيا"
      },
      "bre": {
        "official": "Republik Kolombia",
        "common": "Kolombia"
      },
      "ces": {
        "official": "Kolumbijská republika",
        "common": "Kolumbie"
      },
      "cym": {
        "official": "Gweriniaeth Colombia",
        "common": "Colombia"
      },
      "deu": {
        "official": "Republik Kolumbien",
        "common": "Kolumbien"
      },
      "est": {
        "official": "Colombia Vabariik",
        "common": "Colombia"
      },
      "fin": {
        "official": "Kolumbian tasavalta",
        "common": "Kolumbia"
      },
      "fra": {
        "official": "République de Colombie",
        "common": "Colombie"
      },
      "hrv": {
        "official": "Republika Kolumbija",
        "common": "Kolumbija"
      },
      "hun": {
        "official": "Kolumbiai Köztársaság",
        "common": "Kolumbia"
      },
      "ita": {
        "official": "Repubblica di Colombia",
        "common": "Colombia"
      },
      "jpn": {
        "official": "コロンビア共和国",
        "common": "コロンビア"
      },
      "kor": {
        "official": "콜롬비아 공화국",
        "common": "콜롬비아"
      },
      "nld": {
        "official": "Republiek Colombia",
        "common": "Colombia"
      },
      "per": {
        "official": "جمهوری کلمبیا",
        "common": "کلمبیا"
      },
      "pol": {
        "official": "Republika Kolumbii",
        "common": "Kolumbia"
      },
      "por": {
        "official": "República da Colômbia",
        "common": "Colômbia"
      },
      "rus": {
        "official": "Республика Колумбия",
        "common": "Колумбия"
      },
      "slk": {
        "official": "Kolumbijská republika",
        "common": "Kolumbia"
      },
      "spa": {
        "official": "República de Colombia",
        "common": "Colombia"
      },
      "swe": {
        "official": "Republiken Colombia",
        "common": "Colombia"
      },
      "tur": {
        "official": "Kolombiya Cumhuriyeti",
        "common": "Kolombiya"
      },
      "urd": {
        "official": "جمہوریہ کولمبیا",
        "common": "کولمبیا"
      },
      "zho": {
        "official": "哥伦比亚共和国",
        "common": "哥伦比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Colombian",
        "m": "Colombian"
      },
      "fra": {
        "f": "Colombienne",
        "m": "Colombien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ga.png",
      "svg": "https://flagcdn.com/ga.svg"
    },
    "cca2": "GA",
    "capital": [
      "Libreville"
    ],
    "altSpellings": [
      "GA",
      "Gabonese Republic",
      "République Gabonaise"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية الغابون",
        "common": "الغابون"
      },
      "bre": {
        "official": "Republik Gabonat",
        "common": "Gabon"
      },
      "ces": {
        "official": "Gabonská republika",
        "common": "Gabon"
      },
      "cym": {
        "official": "Gabonese Republic",
        "common": "Gabon"
      },
      "deu": {
        "official": "Gabunische Republik",
        "common": "Gabun"
      },
      "est": {
        "official": "Gaboni Vabariik",
        "common": "Gabon"
      },
      "fin": {
        "official": "Gabonin tasavalta",
        "common": "Gabon"
      },
      "fra": {
        "official": "République gabonaise",
        "common": "Gabon"
      },
      "hrv": {
        "official": "Gabon Republika",
        "common": "Gabon"
      },
      "hun": {
        "official": "Gaboni Köztársaság",
        "common": "Gabon"
      },
      "ita": {
        "official": "Repubblica gabonese",
        "common": "Gabon"
      },
      "jpn": {
        "official": "ガボン共和国",
        "common": "ガボン"
      },
      "kor": {
        "official": "가봉 공화국",
        "common": "가봉"
      },
      "nld": {
        "official": "Republiek Gabon",
        "common": "Gabon"
      },
      "per": {
        "official": "جمهوری گابُن",
        "common": "گابن"
      },
      "pol": {
        "official": "Republika Gabońska",
        "common": "Gabon"
      },
      "por": {
        "official": "República do Gabão",
        "common": "Gabão"
      },
      "rus": {
        "official": "Габона Республика",
        "common": "Габон"
      },
      "slk": {
        "official": "Gabonská republika",
        "common": "Gabon"
      },
      "spa": {
        "official": "República de Gabón",
        "common": "Gabón"
      },
      "swe": {
        "official": "Republiken Gabon",
        "common": "Gabon"
      },
      "tur": {
        "official": "Gabon Cumhuriyeti",
        "common": "Gabon"
      },
      "urd": {
        "official": "جمہوریہ گیبون",
        "common": "گیبون"
      },
      "zho": {
        "official": "加蓬共和国",
        "common": "加蓬"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Gabonese",
        "m": "Gabonese"
      },
      "fra": {
        "f": "Gabonaise",
        "m": "Gabonais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sa.png",
      "svg": "https://flagcdn.com/sa.svg"
    },
    "cca2": "SA",
    "capital": [
      "Riyadh"
    ],
    "altSpellings": [
      "Saudi",
      "SA",
      "Kingdom of Saudi Arabia",
      "Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah"
    ],
    "translations": {
      "ara": {
        "official": "المملكة العربية السعودية",
        "common": "السعودية"
      },
      "bre": {
        "official": "Rouantelezh Arabia Saoudat",
        "common": "Arabia Saoudat"
      },
      "ces": {
        "official": "Saúdskoarabské království",
        "common": "Saúdská Arábie"
      },
      "cym": {
        "official": "Kingdom of Saudi Arabia",
        "common": "Saudi Arabia"
      },
      "deu": {
        "official": "Königreich Saudi-Arabien",
        "common": "Saudi-Arabien"
      },
      "est": {
        "official": "Saudi Araabia Kuningriik",
        "common": "Saudi Araabia"
      },
      "fin": {
        "official": "Saudi-Arabian kuningaskunta",
        "common": "Saudi-Arabia"
      },
      "fra": {
        "official": "Royaume d'Arabie Saoudite",
        "common": "Arabie Saoudite"
      },
      "hrv": {
        "official": "Kraljevina Saudijska Arabija",
        "common": "Saudijska Arabija"
      },
      "hun": {
        "official": "Szaúd-Arábia",
        "common": "Szaúd-Arábia"
      },
      "ita": {
        "official": "Arabia Saudita",
        "common": "Arabia Saudita"
      },
      "jpn": {
        "official": "サウジアラビア王国",
        "common": "サウジアラビア"
      },
      "kor": {
        "official": "사우디아라비아 왕국",
        "common": "사우디아라비아"
      },
      "nld": {
        "official": "Koninkrijk van Saoedi-Arabië",
        "common": "Saoedi-Arabië"
      },
      "per": {
        "official": "پادشاهی عربی سَعودی",
        "common": "عربستان سعودی"
      },
      "pol": {
        "official": "Królestwo Arabii Saudyjskiej",
        "common": "Arabia Saudyjska"
      },
      "por": {
        "official": "Reino da Arábia Saudita",
        "common": "Arábia Saudita"
      },
      "rus": {
        "official": "Королевство Саудовская Аравия",
        "common": "Саудовская Аравия"
      },
      "slk": {
        "official": "Saudskoarabské kráľovstvo",
        "common": "Saudská Arábia"
      },
      "spa": {
        "official": "Reino de Arabia Saudita",
        "common": "Arabia Saudí"
      },
      "swe": {
        "official": "Kungadömet Saudiarabien",
        "common": "Saudiarabien"
      },
      "tur": {
        "official": "Suudi Arabistan Krallığı",
        "common": "Suudi Arabistan"
      },
      "urd": {
        "official": "مملکتِ سعودی عرب",
        "common": "سعودی عرب"
      },
      "zho": {
        "official": "沙特阿拉伯王国",
        "common": "沙特阿拉伯"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Saudi Arabian",
        "m": "Saudi Arabian"
      },
      "fra": {
        "f": "Saoudienne",
        "m": "Saoudien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/gs.png",
      "svg": "https://flagcdn.com/gs.svg"
    },
    "cca2": "GS",
    "capital": [
      "King Edward Point"
    ],
    "altSpellings": [
      "GS",
      "South Georgia and the South Sandwich Islands"
    ],
    "translations": {
      "ara": {
        "official": "جورجيا الجنوبية وجزر ساندوتش الجنوبية",
        "common": "جورجيا الجنوبية"
      },
      "bre": {
        "official": "Georgia ar Su hag Inizi Sandwich ar Su",
        "common": "Georgia ar Su hag Inizi Sandwich ar Su"
      },
      "ces": {
        "official": "Jižní Georgie a Jižní Sandwichovy ostrovy",
        "common": "Jižní Georgie a Jižní Sandwichovy ostrovy"
      },
      "cym": {
        "official": "South Georgia and the South Sandwich Islands",
        "common": "South Georgia"
      },
      "deu": {
        "official": "Südgeorgien und die Südlichen Sandwichinseln",
        "common": "Südgeorgien und die Südlichen Sandwichinseln"
      },
      "est": {
        "official": "Lõuna-Georgia ja Lõuna-Sandwichi saared",
        "common": "Lõuna-Georgia ja Lõuna-Sandwichi saared"
      },
      "fin": {
        "official": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
        "common": "Etelä-Georgia ja Eteläiset Sandwichsaaret"
      },
      "fra": {
        "official": "Géorgie du Sud et les îles Sandwich du Sud",
        "common": "Géorgie du Sud-et-les Îles Sandwich du Sud"
      },
      "hrv": {
        "official": "Južna Džordžija i Otoci Južni Sendvič",
        "common": "Južna Georgija i otočje Južni Sandwich"
      },
      "hun": {
        "official": "Déli-Georgia és Déli-Sandwich-szigetek",
        "common": "Déli-Georgia és Déli-Sandwich-szigetek"
      },
      "ita": {
        "official": "Georgia del Sud e isole Sandwich del Sud",
        "common": "Georgia del Sud e Isole Sandwich Meridionali"
      },
      "jpn": {
        "official": "サウスジョージア·サウスサンドウィッチ諸島",
        "common": "サウスジョージア・サウスサンドウィッチ諸島"
      },
      "kor": {
        "official": "조지아",
        "common": "조지아"
      },
      "nld": {
        "official": "Zuid-Georgië en de Zuidelijke Sandwich-eilanden",
        "common": "Zuid-Georgia en Zuidelijke Sandwicheilanden"
      },
      "per": {
        "official": "جزایر جورجیای جنوبی و ساندویچ جنوبی",
        "common": "جزایر جورجیای جنوبی و ساندویچ جنوبی"
      },
      "pol": {
        "official": "Georgia Południowa i Sandwich Południowy",
        "common": "Georgia Południowa i Sandwich Południowy"
      },
      "por": {
        "official": "Geórgia do Sul e Sandwich do Sul",
        "common": "Ilhas Geórgia do Sul e Sandwich do Sul"
      },
      "rus": {
        "official": "Южная Георгия и Южные Сандвичевы острова",
        "common": "Южная Георгия и Южные Сандвичевы острова"
      },
      "slk": {
        "official": "Južná Georgia a Južné Sandwichove ostrovy",
        "common": "Južná Georgia a Južné Sandwichove ostrovy"
      },
      "spa": {
        "official": "Georgia del Sur y las Islas Sandwich del Sur",
        "common": "Islas Georgias del Sur y Sandwich del Sur"
      },
      "swe": {
        "official": "Sydgeorgien",
        "common": "Sydgeorgien"
      },
      "tur": {
        "official": "Güney Georgia ve Güney Sandwich Adaları",
        "common": "Güney Georgia ve Güney Sandwich Adaları"
      },
      "urd": {
        "official": "جنوبی جارجیا و جزائر جنوبی سینڈوچ",
        "common": "جنوبی جارجیا"
      },
      "zho": {
        "official": "南乔治亚岛和南桑威奇群岛",
        "common": "南乔治亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "South Georgian South Sandwich Islander",
        "m": "South Georgian South Sandwich Islander"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/et.png",
      "svg": "https://flagcdn.com/et.svg"
    },
    "cca2": "ET",
    "capital": [
      "Addis Ababa"
    ],
    "altSpellings": [
      "ET",
      "ʾĪtyōṗṗyā",
      "Federal Democratic Republic of Ethiopia",
      "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية إثيوبيا الفدرالية الديموقراطية",
        "common": "إثيوبيا"
      },
      "bre": {
        "official": "Republik Demokratel Kevredadel Etiopia",
        "common": "Etiopia"
      },
      "ces": {
        "official": "Etiopská federativní demokratická republika",
        "common": "Etiopie"
      },
      "cym": {
        "official": "Gweriniaeth Ddemocrataidd Ffederal Ethiopia",
        "common": "Ethiopia"
      },
      "deu": {
        "official": "Demokratische Bundesrepublik Äthiopien",
        "common": "Äthiopien"
      },
      "est": {
        "official": "Etioopia Demokraatlik Liitvabariik",
        "common": "Etioopia"
      },
      "fin": {
        "official": "Etiopian demokraattinen liittotasavalta",
        "common": "Etiopia"
      },
      "fra": {
        "official": "République fédérale démocratique d'Éthiopie",
        "common": "Éthiopie"
      },
      "hrv": {
        "official": "Savezna Demokratska Republika Etiopija",
        "common": "Etiopija"
      },
      "hun": {
        "official": "Etióp Szövetségi Demokratikus Köztársaság",
        "common": "Etiópia"
      },
      "ita": {
        "official": "Repubblica federale democratica di Etiopia",
        "common": "Etiopia"
      },
      "jpn": {
        "official": "エチオピア連邦民主共和国",
        "common": "エチオピア"
      },
      "kor": {
        "official": "에티오피아 연방 민주 공화국",
        "common": "에티오피아"
      },
      "nld": {
        "official": "Federale Democratische Republiek Ethiopië",
        "common": "Ethiopië"
      },
      "per": {
        "official": "جمهوری فدرال دموکراتیک اتیوپی",
        "common": "اِتیوپی"
      },
      "pol": {
        "official": "Federalna Demokratyczna Republika Etiopii",
        "common": "Etiopia"
      },
      "por": {
        "official": "República Federal Democrática da Etiópia",
        "common": "Etiópia"
      },
      "rus": {
        "official": "Федеративная Демократическая Республика Эфиопия",
        "common": "Эфиопия"
      },
      "slk": {
        "official": "Etiópska federatívna demokratická republika",
        "common": "Etiópia"
      },
      "spa": {
        "official": "República Democrática Federal de Etiopía",
        "common": "Etiopía"
      },
      "swe": {
        "official": "Demokratiska förbundsrepubliken Etiopien",
        "common": "Etiopien"
      },
      "tur": {
        "official": "Etiyopya Federal Demokratik Cumhuriyeti",
        "common": "Etiyopya"
      },
      "urd": {
        "official": "وفاقی جمہوری جمہوریہ ایتھوپیا",
        "common": "ایتھوپیا"
      },
      "zho": {
        "official": "埃塞俄比亚联邦民主共和国",
        "common": "埃塞俄比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Ethiopian",
        "m": "Ethiopian"
      },
      "fra": {
        "f": "Éthiopienne",
        "m": "Éthiopien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/bq.png",
      "svg": "https://flagcdn.com/bq.svg"
    },
    "cca2": "BQ",
    "capital": [
      "Kralendijk"
    ],
    "altSpellings": [
      "BES islands"
    ],
    "translations": {
      "ara": {
        "official": "بونير وسينت أوستاتيوس وسابا",
        "common": "الجزر الكاريبية الهولندية"
      },
      "bre": {
        "official": "Bonaire, Sint Eustatius ha Saba",
        "common": "Bonaire, Sint Eustatius ha Saba"
      },
      "ces": {
        "official": "Karibské Nizozemsko",
        "common": "Karibské Nizozemsko"
      },
      "cym": {
        "official": "Bonaire, Sint Eustatius and Saba",
        "common": "Caribbean Netherlands"
      },
      "deu": {
        "official": "Bonaire, Sint Eustatius und Saba",
        "common": "Karibische Niederlande"
      },
      "est": {
        "official": "Bonaire, Sint Eustatius ja Saba",
        "common": "Bonaire, Sint Eustatius ja Saba"
      },
      "fin": {
        "official": "Bonaire, Sint Eustatius ja Saba",
        "common": "Bonaire, Sint Eustatius ja Saba"
      },
      "fra": {
        "official": "Bonaire, Saint-Eustache et Saba",
        "common": "Pays-Bas caribéens"
      },
      "hrv": {
        "official": "Bonaire, Sint Eustatius i Saba",
        "common": "Bonaire, Sint Eustatius i Saba"
      },
      "hun": {
        "official": "Bonaire",
        "common": "Bonaire"
      },
      "ita": {
        "official": "Bonaire, Sint Eustatius e Saba",
        "common": "Paesi Bassi caraibici"
      },
      "jpn": {
        "official": "ボネール、シント・ユースタティウスおよびサバ",
        "common": "ボネール、シント・ユースタティウスおよびサバ"
      },
      "kor": {
        "official": "보네르, 신트외스타티위스, 사바",
        "common": "카리브 네덜란드"
      },
      "nld": {
        "official": "Bonaire, Sint Eustatius en Saba",
        "common": "Caribisch Nederland"
      },
      "per": {
        "official": "جزایر کارائیب هلند",
        "common": "جزایر کارائیب هلند"
      },
      "pol": {
        "official": "Bonaire, Sint Eustatius i Saba",
        "common": "Antyle Holenderskie"
      },
      "por": {
        "official": "Bonaire, Saba e Santo Eustáquio",
        "common": "Países Baixos Caribenhos"
      },
      "rus": {
        "official": "Бонэйр, Синт-Эстатиус и Саба",
        "common": "Карибские Нидерланды"
      },
      "slk": {
        "official": "Bonaire, Sint Eustatius a Saba",
        "common": "Bonaire, Sint Eustatius a Saba"
      },
      "spa": {
        "official": "Bonaire, San Eustaquio y Saba",
        "common": "Caribe Neerlandés"
      },
      "swe": {
        "official": "Bonaire, Sint Eustatius and Saba",
        "common": "Karibiska Nederländerna"
      },
      "tur": {
        "official": "Karayip Hollandası",
        "common": "Karayip Hollandası"
      },
      "urd": {
        "official": "بونایر، سینٹ ایوسٹائیس اور سابا",
        "common": "کیریبین نیدرلینڈز"
      },
      "zho": {
        "official": "荷蘭加勒比區",
        "common": "荷蘭加勒比區"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Dutch",
        "m": "Dutch"
      },
      "fra": {
        "f": "Néerlandaise",
        "m": "Néerlandais"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mc.png",
      "svg": "https://flagcdn.com/mc.svg"
    },
    "cca2": "MC",
    "capital": [
      "Monaco"
    ],
    "altSpellings": [
      "MC",
      "Principality of Monaco",
      "Principauté de Monaco"
    ],
    "translations": {
      "ara": {
        "official": "إمارة موناكو",
        "common": "موناكو"
      },
      "bre": {
        "official": "Priñselezh Monako",
        "common": "Monako"
      },
      "ces": {
        "official": "Monacké knížectví",
        "common": "Monako"
      },
      "cym": {
        "official": "Principality of Monaco",
        "common": "Monaco"
      },
      "deu": {
        "official": "Fürstentum Monaco",
        "common": "Monaco"
      },
      "est": {
        "official": "Monaco Vürstiriik",
        "common": "Monaco"
      },
      "fin": {
        "official": "Monacon ruhtinaskunta",
        "common": "Monaco"
      },
      "fra": {
        "official": "Principauté de Monaco",
        "common": "Monaco"
      },
      "hrv": {
        "official": "Kneževina Monako",
        "common": "Monako"
      },
      "hun": {
        "official": "Monacói Hercegség",
        "common": "Monaco"
      },
      "ita": {
        "official": "Principato di Monaco",
        "common": "Principato di Monaco"
      },
      "jpn": {
        "official": "モナコ公国",
        "common": "モナコ"
      },
      "kor": {
        "official": "모나코 공국",
        "common": "모나코"
      },
      "nld": {
        "official": "Vorstendom Monaco",
        "common": "Monaco"
      },
      "per": {
        "official": "شاهزاده‌نشین موناکو",
        "common": "موناکو"
      },
      "pol": {
        "official": "Księstwo Monako",
        "common": "Monako"
      },
      "por": {
        "official": "Principado do Mónaco",
        "common": "Mónaco"
      },
      "rus": {
        "official": "Княжество Монако",
        "common": "Монако"
      },
      "slk": {
        "official": "Monacké kniežatstvo",
        "common": "Monako"
      },
      "spa": {
        "official": "Principado de Mónaco",
        "common": "Mónaco"
      },
      "swe": {
        "official": "Furstendömet Monaco",
        "common": "Monaco"
      },
      "tur": {
        "official": "Monako Prensliği",
        "common": "Monako"
      },
      "urd": {
        "official": "جمہوریہ مناکو",
        "common": "موناکو"
      },
      "zho": {
        "official": "摩纳哥公国",
        "common": "摩纳哥"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Monegasque",
        "m": "Monegasque"
      },
      "fra": {
        "f": "Monégasque",
        "m": "Monégasque"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/kp.png",
      "svg": "https://flagcdn.com/kp.svg"
    },
    "cca2": "KP",
    "capital": [
      "Pyongyang"
    ],
    "altSpellings": [
      "KP",
      "Democratic People's Republic of Korea",
      "DPRK",
      "조선민주주의인민공화국",
      "Chosŏn Minjujuŭi Inmin Konghwaguk",
      "Korea, Democratic People's Republic of",
      "북한",
      "북조선"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية كوريا الديمقراطية الشعبية",
        "common": "كوريا الشمالية"
      },
      "bre": {
        "official": "Republik Poblel ha Demokratel Korea",
        "common": "Korea an Norzh"
      },
      "ces": {
        "official": "Korejská lidově demokratická republika",
        "common": "Severní Korea"
      },
      "cym": {
        "official": "Democratic People's Republic of Korea",
        "common": "North Korea"
      },
      "deu": {
        "official": "Demokratische Volksrepublik Korea",
        "common": "Nordkorea"
      },
      "est": {
        "official": "Korea Rahvademokraatlik Vabariik",
        "common": "Põhja-Korea"
      },
      "fin": {
        "official": "Korean demokraattinen kansantasavalta",
        "common": "Pohjois-Korea"
      },
      "fra": {
        "official": "République populaire démocratique de Corée",
        "common": "Corée du Nord"
      },
      "hrv": {
        "official": "Demokratska Narodna Republika Koreja",
        "common": "Sjeverna Koreja"
      },
      "hun": {
        "official": "Koreai Népi Demokratikus Köztársaság",
        "common": "Észak-Korea"
      },
      "ita": {
        "official": "Repubblica democratica popolare di Corea",
        "common": "Corea del Nord"
      },
      "jpn": {
        "official": "朝鮮民主主義人民共和国",
        "common": "朝鮮民主主義人民共和国"
      },
      "kor": {
        "official": "조선민주주의인민공화국",
        "common": "조선"
      },
      "nld": {
        "official": "Democratische Volksrepubliek Korea",
        "common": "Noord-Korea"
      },
      "per": {
        "official": "جمهوری دموکراتیک خلق کره",
        "common": "کُره شمالی"
      },
      "pol": {
        "official": "Koreańska Republika Ludowo-Demokratyczna",
        "common": "Korea Północna"
      },
      "por": {
        "official": "República Popular Democrática da Coreia",
        "common": "Coreia do Norte"
      },
      "rus": {
        "official": "Корейская Народно-Демократическая Республика Корея",
        "common": "Северная Корея"
      },
      "slk": {
        "official": "Kórejská ľudovodemokratická republika",
        "common": "Severná Kórea"
      },
      "spa": {
        "official": "República Popular Democrática de Corea",
        "common": "Corea del Norte"
      },
      "swe": {
        "official": "Demokratiska Folkrepubliken Korea",
        "common": "Nordkorea"
      },
      "tur": {
        "official": "Kore Demokratik Halk Cumhuriyeti",
        "common": "Kuzey Kore"
      },
      "urd": {
        "official": "جمہوری عوامی جمہوریہ کوریا",
        "common": "شمالی کوریا"
      },
      "zho": {
        "official": "朝鲜人民民主共和国",
        "common": "朝鲜"
      }
    },
    "demonyms": {
      "eng": {
        "f": "North Korean",
        "m": "North Korean"
      },
      "fra": {
        "f": "Nord-coréenne",
        "m": "Nord-coréen"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/it.png",
      "svg": "https://flagcdn.com/it.svg"
    },
    "cca2": "IT",
    "capital": [
      "Rome"
    ],
    "altSpellings": [
      "IT",
      "Italian Republic",
      "Repubblica italiana"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية الإيطالية",
        "common": "إيطاليا"
      },
      "bre": {
        "official": "Republik Italia",
        "common": "Italia"
      },
      "ces": {
        "official": "Italská republika",
        "common": "Itálie"
      },
      "cym": {
        "official": "Italian Republic",
        "common": "Italy"
      },
      "deu": {
        "official": "Italienische Republik",
        "common": "Italien"
      },
      "est": {
        "official": "Itaalia Vabariik",
        "common": "Itaalia"
      },
      "fin": {
        "official": "Italian tasavalta",
        "common": "Italia"
      },
      "fra": {
        "official": "République italienne",
        "common": "Italie"
      },
      "hrv": {
        "official": "talijanska Republika",
        "common": "Italija"
      },
      "hun": {
        "official": "Olasz Köztársaság",
        "common": "Olaszország"
      },
      "ita": {
        "official": "Repubblica italiana",
        "common": "Italia"
      },
      "jpn": {
        "official": "イタリア共和国",
        "common": "イタリア"
      },
      "kor": {
        "official": "이탈리아 공화국",
        "common": "이탈리아"
      },
      "nld": {
        "official": "Italiaanse Republiek",
        "common": "Italië"
      },
      "per": {
        "official": "جمهوری ایتالیا",
        "common": "ایتالیا"
      },
      "pol": {
        "official": "Republika Włoska",
        "common": "Włochy"
      },
      "por": {
        "official": "República Italiana",
        "common": "Itália"
      },
      "rus": {
        "official": "итальянская Республика",
        "common": "Италия"
      },
      "slk": {
        "official": "Talianska republika",
        "common": "Taliansko"
      },
      "spa": {
        "official": "República Italiana",
        "common": "Italia"
      },
      "swe": {
        "official": "Republiken Italien",
        "common": "Italien"
      },
      "tur": {
        "official": "İtalyan Cumhuriyeti",
        "common": "İtalya"
      },
      "urd": {
        "official": "جمہوریہ اطالیہ",
        "common": "اطالیہ"
      },
      "zho": {
        "official": "意大利共和国",
        "common": "意大利"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Italian",
        "m": "Italian"
      },
      "fra": {
        "f": "Italienne",
        "m": "Italien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/na.png",
      "svg": "https://flagcdn.com/na.svg"
    },
    "cca2": "NA",
    "capital": [
      "Windhoek"
    ],
    "altSpellings": [
      "NA",
      "Namibië",
      "Republic of Namibia"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية ناميبيا",
        "common": "ناميبيا"
      },
      "bre": {
        "official": "Republik Namibia",
        "common": "Namibia"
      },
      "ces": {
        "official": "Namibijská republika",
        "common": "Namibie"
      },
      "cym": {
        "official": "Republic of Namibia",
        "common": "Namibia"
      },
      "deu": {
        "official": "Republik Namibia",
        "common": "Namibia"
      },
      "est": {
        "official": "Namiibia Vabariik",
        "common": "Namiibia"
      },
      "fin": {
        "official": "Namibian tasavalta",
        "common": "Namibia"
      },
      "fra": {
        "official": "République de Namibie",
        "common": "Namibie"
      },
      "hrv": {
        "official": "Republika Namibija",
        "common": "Namibija"
      },
      "hun": {
        "official": "Namíbiai Köztársaság",
        "common": "Namíbia"
      },
      "ita": {
        "official": "Repubblica di Namibia",
        "common": "Namibia"
      },
      "jpn": {
        "official": "ナミビア共和国",
        "common": "ナミビア"
      },
      "kor": {
        "official": "나미비아 공화국",
        "common": "나미비아"
      },
      "nld": {
        "official": "Republiek Namibië",
        "common": "Namibië"
      },
      "per": {
        "official": "جمهوری نامیبیا",
        "common": "نامیبیا"
      },
      "pol": {
        "official": "Republika Namibii",
        "common": "Namibia"
      },
      "por": {
        "official": "República da Namíbia",
        "common": "Namíbia"
      },
      "rus": {
        "official": "Республика Намибия",
        "common": "Намибия"
      },
      "slk": {
        "official": "Namíbijská republika",
        "common": "Namíbia"
      },
      "spa": {
        "official": "República de Namibia",
        "common": "Namibia"
      },
      "swe": {
        "official": "Republiken Namibia",
        "common": "Namibia"
      },
      "tur": {
        "official": "Namibya Cumhuriyeti",
        "common": "Namibya"
      },
      "urd": {
        "official": "جمہوریہ نمیبیا",
        "common": "نمیبیا"
      },
      "zho": {
        "official": "纳米比亚共和国",
        "common": "纳米比亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Namibian",
        "m": "Namibian"
      },
      "fra": {
        "f": "Namibienne",
        "m": "Namibien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/mn.png",
      "svg": "https://flagcdn.com/mn.svg"
    },
    "cca2": "MN",
    "capital": [
      "Ulan Bator"
    ],
    "altSpellings": [
      "MN"
    ],
    "translations": {
      "ara": {
        "official": "جمهورية منغوليا",
        "common": "منغوليا"
      },
      "bre": {
        "official": "Mongolia",
        "common": "Mongolia"
      },
      "ces": {
        "official": "Stát Mongolsko",
        "common": "Mongolsko"
      },
      "cym": {
        "official": "Mongolia",
        "common": "Mongolia"
      },
      "deu": {
        "official": "Mongolei",
        "common": "Mongolei"
      },
      "est": {
        "official": "Mongoolia",
        "common": "Mongoolia"
      },
      "fin": {
        "official": "Mongolian tasavalta",
        "common": "Mongolia"
      },
      "fra": {
        "official": "Mongolie",
        "common": "Mongolie"
      },
      "hrv": {
        "official": "Mongolija",
        "common": "Mongolija"
      },
      "hun": {
        "official": "Mongólia",
        "common": "Mongólia"
      },
      "ita": {
        "official": "Mongolia",
        "common": "Mongolia"
      },
      "jpn": {
        "official": "モンゴル",
        "common": "モンゴル"
      },
      "kor": {
        "official": "몽골",
        "common": "몽골국"
      },
      "nld": {
        "official": "Mongolië",
        "common": "Mongolië"
      },
      "per": {
        "official": "مغولستان",
        "common": "مغولستان"
      },
      "pol": {
        "official": "Mongolia",
        "common": "Mongolia"
      },
      "por": {
        "official": "Mongólia",
        "common": "Mongólia"
      },
      "rus": {
        "official": "Монголия",
        "common": "Монголия"
      },
      "slk": {
        "official": "Mongolsko",
        "common": "Mongolsko"
      },
      "spa": {
        "official": "Mongolia",
        "common": "Mongolia"
      },
      "swe": {
        "official": "Mongoliet",
        "common": "Mongoliet"
      },
      "tur": {
        "official": "Moğolistan",
        "common": "Moğolistan"
      },
      "urd": {
        "official": "منگولیا",
        "common": "منگولیا"
      },
      "zho": {
        "official": "蒙古",
        "common": "蒙古"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Mongolian",
        "m": "Mongolian"
      },
      "fra": {
        "f": "Mongole",
        "m": "Mongol"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sb.png",
      "svg": "https://flagcdn.com/sb.svg"
    },
    "cca2": "SB",
    "capital": [
      "Honiara"
    ],
    "altSpellings": [
      "SB"
    ],
    "translations": {
      "ara": {
        "official": "جزر سليمان",
        "common": "جزر سليمان"
      },
      "bre": {
        "official": "Inizi Salomon",
        "common": "Inizi Salomon"
      },
      "ces": {
        "official": "Šalamounovy ostrovy",
        "common": "Šalamounovy ostrovy"
      },
      "cym": {
        "official": "Solomon Islands",
        "common": "Solomon Islands"
      },
      "deu": {
        "official": "Salomonen",
        "common": "Salomonen"
      },
      "est": {
        "official": "Saalomoni Saared",
        "common": "Saalomoni Saared"
      },
      "fin": {
        "official": "Salomonsaaret",
        "common": "Salomonsaaret"
      },
      "fra": {
        "official": "Îles Salomon",
        "common": "Îles Salomon"
      },
      "hrv": {
        "official": "Solomonski Otoci",
        "common": "Solomonski Otoci"
      },
      "hun": {
        "official": "Salamon-szigetek",
        "common": "Salamon-szigetek"
      },
      "ita": {
        "official": "Isole Salomone",
        "common": "Isole Salomone"
      },
      "jpn": {
        "official": "ソロモン諸島",
        "common": "ソロモン諸島"
      },
      "kor": {
        "official": "솔로몬 제도",
        "common": "솔로몬 제도"
      },
      "nld": {
        "official": "Solomon eilanden",
        "common": "Salomonseilanden"
      },
      "per": {
        "official": "جزایر سلیمان",
        "common": "جزایر سلیمان"
      },
      "pol": {
        "official": "Wyspy Salomona",
        "common": "Wyspy Salomona"
      },
      "por": {
        "official": "Ilhas Salomão",
        "common": "Ilhas Salomão"
      },
      "rus": {
        "official": "Соломоновы острова",
        "common": "Соломоновы Острова"
      },
      "slk": {
        "official": "Salomonove ostrovy",
        "common": "Salomonove ostrovy"
      },
      "spa": {
        "official": "islas Salomón",
        "common": "Islas Salomón"
      },
      "swe": {
        "official": "Salomonöarna",
        "common": "Salomonöarna"
      },
      "tur": {
        "official": "Solomon Adaları",
        "common": "Solomon Adaları"
      },
      "urd": {
        "official": "جزائر سلیمان",
        "common": "جزائر سلیمان"
      },
      "zho": {
        "official": "所罗门群岛",
        "common": "所罗门群岛"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Solomon Islander",
        "m": "Solomon Islander"
      },
      "fra": {
        "f": "Salomonienne",
        "m": "Salomonien"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ye.png",
      "svg": "https://flagcdn.com/ye.svg"
    },
    "cca2": "YE",
    "capital": [
      "Sana'a"
    ],
    "altSpellings": [
      "YE",
      "Yemeni Republic",
      "al-Jumhūriyyah al-Yamaniyyah"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية اليمنية",
        "common": "اليمن"
      },
      "bre": {
        "official": "Republik Yemen",
        "common": "Yemen"
      },
      "ces": {
        "official": "Jemenská republika",
        "common": "Jemen"
      },
      "cym": {
        "official": "Republic of Yemen",
        "common": "Yemen"
      },
      "deu": {
        "official": "Republik Jemen",
        "common": "Jemen"
      },
      "est": {
        "official": "Jeemeni Vabariik",
        "common": "Jeemen"
      },
      "fin": {
        "official": "Jemenin tasavalta",
        "common": "Jemen"
      },
      "fra": {
        "official": "République du Yémen",
        "common": "Yémen"
      },
      "hrv": {
        "official": "Republika Jemen",
        "common": "Jemen"
      },
      "hun": {
        "official": "Jemeni Köztársaság",
        "common": "Jemen"
      },
      "ita": {
        "official": "Repubblica dello Yemen",
        "common": "Yemen"
      },
      "jpn": {
        "official": "イエメン共和国",
        "common": "イエメン"
      },
      "kor": {
        "official": "예멘 공화국",
        "common": "예멘"
      },
      "nld": {
        "official": "Republiek Jemen",
        "common": "Jemen"
      },
      "per": {
        "official": "جمهوری یمن",
        "common": "یمن"
      },
      "pol": {
        "official": "Republika Jemeńska",
        "common": "Jemen"
      },
      "por": {
        "official": "República do Iêmen",
        "common": "Iémen"
      },
      "rus": {
        "official": "Йеменская Республика",
        "common": "Йемен"
      },
      "slk": {
        "official": "Jemenská republika",
        "common": "Jemen"
      },
      "spa": {
        "official": "República de Yemen",
        "common": "Yemen"
      },
      "swe": {
        "official": "Republiken Jemen",
        "common": "Jemen"
      },
      "tur": {
        "official": "Yemen Cumhuriyeti",
        "common": "Yemen"
      },
      "urd": {
        "official": "جمہوریہ یمن",
        "common": "یمن"
      },
      "zho": {
        "official": "也门共和国",
        "common": "也门"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Yemeni",
        "m": "Yemeni"
      },
      "fra": {
        "f": "Yéménite",
        "m": "Yéménite"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/ro.png",
      "svg": "https://flagcdn.com/ro.svg"
    },
    "cca2": "RO",
    "capital": [
      "Bucharest"
    ],
    "altSpellings": [
      "RO",
      "Rumania",
      "Roumania",
      "România"
    ],
    "translations": {
      "ara": {
        "official": "رومانيا",
        "common": "رومانيا"
      },
      "bre": {
        "official": "Roumania",
        "common": "Roumania"
      },
      "ces": {
        "official": "Rumunsko",
        "common": "Rumunsko"
      },
      "cym": {
        "official": "Romania",
        "common": "Romania"
      },
      "deu": {
        "official": "Rumänien",
        "common": "Rumänien"
      },
      "est": {
        "official": "Rumeenia",
        "common": "Rumeenia"
      },
      "fin": {
        "official": "Romania",
        "common": "Romania"
      },
      "fra": {
        "official": "Roumanie",
        "common": "Roumanie"
      },
      "hrv": {
        "official": "Rumunija",
        "common": "Rumunjska"
      },
      "hun": {
        "official": "Románia",
        "common": "Románia"
      },
      "ita": {
        "official": "Romania",
        "common": "Romania"
      },
      "jpn": {
        "official": "ルーマニア",
        "common": "ルーマニア"
      },
      "kor": {
        "official": "루마니아",
        "common": "루마니아"
      },
      "nld": {
        "official": "Roemenië",
        "common": "Roemenië"
      },
      "per": {
        "official": "رومانی",
        "common": "رومانی"
      },
      "pol": {
        "official": "Rumunia",
        "common": "Rumunia"
      },
      "por": {
        "official": "Romênia",
        "common": "Roménia"
      },
      "rus": {
        "official": "Румыния",
        "common": "Румыния"
      },
      "slk": {
        "official": "Rumunsko",
        "common": "Rumunsko"
      },
      "spa": {
        "official": "Rumania",
        "common": "Rumania"
      },
      "swe": {
        "official": "Rumänien",
        "common": "Rumänien"
      },
      "tur": {
        "official": "Romanya",
        "common": "Romanya"
      },
      "urd": {
        "official": "رومانیہ",
        "common": "رومانیہ"
      },
      "zho": {
        "official": "罗马尼亚",
        "common": "罗马尼亚"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Romanian",
        "m": "Romanian"
      },
      "fra": {
        "f": "Roumaine",
        "m": "Roumain"
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/sj.png",
      "svg": "https://flagcdn.com/sj.svg"
    },
    "cca2": "SJ",
    "capital": [
      "Longyearbyen"
    ],
    "altSpellings": [
      "SJ",
      "Svalbard and Jan Mayen Islands"
    ],
    "translations": {
      "ara": {
        "official": "سفالبارد ويان ماين",
        "common": "سفالبارد ويان ماين"
      },
      "bre": {
        "official": "Svalbard ha Jan Mayen",
        "common": "Svalbard ha Jan Mayen"
      },
      "ces": {
        "official": "Špicberky a Jan Mayen",
        "common": "Špicberky a Jan Mayen"
      },
      "cym": {
        "official": "Svalbard og Jan Mayen",
        "common": "Svalbard and Jan Mayen"
      },
      "deu": {
        "official": "Spitzbergen und Jan Mayen",
        "common": "Spitzbergen und Jan Mayen"
      },
      "est": {
        "official": "Svalbard",
        "common": "Svalbard"
      },
      "fin": {
        "official": "Huippuvuoret",
        "common": "Huippuvuoret"
      },
      "fra": {
        "official": "Jan Mayen Svalbard",
        "common": "Svalbard et Jan Mayen"
      },
      "hrv": {
        "official": "Svalbard og Jan Mayen",
        "common": "Svalbard i Jan Mayen"
      },
      "hun": {
        "official": "Svalbard és Jan Mayen",
        "common": "Svalbard és Jan Mayen"
      },
      "ita": {
        "official": "Svalbard og Jan Mayen",
        "common": "Svalbard e Jan Mayen"
      },
      "jpn": {
        "official": "スバールバル諸島OGヤンマイエン",
        "common": "スヴァールバル諸島およびヤンマイエン島"
      },
      "kor": {
        "official": "스발바르 얀마옌 제도",
        "common": "스발바르 얀마옌 제도"
      },
      "nld": {
        "official": "Svalbard og Jan Mayen",
        "common": "Svalbard en Jan Mayen"
      },
      "per": {
        "official": "سوالبارد و یان ماین",
        "common": "سوالبارد و یان ماین"
      },
      "pol": {
        "official": "Svalbard i Jan Mayen",
        "common": "Svalbard i Jan Mayen"
      },
      "por": {
        "official": "Svalbard og Jan Mayen",
        "common": "Ilhas Svalbard e Jan Mayen"
      },
      "rus": {
        "official": "Свальбарда ог Ян-Майен",
        "common": "Шпицберген и Ян-Майен"
      },
      "slk": {
        "official": "Svalbard a Jan Mayen",
        "common": "Svalbard a Jan Mayen"
      },
      "spa": {
        "official": "Svalbard og Jan Mayen",
        "common": "Islas Svalbard y Jan Mayen"
      },
      "swe": {
        "official": "Svalbard och Jan Mayen",
        "common": "Svalbard och Jan Mayen"
      },
      "tur": {
        "official": "Svalbard ve Jan Mayen",
        "common": "Svalbard ve Jan Mayen"
      },
      "urd": {
        "official": "سوالبارڈ اور جان میئن",
        "common": "سوالبارڈ اور جان میئن"
      },
      "zho": {
        "official": "斯瓦尔巴特",
        "common": "斯瓦尔巴特"
      }
    },
    "demonyms": {
      "eng": {
        "f": "Norwegian",
        "m": "Norwegian"
      },
      "fra": {
        "f": "",
        "m": ""
      }
    }
  },
  {
    "flags": {
      "png": "https://flagcdn.com/w320/fr.png",
      "svg": "https://flagcdn.com/fr.svg"
    },
    "cca2": "FR",
    "capital": [
      "Paris"
    ],
    "altSpellings": [
      "FR",
      "French Republic",
      "République française"
    ],
    "translations": {
      "ara": {
        "official": "الجمهورية الفرنسية",
        "common": "فرنسا"
      },
      "bre": {
        "official": "Republik Frañs",
        "common": "Frañs"
      },
      "ces": {
        "official": "Francouzská republika",
        "common": "Francie"
      },
      "cym": {
        "official": "French Republic",
        "common": "France"
      },
      "deu": {
        "official": "Französische Republik",
        "common": "Frankreich"
      },
      "est": {
        "official": "Prantsuse Vabariik",
        "common": "Prantsusmaa"
      },
      "fin": {
        "official": "Ranskan tasavalta",
        "common": "Ranska"
      },
      "fra": {
        "official": "République française",
        "common": "France"
      },
      "hrv": {
        "official": "Francuska Republika",
        "common": "Francuska"
      },
      "hun": {
        "official": "Francia Köztársaság",
        "common": "Franciaország"
      },
      "ita": {
        "official": "Repubblica francese",
        "common": "Francia"
      },
      "jpn": {
        "official": "フランス共和国",
        "common": "フランス"
      },
      "kor": {
        "official": "프랑스 공화국",
        "common": "프랑스"
      },
      "nld": {
        "official": "Franse Republiek",
        "common": "Frankrijk"
      },
      "per": {
        "official": "جمهوری فرانسه",
        "common": "فرانسه"
      },
      "pol": {
        "official": "Republika Francuska",
        "common": "Francja"
      },
      "por": {
        "official": "República Francesa",
        "common": "França"
      },
      "rus": {
        "official": "Французская Республика",
        "common": "Франция"
      },
      "slk": {
        "official": "Francúzska republika",
        "common": "Francúzsko"
      },
      "spa": {
        "official": "República francés",
        "common": "Francia"
      },
      "swe": {
        "official": "Republiken Frankrike",
        "common": "Frankrike"
      },
      "tur": {
        "official": "Fransa Cumhuriyeti",
        "common": "Fransa"
      },
      "urd": {
        "official": "جمہوریہ فرانس",
        "common": "فرانس"
      },
      "zho": {
        "official": "法兰西共和国",
        "common": "法国"
      }
    },
    "demonyms": {
      "eng": {
        "f": "French",
        "m": "French"
      },
      "fra": {
        "f": "Française",
        "m": "Français"
      }
    }
  }
]

export const getCountries = () => {
  const countriesRaw = countriesValues.map((item) => {
    return {
      name: item.translations.fra.common,
      code: item.cca2,
      nationalities: item.demonyms.fra ? item.demonyms.fra : item.demonyms.eng
    }
  })
  let countries: Country[] = []
  let nationalities: Nationality = {}
  countriesRaw.forEach(country => {
    if (!countries.find(item => item.name === country.name)) {
      countries.push(country)
    }
    if (!nationalities[country.code]) {
      nationalities[country.code] = country.nationalities
    }
  })
 return {
    countries: countries.sort((a, b) => a.name.localeCompare(b.name)),
    nationalities: nationalities
  }
}

export const getNationality = (code: string | null | undefined, gender: 'm' | 'f' = "m") => {
  if (!code) {
    return ""
  }
  const country = countriesValues.find(elt => elt.cca2 === code)
  if (country) {
    return country.demonyms.fra[gender]
  }
  return code
}

export const getCountry = (code: string | null | undefined) => {
  if (!code) {
    return ""
  }
  const country = countriesValues.find(elt => elt.cca2 === code)
  if (country) {
    return country.translations.fra.common
  }
  return code
}