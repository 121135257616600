import { mystesApi as api } from "./index";
export const addTagTypes = ["applications"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      applicationsList: build.query<
        ApplicationsListApiResponse,
        ApplicationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/`,
          params: { session: queryArg.session, state: queryArg.state },
        }),
        providesTags: ["applications"],
      }),
      applicationsCreate: build.mutation<
        ApplicationsCreateApiResponse,
        ApplicationsCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/`,
          method: "POST",
          body: queryArg.application,
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsRetrieve: build.query<
        ApplicationsRetrieveApiResponse,
        ApplicationsRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/applications/${queryArg.id}/` }),
        providesTags: ["applications"],
      }),
      applicationsUpdate: build.mutation<
        ApplicationsUpdateApiResponse,
        ApplicationsUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/${queryArg.id}/`,
          method: "PUT",
          body: queryArg.application,
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsPartialUpdate: build.mutation<
        ApplicationsPartialUpdateApiResponse,
        ApplicationsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedApplication,
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsDestroy: build.mutation<
        ApplicationsDestroyApiResponse,
        ApplicationsDestroyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/${queryArg.id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["applications"],
      }),
      applicationsBulkCreate: build.mutation<
        ApplicationsBulkCreateApiResponse,
        ApplicationsBulkCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/applications/bulk/`,
          method: "POST",
          body: queryArg.bulk,
        }),
        invalidatesTags: ["applications"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mystesApi };
export type ApplicationsListApiResponse = /** status 200  */ Application[];
export type ApplicationsListApiArg = {
  session?: number;
  /** * `0` - Candidature reçue
   * `1` - Candidature validée
   * `2` - Candidat inscrit
   * `3` - Candidat refusé
   * `4` - Abandon */
  state?: 0 | 1 | 2 | 3 | 4;
};
export type ApplicationsCreateApiResponse = /** status 201  */ Application;
export type ApplicationsCreateApiArg = {
  application: Application;
};
export type ApplicationsRetrieveApiResponse = /** status 200  */ Application;
export type ApplicationsRetrieveApiArg = {
  /** A unique integer value identifying this application. */
  id: number;
};
export type ApplicationsUpdateApiResponse = /** status 200  */ Application;
export type ApplicationsUpdateApiArg = {
  /** A unique integer value identifying this application. */
  id: number;
  application: Application;
};
export type ApplicationsPartialUpdateApiResponse =
  /** status 200  */ Application;
export type ApplicationsPartialUpdateApiArg = {
  /** A unique integer value identifying this application. */
  id: number;
  patchedApplication: PatchedApplication;
};
export type ApplicationsDestroyApiResponse = unknown;
export type ApplicationsDestroyApiArg = {
  /** A unique integer value identifying this application. */
  id: number;
};
export type ApplicationsBulkCreateApiResponse = /** status 200  */ Application;
export type ApplicationsBulkCreateApiArg = {
  bulk: Bulk;
};
export type GenderEnum = "H" | "F";
export type EgeKnownEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type WhyApplyEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type Candidate = {
  id: string;
  display_name: string;
  picture?: string;
  resume?: string;
  gender: GenderEnum;
  firstname: string;
  lastname: string;
  birth_date?: string | null;
  birth_place?: string;
  birth_country?: string;
  nationality?: string;
  nationality2?: string;
  ine?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  linkedin?: string;
  high_school?: string;
  college?: string;
  current_studies?: string;
  native_language?: string;
  other_language?: string;
  other_language2?: string;
  other_language3?: string;
  last_jobs?: string;
  career_objectives?: string;
  strength?: string;
  weaknesses?: string;
  responsibilities?: string;
  motivation?: string;
  other_applications?: string;
  readings?: string;
  ege_known?: EgeKnownEnum;
  why_apply?: WhyApplyEnum;
};
export type StateEnum = 0 | 1 | 2 | 3 | 4;
export type Application = {
  id: number;
  state_name: string;
  last_change_user: string;
  candidate: Candidate;
  received_date: string;
  validated_date: string | null;
  enrolled_date: string | null;
  refused_date: string | null;
  dropped_date: string | null;
  interview_date?: string | null;
  state: StateEnum;
  notes?: string;
  last_change_date: string;
  legacy?: boolean;
  email_send?: boolean;
  session: number;
};
export type PatchedApplication = {
  id?: number;
  state_name?: string;
  last_change_user?: string;
  candidate?: Candidate;
  received_date?: string;
  validated_date?: string | null;
  enrolled_date?: string | null;
  refused_date?: string | null;
  dropped_date?: string | null;
  interview_date?: string | null;
  state?: StateEnum;
  notes?: string;
  last_change_date?: string;
  legacy?: boolean;
  email_send?: boolean;
  session?: number;
};
export type ActionEnum = "delete" | "copy" | "move";
export type Bulk = {
  ids: number[];
  session: number | null;
  action: ActionEnum;
};
export const {
  useApplicationsListQuery,
  useLazyApplicationsListQuery,
  useApplicationsCreateMutation,
  useApplicationsRetrieveQuery,
  useLazyApplicationsRetrieveQuery,
  useApplicationsUpdateMutation,
  useApplicationsPartialUpdateMutation,
  useApplicationsDestroyMutation,
  useApplicationsBulkCreateMutation,
} = injectedRtkApi;
