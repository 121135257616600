import {StateEnum} from "../api/applications";
import {EgeKnownEnum, WhyApplyEnum} from "../api/candidates";

export const stateToColor = (state: StateEnum) => {
  switch (state) {
    case 0:
      return "warning"
    case 1:
      return "info"
    case 2:
      return "success"
    case 3:
      return "error"
    case 4:
      return "error"
  }
  return "primary"
}

export const stateToLabel = (state: StateEnum) => {
  switch (state) {
    case 0:
      return "Candidature reçue"
    case 1:
      return "Candidature validée"
    case 2:
      return "Candidat inscrit"
    case 3:
      return "Candidat refusé"
    case 4:
      return "Abandon"
  }
}

export const getApplicationStates = () => {
  let states: StateEnum[] = [0, 1, 2, 3, 4]
  return states.map((state) => {
    return {
      value: state,
      name: stateToLabel(state),
      color: stateToColor(state)
    }
  });
}

export const WhyApplyToLabel = (value: WhyApplyEnum | undefined) => {
  switch (value) {
    case 0:
      return "Autre"
    case 1:
      return "Le réseau et débouchés"
    case 2:
      return "La connaissance produite par l'EGE / AEGE"
    case 3:
      return "La notoriété et le classement de l'École"
    case 4:
      return "Une recommandation"
    case 5:
      return "La possibilité d'alternance"
    case 6:
      return "Le programme"
  }
}

export const EgeKnownToLabel = (state: EgeKnownEnum) => {
  switch (state) {
    case 0:
      return "Autre"
    case 1:
      return "Une recommandation d'un professionnel"
    case 2:
      return "Une recommandation d'un enseignant"
    case 3:
      return "Une recherche sur Internet (Google...)"
    case 4:
      return "Un salon étudiant ou professionnel"
    case 5:
      return "Une porte ouverte EGE"
    case 6:
      return "Par le portail de l'IE"
    case 7:
      return "Un article ou étude, ouvrage produit par l'École"
    case 8:
      return "Un article de presse portant sur l'École"
    case 9:
      return "Une publicité digitale ( Linkedin, Facebook)"
    case 10:
      return "Un événement EGE / AEGE (Conférences, Carré, Gala...)"
    case 11:
      return "Les réseaux sociaux de l'École ou du réseau (Linkedin, Facebook, X, Instagram, Youtube)"
    case 12:
      return "Une recommandation d'un étudiant ou d'un alumni"
  }
}

export const EGE_KNOWN_LABELS: string[] = [
  "Autre",
  "Une recommandation d'un professionnel",
  "Une recommandation d'un enseignant",
  "Une recherche sur Internet (Google...)",
  "Un salon étudiant ou professionnel",
  "Une porte ouverte EGE",
  "Par le portail de l'IE",
  "Un article ou étude, ouvrage produit par l'École",
  "Un article de presse portant sur l'École",
  "Une publicité digitale ( Linkedin, Facebook)",
  "Un événement EGE / AEGE (Conférences, Carré, Gala...)",
  "Les réseaux sociaux de l'École ou du réseau (Linkedin, Facebook, X, Instagram, Youtube)",
  "Une recommandation d'un étudiant ou d'un alumni",
]

export const WHY_APPLY_LABELS: string[] = [
  "Autre",
  "Le réseau et débouchés",
  "La connaissance produite par l'EGE / AEGE",
  "La notoriété et le classement de l'École",
  "Une recommandation",
  "La possibilité d'alternance",
  "Le programme"
]